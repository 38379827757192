import { IProductData } from 'interfaces/IProductData';
import React from 'react';
import Grid from '@material-ui/core/Grid/Grid';
import { paymentService } from 'services/PaymentService';
import { BaseComponent, IStateBase } from 'components/BaseComponent';
import { getCdnImage } from '../../../functions';
import { cartService } from 'services/CartService';
import { ICartModel } from 'interfaces/ICartModel';
import { FieldValidation } from 'components/Field';
import { WithStyles } from 'shared/decorators/withStyles';
import PriceCoupon from './PriceCoupon';
import { historyService } from 'services/HistoryService';

export enum enLayout {
  column = 'column',
  row = 'row'
}

interface IPriceProps {
  cart: IProductData;
  circle?: boolean;
  showConfigSelectors?: boolean;
  showBorder?: boolean;
  layout?: enLayout;
  hideMultipleInstallments?: boolean;
  isTicket?: boolean;
}

interface IState extends IStateBase {
  justTitle: boolean;
  payment: ICartModel;
  changeCard: boolean;
}

@WithStyles(() => ({
  component: {
    '& button, & a': {
      cursor: 'pointer'
    },

    '&.chk-payment-option': {
      position: 'relative',

      '& .row': {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
      },

      '&.show-border': {
        padding: '2%',
        cursor: 'pointer',
        borderRadius: '4px',
        boxShadow: '0 0 5px 0 rgba(0, 0, 0, .2)'
      },

      '& .chk-header-title': {
        textAlign: 'left',
        fontSize: '1.4rem',
        lineHeight: '1.8rem',
        margin: '0 0 8px 0',
        padding: 0,
        wordBreak: 'break-word'
      },

      '& .chk-parts': {
        fontSize: '1.5rem',
        color: 'rgb(51, 51, 51)',
        '&.multiple-installments-free-physical': {
          margin: '0 0 12px 0'
        },
        '& .chk-free-physical': {
          display: 'flex',
          flexDirection: 'column'
        },
        '&.end': {
          textAlign: 'end',
          width: '50%',
          float: 'right'
        },
        '& .chk-number-parts': {
          fontSize: '.85rem',
          color: '#333'
        },

        '& .chk-small-price': {
          fontSize: '.75rem'
        }
      },

      '& .chk-full': {
        '& .chk-number-price': {
          fontSize: '1.5rem'
        },

        '& .chk-full-price': {
          fontSize: '1.5rem',
          color: 'rgb(51, 51, 51)'
        },

        '& .tax': {
          color: '#333',
          fontSize: '.75rem',

          '& .small-text': {
            fontSize: '.875rem',
            color: '#333'
          }
        }
      },

      '& .chk-currency-language': {
        height: '2rem',
        width: '20rem',
        float: 'right'
      }
    },

    '& .chk-product-infos': {
      display: 'flex',
      alignItems: 'center',

      '& img': {
        maxHeight: '9rem',
        height: 'auto',
        maxWidth: '9rem',
        width: 'auto',
        margin: '0 3% 0 0',
        boxShadow: 'none',
        border: '1px solid #e2e2e2'
      }
    },

    '& .freeShipping': {
      justifyContent: 'flex-start'
    }
  }
}))
export default class Price extends BaseComponent<IState, IPriceProps> {
  constructor(props: IPriceProps) {
    super(props);

    this.state = {
      justTitle: false,
      payment: undefined,
      changeCard: false
    };
  }

  componentDidMount() {
    paymentService
      .getPaymentResult()
      .take(1)
      .subscribe(() => {
        this.setState({ justTitle: true });
      });

    this.observeState({ payment: undefined }, paymentService.getCart());
  }

  setValueOnCart = (value: any) => {
    const newValue = { value: Number(value.replace(/\D+/gi, '')) / 100 };
    const price = { price: Number(value.replace(/\D+/gi, '')) / 100 };
    cartService.setCart(this.props.cart, price);
    paymentService.setPaymentData(0, newValue);
  };

  render() {
    const { cart, isTicket, classes, showBorder } = this.props;
    const { justTitle, payment } = this.state;
    const { installments, config } = cart;
    const isStepAskAddressAfterPayment = historyService.hasAskAddressAfterPaymentInUrl();

    if (!this.state || !installments || !payment || isStepAskAddressAfterPayment) {
      return null;
    }

    const image = getCdnImage(config.logo);

    return (
      <div className={`chk-payment-option ${classes.component} ${showBorder && 'show-border'}`}>
        <FieldValidation.Provider value={this.registerFields}>
          <Grid item xs={12}>
            {justTitle ? (
              <Grid item xs={12}>
                <h4 className="chk-header-title">{cart.content.title}</h4>
              </Grid>
            ) : (
              <Grid item xs={12} sm={12} className="chk-product-infos">
                {!isTicket && image && <img src={image} id="logo-content" />}
                <PriceCoupon cart={cart} />
              </Grid>
            )}
          </Grid>
        </FieldValidation.Provider>
      </div>
    );
  }
}
