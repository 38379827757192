import { IS_SHOPIFY } from 'settings';
import { Observable } from 'rxjs';
import { apiService } from 'shared/services';
import { IUserAddressModel } from 'interfaces/IUserAddressModel';

export class ShopifyService {
  getShopifyCartData(): Observable<any> {
    if (!IS_SHOPIFY) {
      return Observable.of(null);
    }

    return apiService.get(`${window.location.origin}/cart.js`);
  }

  clearCartData(): void {
    if (!IS_SHOPIFY) {
      return;
    }

    apiService.get(`${window.location.origin}/cart/clear.js`).subscribe();
  }

  getShippingData(address: IUserAddressModel) {
    if (!IS_SHOPIFY || !address) {
      return Observable.of(null);
    }

    const query = `shipping_address[zip]=${address.postalCode}
    &shipping_address[country]=${address.country ? address.country : 'BR'}&shipping_address[province]=${address.province}`;

    return apiService.get(`${window.location.origin}/cart/shipping_rates.json?${query}`);
  }
}

// tslint:disable-next-line:variable-name
export const ShopifyServiceFactory = () => new ShopifyService();
export const shopifyService = ShopifyServiceFactory();
export default shopifyService;
