import React from 'react';
import { BaseComponent, IStateBase } from 'components/BaseComponent';
import Grid from '@material-ui/core/Grid/Grid';
import MultipleInstallments from 'components/Cart/Price/MultipleInstallments';
import { IProductData } from 'interfaces/IProductData';
import Installments from 'components/Cart/MainForm/Payment/PaymentForm/CreditCardForm/Installments';
import CreditCard from 'components/Cart/Templates/Mobile/OneClickBuy/CreditCard';
import { paymentService } from 'services/PaymentService';
import { cartService } from 'services/CartService';
import { Subscription } from 'components/Cart/Price/Subscription';
import AcceptTerms from 'components/AcceptTerms';
import { enPaymentType } from 'enums/enPaymentType';
import WithoutInstallment from 'components/Cart/Price/WithoutInstallment';
import PaymentButton from 'components/Cart/PaymentButton';
import { FieldValidation } from 'components/Field';
import { TicketTimer } from 'components/Cart/TicketTimer';
import Paper from '@material-ui/core/Paper/Paper';
import { enProductType } from 'enums/enProductType';
import QuantityForm from 'components/Cart/Physical/QuantityForm';
import { IS_ECOMMERCE } from 'settings';
import OrderBump from '../../../../OrderBump/index';
import OpenValue from 'components/Cart/OpenValue';
import { ticketService } from 'services/TicketService';
import Summary from '../../../../Summary/index';
import { getPaymentButtonMessage, unescapeHtml } from 'functions';
import { ICartModel } from 'interfaces/ICartModel';
import { AcceptProducerTerms } from 'components/AcceptProducerTerms';
import Coupon from 'components/Cart/Coupon';
import OrderDetails from 'components/OrderDetails';

interface IProps {
  onPay?: any;
  cart?: IProductData;
  disableButton?: boolean;
}

interface IState extends IStateBase {
  showTimer: boolean;
  payment: ICartModel;
  isMultipleCards: boolean;
  messageButton: string;
}

export class ContentOneClickBuy extends BaseComponent<IState, IProps> {
  constructor(props: any) {
    super(props);
    this.state = { showTimer: false, payment: null, isMultipleCards: false, messageButton: '' };
  }

  componentDidMount() {
    this.observeState({ payment: null }, paymentService.getCart());
    this.observeState({ isMultipleCards: false }, paymentService.isMultipleCreditCards());
    ticketService
      .show()
      .bindComponent(this)
      .subscribe((showTimer) => {
        this.setState({ showTimer });
      });

    paymentService
      .getCart()
      .combineLatest(cartService.getCart(), cartService.getConfig())
      .bindComponent(this)
      .subscribe(([payment, cart, config]) => {
        this.setState({ messageButton: getPaymentButtonMessage(payment, cart, config) });
      });
  }

  finish = async () => {
    if (await this.isFormValid()) {
      this.props.onPay();
    }
  };

  render() {
    const { cart } = this.props;
    const { payment, showTimer, isMultipleCards, messageButton } = this.state;
    if (!this.state || !payment) {
      return null;
    }

    return (
      <div>
        <FieldValidation.Provider value={this.registerFields}>
          {showTimer && enProductType.TICKET === cart.config.productType && (
            <Paper className="ticketTimer">
              <TicketTimer />
            </Paper>
          )}
          <Grid className="chk-oneClickBuy__product" container direction="row" justify="space-between" alignItems="center">
            <Grid item xs={6} className="chk-oneClickBuy__title">
              <h1 className="product-name">{unescapeHtml(cart.content.title)}</h1>
            </Grid>
            <Grid item xs={6} className="chk-oneClickBuy__productPrice">
              {[enPaymentType.NORMAL, enPaymentType.ECOMMERCE].includes(cart.config.paymentType) &&
                (payment.payment[0].numberInstallments > 1 && !isMultipleCards ? (
                  <MultipleInstallments cart={cart} />
                ) : (
                  <WithoutInstallment cart={cart} />
                ))}
              {cart.config.paymentType === enPaymentType.SUBSCRIPTION && <Subscription payment={payment} cart={cart} />}
            </Grid>
            <Grid item xs={12}>
              <OpenValue />
            </Grid>
            {enProductType.TICKET === cart.config.productType && !IS_ECOMMERCE && (
              <div className="quantityContent">
                <label>Qtde</label>
                <QuantityForm hideLabel />
              </div>
            )}
          </Grid>
          <Grid item xs={12} className="chk-oneClickBuy__coupon">
            <Coupon />
          </Grid>

          <Grid item xs={12} className="chk-oneClickBuy__content">
            <div className="chk-oneClickBuy__form">
              <OrderDetails user={cart.user} />
              <div className="chk-oneClickBuy__card">
                <CreditCard creditCard={cart.user.creditCard} askCVV={cart.config.askCVV} />
              </div>
              <Installments index={0} />
              <Grid item xs={12}>
                <OrderBump />
                <Summary />
                {cart.config.paymentType == enPaymentType.SUBSCRIPTION && <AcceptTerms />}
                <PaymentButton finish={this.finish} disabledButton={this.props.disableButton} messageButton={messageButton} singleStep={true} />
                <AcceptProducerTerms termsUrl={cart.config.termsUrl} />
              </Grid>
            </div>
          </Grid>
        </FieldValidation.Provider>
      </div>
    );
  }
}
