import * as React from 'react';
import { BaseComponent } from 'components/BaseComponent';
import FieldText from 'components/Field/Text';
import Translate from 'components/Translate';
import { IPaymentModel } from 'interfaces/IPaymentModel';
import { paymentService } from 'services/PaymentService';
import FormControlLabel from '@material-ui/core/FormControlLabel/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox/Checkbox';
import { WithStyles } from 'shared/decorators/withStyles';

interface IState {
  model: IPaymentModel;
  allowEduzzWallet: boolean;
}
interface IProps {
  classes?: any;
}

@WithStyles(() => ({
  component: {
    '& .wallet_description span': {
      fontWeight: 'bold',
      fontSize: '0.75rem'
    }
  }
}))
export default class EduzzWallet extends BaseComponent<IState, IProps> {
  componentDidMount() {
    this.bindModel(paymentService.getPayment(0));
    paymentService.setBlockPayment(true);
  }

  componentWillUnmount() {
    paymentService.setBlockPayment(false);
  }

  setEmail = (email: string) => {
    paymentService.setPaymentData(0, { email });
  };

  setPassword = (password: string) => {
    paymentService.setPaymentData(0, { password });
  };

  allowEduzzWallet = (e: any) => {
    this.setState({ allowEduzzWallet: e.target.checked });
    paymentService.setBlockPayment(!e.target.checked);
  };

  render() {
    if (!this.state) {
      return null;
    }

    const { model, allowEduzzWallet } = this.state;
    const { classes } = this.props;

    return (
      <div className={classes.Component}>
        <div className="chk-paymentForm__boletoMessage">
          <h3 className="wallet_description">
            <Translate term="Utilizando Saldo Eduzz" />
          </h3>

          <Translate term="Se você é um produtor ou afiliado na Eduzz você pode usar o seu saldo disponível para realizar compras" />
        </div>
        <FieldText
          id="email-wallet"
          label={<Translate term="E-mail" />}
          validation="required|email|max:150"
          value={model.email}
          type="email"
          mask="nospace"
          onChange={this.setEmail}
          inputProps={{
            className: 'theme-input'
          }}
          InputLabelProps={{
            className: 'theme-label',
            id: 'label-email'
          }}
        />

        <FieldText
          id="password-wallet"
          label={<Translate term="Senha" />}
          validation="required"
          value={model.password}
          type="password"
          onChange={this.setPassword}
          inputProps={{
            className: 'theme-input'
          }}
          InputLabelProps={{
            className: 'theme-label',
            id: 'label-password'
          }}
        />

        <div className="checkbox_wallet">
          <FormControlLabel
            control={
              <Checkbox id={'chk-allow-eduzz-wallet'} className="checkbox" value="1" checked={!!allowEduzzWallet} onChange={this.allowEduzzWallet} />
            }
            label={<Translate term="Concordo em utilizar meu saldo Eduzz para essa compra" />}
          />
        </div>
      </div>
    );
  }
}
