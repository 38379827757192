import React from 'react';
import ReactDOM from 'react-dom';
import { IScript } from 'interfaces/IScript';
import { BaseComponent } from 'components/BaseComponent';

interface IProps {
  script: IScript;
}

export default class Jivo extends BaseComponent<{}, IProps> {
  componentDidMount() {
    const script = document.createElement('script');

    script.innerHTML = `(function () {
        var widget_id = '${this.props.script.value}';
        var d = document;
        var w = window;
        function l() {
          var s = document.createElement('script');
          s.type = 'text/javascript';
          s.async = true;
          s.src = '//code.jivosite.com/script/widget/' + widget_id;
          var ss = document.getElementsByTagName('script')[0];
          ss.parentNode.insertBefore(s, ss);
        }
        if (d.readyState == 'complete') { l(); }
        else {
          if (w.attachEvent) {
            w.attachEvent('onload', l); }
          else {
            w.addEventListener('load', l, false);
          } }
      })();`;

    // eslint-disable-next-line react/no-find-dom-node
    ReactDOM.findDOMNode(this).appendChild(script);

    const tout = window.setInterval(() => {
      const el = document.getElementsByClassName('wrap_mW');
      if (el.length === 0) {
        return;
      }

      el[0].setAttribute('style', 'z-index: 5555');
      window.clearInterval(tout);
    }, 50);
  }

  render() {
    return <div />;
  }
}
