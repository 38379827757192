import { apiService } from 'shared/services';

export class TerminalService {
  public cancel(cancelUrl: string, transactionKey: string) {
    return apiService.post(cancelUrl, { transactionKey }).take(1);
  }
}

// tslint:disable-next-line:variable-name
export const TerminalServiceFactory = () => new TerminalService();

export const terminalService = TerminalServiceFactory();
export default terminalService;
