import React from 'react';
import { BaseComponent } from 'components/BaseComponent';
import YoutubeEmbed from 'components/YoutubeEmbed/index';
import { getCdnImage } from 'functions';
import { WithStyles } from 'shared/decorators/withStyles';

interface IProps {
  media: string;
  isVideo: boolean;
  title: string;
}

@WithStyles(() => ({
  component: {
    '& img': {
      borderRadius: '8px',
      height: '100%',
      objectFit: 'cover',
      width: '100%'
    }
  }
}))
export class BannerCenter extends BaseComponent<any, IProps> {
  getMedia() {
    if (this.props.isVideo && this.props.media) {
      return <YoutubeEmbed url={this.props.media} />;
    }

    if (!this.props.isVideo && this.props.media) {
      const bannerCenter = getCdnImage(this.props.media);
      const image = bannerCenter && bannerCenter.includes('data:') ? this.props.media : bannerCenter;

      if (image) {
        return <img src={image} alt={this.props.title} />;
      }
    }

    return null;
  }

  render() {
    return <div className={`chk-media-content ${this.props.classes.component}`}>{this.getMedia()}</div>;
  }
}
