export class HistoryService {
  public hasAskAddressAfterPaymentInUrl() {
    const url = window.location.href;
    return url.includes('askaddress');
  }
}

// tslint:disable-next-line:variable-name
export const HistoryServiceFactory = () => new HistoryService();

export const historyService = HistoryServiceFactory();
