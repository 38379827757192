import { ReplaySubject } from 'rxjs/ReplaySubject';
import { Observable } from 'rxjs/Observable';
import { IShippingData } from 'interfaces/IProductData';
import { paymentService } from './PaymentService';
import { cartService } from 'services/CartService';
import { IS_ECOMMERCE, IS_SHOPIFY } from 'settings';
import { parameterService } from './ParameterService';
import { enParameters } from 'enums/enParameters';

export class ShippingService {
  private shipping$ = new ReplaySubject<IShippingData>(1);
  private loaded = false;

  constructor() {
    this.loadShipping();
  }

  public getShipping(): Observable<IShippingData> {
    return this.shipping$.asObservable().distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b));
  }

  private loadShipping() {
    cartService
      .getCart()
      .combineLatest(paymentService.getCart())
      .filter(([cart]) => {
        if (IS_ECOMMERCE || cart.isCached) {
          return false;
        }

        return true;
      })
      .map(([cart, payment]) => {
        return {
          postalCode: (payment.address && payment.address.postalCode) || '',
          province: (payment.address && payment.address.province) || '',
          quantity: Number(payment.quantity || 0),
          price: payment.fullPrice,
          coupon: cart.coupon,
          products: payment.products,
          currency: cart.config.currency,
          isInternational: payment.personType === 'E',
          mustEstimate: cart.config.mustEstimate
        };
      })
      .filter((data) => (IS_SHOPIFY && !!data.province) || !IS_SHOPIFY)
      .distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b))
      .debounceTime(500)
      .switchMap((data) => {
        const shipping$ = cartService.setShippingData(data.products, data.postalCode);
        if (this.loaded === false) {
          return shipping$;
        }

        return shipping$.loader();
      })
      .combineLatest(parameterService.get(enParameters.p), cartService.getCart())
      .subscribe(([shippings, parameter, cart]) => {
        this.loaded = true;
        this.shipping$.next(shippings);
        const shipping = { value: shippings.price, installments: shippings.installments };

        if (shippings.shippingItems.length > 0) {
          cartService.setShipping(shippings.hash, shippings.shippingItems[0]);
          return;
        }

        if (parameter) {
          const installmentsParameters = Number(parameter) > cart.installments.length ? cart.installments.length : Number(parameter);
          paymentService.setPaymentData(0, { ...shipping, numberInstallments: installmentsParameters });
          return;
        }

        if (cart.installmentSelected) {
          paymentService.setPaymentData(0, { ...shipping, numberInstallments: cart.installmentSelected });
          return;
        }

        paymentService.setPaymentData(0, {
          ...shipping
        });

        paymentService.setInstallmentsDefault();
      });
  }
}

// tslint:disable-next-line:variable-name
export const ShippingServiceFactory = () => new ShippingService();

export const shippingService = ShippingServiceFactory();
