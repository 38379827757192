/* eslint-disable max-len */
import React from 'react';
import { SvgIcon } from 'components/SvgIcon';
import { IThankYou } from 'services/ThankYouService';
import { enPaymentMethod } from 'enums/enPaymentMethod';
import Translate from 'components/Translate';
import { SupportContactText } from '../../Text/SupportContactText';

interface IProps {
  model: IThankYou;
}
const Job: React.FC<IProps> = ({ model }) => {
  const textInformation = {
    [enPaymentMethod.BANKSLIP]: (
      <p>
        <Translate
          hasHTML
          term="Este é um serviço oferecido por {producerName}, assim que o boleto for pago as instruções chegarão no seu <b>e-mail</b>. Enquanto isso, confirme os seus dados."
          properties={{
            producerName: model.content.producerName
          }}
        />
      </p>
    ),
    [enPaymentMethod.PAGDIVIDIDO]: (
      <p>
        <Translate
          hasHTML
          term="Este é um serviço oferecido por {producerName}. Fique atento, você receberá um <b>SMS</b> para finalizar o processo. Após finalizado as instruções estará disponível no seu <b>e-mail</b>. Enquanto isso, confirme os seus dados."
          properties={{ producerName: model.content.producerName }}
        />
      </p>
    )
  };

  return (
    <React.Fragment>
      <div className={'message'}>
        {textInformation[model.paymentResult.paymentMethod] ?? (
          <p>
            <Translate
              hasHTML
              term="Este é um serviço oferecido por {producerName}, estamos preparando com carinho as instruções e enviaremos no seu <b>e-mail</b>. Enquanto isso, confirme os seus dados."
              properties={{ producerName: model.content.producerName }}
            />
          </p>
        )}
      </div>
      <div className={'data'}>
        <div className={'user'}>
          <div className={'user'}>
            <p>
              <SvgIcon name="user" />
              <span>{model.studdent ? model.studdent.name : model.user.name}</span>
            </p>
            <p>
              <SvgIcon name="email" />
              <span>{model.studdent ? model.studdent.email : model.user.email}</span>
            </p>
          </div>
        </div>
      </div>
      <div className={'alert'}>
        <SupportContactText contactEmail={model.content.producerEmail} />
      </div>
    </React.Fragment>
  );
};

export default React.memo(Job);
