import { makeStyles } from '@material-ui/core';
import React from 'react';
import ConfigCountry from '../ConfigCountry';
import { IS_ECOMMERCE } from 'settings';

const useStyles = makeStyles({
  header: {
    width: '100%',
    display: 'flex',
    borderBottom: '2px solid #ECEFF1',
    padding: 10,
    marginBottom: 16,
    justifyContent: 'flex-end'
  }
});

const FormHeader: React.FC = () => {
  const classes = useStyles({});

  if (IS_ECOMMERCE) {
    return null;
  }

  return (
    <div className={classes.header}>
      <ConfigCountry />
    </div>
  );
};

export default FormHeader;
