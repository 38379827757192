import { makeStyles } from '@material-ui/styles';
import Translate from 'components/Translate';
import React from 'react';
import { useObservable } from 'react-use-observable';
import { cartService } from 'services/CartService';

import FeeInstallments from './feeInstallment';

import { paymentService } from '../../../services/PaymentService';
import { enPaymentMethod } from 'enums/enPaymentMethod';

interface IProps {
  hiddenFeeInstallments?: boolean;
}
const useStyles = makeStyles({
  component: {
    textAlign: 'center',
    display: 'block',
    bottom: 0,
    left: 0,
    right: 0,
    fontSize: '0.75rem',
    color: 'black',
    textShadow: '1px 1px 2px #fff',

    '@media screen and (max-width: 960px)': {
      justifyContent: 'center'
    },

    '& a': {
      '&:after': {
        content: '"|"',
        display: 'inline-block',
        margin: '0 5px'
      },

      '&:last-child:after': {
        content: '""'
      }
    }
  }
});

const Footer: React.FC<IProps> = React.memo((props) => {
  const [cart] = useObservable(() => cartService.getCart(), []);
  const [payment] = useObservable(() => paymentService.getPayment(0), []);
  const classes = useStyles({});
  if (!cart) {
    return null;
  }

  return (
    <div className={`${classes.component} chk-media-footer`}>
      {!props.hiddenFeeInstallments && payment.paymentMethod !== enPaymentMethod.PAGDIVIDIDO && <FeeInstallments />}
      &nbsp;
      <span className="chk-footer-links">
        <a href="https://eduzz.com/privacidade" target="_blank" rel="noopener noreferrer">
          <Translate term="Política de privacidade" />
        </a>
        <a href="https://eduzz.com/termos" target="_blank" rel="noopener noreferrer">
          <Translate term="Termos de compra" />
        </a>
        {cart.config.affiliateId !== 0 && (
          <a target="_blank" rel="noopener noreferrer">
            REF: {cart.config.affiliateId}
          </a>
        )}
      </span>
    </div>
  );
});
export default Footer;
