/* eslint-disable max-lines */
import { BaseComponent, IStateBase } from 'components/BaseComponent';
import { ICartModel } from 'interfaces/ICartModel';
import { IProductConfig, IProductData, IProduct } from 'interfaces/IProductData';
import React from 'react';
import { cartService } from 'services/CartService';
import { paymentService } from 'services/PaymentService';
import { Sidebar } from 'components/Cart/Sidebar/index';
import AddressAfter from './AddressAfter';
import { IPayProps, PayContext } from 'components/Cart';
import { HistoryContext } from 'shared/HistoryContext';
import { History } from 'history';
import { Observable } from 'rxjs/Observable';
import StepUser from './StepUser';
import StepAddress from './StepAddress';
import StepPayment from './StepPayment';
import { userService } from 'services/UserService';
import { TicketTimer } from 'components/Cart/TicketTimer';
import { enProductType } from 'enums/enProductType';
import StepTicket from './StepTicket';
import { Hidden } from '@material-ui/core';
import { WithStyles } from 'shared/decorators/withStyles';
import { currencyService } from 'services/CurrencyService';
import { ticketService } from 'services/TicketService';
import { enPaymentMethod } from 'enums/enPaymentMethod';
import { thankYouService } from 'services/ThankYouService';

export enum enStep {
  user,
  address,
  payment,
  addressAfter,
  ticket
}

export interface IStepProps {
  setStep: (step: enStep) => void;
}

interface IState extends IStateBase {
  cart: IProductData;
  config: IProductConfig;
  model: ICartModel;
  step: enStep;
  showTimer: boolean;
}

interface IProps extends IPayProps {
  product: IProduct;
}

@WithStyles(() => ({
  component: {
    display: 'flex',
    transition: 'all .5s ease-in-out',

    '@media screen and (min-width: 960px)': {
      marginTop: '60px'
    },

    '& .field.normal': {
      '& input': {
        padding: '20px 8px 4px 8px',
        appearance: 'none',

        '&.quantity': {
          padding: '10px 8px 8px 10px'
        }
      },

      '& label': {
        fontSize: '0.875rem',
        zIndex: 1
      },

      '& label[data-shrink="true"]': {
        transform: 'translate(8px, 20px) scale(0.75)'
      },

      '& label[data-shrink="false"]': {
        transform: 'translate(8px, 33px) scale(1)',

        '@media screen and (max-width: 959px)': {
          '&#label-credit-card-number': {
            transform: 'translate(45px, 33px) scale(1)'
          }
        }
      },

      '& label+div': {
        marginTop: '8px'
      }
    },

    '& .chk-coupon': {
      '&.chk-coupon-code-input': {
        margin: '16px 0 0 0'
      },

      '& Button': {
        height: '54px'
      },

      '& .field.normal': {
        '&label[data-shrink="false"]': {
          transform: 'translate(8px, 30px) scale(1)',
          fontSize: '0.75rem'
        },

        '& label[data-shrink="true"]': {
          transform: 'translate(8px, 20px) scale(0.75)'
        }
      }
    },

    // eslint-disable-next-line max-len
    '& .field, & .chk-cityState__state label, & .chk-cityState__city label, & .chk-userAddressInternational__country label, & .chk-installments__installmentFieldSelect label':
      {
        fontSize: '0.75rem',
        transform: 'scale(1)'
      },

    '& .chk-multiple-cards-header': {
      padding: '12px 0',
      marginTop: '12px'
    },

    '& .steps': {
      flex: 1,
      width: '100%',

      '& .chk-installments__value': {
        margin: 0
      },

      '& .ticket': {
        backgroundColor: '#fff',
        padding: '5px 16px'
      },

      '& .grid': {
        width: '100%',
        margin: '0 auto',

        '& .cpf-form': {
          marginBottom: '5%'
        },

        '& .accept-terms': {
          margin: '0 0 10px 0'
        },

        '& .chk-add-credit-card': {
          background: '#00b356',
          borderRadius: '5px',
          margin: '8px 0',
          padding: '4px 16px',
          color: '#fff',
          fontSize: '0.75rem',
          fontWeight: 500,
          textAlign: 'left',
          textTransform: 'unset',

          '& svg': {
            width: '25px',
            margin: '0 10px 0 0'
          }
        },

        '& .chk-stepUser__userForm': {
          margin: '0px 0 22px 0',
          padding: 0
        }
      },

      '& .footer': {
        width: '100%',

        '& .security': {
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'row',
          justifyContent: 'center',
          color: '#009358',
          fontSize: '.8rem',
          fontWeight: 600,
          marginTop: '16px',

          '& svg': {
            width: '40px',
            fill: '#009358'
          },

          '& p': {
            margin: 0
          }
        },

        '& .links': {
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'row',
          justifyContent: 'center',
          margin: '16px 0',

          '& a': {
            display: 'inline-block',
            textDecoration: 'none',
            color: '#3C3C3C',

            '&:after': {
              content: '"|"',
              display: 'inline-block',
              margin: '0 5px'
            },

            '&:last-child:after': {
              content: '""'
            }
          },

          '& .chk-media-footer': {
            fontSize: '.625rem',
            textAlign: 'center',
            color: 'black',
            textShadow: '1px 1px 2px #fff'
          }
        }
      },

      '& .product-info': {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        backgroundColor: '#f8f8f8',
        padding: '20px',
        fontSize: '.8rem',

        '& .title': {
          fontSize: '1rem',
          fontWeight: 600,
          marginRight: '20px'
        },

        '& .price': {
          whiteSpace: 'nowrap',
          textAlign: 'right',

          '& span': {
            fontSize: '1.5rem',
            fontWeight: 600
          }
        }
      },

      '& .chk-payment-flags': {
        height: '40px',

        '& .chk-flag-option': {
          margin: '5px 1px',
          width: '40px',
          height: '35px',

          '&:hover': {
            opacity: 1,
            width: '40px',
            height: '35px'
          }
        }
      }
    },

    '& .sidebar': {
      marginRight: '24px',
      width: '273px',

      '@media screen and (max-width: 959px)': {
        margin: 0,

        '& .chk-banner-right': {
          border: 'none'
        }
      },

      '@media screen and (max-width: 600px)': {
        display: 'none'
      }
    },

    '& .chk-btn-download': {
      padding: '14px',
      width: '100%',
      marginTop: '8px'
    },

    '& .chk-payment-option': {
      '& .chk-product-infos': {
        display: 'none'
      }
    },

    '& .chk-cityState__city': {
      '@media screen and (max-width: 600px)': {
        margin: '0px 0 8px 0'
      },

      margin: '8px 0 8px 0'
    },

    '& .chk-cityState__state, & .chk-userAddressInternational__country': {
      marginTop: '16px',
      fontSize: '1.125rem',
      padding: 0
    },

    '& .tooltip-cvv-card .cvv-question-mark': {
      display: 'none !important'
    },

    '& .chk-subscription': {
      textAlign: 'right',
      lineHeight: '20px',

      '& .chk-subscription__text': {
        fontSize: '0.75rem',
        textTransform: 'uppercase'
      },

      '& .chk-subscription__value': {
        fontSize: '1.5rem'
      },

      '& .chk-subscription__frequency, & .chk-subscription__trial': {
        fontSize: '0.75rem'
      },

      '& .chk-subscription__trial': {
        padding: '4px 0',

        '& span:first-child': {
          cursor: 'auto',
          padding: '2px 4px'
        }
      }
    },

    '& .chk-stepPayment__feeInstallments': {
      fontSize: '0.625rem',
      margin: '8px 0 0 0',
      textAlign: 'center',
      width: '100%'
    },

    '& .tooltip-email-information': {
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',

      '& .information': {
        fontSize: '12px',
        position: 'absolute',
        fontStyle: 'italic',
        color: '#868686',
        margin: '-2px 0 0 2px'
      }
    },

    '& .autoSuggestContainer': {
      zIndex: 10,
      position: 'absolute',
      top: '70px',

      '& .autoSuggest': {
        cursor: 'pointer',
        width: '270px',

        '& .item': {
          height: '30px',
          padding: '0 5px',

          '&:hover': {
            backgroundColor: '#f1f1f1'
          },

          '&.actualEmail': {
            backgroundColor: '#f1f1f1'
          }
        }
      }
    },
    '& .chk-img': {
      maxHeight: '9rem',
      height: 'auto',
      maxWidth: '4rem',
      width: 'auto',
      margin: '0 3px 0 0',
      boxShadow: 'none',
      border: '1px solid #e2e2e2'
    },

    '& .no-open-modal': {
      margin: '10px 0'
    }
  }
}))
export default class MultiStep extends BaseComponent<IState, IProps> {
  private history: History;

  componentDidMount() {
    this.observeState({ cart: null }, cartService.getCart());
    this.observeState({ config: null }, cartService.getConfig());
    this.bindModel(paymentService.getCart());
    this.setState({ step: enStep.user });

    currencyService
      .getCurrency()
      .bindComponent(this)
      .subscribe(() => this.setState({ step: enStep.user }));

    cartService
      .getCart()
      .filter((cart) => !cart.isCached && cart.upsell == enPaymentMethod.BANKSLIP && cart.config.productType != enProductType.PHYSICAL)
      .take(1)
      .subscribe(() => {
        this.setState({ step: enStep.payment });
        this.onPay();
      });

    cartService.shouldForcePixForSaleRecoveryWithContract().subscribe(() => {
      this.setState({ step: enStep.payment });
      this.onPay();
    });

    ticketService
      .show()
      .bindComponent(this)
      .subscribe((showTimer) => {
        this.setState({ showTimer });
      });
  }

  setStep = (step: enStep) => {
    const { config } = this.state;

    if (step == enStep.address && !config.askAddressBeforePayment) {
      step = enStep.payment;
    }

    userService.setBasicUser().subscribe();

    this.setState({ step });
  };

  onPay = () => {
    if (paymentService.hasPaymentInProgress()) {
      return;
    }

    this.props
      .onPay()
      .switchMap(() => cartService.getCart())
      .combineLatest(paymentService.shouldBlockAddressAfterPayment())
      .take(1)
      .switchMap(([cart, shouldBlockAddressAfterPayment]) => {
        if (cart.config.askAddressAfterPayment && !shouldBlockAddressAfterPayment) {
          this.setStep(enStep.addressAfter);
          return Observable.of(`/${cart.contentId}/addressafter`);
        }

        return paymentService.getPaymentResult().map((result) => {
          return thankYouService.redirectThankYou(result);
        });
      })
      .filter((url) => url !== null)
      .bindComponent(this)
      .subscribe((url) => {
        this.history.push(url);
      });
  };

  setHistory(history: History): any {
    this.history = history;
    return null;
  }

  render() {
    if (!this.state) {
      return '';
    }

    const { step, cart, showTimer } = this.state;

    return (
      <div className={this.props.classes.component}>
        <PayContext.Provider value={this.onPay.bind(this)}>
          <HistoryContext.Consumer>{(h) => this.setHistory(h)}</HistoryContext.Consumer>
          <div className="steps">
            {showTimer && cart.config.productType == enProductType.TICKET ? (
              <Hidden smUp>
                <div className={'ticket'}>
                  <TicketTimer />
                </div>
              </Hidden>
            ) : null}
            {enStep.user == step ? <StepUser setStep={this.setStep} onPay={this.onPay} /> : null}
            {enStep.ticket == step ? <StepTicket step={this.state.step} setStep={this.setStep} /> : null}
            {enStep.address == step ? <StepAddress step={this.state.step} setStep={this.setStep} onPay={this.onPay} /> : null}
            {enStep.payment == step ? <StepPayment step={this.state.step} setStep={this.setStep} onPay={this.onPay} /> : null}
            {enStep.addressAfter == step ? <AddressAfter setStep={this.setStep} history={this.history} /> : null}
          </div>
          <div className="sidebar">
            <Sidebar cart={cart} />
          </div>
        </PayContext.Provider>
      </div>
    );
  }
}
