/* eslint-disable max-len */
import React from 'react';
import { SvgIcon } from 'components/SvgIcon';
import { IThankYou } from 'services/ThankYouService';
import { enPaymentMethod } from 'enums/enPaymentMethod';
import Translate from 'components/Translate';
import { SupportContactText } from '../../Text/SupportContactText';

interface IProps {
  model: IThankYou;
}

const Physical: React.FC<IProps> = ({ model }) => {
  const textInformation = {
    [enPaymentMethod.BANKSLIP]: (
      <p>
        <Translate
          hasHTML
          term="Você receberá no seu <b>e-mail</b> o código de rastreio do seu produto assim que o pagamento do boleto for confirmado. Enquanto isso, confirme os seus dados de compra."
        />
      </p>
    ),
    [enPaymentMethod.PAGDIVIDIDO]: (
      <p>
        <Translate
          hasHTML
          term="Fique atento, você receberá um <b>SMS</b> para finalizar o processo. Após isso, você receberá no seu <b>e-mail</b> o código de rastreio do seu produto. Enquanto isso, confirme os seus dados de compra. "
        />
      </p>
    )
  };
  return (
    <React.Fragment>
      <div className="message">
        {textInformation[model.paymentResult.paymentMethod] ?? (
          <p>
            <Translate
              hasHTML
              term="Estamos preparando com carinho a entrega do seu produto. Você receberá no seu <b>e-mail</b> o código de rastreio do seu produto. Enquanto isso, confirme os seus dados de compra."
            />
          </p>
        )}
      </div>
      <div className="data">
        <div className="user">
          <p>
            <SvgIcon name="email" />
            <span>{model.user.email}</span>
          </p>
          <p>
            <SvgIcon name="truck" />
            <span>
              {model.user.address.street}, {model.user.address.number}, {model.user.address.district}
              ,&nbsp;
              {model.user.address.city} - {model.user.address.province} - {model.user.address.postalCode}
            </span>
          </p>
        </div>
      </div>
      <div className="alert">
        <SupportContactText contactEmail={model.content.producerEmail} />
      </div>
    </React.Fragment>
  );
};

export default React.memo(Physical);
