import React from 'react';
import { BaseComponent } from 'components/BaseComponent';
import UserAddressForm from 'components/UserAddressForm';
import { IStepProps } from 'components/Cart/Templates/Mobile/MultiStep';
import { SvgIcon } from 'components/SvgIcon';
import { userService } from 'services/UserService';
import Grid from '@material-ui/core/Grid/Grid';
import Button from '@material-ui/core/Button/Button';
import { FieldValidation } from 'components/Field';
import { cartService } from 'services/CartService';
import { Observable } from 'rxjs/Observable';
import { paymentService } from 'services/PaymentService';
import Translate from 'components/Translate';
import { WithStyles } from 'shared/decorators/withStyles';

export interface IProps extends IStepProps {}

@WithStyles(() => ({
  component: {
    '& .chk-addressAfter-grid': {
      alignItems: 'center',
      display: 'flex',
      padding: '0 12px',

      '& .icon': {
        marginRight: '20px',
        '& svg': {
          width: '60px',
          height: '60px',
          '& path': {
            fill: '#009358'
          }
        }
      },

      '& .chk-paragraph': {
        '& .title': {
          color: '#5A2254',
          fontSize: '1em',
          textAlign: 'left',
          marginBottom: '5px'
        },

        '& .label': {
          fontSize: '0.8em',
          color: 'rgb(147, 147, 147)',
          marginTop: 0
        }
      }
    }
  }
}))
export default class AddressAfter extends BaseComponent<any, IProps> {
  finish = async () => {
    if (await this.isFormValid()) {
      userService.setBasicUser().bindComponent(this).subscribe();

      Observable.combineLatest(cartService.getCart(), paymentService.getPaymentResult())
        .loader()
        .bindComponent(this)
        .subscribe(([cart, paymentResult]) => {
          if (paymentResult.redirectTo) {
            Observable.never().loader().subscribe();

            window.location.href = paymentResult.redirectTo;
            return;
          }

          this.props.history.push(`/thankyou/${cart.contentId}/${paymentResult.key}`);
        });
    }
  };

  render() {
    return (
      <div>
        <FieldValidation.Provider value={this.registerFields}>
          <Grid container spacing={3} className={`${this.props.classes.component} grid`}>
            <Grid item className="chk-addressAfter-grid" xs={12} style={{ padding: '1.25rem 1.25rem 0 1.25rem' }}>
              <div className="icon">
                <SvgIcon name="check" />
              </div>
              <div className="chk-paragraph">
                <h1 className="title" id="thankyou-title">
                  <Translate term="Obrigado pela Compra!" />
                </h1>
                <p className="label">
                  <Translate term="O seu pagamento está em processamento, complete o breve formulário abaixo para continuar" />
                </p>
              </div>
            </Grid>
            <Grid item xs={12} style={{ padding: '1.25rem' }}>
              <UserAddressForm />
            </Grid>
            <Button
              fullWidth
              id="chk-addressafter-button"
              variant="contained"
              size="large"
              color="primary"
              className="chk-btn-download theme-button"
              onClick={this.finish}
            >
              <Translate term="Concluir" />
            </Button>
          </Grid>
        </FieldValidation.Provider>
      </div>
    );
  }
}
