/* eslint-disable max-lines */
import TextField from '@material-ui/core/TextField/TextField';
import React, { Fragment } from 'react';
import currencyFormatter from 'currency-formatter';
import FieldBase, { IPropsFieldBase } from './Base';
import Translate from 'components/Translate';
import { phoneMask } from 'shared/masks/phone';

interface IProps extends IPropsFieldBase {
  mask?:
    | 'date'
    | 'name'
    | 'number'
    | 'nospace'
    | 'phone'
    | 'intlPhone'
    | 'creditCard'
    | 'monthYear'
    | 'cvv'
    | 'cpf'
    | 'cnpj'
    | 'cpfCnpj'
    | 'postalCode'
    | 'money';
  icon?: JSX.Element;
}

export default class FieldText extends FieldBase<IProps> {
  private readonly masks: any = {
    nospace: {
      apply: (value: string) => {
        if (!value) {
          return value;
        }

        return value.replace(/\s+/, '');
      },
      clean: (value: string) => value.replace(/\s+/, '')
    },
    number: {
      apply: (value: string) => {
        if (!value) {
          return value;
        }

        if (value === 'S/N') {
          return value;
        }

        return value.replace(/\D/gi, '');
      },
      clean: (value: string) => {
        if (value === 'S/N') {
          return value;
        }

        return value.replace(/\D/gi, '');
      }
    },
    name: {
      apply: (value: string) => {
        if (!value) {
          return value;
        }

        return value.replace(/[><;"&()@#$%*]/, '');
      },
      clean: (value: string) => value.replace(/[><;"&()@#$%*]/, '')
    },
    phone: phoneMask,
    intlPhone: {
      apply: (value: string) => {
        if (!value) {
          return value;
        }

        return value.replace(/\D/g, '').trim();
      },
      clean: (value: string) => value.replace(/\D/gi, '').substring(0, 36)
    },
    creditCard: {
      apply: (value: string) => {
        if (!value) {
          return value;
        }

        const regexp = /^(\d{0,4})?(\d{0,4})?(\d{0,4})?(\d{0,4})?/;

        return value.replace(regexp, '$1  $2  $3  $4').replace(/-$/, '').trim();
      },
      clean: (value: string) => value.replace(/\D/gi, '').substr(0, 16)
    },
    monthYear: {
      apply: (value: string) => {
        if (!value) {
          return value;
        }

        const regexp = /^(\d{0,2})(\d{0,2})/;
        const replacement = value.length < 3 ? '$1' : '$1/$2';

        return value.replace(/\D/g, '').replace(regexp, replacement);
      },
      clean: (value: string) => value.substr(0, 5)
    },
    cvv: {
      apply: (value: string) => {
        if (!value) {
          return value;
        }

        return value.replace(/[^0-9]+/g, '');
      },
      clean: (value: string) => value.substr(0, 4)
    },
    cpf: {
      apply: (value: string) => {
        if (!value) {
          return value;
        }

        const regexp = /^(\d{0,3})(\d{0,3})(\d{0,3})(\d{0,2})/;

        const result = value.length < 4 ? '$1' : value.length < 7 ? '$1.$2' : value.length < 10 ? '$1.$2.$3' : '$1.$2.$3-$4';

        return value.replace(regexp, result).replace(/-$/, '');
      },
      clean: (value: string) => value.replace(/\D/gi, '').substr(0, 11)
    },
    cnpj: {
      apply: (value: string) => {
        if (!value) {
          return value;
        }

        const regexp = /^(\d{0,2})(\d{0,3})(\d{0,3})(\d{0,4})(\d{0,2})/;

        const result =
          value.length < 3
            ? '$1'
            : value.length < 6
            ? '$1.$2'
            : value.length < 9
            ? '$1.$2.$3'
            : value.length <= 12
            ? '$1.$2.$3/$4'
            : '$1.$2.$3/$4-$5';

        return value.replace(regexp, result).replace(/-$/, '');
      },
      clean: (value: string) => value.replace(/\D/gi, '').substr(0, 14)
    },
    cpfCnpj: {
      apply: (value: string) => {
        if (!value) {
          return value;
        }

        const regexp = value.length <= 11 ? /^(\d{0,3})(\d{0,3})(\d{0,3})(\d{0,2})/ : /^(\d{0,2})(\d{0,3})(\d{0,3})(\d{0,4})(\d{0,2})/;

        const result =
          value.length < 4
            ? '$1'
            : value.length < 7
            ? '$1.$2'
            : value.length < 10
            ? '$1.$2.$3'
            : value.length <= 11
            ? '$1.$2.$3-$4'
            : '$1.$2.$3/$4-$5';

        return value.replace(regexp, result).replace(/-$/, '');
      },
      clean: (value: string) => value.replace(/\D/gi, '').substr(0, 14)
    },
    postalCode: {
      apply: (value: string) => {
        if (typeof value !== 'string') {
          return '';
        }

        if (!value || !value.replace) return value;
        const regexp = /^(\d{5})(\d{3})?/;

        return value.replace(/\D+/g, '').replace(regexp, '$1-$2').replace(/-$/, '').trim();
      },
      clean: (value: string) =>
        value
          .replace(/\D+/g, '')
          .substr(0, 8)
          .replace(/^(\d{5})(\d{3})?/, '$1-$2')
    },
    date: {
      apply: (value: string) => {
        if (typeof value !== 'string') {
          return '';
        }

        if (!value || !value.replace) {
          return value;
        }

        const stringDate = value.replace(/\D+/g, '');

        if (stringDate.length <= 2) {
          return stringDate;
        }

        if (stringDate.length <= 4) {
          return stringDate.replace(/^(\d{2})(\d{0,})/, '$1/$2');
        }

        return stringDate.replace(/^(\d{2})(\d{2})(\d{1,4})/, '$1/$2/$3');
      },
      clean: (value: string) =>
        value
          .replace(/\D+/g, '')
          .substr(0, 8)
          .replace(/^(\d{2})(\d{2})?(\d{4})?/, '$1/$2/$3')
          .replace(/^[/]*/, '')
          .replace(/[/]*$/, '')
          .replace(/[/]+/, '/')
    },
    money: {
      apply: (value: string) => {
        if (!value) {
          return value;
        }

        value = value.toString();

        if (value.match(/^\d+$/)) {
          value = `${value}.00`;
        }

        if (value.match(/^\d+\.\d+$/)) {
          value = Number(value).toFixed(2);
        }

        value = value.replace(/\D+/gi, '');

        const intval = value.substr(0, value.length - 2);
        const cents = value.substr(value.length - 2, 2);

        value = `${intval}.${cents}`;

        return currencyFormatter.format(Number(value), { code: this.state.currency, precision: 2 });
      },
      clean: (value: string) => value
    }
  };

  componentDidMount() {
    this.forceIsValid();
  }

  moveCaretToEnd(el: any) {
    if (!el) {
      return;
    }

    if (el.createTextRange) {
      const range = el.createTextRange();
      range.move('character', el.value.length);
      range.select();
      return;
    }

    if (el.selectionStart || el.selectionStart === 0) {
      el.focus();
      el.setSelectionRange(el.value.length, el.value.length);
      return;
    }

    el.focus();
  }

  onChange(event: any) {
    const value = this.cleanValue(event.target ? event.target.value : event);
    super.onChange(value);

    const target = event.currentTarget;

    setTimeout(() => {
      try {
        this.moveCaretToEnd(target);
      } catch (err) {}
    }, 1);
  }

  getRenderValue(): string {
    const { value, mask } = this.props;

    let newValue = JSON.parse(JSON.stringify(value || ''));

    const maskFunc = this.masks[mask];

    if (maskFunc) {
      newValue = maskFunc.apply(newValue);
    }

    return newValue;
  }

  cleanValue(value: string) {
    const { mask } = this.props;

    const maskFunc = this.masks[mask];
    return !maskFunc ? value : maskFunc.clean(value);
  }

  render() {
    const { icon } = this.props;
    const { config } = this.state;
    const value = this.getRenderValue();

    const inputStyle = config ? config.template.inputStyle : 'normal';

    return (
      <Fragment>
        {super.render()}

        <TextField
          {...{
            className: `field ${inputStyle}`,
            fullWidth: true,
            margin: 'normal',
            ...this.props,
            value: (value === undefined || value === null ? '' : value).toString(),
            error: !!this.errorMessage,
            helperText: <Translate term={this.errorMessage} />,
            onChange: this.onChange.bind(this),
            onBlur: this.onBlur.bind(this),
            submitted: null,
            InputProps: {
              disableUnderline: inputStyle != 'material',
              startAdornment: icon ? icon : null
            },
            FormHelperTextProps: {
              classes: {
                root: 'field-error',
                error: 'error',
                disabled: 'disabled'
              }
            },
            touched: null
          }}
        />
      </Fragment>
    );
  }
}
