import { enStatusWaiter } from 'enums/enStatusWaiter';
import React from 'react';
import { paymentWaiter } from 'services/PaymentWaiterService';

const Component: React.FC = () => {
  return (
    <div className="chk-pix-modal-footer">
      <div className="chk-pix-divider"></div>
      <span onClick={() => paymentWaiter.changeWaiterStatus(enStatusWaiter.CANCELED)}>Fechar</span>
    </div>
  );
};

export const PixCloseModal = React.memo(Component);
