import React, { FC } from 'react';
import { IProductData } from 'interfaces/IProductData';
import { SubscriptionWithTax } from './SubscriptionWithTax/index';
import { SubscriptionWithTrial } from './SubscriptionWithTrial';
import { SubscriptionWithoutTaxTrial } from './SubscriptionWithoutTaxTrial/index';
import { ICartModel } from 'interfaces/ICartModel';

interface IPriceProps {
  cart: IProductData;
  payment: ICartModel;
}

// tslint:disable-next-line:variable-name
export const Subscription: FC<IPriceProps> = (props) => {
  const { cart, payment } = props;

  return (
    <div>
      {cart.subscription.hasTax && <SubscriptionWithTax cart={cart} payment={payment} />}

      {cart.subscription.hasTrial && <SubscriptionWithTrial subscription={cart.subscription} />}

      {!cart.subscription.hasTax && !cart.subscription.hasTrial && <SubscriptionWithoutTaxTrial payment={payment} cart={cart} />}
    </div>
  );
};
