import React from 'react';
import { BaseComponent, IStateBase } from 'components/BaseComponent';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';
import { enLegacyPixelPath, legacyPixelService } from 'services/LegacyPixelService';
import { pixelService, enPixelPath } from 'services/PixelService';
import Translate from 'components/Translate';

interface IState extends IStateBase {
  textInput: any;
  visibilityTooltip: boolean;
  tooltipMessage: string;
  thankyouUrl?: string;
}

interface IProps {
  code: string;
  thankyouUrl?: string;
}

export default class Code extends BaseComponent<IState, IProps> {
  constructor(props: any) {
    super(props);
    this.focusTextInput = this.focusTextInput.bind(this);
    this.state = { visibilityTooltip: false, textInput: '', tooltipMessage: 'Copiar' };
  }

  focusTextInput = (event: any) => {
    event.preventDefault();
    this.state.textInput.focus();
    this.state.textInput.setSelectionRange(0, 9999);
    document.execCommand('Copy');

    /*
      legacyPixelService: Should be removed in not too distance future
    */
    legacyPixelService.track(enLegacyPixelPath.BANKSLIP);

    pixelService.setPath(enPixelPath.BANKSLIP_VIEW);

    this.setState({ visibilityTooltip: true, tooltipMessage: 'Copiado!' });
    setTimeout(() => this.setState({ visibilityTooltip: false, tooltipMessage: 'Copiar' }), 1500);
    this.handleRedirect();
  };

  setTextInputValue = (input: any) => {
    this.setState({ textInput: input });
  };

  handleRedirect = () => {
    if (!!this.props.thankyouUrl) {
      setTimeout(() => (window.location.href = this.props.thankyouUrl), 2500);
    }
  };

  render() {
    if (!this.props.code) {
      return null;
    }

    return (
      <div style={{ width: '100%' }}>
        <label className="code">
          <Translate term={'Código de pagamento do seu boleto'} />
        </label>
        <div className={`copy`}>
          <TextField
            inputRef={this.setTextInputValue}
            value={this.props.code}
            id="boleto-cod"
            className={'input-code'}
            onClick={this.focusTextInput}
            InputProps={{
              disableUnderline: true,
              readOnly: true,
              className: 'input'
            }}
          />
          <Tooltip
            PopperProps={{
              disablePortal: true
            }}
            placement="top"
            open={this.state.visibilityTooltip}
            disableFocusListener
            disableHoverListener
            title={this.state.tooltipMessage}
          >
            <button id="chk-copy-button" className="button" onClick={this.focusTextInput}>
              <Translate term="Copiar" />
            </button>
          </Tooltip>
        </div>
      </div>
    );
  }
}
