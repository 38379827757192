import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/observable/empty';

interface ICacheGenerator<T> {
  (): T;
}

export function getValue<T>(key: string, defaultValueGenerator: ICacheGenerator<T> = null): Observable<T> {
  const value = sessionStorage.getItem(key);

  if (value) {
    return Observable.of<T>(JSON.parse(value));
  }

  if (!defaultValueGenerator) {
    return Observable.empty<T>();
  }

  const newValue = defaultValueGenerator();

  return setValue(key, newValue);
}

export function setValue<T>(key: string, newValue: T): Observable<T> {
  sessionStorage.setItem(key, JSON.stringify(newValue));
  return Observable.of(newValue);
}
