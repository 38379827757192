import { BaseComponent, IStateBase } from 'components/BaseComponent';
import React from 'react';
import Divider from '@material-ui/core/Divider';
import { IProductData } from 'interfaces/IProductData';
import { cartService } from 'services/CartService';
import { paymentService } from 'services/PaymentService';
import { ICartModel } from 'interfaces/ICartModel';
import { Locale } from '../Locale';
import Body from './Body';
import Collapse from '@material-ui/core/Collapse';
import { IS_ECOMMERCE } from 'settings';
import Translate from 'components/Translate';
import { WithStyles } from 'shared/decorators/withStyles';
import { nl2br } from 'shared/functions';
import { unescapeHtml, getCdnImage } from 'functions';

interface IState extends IStateBase {
  cart: IProductData;
  circle?: boolean;
  payment: ICartModel;
  showDetails: boolean;
}

@WithStyles(() => ({
  component: {
    '& .chk-product-infos': {
      display: 'flex',
      alignItems: 'center',
      '& img': {
        width: 'auto',
        border: '1px solid #e2e2e2',
        height: 'auto',
        margin: '0 3% 0 0',
        maxWidth: '9rem',
        boxShadow: 'none',
        maxHeight: '9rem'
      }
    },
    '& .descriptionQuantity': {
      '@media screen and (min-width: 960px)': {
        display: 'none'
      }
    },

    '& .title': {
      '@media screen and (max-width: 600px)': {
        margin: '0 0 8px 0',
        textAlign: 'center'
      },

      margin: '0 0 24px 0',
      fontSize: '1rem'
    },

    '& .nameColumn': {
      color: '#192542',
      fontWeight: 'bold',
      fontSize: '0.785rem',

      '&.right': {
        width: '100%',
        textAlign: 'right'
      },

      '& svg': {
        height: '13px',
        marginRight: '5px'
      }
    },

    '& .coupon_price': {
      display: 'flex',
      justifyContent: 'space-between',
      margin: '12px 0 12px 0',

      '& .chk-coupon .field.normal': {
        '& label': {
          fontSize: '1rem',

          '@media screen and (max-width: 600px)': {
            fontSize: '0.750rem'
          },

          '&[data-shrink="false"], &[data-shrink="true"]': {
            transform: 'translate(0, -8px) scale(1)'
          }
        }
      },

      '&.just_price': {
        justifyContent: 'flex-end'
      },

      '& .price': {
        display: 'flex',
        alignItems: 'flex-end',
        fontSize: '1.225rem',
        fontWeight: 'bold',
        flexDirection: 'column',

        '& .total': {
          fontSize: '0.775rem',
          fontWeight: 'normal',
          margin: '0 4px 2px 0'
        },

        '& .value': {
          marginRight: '5px'
        }
      }
    },

    '& .details': {
      display: 'flex',
      flexDirection: 'column',

      '&.reverse': {
        flexDirection: 'column-reverse'
      },

      '& .showDetails': {
        textAlign: 'center',
        margin: '0 0 18px',
        fontSize: '0.675rem',
        textDecoration: 'underline',
        color: '#596375',

        '@media screen and (min-width: 600px)': {
          display: 'none'
        }
      }
    }
  }
}))
export default class PriceQuantity extends BaseComponent<IState, { step?: number }> {
  componentDidMount() {
    this.observeState({ cart: null }, cartService.getCart());
    this.observeState({ payment: null }, paymentService.getCart());
  }

  setShowDetails = () => {
    this.setState({ showDetails: !this.state.showDetails });
  };

  render() {
    if (!this.state) {
      return null;
    }
    const { cart, showDetails } = this.state;

    const image = getCdnImage(cart.config.logo);

    return (
      <div className={this.props.classes.component}>
        <div className="chk-product-infos">
          {!this.props.step && image && <img src={image} />}
          <h3 className="title">{unescapeHtml(cart.content.title)}</h3>
        </div>

        <div className={`details ${this.setShowDetails ? 'reverse' : ''}`}>
          <div className="showDetails" onClick={this.setShowDetails}>
            {showDetails ? <Translate term="Esconder Detalhes" /> : <Translate term="Detalhes do evento" />}
          </div>
          <Collapse in={showDetails}>
            <Locale />
          </Collapse>
        </div>

        {!IS_ECOMMERCE ? (
          <div>
            <Body step={this.props.step} />
            <Divider />
          </div>
        ) : (
          <div className="descriptionQuantity">
            <div dangerouslySetInnerHTML={{ __html: nl2br(cart.products[0].description) }} />
            <Divider />
          </div>
        )}
      </div>
    );
  }
}
