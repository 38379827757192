/* eslint-disable max-lines */
import { BaseComponent, IStateBase } from 'components/BaseComponent';
import React, { Fragment } from 'react';
import Checkbox from '@material-ui/core/Checkbox/Checkbox';
import Translate from 'components/Translate';
import { IOrderBump, IProduct, IProductData } from 'interfaces/IProductData';
import { cartService } from 'services/CartService';
import { getCdnImage } from 'functions';
import { WithStyles } from 'shared/decorators/withStyles';
import OrderBumpQuantityForm from './OrderBumpQuantityForm';
import { enProductType } from 'enums/enProductType';
import { paymentService } from 'services/PaymentService';
import { of } from 'rxjs/observable/of';
import { CurrencyFormat } from 'components/CurrencyFormat';
import FormControlLabel from '@material-ui/core/FormControlLabel/FormControlLabel';

interface IState extends IStateBase {
  orderBumpProducts: IOrderBump[];
  orderBumpProductsSelected: string[];
  product: IProduct;
  productsIds: string[];
  templateId: string;
  isOneClick?: boolean;
}

@WithStyles(() => ({
  component: {
    border: '1px solid #b0bec5',
    backgroundColor: '#f5f7f8',
    borderRadius: '4px',
    marginTop: '16px',
    width: '100%',
    padding: '16px',
    '& .orderBumpItem': {
      cursor: 'pointer'
    },
    '& .orderBumpItem:not(:last-child)': {
      borderBottom: '1px solid #b0bec5',
      paddingBottom: 16,
      marginBottom: 16
    },
    '& h1.theme-orderbump-highlight': {
      color: '#072d83',
      fontSize: 20,
      margin: 0,
      textAlign: 'left'
    },
    '& .orderBumpOffer': {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'left',
      '& .productImage': {
        height: 60,
        width: 60,
        minHeight: 60,
        minWidth: 60,
        margin: '0 16px',
        '& img': {
          borderRadius: 4,
          width: '100%',
          height: '100%',
          objectFit: 'cover'
        },

        '@media screen and (max-width: 768px)': {
          margin: '0 8px'
        }
      },
      '& .orderBumpDescription': {
        fontSize: 16,
        fontWeight: 700,
        color: '#37474f',
        margin: 0,
        '& .orderBumpProductTitle': {
          fontWeight: 'bold',
          wordBreak: 'break-word'
        },
        '& p': {
          margin: 0,
          fontWeight: 'normal'
        },
        '& .theme-orderbump-highlight': {
          fontWeight: 'bold'
        },
        '& .orderBumpDescriptionText': {
          margin: 0,
          color: '#37474f !important'
        }
      }
    },
    '& .orderBumpSubtitle': {
      fontSize: 16,
      color: '#37474f',
      margin: 0
    },
    '& .orderBumpItems': {
      margin: '24px 0 0'
    },
    '& .orderBumpSelector': {
      cursor: 'pointer',
      marginTop: 16,
      paddingTop: 16,
      borderTop: '1px solid #b0bec5',
      '& label': {
        whiteSpace: 'normal',
        marginLeft: 0,
        transform: 'none'
      },
      '& span': {
        color: '#072d83',
        fontSize: 18,
        fontWeight: 'bold'
      },
      '& .orderBumpSelectorLabel': {
        display: 'block',
        height: 22,
        padding: '0 8px',

        '@media screen and (max-width: 768px)': {
          height: 'auto'
        }
      }
    },
    '& .orderBumpCheckbox': {
      padding: 0
    },
    '&.orderBump-template-14, &.orderBump-template-16, &.orderBump-template-ocb': {
      padding: 16,
      '& .orderBumpSelector .orderBumpSelectorLabel': {
        height: 'auto'
      },
      '& .orderBumpOffer .productImage': {
        margin: '0 8px'
      }
    }
  }
}))
class OrderBump extends BaseComponent<IState> {
  constructor(props: any) {
    super(props);

    this.state = {
      orderBumpProducts: null,
      orderBumpProductsSelected: [],
      product: null,
      productsIds: [],
      templateId: null
    };
  }

  componentDidMount() {
    cartService
      .getCart()
      .filter((cart: IProductData) => !!cart && cart?.products?.length > 0)
      .bindComponent(this)
      .subscribe((cart: IProductData) => {
        const orderBumpProducts = cart.orderBump;
        this.setState({ orderBumpProducts });

        const productsIds = cart.products.map((p) => p.id.toString());
        this.setState({ productsIds });

        const orderBumpProductsSelected = productsIds.filter((p) => p !== cart.contentId.toString());
        this.setState({ orderBumpProductsSelected });

        if (!orderBumpProducts || orderBumpProducts.length < 1) {
          return;
        }

        const product: IOrderBump = orderBumpProducts.find((ob: IOrderBump) => productsIds.includes(ob.contentId.toString()));

        if (!product) {
          return;
        }

        const productData: IProduct = cart.products.find((p: IProduct) => p.id.toString() === product.contentId.toString());
        this.setState({ product: productData });

        if (!productData) {
          paymentService.setBlockAddressAfterPayment(false);
        }
      });

    cartService
      .getConfig()
      .filter((config) => !!config)
      .bindComponent(this)
      .take(1)
      .subscribe((config) => {
        this.setState({ templateId: config.template.id.toString(), isOneClick: config.isOneClick });
      });
  }

  getCheckoutsIds = (orderBumpProductsSelected: string[]) => {
    const { orderBumpProducts } = this.state;
    return orderBumpProductsSelected.map((productId) => {
      const product = orderBumpProducts.find((p) => String(p.contentId) === String(productId));
      return product?.offer?.key ?? productId;
    });
  };

  addOrderBump = (productId: string, checked: boolean) => {
    of(true)
      .loader()
      .switchMap(() => {
        const orderBumpProductsSelected: string[] = JSON.parse(JSON.stringify(this.state.orderBumpProductsSelected));
        if (checked) {
          orderBumpProductsSelected.push(productId);
        }

        if (!checked) {
          const index = orderBumpProductsSelected.indexOf(productId);
          orderBumpProductsSelected.splice(index, 1);
        }

        this.setState({ orderBumpProductsSelected });

        const selectedBumpsCheckoutIds = this.getCheckoutsIds(orderBumpProductsSelected);

        return cartService.setOrderBumps(selectedBumpsCheckoutIds);
      })
      .loader()
      .subscribe((cart) => {
        const product = cart.products.find((p: IProduct) => p.id.toString() === productId);

        this.setState({ product });

        if (!product) {
          paymentService.setBlockAddressAfterPayment(false);
        }
      });
  };

  handleClickBumpItem = (product: IOrderBump) => {
    const productId = String(product.contentId);
    const { orderBumpProductsSelected, productsIds } = this.state;
    const checked = orderBumpProductsSelected.includes(String(productId)) || productsIds.includes(String(productId));
    this.addOrderBump(productId, !checked);
  };

  handleToggleSelectAllBumps = (event: React.FormEvent<HTMLInputElement>) => {
    const { checked } = event.currentTarget;

    of(true)
      .loader()
      .switchMap(() => {
        const { orderBumpProducts } = this.state;
        let orderBumpProductsSelected: string[] = [];

        orderBumpProductsSelected = checked ? orderBumpProducts.map((ob) => ob.contentId) : [];

        this.setState({ orderBumpProductsSelected });
        const selectedBumpsCheckoutIds = this.getCheckoutsIds(orderBumpProductsSelected);
        return cartService.setOrderBumps(selectedBumpsCheckoutIds);
      })
      .loader()
      .subscribe(() => {});
  };

  render() {
    if (!this.state || !this.state.orderBumpProducts) {
      return null;
    }

    const { orderBumpProductsSelected, orderBumpProducts, productsIds, templateId, isOneClick } = this.state;

    const isMultipleOrderBumps = orderBumpProducts.length > 1;
    const offerTitle = orderBumpProducts[0].offerTitle;

    return (
      <Fragment>
        <div
          className={`${this.props.classes.component} orderBump-template-${templateId} ${isOneClick ? 'orderBump-template-ocb' : ''} theme-orderbump`}
        >
          <h1 className="theme-orderbump-highlight">
            <Translate term={offerTitle || 'Oferta Imperdível'} />:
          </h1>
          {isMultipleOrderBumps && <p className="orderBumpSubtitle">{orderBumpProducts[0].description}</p>}
          <div className="orderBumpItems">
            {orderBumpProducts.map((bump, index) => (
              <div className="orderBumpItem theme-orderbump" key={bump.contentId}>
                <div className="orderBumpOffer theme-orderbump-highlight" onClick={() => this.handleClickBumpItem(bump)}>
                  <Checkbox
                    id={'check-order-bump-' + index}
                    className="orderBumpCheckbox"
                    value={String(bump.contentId)}
                    checked={orderBumpProductsSelected.includes(String(bump.contentId)) || productsIds.includes(String(bump.contentId))}
                  />
                  <div className="productImage">{bump.logo && <img src={getCdnImage(bump.logo)} />}</div>
                  <div className="orderBumpDescription">
                    <p className="orderBumpProductTitle">{bump.title}</p>
                    <p>
                      {bump.price === 0 || bump.paymentType === 'G' ? (
                        <>
                          Adquira&nbsp;
                          <span className="theme-orderbump-highlight">
                            <Translate term="gratuitamente" />
                          </span>
                        </>
                      ) : (
                        <Translate
                          className="orderBumpDescriptionText"
                          term={`Adquira também por apenas {price}${bump.shipping ? ' + frete' : ''}`}
                          properties={{
                            price: (
                              <span className="theme-orderbump-highlight">
                                <CurrencyFormat value={bump.price} />
                              </span>
                            )
                          }}
                        />
                      )}
                      {!isMultipleOrderBumps && <>&nbsp;{bump.description}</>}
                    </p>
                  </div>
                </div>
                <div>
                  {(orderBumpProductsSelected.includes(String(bump.contentId)) || productsIds.includes(String(bump.contentId))) && (
                    <OrderBumpQuantityForm
                      productId={bump.contentId.toString()}
                      limit={bump.purchaseLimit || 999999}
                      hideQuantity={bump.hideQuantity || bump.type !== enProductType.PHYSICAL}
                    />
                  )}
                </div>
              </div>
            ))}
          </div>
          {isMultipleOrderBumps && (
            <div className="orderBumpSelector theme-orderbump-highlight theme-orderbump">
              <FormControlLabel
                control={
                  <Checkbox
                    id={'check-order-bump-all'}
                    className="orderBumpCheckbox"
                    checked={orderBumpProductsSelected.length === orderBumpProducts.length}
                    onChange={this.handleToggleSelectAllBumps}
                  />
                }
                label={<Translate className="orderBumpSelectorLabel" term="Adicionar todas as ofertas em minha compra" />}
              />
            </div>
          )}
        </div>
      </Fragment>
    );
  }
}

export default React.memo(OrderBump);
