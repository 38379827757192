import { DependencyList, useCallback } from 'react';

import { useObservable } from 'react-use-observable';
import { observerFunction } from 'react-use-observable/dist/observable';

/**
 * Create a memoized observable with a map implemented and a distinctUntilChanged,
 * the observable will be unsubscribe automatically if component unmount
 * @returns [observableValue, error, isCompleted]
 */
export function useMappedObservable<T, W>(
  observableGenerator: observerFunction<T>,
  mapperFunction: (data: T) => W,
  deps: DependencyList
): [W, any, boolean, undefined] {
  const newGenerator = useCallback(() => {
    return observableGenerator()
      .map(mapperFunction)
      .distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b));
  }, [observableGenerator, mapperFunction]);

  return useObservable(newGenerator, deps);
}
