import MenuItem from '@material-ui/core/MenuItem';
import * as React from 'react';

import Grid from '@material-ui/core/Grid/Grid';
import { BaseComponent, IStateBase } from 'components/BaseComponent';
import { CurrencyFormat } from 'components/CurrencyFormat';
import FieldSelect from 'components/Field/Select';
import Translate from 'components/Translate';
import { enParameters } from 'enums/enParameters';
import { IPaymentModel } from 'interfaces/IPaymentModel';
import { IInstallment, IProductConfig, enLang } from 'interfaces/IProductData';
import { cartService } from 'services/CartService';
import { configService } from 'services/ConfigService';
import { calcDefaultNumberInstallments } from 'services/InstallmentsService';
import { internationalService } from 'services/InternationalService';
import { parameterService } from 'services/ParameterService';
import { paymentService } from 'services/PaymentService';

interface IState extends IStateBase {
  model: IPaymentModel;
  feeInstallment: number;
  installments: IInstallment[];
  selected?: IInstallment;
  selectedInstallment?: number;
  config: IProductConfig;
  changeInstallment: boolean;
}

export default class Installments extends BaseComponent<IState, { index: number }> {
  constructor(props: any) {
    super(props);

    this.state = {
      model: null,
      feeInstallment: 0,
      installments: null,
      selected: null,
      selectedInstallment: null,
      config: null,
      changeInstallment: false
    };
  }

  componentDidMount() {
    const feeObservable = cartService
      .getCart()
      .map((c) => c.feeInstallment)
      .distinctUntilChanged();

    configService.getConfig().subscribe((config) => this.setState({ config }));

    this.observeState({ feeInstallment: null }, feeObservable);

    paymentService
      .getPaymentInstallments(this.props.index)
      .map((payment) => payment.installments)
      .distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b))
      .bindComponent(this)
      .subscribe((installments) => this.setState({ installments }));

    parameterService
      .get(enParameters.p)
      .take(1)
      .filter((p) => !!p)
      .subscribe((p) => {
        this.setState({
          selectedInstallment: Number(p) || 12
        });

        if (Number(p) > 12) {
          this.enableChangeInstallment();
        }
      });

    cartService
      .getCart()
      .take(1)
      .filter((cart) => !!cart.installmentSelected)
      .combineLatest(parameterService.get(enParameters.pf), parameterService.get(enParameters.p))
      .filter(([, pf]) => pf !== '1')
      .bindComponent(this)
      .subscribe(([cart, , p]) => {
        this.setState({ selectedInstallment: Number(p) || cart.installmentSelected });
      });

    paymentService
      .getPayment(this.props.index)
      .filter((model) => !!model)
      .filter((model) => model.installments.length >= 1)
      .combineLatest(parameterService.get(enParameters.np))
      .map(([currentModel, np]) => {
        const model: IPaymentModel = JSON.parse(JSON.stringify(currentModel));
        const maxInstallments = Math.trunc(Number(np) || 0);

        if (maxInstallments > 0) {
          model.numberInstallments = maxInstallments;
          model.installments = model.installments.slice(0, maxInstallments);
        }

        return model;
      })
      .combineLatest(parameterService.get(enParameters.pr))
      .map(([currentModel, pr]) => {
        const model: IPaymentModel = JSON.parse(JSON.stringify(currentModel));

        if (!pr) {
          model.installments = model.installments.reverse();
        }

        return model;
      })
      .combineLatest(parameterService.get(enParameters.pf))
      .bindComponent(this)
      .debounceTime(50)
      .subscribe(([currentModel, pf]) => {
        const model: IPaymentModel = JSON.parse(JSON.stringify(currentModel));
        const maxInstallments = [...model.installments].sort((a, b) => (a.value > b.value ? 1 : -1))[0].installment;
        const defaultInstallments = calcDefaultNumberInstallments(maxInstallments);

        model.numberInstallments = this.state.selectedInstallment || model.numberInstallments || defaultInstallments;

        if (model.numberInstallments > maxInstallments) {
          model.numberInstallments = maxInstallments;
        }

        this.setModel(model);

        let selected = model.installments.find((i) => i.installment == model.numberInstallments) || null;

        if (selected && selected.installment > maxInstallments) {
          model.numberInstallments = maxInstallments;
          selected = null;
        }

        if (pf == '1' && !this.state.selectedInstallment && model.installments.length > 1) {
          model.numberInstallments = 0;
          this.enableChangeInstallment();
        }

        selected = model.installments.find((i) => i.installment == model.numberInstallments) || null;

        this.setState({
          installments: model.installments,
          selected
        });

        if (selected) {
          paymentService.setPaymentData(this.props.index, { numberInstallments: selected.installment });
        }
      });

    internationalService
      .getSelectedCountry()
      .skip(1)
      .debounceTime(100)
      .subscribe((country) => {
        if (country.lang !== enLang.PT_BR) {
          this.setState({
            selectedInstallment: 1,
            selected: null
          });
          paymentService.setPaymentData(this.props.index, { numberInstallments: 1 });
          cartService.setSelectedInstallment(1);
        } else {
          this.setState({
            selectedInstallment: 12,
            selected: null
          });
          paymentService.setPaymentData(this.props.index, { numberInstallments: 12 });
          cartService.setSelectedInstallment(12);
        }
      });
  }

  handleChange = (installment: any) => {
    if (installment === 0) {
      return;
    }

    const selected = this.state.installments.find((i) => i.installment == installment) || null;

    this.setState({
      selectedInstallment: installment,
      selected
    });

    setTimeout(() => {
      paymentService.setPaymentData(this.props.index, { numberInstallments: selected.installment });
    }, 0);
  };

  enableChangeInstallment = () => {
    this.setState({
      changeInstallment: true
    });
  };

  render() {
    const { installments, changeInstallment, model, feeInstallment, config, selectedInstallment } = this.state;
    const { index } = this.props;

    let { selected } = this.state;

    if (!installments || installments.length === 1 || selected === undefined || !model || (config.isCrossborder && selectedInstallment == 1)) {
      return null;
    }

    selected = selected || { installment: 0, value: 0 };

    return (
      <Grid item xs={12}>
        <div className="chk-installments-wrapper">
          <label className="chk-input-group">
            <div>
              {!changeInstallment ? (
                <div className="chk-installments__value">
                  <div style={{ display: 'inline' }} id="chk-selected-installment-value">
                    {selected.installment}x
                    <span>
                      &nbsp;
                      <CurrencyFormat value={selected.value} />
                      {feeInstallment === 0 ? (
                        <span className="chk-no-fee">
                          <Translate term="SEM JUROS" />
                        </span>
                      ) : (
                        ''
                      )}
                    </span>
                  </div>
                  {model.numberInstallments ? (
                    <a id={`chk-edit-installments-button-${index}`} className="theme-label" onClick={this.enableChangeInstallment}>
                      Editar Parcelas
                    </a>
                  ) : (
                    <a id={`chk-select-installments-button${index}`} className="theme-label" onClick={this.enableChangeInstallment}>
                      Selecione uma Parcela
                    </a>
                  )}
                </div>
              ) : (
                <Grid item xs={7}>
                  <div className="chk-installments-select zlabel">
                    <FieldSelect
                      id="installments"
                      className="chk-installments__installmentFieldSelect"
                      value={selected.installment}
                      validation="installments|installments_card_brand"
                      onChange={this.handleChange}
                      label="Parcelas"
                      InputLabelProps={{
                        className: 'theme-label'
                      }}
                      InputProps={{
                        id: 'installment-combobox'
                      }}
                    >
                      {selected.installment === 0 && <MenuItem value={0}>Selecione uma parcela</MenuItem>}
                      {installments.map((i, key) => (
                        <MenuItem key={key} value={i.installment} id={`installment-${i.installment}-combobox`}>
                          {i.installment}x&nbsp;de&nbsp;
                          <CurrencyFormat value={i.value} />
                          &nbsp;
                          {i.installment > 1 && feeInstallment > 0 ? '*' : ''}
                          {feeInstallment === 0 ? 'sem juros' : ''}
                        </MenuItem>
                      ))}
                    </FieldSelect>
                  </div>
                </Grid>
              )}
            </div>
          </label>
        </div>
      </Grid>
    );
  }
}
