interface IConfig {
  ignoreChars?: string;
}

export const sanitizePhone = (phone: string | undefined, config?: IConfig) => {
  const { ignoreChars = '' } = config ?? {};

  const regex = new RegExp(`[^0-9${ignoreChars}]`, 'g');

  return phone?.replace(regex, '');
};
