import React, { useEffect } from 'react';
import { memo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
// @ts-ignore
import { IThankYouPage } from 'interfaces/IThankYouPage.ts';
import { thankYouService } from 'services/ThankYouService';
import { useObservable } from 'react-use-observable';
import { enPaymentMethod } from 'enums/enPaymentMethod';
import { enLegacyPixelPath, legacyPixelService } from 'services/LegacyPixelService';
import { pixelService, enPixelPath } from 'services/PixelService';
import { postBackService } from 'services/PostBackService';
import ThankYou from 'components/ThankYou';
import MensageThankYou from './MensageThankYou';
import BankSlip from './BankSlip';

interface IProps {
  thankYouPage: IThankYouPage;
}

const useStyles = makeStyles((theme) => ({
  '@global': {
    '.content': {
      height: '98.85vh',
      width: '99.8vw'
    }
  },
  card: {
    backgroundColor: '#FFF',
    borderBottom: '1px solid #939393',
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 16,
    width: '100%',
    [theme.breakpoints.down('md')]: {
      padding: '20px',
      flexFlow: 'wrap',
      height: 'auto'
    }
  },
  buttonBankslip: {
    backgroundColor: '#367BD9',
    color: '#FFF',
    fontSize: '12px',
    fontWeight: 500,
    padding: '9px 21px',
    width: '24%',
    [theme.breakpoints.down('sm')]: {
      width: '70%',
      marginTop: '10px'
    },
    '&:hover': {
      backgroundColor: 'rgba(54,123,217, 0.58)'
    }
  },
  bankslip: {
    alignItems: 'center',
    display: 'flex',
    flexFlow: 'row wrap',
    justifyContent: 'space-between',
    width: '50%',
    [theme.breakpoints.down('sm')]: {
      marginTop: '10px',
      width: '100%',
      justifyContent: 'center'
    },
    '& p': {
      color: '#596375',
      fontSize: '14px',
      margin: 0,
      width: '100%'
    }
  },
  iframe: {
    border: 'none',
    height: '100%',
    width: '100%'
  },
  containerIframe: {
    height: '84vh',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      height: 'calc(100vh - 50px);'
    }
  }
}));

const ThankYouWithUpsell = memo((props: IProps) => {
  const style = useStyles(props);

  const transactionKey = window.location.pathname
    .replace(/^\/+|\/+$/gi, '')
    .split('/')
    .pop();

  const [thankYou] = useObservable(() => thankYouService.getThankyouByHash(transactionKey), []);
  const [result] = useObservable(() => thankYouService.getPostBack(), []);
  const [paymentResult, params] = result || [];

  useEffect(() => {
    if (!!paymentResult) {
      setTimeout(() => {
        postBackService.postParams(params, paymentResult.thankyouUrl, paymentResult.key);
      }, 100);
    }

    if (!!thankYou) {
      let pixelPath = enPixelPath.CREDITCARD;

      if (thankYou.paymentResult.paymentMethod === enPaymentMethod.BANKSLIP) {
        pixelPath = enPixelPath.BANKSLIP;
      }

      if (thankYou.paymentResult.paymentMethod === enPaymentMethod.PIX) {
        pixelPath = enPixelPath.PIX;
      }

      if (thankYou.paymentResult.paymentMethod === enPaymentMethod.PAGDIVIDIDO) {
        pixelPath = enPixelPath.PAGDIVIDIDO;
      }
      const isBankslip = thankYou.paymentResult.paymentMethod === enPaymentMethod.BANKSLIP;
      legacyPixelService.track(isBankslip ? enLegacyPixelPath.THANKYOU_BANKSLIP : enLegacyPixelPath.THANKYOU);
      pixelService.setPath(pixelPath);
    }
  }, [paymentResult, thankYou, params]);

  if (!thankYou) {
    return null;
  }

  const isBankslip = thankYou.paymentResult.paymentMethod === enPaymentMethod.BANKSLIP;
  const page = paymentResult ? postBackService.getParams(params, paymentResult.thankyouUrl, paymentResult.key) : thankYou.paymentResult.thankyouUrl;

  if (!thankYou.hasThankyouUpsell || !thankYou.paymentResult.thankyouUrl || !thankYou.paymentResult.thankyouUrl.includes('https')) {
    return <ThankYou />;
  }

  return (
    <React.Fragment>
      <div className={style.card}>
        <MensageThankYou thankYou={thankYou} paymentMethod={thankYou.paymentResult.paymentMethod} />
        {isBankslip && <BankSlip ThankYouUrl={page} Url={thankYou.paymentResult.bankslipUrl} Code={thankYou.paymentResult.bankslipCode} />}
      </div>
      <div className={style.containerIframe}>
        <div id="post-get" />
        <iframe className={style.iframe} id="post-params" name="post-params" src={page} />
      </div>
    </React.Fragment>
  );
});

export default ThankYouWithUpsell;
