import React from 'react';
import ReactDOM from 'react-dom';
import { IScript } from 'interfaces/IScript';
import { BaseComponent } from 'components/BaseComponent';

interface IProps {
  script: IScript;
}

export default class ClickDesk extends BaseComponent<{}, IProps> {
  componentDidMount() {
    const script = document.createElement('script');

    script.innerHTML = `var _glc = _glc || []; _glc.push('${this.props.script.value}');
      var glcpath = (('https:' == document.location.protocol) ? 'https://my.clickdesk.com/clickdesk-ui/browser/' :
        'http://my.clickdesk.com/clickdesk-ui/browser/');
      var glcp = (('https:' == document.location.protocol) ? 'https://' : 'http://');
      var glcspt = document.createElement('script'); glcspt.type = 'text/javascript';
      glcspt.async = true; glcspt.src = glcpath + 'livechat-new.js';
      var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(glcspt, s);`;

    // eslint-disable-next-line react/no-find-dom-node
    ReactDOM.findDOMNode(this).appendChild(script);

    const tout = window.setInterval(() => {
      const el = document.getElementsByClassName('cd-mob-chat-widget');
      if (el.length === 0) {
        return;
      }
      el[0].setAttribute('style', 'z-index: 5555');
      window.clearInterval(tout);
    }, 50);
  }

  render() {
    return <div />;
  }
}
