/* eslint-disable max-lines */
import { enFrequencyType } from 'enums/enFrequencyType';
import { enParameters } from 'enums/enParameters';
import { enPaymentMethod } from 'enums/enPaymentMethod';
import { enPaymentStatus } from 'enums/enPaymentStatus';
import { enPaymentType } from 'enums/enPaymentType';
import { enTerminalMethod } from 'enums/enTerminalMethod';
import { ICartModel, IProductModel, IStuddentModel } from 'interfaces/ICartModel';
import { ICouponModel } from 'interfaces/ICouponModel';
import { IPlan } from 'interfaces/INext';
import { IPaymentResult } from 'interfaces/IPaymentResult';
import { IProductConfig, IProductData } from 'interfaces/IProductData';
import { IThankYouPage } from 'interfaces/IThankYouPage';
import { IUserAddressModel } from 'interfaces/IUserAddressModel';
import get from 'lodash/get';
import { Cookies } from 'react-cookie';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Observable } from 'rxjs/Observable';
import { combineLatest } from 'rxjs/observable/combineLatest';
import { empty } from 'rxjs/observable/empty';
import { of } from 'rxjs/observable/of';
import { ReplaySubject } from 'rxjs/ReplaySubject';
import { cartService } from 'services/CartService';
import { paymentProgressService } from 'services/PaymentProgressService';
import { GOOFY_ID, MAX_CREDIT_CARDS } from 'settings';
import { round10, round2 } from 'shared/functions';
import { apiService } from 'shared/services';
import { isInternationalCurrency } from 'shared/utils/isInternationalCurrency';

import { enStatusWaiter } from 'enums/enStatusWaiter';
import { ValueOf } from 'interfaces/IValueOf';
import countriesList from '../data/countries.json';
import { IPaymentModel } from '../interfaces/IPaymentModel';
import { showAlert } from './../shared/services/dialog';
import { brandService } from './BrandService';
import { cardHashService } from './CardHashService';
import { currencyService } from './CurrencyService';
import { fingerprintService } from './Fingerprint';
import { goofyService } from './GoofyService';
import { hotJarService } from './HotJar';
import { getInstallments } from './InstallmentsService';
import { getCitiesByState } from './LocationService';
import { parameterService } from './ParameterService';
import { paymentWaiter } from './PaymentWaiterService';
import { pixelService } from './PixelService';
import shopifyService from './ShopifyService';
import { sanitizePhone } from 'shared/utils/sanitizePhone';

let attempts = 0;
const payAttempts = {};

const cookie = new Cookies();

export class PaymentService {
  private state: ICartModel = {
    payment: [{}]
  };

  private data$: BehaviorSubject<ICartModel>;
  private lockPayment$: BehaviorSubject<number>;
  private paymentResult$ = new ReplaySubject<IPaymentResult>(1);
  private paymentSent$ = new BehaviorSubject<boolean>(false);
  private blockPayment$ = new BehaviorSubject<boolean>(false);
  private blockAddressAfterPayment$ = new BehaviorSubject<boolean>(false);

  private hasPaymentResult = false;
  private paymentInProgress = false;
  private moreThan12InstallmentsFailed = false;

  constructor(state: ICartModel = null) {
    if (state) {
      this.state = state;
    }

    this.lockPayment$ = new BehaviorSubject(0);
    this.data$ = new BehaviorSubject(this.state);

    Observable.combineLatest(parameterService.get(enParameters.df), parameterService.get(enParameters.terminalId)).subscribe(([df, terminalId]) => {
      df = (df || '').replace(/[^0-9]/, '');
      if (df.length === 11) {
        this.setData({ document: df, terminalId });
      }
    });

    this.data$
      .asObservable()
      .map((state) => state.payment.map((p) => p.value))
      .filter((v) => v[0] != null)
      .map((v) => JSON.stringify(v))
      .debounceTime(500)
      .distinctUntilChanged()
      .switchMap(() => this.data$.asObservable().take(1))
      .switchMap((state) =>
        Observable.combineLatest(getInstallments(state.payment.map((p) => p.value)), Observable.of(state), currencyService.getCurrency())
      )
      .filter(([, , currency]) => !isInternationalCurrency(currency))
      .subscribe(([baseInstallments, state]) => {
        let installments = Object.values(baseInstallments);

        state.payment.forEach((p, key) => {
          const value = p.value;

          const i = installments.filter((installment) => {
            installment.installments.sort((a, b) => b.value - a.value);
            return installment.installments[0].value == value;
          });

          if (i.length === 0) {
            return;
          }

          const installment = i[0];
          this.setPaymentData(key, {
            installments: installment.installments,
            method: p.method ?? enTerminalMethod.CREDIT
          });

          this.setInstallmentsDefault();
        });
      });

    this.emit();
  }

  setAllowPayment() {
    return Observable.combineLatest(
      cartService.getCart().map((cart) => {
        return {
          config: cart.config,
          subscription: cart.subscription,
          price: cart.price
        };
      }),
      paymentService.isMultipleCreditCards(),
      parameterService.get(enParameters.changeCard),
      parameterService.get(enParameters.terminalId),
      parameterService.get(enParameters.b),
      parameterService.get(enParameters.bs),
      currencyService.getCurrency(),
      paymentService.getCart().map((p) => p.personType)
    )
      .distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b))
      .map(([{ config, subscription }, isMultipleCards, changeCard, terminalId, parameterBankslip, hideBankSlip, currency, personType]) => {
        let allPaymentsMethods = [...Object.values(enPaymentMethod)];
        let toRemove: enPaymentMethod[] = [enPaymentMethod.PAGDIVIDIDO];
        const isTrialSubscription = config.paymentType === enPaymentType.SUBSCRIPTION && subscription.hasTrial;

        if (!config.isOneClick) {
          toRemove.push(enPaymentMethod.ONE_CLICK_BUY);
        }
        if (config.paymentType !== enPaymentType.FREE) {
          toRemove.push(enPaymentMethod.FREE);
        }

        if (!config.enableBankslip || (parameterBankslip && Number(parameterBankslip) === 0) || hideBankSlip !== null) {
          toRemove.push(enPaymentMethod.BANKSLIP);
        }

        if (parameterBankslip && Number(parameterBankslip) === 1) {
          const index = toRemove.indexOf(enPaymentMethod.BANKSLIP);
          if (index > -1) {
            toRemove.splice(index, 1);
          }
        }
        if (!terminalId) {
          toRemove.push(enPaymentMethod.TERMINAL);
        }

        if (!config.enablePix) {
          toRemove.push(enPaymentMethod.PIX);
        }

        if (isInternationalCurrency(currency) || personType === 'E') {
          toRemove.push(enPaymentMethod.BANKSLIP, enPaymentMethod.PIX);
        }

        if (isTrialSubscription) {
          toRemove.push(enPaymentMethod.BANKSLIP);
        }

        if (!config.enablePaypal) {
          toRemove.push(enPaymentMethod.PAYPAL);
        }

        if (!config.enableEduzzWallet) {
          toRemove.push(enPaymentMethod.EDUZZ);
        }

        if (isMultipleCards) {
          toRemove.push(enPaymentMethod.PAYPAL, enPaymentMethod.BANKSLIP, enPaymentMethod.PIX);
        }

        if (!config.enableCreditcard) {
          toRemove.push(enPaymentMethod.CREDIT_CARD);
        }

        if (changeCard) {
          toRemove.push(enPaymentMethod.PAYPAL, enPaymentMethod.BANKSLIP, enPaymentMethod.PIX, enPaymentMethod.EDUZZ);
        }

        if (terminalId) {
          toRemove.push(
            enPaymentMethod.PAYPAL,
            enPaymentMethod.CREDIT_CARD,
            enPaymentMethod.PAGDIVIDIDO,
            enPaymentMethod.EDUZZ,
            enPaymentMethod.BANKSLIP
          );
        }

        const allowPayments = allPaymentsMethods.filter((method) => !toRemove.includes(method));
        this.data$.value.payment.forEach((p, index) => this.setPaymentData(index, { allowPayments }));
        this.setPaymentMethodDefault(allowPayments);
        return allowPayments;
      });
  }

  setPaymentMethodDefault(allowPayments: enPaymentMethod[]) {
    const currentPaymentMethod = this.data$.value.payment[0]?.paymentMethod;

    if (allowPayments.includes(currentPaymentMethod)) {
      return;
    }

    if (allowPayments.includes(enPaymentMethod.TERMINAL)) {
      this.setPaymentData(0, { paymentMethod: enPaymentMethod.TERMINAL });
      return;
    }

    if (allowPayments.includes(enPaymentMethod.ONE_CLICK_BUY)) {
      this.setPaymentData(0, { paymentMethod: enPaymentMethod.ONE_CLICK_BUY });
      return;
    }

    if (allowPayments.includes(enPaymentMethod.FREE)) {
      this.setPaymentData(0, { paymentMethod: enPaymentMethod.FREE });
      return;
    }

    if (allowPayments.includes(enPaymentMethod.CREDIT_CARD)) {
      this.setPaymentData(0, { paymentMethod: enPaymentMethod.CREDIT_CARD });
      return;
    }

    if (allowPayments.includes(enPaymentMethod.PIX)) {
      this.setPaymentData(0, { paymentMethod: enPaymentMethod.PIX });
      return;
    }

    if (allowPayments.includes(enPaymentMethod.BANKSLIP)) {
      this.setPaymentData(0, { paymentMethod: enPaymentMethod.BANKSLIP });
      return;
    }

    if (allowPayments.includes(enPaymentMethod.PAGDIVIDIDO)) {
      this.setPaymentData(0, { paymentMethod: enPaymentMethod.PAGDIVIDIDO });
      return;
    }

    if (allowPayments.includes(enPaymentMethod.PAYPAL)) {
      this.setPaymentData(0, { paymentMethod: enPaymentMethod.PAYPAL });
      return;
    }

    if (allowPayments.includes(enPaymentMethod.EDUZZ)) {
      this.setPaymentData(0, { paymentMethod: enPaymentMethod.EDUZZ });
      return;
    }

    this.setPaymentData(0, { paymentMethod: enPaymentMethod.CREDIT_CARD });
  }

  addLock() {
    this.lockPayment$.next(this.lockPayment$.value + 1);
  }

  removeLock() {
    if (this.lockPayment$.value <= 0) {
      this.lockPayment$.next(0);
      return;
    }

    this.lockPayment$.next(this.lockPayment$.value - 1);
  }

  getMoreThan12InstallmentsFailed() {
    return this.moreThan12InstallmentsFailed;
  }

  hasPaymentInProgress(): boolean {
    return this.paymentInProgress;
  }

  hasPaymentSent(): Observable<boolean> {
    return this.paymentSent$.asObservable().distinctUntilChanged();
  }

  pay(): Observable<IPaymentResult> {
    this.paymentInProgress = true;
    this.paymentSent$.next(true);

    paymentProgressService.setPaymentProgressStatus({
      status: enPaymentStatus.PROGRESS
    });

    let selectedPaymentMethod: enPaymentMethod;

    return (
      this.lockPayment$
        .filter((lock) => lock === 0)
        .take(1)
        .switchMap(() => cartService.getCart())
        .filter((data) => !data.isCached)
        .map((cart) => cart.price)
        .map((price) => {
          if (this.state.payment.length > 1) {
            const sumValuePayment = round10(this.state.payment.reduce((acc, p) => acc + p.value, 0));
            if (sumValuePayment < round10(price)) {
              return Observable.throw(new Error('Por favor, verifique a soma dos valores dos cartões.'));
            }
          }

          return true;
        })
        .switchMap(() => combineLatest(this.normalizePaymentData()))
        .take(1)
        .switchMap(([data]) => {
          return combineLatest(
            cartService.getCart(),
            this.getCart(),
            parameterService.getAll(),
            of(data),
            fingerprintService.getFingerprint(data.payment[0].paymentMethod)
          );
        })
        .take(1)
        .do(([cart, payment, , , fingerprint]) => {
          this.sendToGoofy(payment, cart, fingerprint);
        })
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .switchMap(([cart, , parameters, data, fingerprint]) => {
          selectedPaymentMethod = data.payment[0].paymentMethod;

          let url = '/pay/creditcard';

          if (selectedPaymentMethod === enPaymentMethod.EDUZZ) {
            url = '/pay/eduzzwallet';
          }

          if (selectedPaymentMethod === enPaymentMethod.BANKSLIP) {
            url = '/pay/bankslip';
          }

          if (selectedPaymentMethod === enPaymentMethod.FREE) {
            url = '/pay/free';
          }

          if (selectedPaymentMethod === enPaymentMethod.PIX) {
            url = '/pay/pix';
          }

          if (selectedPaymentMethod === enPaymentMethod.PAGDIVIDIDO) {
            url = '/pay/pagdividido';
          }

          if (selectedPaymentMethod === enPaymentMethod.PAYPAL) {
            try {
              cookie.set(`pixel-${cart.contentId}`, true, {
                maxAge: 60 * 60 * 24 * 90
              });
            } catch (err) {}

            url = '/pay/paypal';
          }

          if (cart.config.isOneClick) {
            url = '/pay/oneclickbuy';
            selectedPaymentMethod = enPaymentMethod.ONE_CLICK_BUY;
          }

          if (cart.subscription && (cart.subscription.hasTrial || cart.price == 0) && !cart.contractId) {
            url = '/pay/creditcardtrial';
          }

          if (
            cart.subscription &&
            (cart.subscription.hasTrial || cart.price == 0) &&
            !cart.contractId &&
            selectedPaymentMethod === enPaymentMethod.PIX
          ) {
            url = '/pay/pixtrial';
          }

          if (cart.subscription && (cart.subscription.hasTrial || cart.price == 0) && cart.config.isOneClick) {
            url = '/pay/oneclickbuytrial';
          }

          if (
            cart.subscription &&
            (cart.subscription.hasTrial || cart.price == 0) &&
            !cart.contractId &&
            selectedPaymentMethod === enPaymentMethod.BANKSLIP
          ) {
            url = '/pay/trial';
          }

          if (selectedPaymentMethod === enPaymentMethod.TERMINAL) {
            url = '/pay/terminal';
          }

          if (cart.subscription && (cart.subscription.hasTrial || cart.price == 0) && selectedPaymentMethod === enPaymentMethod.EDUZZ) {
            url = '/pay/eduzzwallettrial';
          }

          const body: any = {
            transactionKey: data.ssid,
            ead: cart.config.ead,
            terminalId: parameters?.terminalId ?? null,
            goofyId: GOOFY_ID,
            contentId: cart.contentId,
            document: cart.config.ead ? data.payment[0].document ?? data.document.trim() : data?.document?.trim(),
            currency: cart.config.currency,
            email: data?.email?.trim(),
            fullPrice: data.fullPrice,
            name: data?.name?.trim(),
            studdent: cart.config.ead ? { ...data.studdent, phone: `${data?.ddi ?? ''}${sanitizePhone(data.studdent.phone)}` } : null,
            same: data.samePerson,
            fingerprint,
            payment: data.payment.map((p) => {
              let installments = cart.config.currency === 'USD' ? 1 : p.numberInstallments || 1;

              if ([enPaymentMethod.PIX, enPaymentMethod.BANKSLIP, enPaymentMethod.EDUZZ, enPaymentMethod.PAYPAL].includes(selectedPaymentMethod)) {
                installments = 1;
              }

              if (selectedPaymentMethod === enPaymentMethod.PAGDIVIDIDO) {
                return {
                  paymentMethod: p.paymentMethod,
                  value: p.value,
                  document: p.document,
                  birthDate: `${data.pagDividido?.birthDate}`.replace(/^([0-9]{2})\/([0-9]{2})\/([0-9]{4})$/, '$3-$2-$1'),
                  gender: `${data.pagDividido?.gender || 'M'}`.toUpperCase(),
                  city: data.pagDividido?.city,
                  province: data.pagDividido?.province,
                  offerId: p.pagDivididoOffer?.id
                };
              }

              return {
                brand: p.brand,
                creditCard: p.creditCard,
                paymentMethod: p.paymentMethod,
                method: p.method,
                saveCard: p.saveCard,
                value: p.value,
                expires: p.expires,
                cvv: p.cvv,
                name: p.name,
                installments,
                document: p.document,
                email: p.email,
                password: p.password,
                hashes: p.hashes
              };
            }),
            personType: data.personType,
            phone: `${data?.ddi || ''}${sanitizePhone(data?.phone)}`,
            productType: data.productType,
            quantity: data.quantity,
            captcha: data.captchaToken || null,
            paymentMethod: selectedPaymentMethod,
            parameters: { ...parameters, phone: sanitizePhone(parameters?.phone), studentPhone: sanitizePhone(parameters?.studentPhone) }
          };

          if (cart.config.ead && (data.samePerson || selectedPaymentMethod === enPaymentMethod.FREE)) {
            body.ead = true;
            body.email = data.studdent.email.trim();
            body.name = data.studdent.name.trim();
            body.phone = sanitizePhone(data?.studdent?.phone);
            body.document = data.studdent.document.trim();
          }

          if (data.shipping) {
            body.shipping = data.shipping;
          }

          if (data.address && data.address.postalCode) {
            if (data.personType === 'E') {
              body.address = {
                cityId: data.address.cityId || null,
                street: data.address.street || null,
                complement: data.address.complement || null,
                district: data.address.district || null,
                postalCode: data.address.postalCode || null,
                number: data.address.number || null,
                province: data.address.province || null,
                countryId: data.address.country || null,
                cityName: data.address.cityName || null,
                countryName: data.address.countryName || null
              };
            }

            if (data.personType !== 'E') {
              body.address = {
                cityId: data.address.cityId || null,
                street: data.address.street || null,
                complement: data.address.complement || null,
                district: data.address.district || null,
                postalCode: data.address.postalCode || null,
                number: data.address.number || null,
                province: data.address.province || null,
                countryId: data.address.country || null
              };
            }
          }

          if (data.next) {
            body.next = data.next;
          }

          return apiService.post<IPaymentResult>(url, body, null, { maxAttempts: 1 }).map((r) => {
            this.hasPaymentResult = true;

            r.personType = data.personType;
            r.brand = r.brand || data.payment[0].brand;
            r.producerId = cart.config.producerId;
            r.contentId = cart.contentId;

            if (selectedPaymentMethod != enPaymentMethod.CREDIT_CARD) {
              r.brand = '';
            }

            return r;
          });
        })
        .switchMap((result) => {
          return pixelService
            .sendPurchaseData(result)
            .switchMap(() => of(result))
            .catch((err) => {
              console.error('Erro ao enviar dados da compra para o Pixel (API de Conversão)', err);
              return of(result);
            });
        })
        .switchMap((result) => {
          if (result.checkUrl) {
            return paymentWaiter
              .waitPayment(result.type as enPaymentMethod, result)
              .take(1)
              .switchMap((status) => {
                if ([enStatusWaiter.TIMEOUT, enStatusWaiter.CANCELED].includes(status)) {
                  paymentProgressService.setPaymentProgressStatus({
                    status: enPaymentStatus.IDLE,
                    message: ''
                  });

                  return empty<IPaymentResult>();
                }

                return of(result);
              });
          }

          return of(result);
        })
        .do((result) => {
          this.paymentResult$.next(result);
          shopifyService.clearCartData();
          window.parent.postMessage(`EdzSuccess_${selectedPaymentMethod}`, '*');
        })
        .catch((err) => {
          return Observable.throw(err);
        })
        .finally(() => {
          paymentWaiter.resetWaiter();
          this.setData({ captchaToken: null });
          this.paymentInProgress = false;
        })
        .logError()
    );
  }

  hasPayment() {
    return this.hasPaymentResult;
  }

  switchPersonType() {
    this.setData({
      personType: this.state.personType === 'E' ? 'F' : 'E'
    });
  }

  loadPaymentByHash(transactionKey: string, parameters: any): Observable<IThankYouPage> {
    return apiService.get(`/thankyou/${transactionKey}`, parameters).logError();
  }

  getPaymentResult(): Observable<IPaymentResult> {
    return this.paymentResult$.asObservable();
  }

  getCart(): Observable<ICartModel> {
    return this.data$.asObservable().distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b));
  }

  getCurrentCart(): ICartModel {
    return JSON.parse(JSON.stringify(this.data$.value));
  }

  getProduct(index: number) {
    return this.getCart()
      .filter((c) => !!c && !!c.products && c.products.length > 0)
      .map((c) => c.products[index])
      .distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b));
  }

  getPayment(index: number) {
    return this.getCart()
      .map((c) => c.payment[index])
      .distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b));
  }

  getPaymentInstallments(index: number) {
    return this.getCart()
      .filter((cart) => !!cart.payment[index])
      .map((cart) => cart.payment[index])
      .map((payment) => {
        return {
          installments: payment.installments,
          numberInstallments: payment.numberInstallments
        };
      })
      .distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b));
  }

  isMultipleCreditCards(): Observable<boolean> {
    return this.getCart().map((cart) => cart.payment.length > 1);
  }

  getAddress(field: 'address' | 'deliveryAddress' = 'address') {
    return this.getCart()
      .map((c) => c[field] || {})
      .distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b));
  }

  getCoupon() {
    return this.getCart()
      .map((c) => c.coupon || {})
      .distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b));
  }

  setMoreThan12InstallmentsFailed(moreThan12InstallmentsFailed: boolean) {
    this.moreThan12InstallmentsFailed = this.moreThan12InstallmentsFailed || moreThan12InstallmentsFailed;
  }

  setData(obj: Partial<ICartModel> = {}): void {
    if (obj.payment) {
      throw new Error('Invalid property, use setPaymentData');
    }

    if (obj.address) {
      throw new Error('Invalid property, use setAddressData');
    }
    this.state = { ...this.state, ...obj };

    if (this.state.personType !== 'E') {
      this.state.document = (this.state.document || '').replace(/\D/gi, '');
      this.state.personType = this.state.document.length <= 11 ? 'F' : 'J';
    }

    if (!!this.state.phone) {
      const ddiList = countriesList.map((country) => country.DDI);

      const ddiPhone = ddiList.find((item) => {
        item.replace('+', '');
        return this.state.phone.includes(item);
      });

      if (ddiPhone) {
        this.state.phone = this.state.phone?.toString().replaceAll(ddiPhone, '');
      }
    }

    this.emit();
  }

  setStudentData(obj: Partial<IStuddentModel> = {}): void {
    const currentStuddent = this.state.studdent || {};

    const studdentData = {
      studdent: { ...currentStuddent, ...obj }
    };

    this.state = { ...this.state, ...studdentData };

    this.emit();
  }

  setProductData(index: number, obj: Partial<IProductModel> = {}) {
    const product = this.state.products[index];

    this.state.products[index] = { ...product, ...obj };
    this.emit();
  }

  setInstallmentsDefault = () => {
    this.getCart()
      .combineLatest(parameterService.get(enParameters.p), cartService.getCart())
      .take(1)
      .subscribe(([paymentModel, parameter, cart]) => {
        const [mainPayment] = paymentModel.payment;
        if (parameter) {
          const installmentsParameters = Number(parameter) > cart.installments.length ? cart.installments.length : Number(parameter);
          this.setPaymentData(0, { numberInstallments: installmentsParameters });
          return;
        }

        if (mainPayment.paymentMethod === enPaymentMethod.TERMINAL && mainPayment.method !== enTerminalMethod.CREDIT) {
          this.setPaymentData(0, { numberInstallments: 1 });
          return;
        }

        if ([enPaymentMethod.BANKSLIP, enPaymentMethod.PIX, enPaymentMethod.EDUZZ, enPaymentMethod.PAGDIVIDIDO].includes(mainPayment.paymentMethod)) {
          this.setPaymentData(0, { numberInstallments: 1 });
          return;
        }

        if (cart.installmentSelected) {
          this.setPaymentData(0, { numberInstallments: cart.installmentSelected });
          return;
        }

        cartService.updateInstallments();
      });
  };

  setPaymentData(index: number, obj: Partial<IPaymentModel> = {}) {
    const payment = this.state.payment[index];

    if (obj.creditCard) {
      obj.brand = brandService.getCardBrandByNumber(obj.creditCard);
    }

    this.state.payment[index] = { ...payment, ...obj };
    this.emit();
  }

  handleChangeSaveCard(index: number, saveCard: boolean) {
    const currentPayments = this.state.payment;
    currentPayments.map((payment, i) => {
      this.state.payment[i] = {
        ...payment,
        saveCard: index === i ? saveCard : false
      };
    });

    this.emit();
  }

  addPayment(): Observable<any> {
    if (this.state.payment.length >= MAX_CREDIT_CARDS) {
      return Observable.empty();
    }

    return cartService
      .getCart()
      .take(1)
      .map((cart) => cart.price)
      .switchMap((price) => {
        const currentIndex = this.state.payment.length - 1;
        const currentPayment = this.state.payment[currentIndex];

        const lastValue = currentPayment.value;
        const newValue = round10(lastValue / 2);

        currentPayment.value = newValue;

        let sumValueAllPayments = newValue;
        this.state.payment.forEach((payment) => {
          sumValueAllPayments += payment.value;
        });

        const valueNewPayment = round10(newValue + price - sumValueAllPayments);

        return Observable.combineLatest(
          getInstallments([newValue, valueNewPayment]),
          Observable.of(currentPayment),
          Observable.of(valueNewPayment),
          parameterService.get(enParameters.pf)
        );
      })
      .map(([installments, currentPayment, valueNewPayment, pf]) => {
        const _installments = Object.entries(installments);

        const installmentsNewPayment = _installments.find((i) => Number(i[0]) === valueNewPayment)[1];
        const installmentscurrentPayment = _installments.find((i) => Number(i[0]) === currentPayment.value)[1];

        currentPayment.installments = installmentscurrentPayment.installments;
        currentPayment.numberInstallments = installmentscurrentPayment.maxInstallments;

        if (pf === '1') {
          currentPayment.installments = [{ installment: 0, value: 0 }];
          currentPayment.numberInstallments = 0;
        }

        const newPayment = {
          value: round2(valueNewPayment),
          installments: installmentsNewPayment.installments
        };

        this.setInstallmentsDefault();

        const payment = [...this.state.payment, newPayment];
        this.state.payment = payment;

        this.emit();
      });
  }

  removePayment(index: number, numberCreditCards: number = 1) {
    if (this.state.payment.length > 1) {
      const payment = this.state.payment;
      payment.splice(index, numberCreditCards);

      this.state.payment = payment;
      this.emit();

      if (payment.length == 1) {
        cartService
          .getCart()
          .take(1)
          .map((cart) => cart.price)
          .subscribe((price) => {
            payment[0].value = price;
            this.state.payment = payment;
            this.emit();
          });
      }
    }
  }

  setMethodPaymentDefault = (config: IProductConfig, terminalId?: string) => {
    const { payment } = this.data$.value;

    if (Boolean(terminalId)) {
      const data = { paymentMethod: enPaymentMethod.TERMINAL };
      payment.forEach((model, index) => this.setPaymentData(index, data));
      return;
    }

    if (config.enableCreditcard) {
      const data = { paymentMethod: enPaymentMethod.CREDIT_CARD, brand: 'other' };
      payment.forEach((model, index) => this.setPaymentData(index, data));
      return;
    }

    if (config.enablePaypal) {
      const data = { paymentMethod: enPaymentMethod.PAYPAL };
      payment.forEach((model, index) => this.setPaymentData(index, data));
      return;
    }
  };

  setSelectedPlan = (plan: IPlan) => {
    cartService
      .getCart()
      .switchMap((cart) => {
        return apiService.put(`/plan/${cart.transactionKey}/${cart.contentId}`, plan);
      })
      .loader()
      .subscribe(() => {
        this.setData({ next: { plan } });
      });
  };

  setAddressData(field: 'address' | 'deliveryAddress', obj: Partial<IUserAddressModel> = {}) {
    const address = this.state[field] || {};
    this.state[field] = { ...address, ...obj };

    this.getCart()
      .combineLatest(cartService.getCart())
      .take(1)
      .filter(([payment, cart]) => payment.personType != 'E' || !cart.config.allowForeignShipping)
      .switchMap(() => getCitiesByState(this.state[field].province))
      .take(1)
      .subscribe((cities) => {
        const city = cities.find(
          (c) =>
            String(c.name).toLowerCase() === String(get(this.state, `${field}.city`, '')).toLowerCase() ||
            c.id == get(this.state, `${field}.cityId`, '')
        );

        if (city) {
          this.state[field].city = city.name;
          this.state[field].cityId = city.id;
        }
        this.emit();
      });

    this.getCart()
      .take(1)
      .filter((cart) => cart.personType == 'E')
      .subscribe(() => {
        this.emit();
      });
  }

  setCoupon(obj: Partial<ICouponModel> = {}) {
    const coupon = this.state.coupon || {};
    this.state.coupon = { ...coupon, ...obj };
    this.emit();
  }

  showPaymentMultipleCardsButton(): Observable<boolean> {
    return cartService
      .getCart()
      .take(1)
      .combineLatest(this.getCart(), currencyService.getCurrency())
      .switchMap(([cart, payment, currency]) => {
        if (isInternationalCurrency(currency)) {
          this.removePayment(1, payment.payment.length - 1);

          return Observable.of(false);
        }

        if (cart.config.paymentType === enPaymentType.FREE) {
          return Observable.of(false);
        }

        const isMultipleCreditCards = cart.config.multipleCreditCards;
        const viewButtonMultipleCards = [enPaymentMethod.CREDIT_CARD, enPaymentMethod.ONE_CLICK_BUY].includes(payment.payment[0].paymentMethod);

        return Observable.of(isMultipleCreditCards && viewButtonMultipleCards);
      });
  }

  public loadUserParameters({ hasAddress = false, isEad = false }: { hasAddress: boolean; isEad: boolean }) {
    parameterService.getAll().subscribe((parameters) => {
      const BRAZIL_ID = '112';
      const INITIAL_REDUCER = { user: {}, student: {}, address: {} };

      const addressToKey: Partial<Record<enParameters, keyof IUserAddressModel>> = {
        [enParameters.comp]: 'complement',
        [enParameters.num]: 'number',
        [enParameters.cep]: 'postalCode',
        [enParameters.zip]: 'postalCode',
        [enParameters.street]: 'street',
        [enParameters.state]: 'province',
        [enParameters.location]: 'country',
        [enParameters.district]: 'district',
        [enParameters.city]: (parameters[enParameters.location] || BRAZIL_ID) === BRAZIL_ID ? 'city' : 'cityName'
      };

      const userToKey: Partial<Record<enParameters, keyof ICartModel>> = {
        [enParameters.doc]: 'document',
        [enParameters.name]: 'name',
        [enParameters.nome]: 'name',
        [enParameters.email]: 'email',
        [enParameters.cel]: 'phone',
        [enParameters.phone]: 'phone',
        [enParameters.samePerson]: 'samePerson'
      };

      const studentToKey: Partial<Record<enParameters, keyof IStuddentModel>> = {
        [enParameters.studentDocument]: 'document',
        [enParameters.studentName]: 'name',
        [enParameters.studentEmail]: 'email',
        [enParameters.studentPhone]: 'phone'
      };

      const addressObj = (parameterKey: string, parameterValue: string) => {
        if (!addressToKey.hasOwnProperty(parameterKey)) {
          return {};
        }

        let value: ValueOf<IUserAddressModel> = parameterValue;
        const postalCode: keyof IUserAddressModel = 'postalCode';

        if (addressToKey[parameterKey] === postalCode && !(value.indexOf('-') > -1) && value.length === 8) {
          value = value.substr(0, 5) + '-' + value.substr(4 + 1);
        }

        return { [addressToKey[parameterKey]]: value };
      };

      const userObj = (parameterKey: string, parameterValue: string) => {
        if (!userToKey.hasOwnProperty(parameterKey)) {
          return {};
        }

        let value: ValueOf<ICartModel> = parameterValue;
        const samePerson: keyof ICartModel = 'samePerson';

        if (userToKey[parameterKey] === samePerson) {
          value = value === 'true';
        }

        return { [userToKey[parameterKey]]: value };
      };

      const studentObj = (parameterKey: string, parameterValue: string) => {
        if (!studentToKey.hasOwnProperty(parameterKey)) {
          return {};
        }

        return { [studentToKey[parameterKey]]: parameterValue };
      };

      const reduceParameters = (acc: typeof INITIAL_REDUCER, [parameterKey, parameterValue]: [string, string]) => {
        if (hasAddress) {
          acc.address = { ...acc.address, ...addressObj(parameterKey, parameterValue) };
        }

        if (isEad) {
          acc.student = { ...acc.student, ...studentObj(parameterKey, parameterValue) };
        }

        acc.user = { ...acc.user, ...userObj(parameterKey, parameterValue) };

        return acc;
      };

      const { address, user, student } = Object.entries(parameters).reduce(reduceParameters, INITIAL_REDUCER);

      if (hasAddress) {
        this.setAddressData('address', address);
      }

      if (isEad) {
        this.setStudentData(student);
      }

      this.setData(user);
    });
  }

  public loadParameters() {
    parameterService.getAll().subscribe((parameters) => {
      Object.keys(parameters).map((p) => {
        if (p === enParameters.p) {
          this.setDefaultInstallment(Number(parameters[p]));
        } else if (p === enParameters.pf) {
          this.setPaymentData(0, { numberInstallments: 0 });
        }
      });
    });

    parameterService
      .get(enParameters.cupom)
      .combineLatest(cartService.getCart())
      .take(1)
      .filter(([coupon, cart]) => !!coupon && cart.config.paymentType !== enPaymentType.DONATION)
      .switchMap(([coupon]) => cartService.setCoupon(coupon))
      .subscribe(
        () => {},
        () => {
          showAlert('Há algo errado com o seu cupom, por favor entre em contato com o vendedor', 'Ops!').subscribe();
        }
      );
  }

  public setDefaultInstallment(installmentSelected: number) {
    const maxNumberInstallment = Math.max.apply(
      Math,
      this.state.payment[0].installments.map((i) => i.installment)
    );
    const numberInstallments = maxNumberInstallment >= installmentSelected ? installmentSelected : maxNumberInstallment;
    this.setPaymentData(0, { numberInstallments });
  }

  public getNormalizedFrequencyType(type: enFrequencyType, frequency: number) {
    if (type == enFrequencyType.DIARIO) {
      return frequency > 1 ? 'dias' : 'dia';
    }

    if (type == enFrequencyType.MENSAL) {
      return frequency > 1 ? 'meses' : 'mês';
    }

    if (type == enFrequencyType.ANUAL) {
      return frequency > 1 ? 'anos' : 'ano';
    }

    return '';
  }

  public getNormalizedFrequencyTypeEnUs(type: enFrequencyType, frequency: number) {
    if (type == enFrequencyType.DIARIO) {
      return frequency > 1 ? 'days' : 'day';
    }

    if (type == enFrequencyType.MENSAL) {
      return frequency > 1 ? 'months' : 'month';
    }

    if (type == enFrequencyType.ANUAL) {
      return frequency > 1 ? 'years' : 'year';
    }

    return '';
  }

  public getNormalizedPaymentType(type: enPaymentMethod): enPaymentMethod {
    if (type === enPaymentMethod.PAYPAL) {
      hotJarService.log('paypal', true);
      return enPaymentMethod.PAYPAL;
    }

    if (type === enPaymentMethod.BANKSLIP) {
      hotJarService.log('bankslip', true);
      return enPaymentMethod.BANKSLIP;
    }

    if (type === enPaymentMethod.FREE) {
      hotJarService.log('free', true);
      return enPaymentMethod.FREE;
    }

    if (type === enPaymentMethod.EDUZZ) {
      hotJarService.log('free', true);
      //verificar
      return enPaymentMethod.EDUZZ;
    }

    if (type === enPaymentMethod.TERMINAL) {
      hotJarService.log('free', true);
      return enPaymentMethod.TERMINAL;
    }

    if (type === enPaymentMethod.ONE_CLICK_BUY) {
      hotJarService.log('oneClick', true);
      return enPaymentMethod.ONE_CLICK_BUY;
    }

    if (type === enPaymentMethod.PIX) {
      hotJarService.log('pix', true);
      return enPaymentMethod.PIX;
    }

    if (type === enPaymentMethod.PAGDIVIDIDO) {
      hotJarService.log('pagDividido', true);
      return enPaymentMethod.PAGDIVIDIDO;
    }

    hotJarService.log('creditCard', true);
    return enPaymentMethod.CREDIT_CARD;
  }

  public setBlockPayment(blockPayment: boolean): void {
    this.blockPayment$.next(blockPayment);
  }

  public shouldBlockPayment(): Observable<boolean> {
    return this.blockPayment$.asObservable().distinctUntilChanged();
  }

  public setBlockAddressAfterPayment(blockAddressAfter: boolean): void {
    this.blockAddressAfterPayment$.next(blockAddressAfter);
  }

  public shouldBlockAddressAfterPayment(): Observable<boolean> {
    return this.blockAddressAfterPayment$.asObservable().distinctUntilChanged();
  }

  private async generatePaymentTokens(data: ICartModel): Promise<ICartModel> {
    for (const payment of data.payment) {
      if (payment.paymentMethod !== enPaymentMethod.CREDIT_CARD) {
        continue;
      }

      payment.secret = `${payment?.creditCard?.substring(0, 2)}** **** **** **${payment?.creditCard?.substring(payment?.creditCard?.length - 2)}`;

      const [month, year] = (payment.expires || '/').split('/');

      payment.hashes = await cardHashService.generateHashes({
        email: data.email,
        number: payment?.creditCard,
        month,
        year,
        document: data.document,
        cvv: payment?.cvv,
        name: payment?.name
      });
    }

    return data;
  }

  private normalizePaymentData(): Observable<ICartModel> {
    const data: ICartModel = JSON.parse(JSON.stringify(this.state));

    return Observable.of(data).switchMap((data) => {
      if (!data.personType) {
        data.personType = (data.document || '').length == 14 ? 'J' : 'F';
      }

      for (const payment of data.payment) {
        payment.paymentMethod = this.getNormalizedPaymentType(payment.paymentMethod);
      }

      return Observable.fromPromise(this.generatePaymentTokens(data));
    });
  }

  private sendToGoofy(cart: ICartModel, mountedCart: IProductData, fingerprint: string) {
    attempts++;

    const extraPaymentData = {};

    const payment = cart.payment[0];

    let method = 'CreditCard';

    if (payment.paymentMethod == enPaymentMethod.EDUZZ) {
      method = 'EduzzWallet';
    }

    if (payment.paymentMethod == enPaymentMethod.PAGDIVIDIDO) {
      method = 'Pagdividido';
    }

    if (payment.paymentMethod == enPaymentMethod.BANKSLIP) {
      method = 'Bankslip';
    }

    if (payment.paymentMethod == enPaymentMethod.ONE_CLICK_BUY) {
      method = 'OneClickBuy';
    }

    if (payment.paymentMethod == enPaymentMethod.PAYPAL) {
      method = 'Paypal';
    }

    if (payment.paymentMethod == enPaymentMethod.PIX) {
      method = 'Pix';
    }

    if (payment.paymentMethod == enPaymentMethod.FREE) {
      method = 'Free';
    }

    if (payment.paymentMethod === enPaymentMethod.TERMINAL) {
      method = 'Terminal';
    }

    payAttempts[method] = payAttempts[method] || 0;
    payAttempts[method]++;

    extraPaymentData[`methodAttempt${method}`] = true;
    extraPaymentData['lastAttempt'] = method;
    extraPaymentData['brand'] = payment.brand || '';

    Object.keys(payAttempts).forEach((key) => {
      extraPaymentData[`payAttempts${key}`] = payAttempts[key];
    });

    let extraUserData = {};

    if (cart.name) {
      extraUserData = {
        name: cart.name || '',
        email: cart.email || '',
        phone: cart?.phone ? `${cart?.ddi ?? ''}${sanitizePhone(cart.phone)}` : ''
      };
    }

    let cartPrice = mountedCart.cartPrice;

    if (mountedCart.config.paymentType === enPaymentType.DONATION) {
      cartPrice = mountedCart.price;
    }

    if (payment.paymentMethod == enPaymentMethod.PIX) {
      extraPaymentData['brand'] = '';
    }

    // Goofy de envio de pagamento
    goofyService.track({
      flow: 'sale',
      trackerId: GOOFY_ID,
      step: 'payment_sent',
      data: {
        ...extraUserData,
        ...extraPaymentData,
        adBlock: window.AD_BLOCK || false,
        fingerprint,
        currency: mountedCart.config.currency,
        paymentMethod: paymentService.getNormalizedPaymentType(payment.paymentMethod),
        cartPrice,
        payAttempts: attempts,
        coupon: (mountedCart.coupon && mountedCart.coupon.code) ?? null,
        couponValue: Number(mountedCart.coupon && mountedCart.coupon.value) ?? null,
        installments: cart.payment[0].numberInstallments || 1,
        cards: cart.payment.length,
        hasMultipleCards: cart.payment.length > 1,
        items: mountedCart.products,
        selectedCountry: cart.selectedCountry
      }
    });
  }

  private emit() {
    const data = JSON.parse(JSON.stringify(this.state));
    this.data$.next(data);
  }
}

// tslint:disable-next-line:variable-name
export const PaymentServiceFactory = () => new PaymentService();

export const paymentService = PaymentServiceFactory();
