import React from 'react';
import { BaseComponent, IStateBase } from 'components/BaseComponent';
import { ICreditCard, IProductConfig } from 'interfaces/IProductData';
import FieldText from 'components/Field/Text';
import { IPaymentModel } from 'interfaces/IPaymentModel';
import { paymentService } from 'services/PaymentService';
import { SvgIcon } from 'components/SvgIcon';
import Translate from 'components/Translate';
import StuddentForm from 'components/Cart/MainForm/StuddentForm';
import { cartService } from 'services/CartService';
import { WithStyles } from 'shared/decorators/withStyles';

interface IProps {
  creditCard: ICreditCard;
  askCVV: boolean;
}

interface IState extends IStateBase {
  model: IPaymentModel;
  config: IProductConfig;
}

@WithStyles(() => ({
  component: {
    minHeight: '150px',
    borderRadius: '3px',
    backgroundColor: '#909090',
    padding: '8px 0',
    '& .credit-card-cvv-label': {
      color: '#FFF'
    },
    '& .chk-creditCard__cardNumber': {
      backgroundColor: '#6A6A6A',
      color: '#FFF',
      fontSize: '0.75rem',
      fontWeight: 600,
      padding: '8px 16px',
      '& div:first-child': {
        margin: '0 0 4px 0'
      },
      '& .chk-creditCard__number': {
        letterSpacing: '1.70px'
      }
    },
    '& .chk-creditCard__content': {
      color: '#FFF',
      fontSize: '0.75rem',
      padding: '8px 16px',
      '& .chk-creditCard__cvvBrand': {
        alignItems: 'flex-end',
        display: 'flex',
        justifyContent: 'space-between',
        '& .chk-creditCard__cvv': {
          width: '85px',
          '& input': {
            backgroundColor: '#FFF'
          }
        },
        '& .chk-creditCard__brand': {
          '& svg': {
            width: '50px'
          }
        }
      }
    }
  }
}))
export default class CreditCard extends BaseComponent<IState, IProps> {
  componentDidMount() {
    this.bindModel(paymentService.getPayment(0));
    this.observeState({ config: null }, cartService.getConfig());
  }

  public setCvv = (cvv: any) => {
    paymentService.setPaymentData(0, { cvv });
  };

  render() {
    if (!this.state) {
      return null;
    }

    const { model, config } = this.state;

    return (
      <div>
        {config.ead && <StuddentForm />}
        <div className="chk-oneClickBuy__securityMessage">
          <Translate term="Seus dados estão protegidos e encriptados" />
        </div>
        <div className={`${this.props.classes.component} ${!this.props.askCVV ? 'without-cvv' : null}`}>
          <div className="chk-creditCard__security">
            <SvgIcon name="security_card" />
          </div>
          <div className="chk-creditCard__cardNumber">
            <div>
              <Translate term="Número do Cartão" />
            </div>
            <div className="chk-creditCard__number" id="credit-card-number-ocb">
              {this.props.creditCard.identifier}
            </div>
          </div>
          <div className="chk-creditCard__content">
            {this.props.askCVV ? (
              <div>
                <Translate term="Digite o Código de Segurança" />
              </div>
            ) : null}

            <div className="chk-creditCard__cvvBrand">
              <div className="chk-creditCard__cvv">
                {this.props.askCVV ? (
                  <FieldText
                    id="credit-card-cvv"
                    label="CVV"
                    validation="required|min:3|max:4"
                    value={model.cvv}
                    mask="cvv"
                    inputProps={{
                      minLength: 3,
                      maxLength: 4,
                      pattern: '[0-9]*',
                      inputMode: 'numeric'
                    }}
                    InputLabelProps={{
                      className: 'credit-card-cvv-label'
                    }}
                    onChange={this.setCvv}
                  />
                ) : null}
              </div>
              <div className="chk-creditCard__brand">
                <SvgIcon name={`pm_${this.props.creditCard.brand}`} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
