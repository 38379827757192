import { ReplaySubject, Observable } from 'rxjs';
import { IProductConfig } from 'interfaces/IProductData';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { ICartConfigReplacer } from './CartService';
import merge from 'lodash/merge';

class ConfigService {
  private config$ = new ReplaySubject<IProductConfig>(1);
  private configReplacer$ = new BehaviorSubject<ICartConfigReplacer>({});

  public setConfig(config: IProductConfig) {
    this.config$.next(config);
  }

  public setReplacerConfig(name: string, value: any) {
    const config = JSON.parse(JSON.stringify(this.configReplacer$.value));

    config[name] = value;

    this.configReplacer$.next(config);
  }

  public getConfig() {
    return Observable.combineLatest(this.config$, this.configReplacer$).map(
      ([config, configReplacer]) => merge(config, configReplacer) as IProductConfig
    );
  }

  public getMappedConfig<T>(fn: (config: IProductConfig) => T) {
    return this.getConfig()
      .map(fn)
      .distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b));
  }
}

export const configService = new ConfigService();
