/* eslint-disable max-lines */
import React from 'react';
import { IStateBase, BaseComponent } from 'components/BaseComponent';
import { IProductData, IProductConfig } from 'interfaces/IProductData';
import { cartService } from 'services/CartService';
import { IPayProps, PayContext } from 'components/Cart';
import { paymentService } from 'services/PaymentService';
import { Observable } from 'rxjs/Observable';
import { HistoryContext } from 'shared/HistoryContext';
import { History } from 'history';
import { enStep } from 'components/Cart/Templates/Hotsite/MultiStep';
import { ContentOneClickBuy } from './ContentOneClickBuy';
import AskAddress from 'components/Cart/AskAddress';
import { userService } from 'services/UserService';
import { WithStyles } from 'shared/decorators/withStyles';
import FormHeader from 'components/Cart/FormHeader';

interface IState extends IStateBase {
  cart: IProductData;
  step: enStep;
  config: IProductConfig;
  showAskAddress: boolean;
}

export interface IStepProps {
  setStep: (step: enStep) => void;
}

interface IProps extends IPayProps {
  hiddenFooter?: boolean;
}

@WithStyles(() => ({
  component: {
    '& .container': {
      flex: '1 1'
    },

    '& .without-cvv': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between'
    },

    '& .chk-oneClickBuy__product': {
      height: 'auto',
      '& .chk-subscription': {
        textAlign: 'right',

        '& .chk-subscription__text': {
          color: '#333',
          fontSize: '0.75rem',
          fontWeight: 700,
          textTransform: 'uppercase'
        },

        '& .chk-subscription__value': {
          fontSize: '1.25rem',
          fontWeight: 700
        },

        '& .chk-subscription__frequency': {
          color: '#333'
        },

        '& .chk-subscription__frequency, & .chk-subscription__trial': {
          fontSize: '0.75rem'
        },

        '& .chk-subscription__trial': {
          padding: '4px 0',

          '& span:first-child': {
            backgroundColor: '#000',
            color: '#FFF',
            cursor: 'auto',
            padding: '2px 4px'
          }
        }
      }
    },

    '& .chk-oneClickBuy__coupon': {
      padding: '0 16px',
      '& .theme-label': {
        zIndex: 2,
        fontSize: '0.75rem',
        transform: 'translate(0, 6px)',
        '& + div': {
          marginTop: 24
        }
      },
      '& .theme-button:not(.chk-close)': {
        height: 43
      }
    },

    '& .chk-oneClickBuy__content': {
      '& .chk-acceptTerms__span': {
        margin: '0 0 8px 0'
      },

      '& .chk-oneClickBuy__securityMessage': {
        color: '#505050',
        fontSize: '0.75rem',
        fontWeight: 600,
        padding: '0 16px'
      },

      '& .chk-creditCard__security': {
        position: 'absolute',
        width: '295px',

        '& svg': {
          float: 'right',
          margin: '8px 0 0 0',
          position: 'relative',
          width: '95px'
        }
      },

      '& .chk-userInfos': {
        margin: '4px 0 0 0'
      },

      '& .chk-stepCreditCard__finishButton': {
        backgroundColor: '#238533',
        borderRadius: '4px',
        color: '#FFFFFF',
        fontSize: '0.75rem',
        fontWeight: 700,
        height: '45px',
        margin: '16px 0',
        textTransform: 'uppercase',

        '& svg': {
          fill: '#FFF',
          margin: '0 8px 0 0',
          width: '20px'
        }
      }
    },

    '& .chk-installments__value': {
      fontSize: '0.75rem',
      margin: '8px 0 0 0',
      color: '#585858',

      '& span': {
        fontWeight: 700,
        marginRight: '8px'
      },

      '& a': {
        textDecoration: 'underline',
        color: '#333',
        cursor: 'pointer'
      }
    },

    '& .links': {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row',
      justifyContent: 'center',
      margin: '16px 0',

      '& a': {
        display: 'inline-block',
        textDecoration: 'none',
        color: '#3C3C3C',

        '&:after': {
          content: '"|"',
          display: 'inline-block',
          margin: '0 5px'
        },

        '& &:last-child:after': {
          content: '""'
        }
      },

      '& .chk-media-footer': {
        fontSize: '.625rem',
        textAlign: 'center',
        color: 'black',
        textShadow: '1px 1px 2px #fff'
      }
    },

    '& .grid': {
      width: '100%',
      minHeight: '150px',
      margin: '0 auto'
    }
  },

  '.theme_18': {
    overflowX: 'hidden'
  }
}))
export default class OneClickBuy extends BaseComponent<IState, IProps> {
  private history: History;

  componentDidMount() {
    this.observeState({ cart: null }, cartService.getCart());
    this.observeState({ config: null }, cartService.getConfig());
    this.setState({ showAskAddress: false });

    cartService
      .getCart()
      .filter((cart) => !cart.isCached && cart.upsell && !cart.config.askCVV)
      .take(1)
      .subscribe(() => {
        this.onPay();
      });
  }

  public setStep(step: enStep) {
    const { config } = this.state;
    if (step == enStep.address && !config.askAddressBeforePayment) {
      step = enStep.payment;
    }

    userService.setBasicUser().subscribe();

    this.setState({ step });
  }

  onValidate(errors: any) {
    this.setState({ validation: errors || {} });
  }

  isValid(): boolean {
    const validation = this.state.validation || {};
    return Object.keys(validation).length === 0;
  }

  onPay = async () => {
    if (await this.isFormValid()) {
      this.props
        .onPay()
        .switchMap(() => cartService.getCart())
        .combineLatest(paymentService.shouldBlockAddressAfterPayment())
        .take(1)
        .switchMap(([cart, shouldBlockAddressAfterPayment]) => {
          if (cart.config.askAddressAfterPayment && !shouldBlockAddressAfterPayment) {
            this.setState({ showAskAddress: true });
            return Observable.of('#');
          }

          return paymentService.getPaymentResult().map((result) => {
            if (result.redirectTo) {
              Observable.never().loader().subscribe();

              window.location.href = result.redirectTo;
              return null;
            }

            return `/thankyou/${cart.contentId}/${result.key}`;
          });
        })
        .filter((url) => url !== null)
        .bindComponent(this)
        .subscribe((url) => {
          this.history.push(url);
        });
    }
  };

  editInfos() {
    cartService.disableOneClickBuy().subscribe();
  }

  setHistory(history: History): any {
    this.history = history;
    return null;
  }

  render() {
    if (!this.state) {
      return null;
    }

    const { cart, showAskAddress } = this.state;

    return (
      <div className={this.props.classes.component}>
        <PayContext.Provider value={this.onPay.bind(this)}>
          <HistoryContext.Consumer>{(h) => this.setHistory(h)}</HistoryContext.Consumer>
          <div className={'chk-oneClickBuy__container'}>
            <FormHeader />
            {showAskAddress ? (
              <AskAddress />
            ) : (
              <ContentOneClickBuy cart={cart} onPay={this.onPay} disableButton={!this.isValid && this.state.formSubmitted} />
            )}
          </div>
        </PayContext.Provider>
      </div>
    );
  }
}
