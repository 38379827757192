import React from 'react';
import { IProductData } from 'interfaces/IProductData';
import { CurrencyFormat } from 'components/CurrencyFormat';
import Translate from 'components/Translate';
import { BaseComponent, IStateBase } from 'components/BaseComponent';
import { paymentService } from 'services/PaymentService';
import { IPaymentModel } from 'interfaces/IPaymentModel';
import { cartService } from 'services/CartService';
import { enProductType } from 'enums/enProductType';
import { enCurrency } from 'enums/enCurrency';

interface IPriceProps {
  cart: IProductData;
  isTicket?: boolean;
}

interface IState extends IStateBase {
  payment: IPaymentModel;
  currency: enCurrency;
  feeInstallment: number;
}

// tslint:disable-next-line:variable-name
export default class MultipleInstallments extends BaseComponent<IState, IPriceProps> {
  componentDidMount() {
    const feeObservable = cartService
      .getCart()
      .map((c) => c.feeInstallment)
      .distinctUntilChanged();

    this.observeState({ feeInstallment: undefined }, feeObservable);
    this.observeState({ payment: undefined }, paymentService.getPayment(0));
  }

  render() {
    if (!this.state) {
      return null;
    }

    const { payment, feeInstallment } = this.state;
    const numberInstallments = payment.numberInstallments;
    const { cart, isTicket } = this.props;
    const product = cart.products[0];

    let value = 0;
    payment.installments
      .filter((i) => i.installment == numberInstallments)
      .map((i) => {
        value = i.value;
      });

    // Produto físico gratuito com frete
    if (product.totalPrice === 0 && product.type === enProductType.PHYSICAL) {
      return (
        <div className={`chk-parts multiple-installments multiple-installments-free-physical`}>
          <div className="chk-free-physical">
            <span className="chk-number-price theme-label">
              <Translate term="Grátis" />
              <br />
            </span>
            <span className="chk-number-parts">
              <Translate term="Frete de" /> {numberInstallments}x <CurrencyFormat value={value} />
            </span>
          </div>
          <div className={'chk-no-fee'}>{feeInstallment === 0 && <Translate term={'SEM JUROS'} />}</div>
          <div className="chk-small-price">
            <Translate
              term="ou {fullPrice} à vista"
              properties={{
                fullPrice: <CurrencyFormat id="full-price" value={cart.price} />
              }}
            />
          </div>
        </div>
      );
    }

    return (
      <div className={`chk-parts ${isTicket ? 'end' : ''} multiple-installments`}>
        <div style={{ display: 'inline' }} id="chk-price-with-installment">
          <span className="chk-number-parts">{numberInstallments}x</span>
          <span className={'chk-number-price theme-label'}>
            &nbsp;
            <CurrencyFormat value={value} />
          </span>
        </div>
        <div className={'chk-no-fee'}>{feeInstallment === 0 && <Translate term={'SEM JUROS'} />}</div>

        <div className="chk-small-price" id="chk-price-complete">
          <Translate
            term="ou {fullPrice} à vista"
            properties={{
              fullPrice: <CurrencyFormat id="full-price" value={cart.price} />
            }}
          />
        </div>
      </div>
    );
  }
}
