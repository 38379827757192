import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Observable } from 'rxjs';

export interface IMessage {
  // eslint-disable-next-line camelcase
  tra_key: string;
  url: string;
  checkout: IData;
}
interface IData {
  // eslint-disable-next-line camelcase
  display_message?: string;
  // eslint-disable-next-line camelcase
  display_show?: boolean;
}

export class ContentDeliveryService {
  private contentDelivery$ = new BehaviorSubject<IMessage>(null);

  listen(): Observable<IMessage> {
    return this.contentDelivery$.asObservable();
  }

  set(data: IMessage) {
    this.contentDelivery$.next(data);
  }
}

// tslint:disable-next-line:variable-name
export const ContentDeliveryServiceFactory = () => new ContentDeliveryService();
export const contentDeliveryService = ContentDeliveryServiceFactory();
export default contentDeliveryService;
