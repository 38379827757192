/* eslint-disable max-len */
import React from 'react';
import { memo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { SvgIcon } from 'components/SvgIcon';
import Clipboard from 'react-clipboard.js';

interface IProps {
  bankslipCode: string;
  onClick?: () => void;
}

const useStyles = makeStyles(() => ({
  input: {
    alignItems: 'center',
    border: '1px solid #D9D9D9',
    borderRadius: '2px',
    boxSizing: 'border-box',
    display: 'flex',
    padding: '8px 10px',
    justifyContent: 'space-between',
    width: '100%',
    '& span': {
      border: 'none',
      resize: 'none',
      outline: 'none',
      color: '#596375',
      letterSpacing: '0.5px',
      marginLeft: '10px'
    }
  },
  spanCode: {
    wordBreak: 'break-all'
  },
  copyButton: {
    background: '#fff',
    border: 'none',
    display: 'flex',

    '&:focus': {
      border: 'none !important',
      outline: 'none !important'
    }
  },
  copySvg: {
    fill: '#367BD9',
    '&:hover': {
      fill: 'rgba(54,123,217, 0.58)'
    }
  },
  containerCode: {
    display: 'flex',
    alignItems: 'center'
  },
  code: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%'
  }
}));

const BankSlipInput = memo((props: IProps) => {
  const style = useStyles(props);
  const { bankslipCode, onClick } = props;

  const copyBarCode = () => {
    onClick();
  };

  return (
    <div className={style.input}>
      <div className={style.code}>
        <div className={style.containerCode}>
          <SvgIcon name="boleto" />
          <span className={style.spanCode} role="textbox" id="copy-button">
            {bankslipCode}
          </span>
        </div>
        <Clipboard className={style.copyButton} onClick={() => copyBarCode()} data-clipboard-text={bankslipCode}>
          <svg height="20" onClick={copyBarCode} className={style.copySvg} viewBox="-40 0 512 512" xmlns="http://www.w3.org/2000/svg">
            <path d="m271 512h-191c-44.113281 0-80-35.886719-80-80v-271c0-44.113281 35.886719-80 80-80h191c44.113281 0 80 35.886719 80 80v271c0 44.113281-35.886719 80-80 80zm-191-391c-22.054688 0-40 17.945312-40 40v271c0 22.054688 17.945312 40 40 40h191c22.054688 0 40-17.945312 40-40v-271c0-22.054688-17.945312-40-40-40zm351 261v-302c0-44.113281-35.886719-80-80-80h-222c-11.046875 0-20 8.953125-20 20s8.953125 20 20 20h222c22.054688 0 40 17.945312 40 40v302c0 11.046875 8.953125 20 20 20s20-8.953125 20-20zm0 0" />
          </svg>
        </Clipboard>
      </div>
    </div>
  );
});

export default BankSlipInput;
