/* eslint-disable max-len */
import Dialog from '@material-ui/core/Dialog';
import { Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import Slide from '@material-ui/core/Slide';
import { makeStyles } from '@material-ui/styles';
import { SvgIcon } from 'components/SvgIcon';
import React, { useCallback, useState } from 'react';

import { styles } from './styles';
import Translate from 'components/Translate';

function Transition(props: any) {
  return <Slide direction="up" {...props} />;
}

const openHelp = () => {
  window.open('https://ajuda.eduzz.com/?article=como-reimprimir-o-boleto-ou-mudar-o-metodo-de-pagamento');
};

const openHelp2 = () => {
  window.open('https://ajuda.eduzz.com/?article=1167');
};

const openHelp3 = () => {
  window.open('https://ajuda.eduzz.com/?article-category=clientes');
};

const icon = (
  <span
    style={{
      borderTop: '5px solid #5A2254',
      borderLeft: '5px solid transparent',
      borderRight: '5px solid transparent',
      borderBottom: '5px solid transparent'
    }}
  />
);

const useStyles = makeStyles(styles);

const Faq: React.FC<any> = React.memo((props) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = useCallback(() => setOpen(true), []);

  const handleClose = useCallback(() => setOpen(false), []);

  const classes = useStyles({});

  return (
    <div className={'chk-modal-doubts'}>
      {props.showIcon ? <SvgIcon name="chatIcon" /> : ''}{' '}
      <a id="exitpoup-close" role="button" onClick={handleClickOpen}>
        <Translate term={props.name} />
      </a>
      <Dialog className={classes.component} open={open} onClose={handleClose} TransitionComponent={Transition} transitionDuration={800}>
        <div className={'chk-title'}>
          <div className={'chk-span-icon'}>
            <SvgIcon name="chatIcon" />

            <Translate term="Dúvidas Frequentes" />
          </div>
          <div className="chk-close" color="inherit" onClick={handleClose} aria-label="Close">
            <SvgIcon name="closeIcon" />
          </div>
        </div>

        <Accordion className={'chk-expansion-panel'}>
          <AccordionSummary expandIcon={icon}>
            <p className={'chk-expansion-title zlabel'}>
              <Translate term="Como Receberei meu produto/conteúdo?" />
            </p>
          </AccordionSummary>
          <AccordionDetails className={'chk-expansion-panel-detail '}>
            <p>
              <Translate term="Através da Eduzz, você receberá e-mails de recibo de pagamento, links de acesso ao produto. Nesses e-mails contém informações e dados de acesso ou link para download do conteúdo adquirido, também Informações importantes, como dados do Produtor responsável pelo conteúdo." />
            </p>
          </AccordionDetails>
        </Accordion>

        <Accordion className={'chk-expansion-panel'}>
          <AccordionSummary expandIcon={icon}>
            <p className={'chk-expansion-title zlabel'}>
              <Translate term="O que são produtos digitais? Quais as vantagens?" />
            </p>
          </AccordionSummary>
          <AccordionDetails className="chk-expansion-panel-detail">
            <div>
              <li>
                <Translate term="Pode ser acessado de qualquer lugar" />:
              </li>
              <p>
                <Translate term="Produtos digitais podem ser acessados em qualquer lugar do planeta, basta possuir um dispositivo capaz de processar e visualizar o conteúdo, por exemplo um smartphone, computador ou tablet." />
              </p>
              <li>
                <Translate term="O Prazo de entrega" />:
              </li>
              <p>
                <Translate term="Assim que o pagamento é confirmado, o Infoproduto é enviado instantaneamente ao seu e-mail. Você não precisa se preocupar com longos prazos de espera para desfrutar do conteúdo." />
              </p>
              <li>
                <Translate term="Dispensa o espaço físico:" />
              </li>
              <p>
                <Translate term="Produtos digitais podem ser armazenados em computadores, smartphones ou até mesmo em sites especializados em armazenamento de informações em “nuvem”, como o Google Drive e o Dropbox." />
              </p>
            </div>
          </AccordionDetails>
        </Accordion>

        <Accordion className={'chk-expansion-panel'}>
          <AccordionSummary expandIcon={icon}>
            <p className={'chk-expansion-title zlabel'}>
              <Translate term="Como Reimprimir o boleto ou mudar o método de pagamento?" />
            </p>
          </AccordionSummary>
          <AccordionDetails className={'chk-expansion-panel-detail '}>
            <div>
              <p>
                <Translate term="Todas as compras são intermediadas pela Eduzz. Você deverá acessar o site para poder reimprimir ou mudar a forma de pagamento. Para saber mais click" />
              </p>
              <a onClick={openHelp} role="button">
                <Translate term="Aqui" />.
              </a>
            </div>
          </AccordionDetails>
        </Accordion>

        <Accordion className={'chk-expansion-panel'}>
          <AccordionSummary expandIcon={icon}>
            <p className={'chk-expansion-title zlabel'}>
              <Translate term="Como visualizar meu código de rastreio?" />
            </p>
          </AccordionSummary>
          <AccordionDetails className={'chk-expansion-panel-detail '}>
            <p>
              <Translate term="Após o seu produto ser enviado pelo Produtor, ele irá inserir o código de rastreio na Eduzz. Para visualizar e acompanhar a sua compra, é preciso que acesse a sua conta. Saiba como clicando" />{' '}
              <a onClick={openHelp2} role="button">
                {' '}
                <Translate term="Aqui" />.
              </a>
            </p>
          </AccordionDetails>
        </Accordion>

        <div className={'chk-central-help'}>
          {props.showProducerContact ? (
            <div className="producer-contact">
              <Translate term="Dúvidas sobre o produto adquirido entre em contato com o produtor:" />
              <p>{props.infos.producerName}</p>
              <p>{props.infos.producerEmail}</p>
            </div>
          ) : null}
          <p>
            <Translate term="Não achou o que precisa?" />
          </p>
          <p>
            <Translate term="Acesse nossa" />{' '}
            <a onClick={openHelp3} role="button">
              <Translate term="Central de Ajuda" />
            </a>
          </p>
        </div>
      </Dialog>
    </div>
  );
});

export default Faq;
