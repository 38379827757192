import validator from 'validatorjs';

const validate = (name: string): boolean => {
  if (!name) {
    return true;
  }

  return !!`${name}`.match(/^[^ ]{2,}.* +.*[^ ]{2,}$/);
};

validator.register('name', validate, 'invalid_name');
