import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import FieldAutocomplete from 'components/Field/Autocomplete';
import FieldText from 'components/Field/Text';
import Translate from 'components/Translate';
import React, { useCallback, useContext } from 'react';
import { useObservable } from 'react-use-observable';
import { getCountries } from 'services/LocationService';
import { AddressContext } from '..';

const International: React.FC = () => {
  const context = useContext(AddressContext);
  const [countries] = useObservable(() => getCountries(), []);

  const setStreet = useCallback(
    (street: any) => {
      context.setData({ street });
    },
    [context]
  );

  const setCity = useCallback(
    (cityName: any) => {
      context.setData({ cityName });
    },
    [context]
  );

  const setProvince = useCallback(
    (province: any) => {
      context.setData({ province });
    },
    [context]
  );

  const setPostalCode = useCallback(
    (postalCode: any) => {
      context.setData({ postalCode });
    },
    [context]
  );

  const setCountry = useCallback(
    (countryData: any) => {
      const country = countries.find((c) => c.id === countryData);
      context.setData({ country: countryData, countryName: country?.country });
    },
    [context, countries]
  );

  const model = context.data;

  if (!model || !countries) {
    return null;
  }

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <FieldText
            id="street"
            label={<Translate term="Endereço" />}
            validation="required"
            value={model.street}
            inputProps={{
              className: 'theme-input'
            }}
            InputLabelProps={{
              className: 'theme-label',
              id: 'label-international-street'
            }}
            onChange={setStreet}
          />
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <FieldText
            id="city"
            label={<Translate term={'Cidade'} />}
            validation="required"
            value={model.cityName}
            inputProps={{
              className: 'field normal theme-input'
            }}
            InputLabelProps={{
              className: 'theme-label'
            }}
            onChange={setCity}
          />
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <FieldText
            id="province"
            label={<Translate term={'Estado'} />}
            validation="required"
            value={model.province}
            inputProps={{
              className: 'theme-input'
            }}
            InputLabelProps={{
              className: 'theme-label'
            }}
            onChange={setProvince}
          />
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <FieldText
            id="postalCode"
            label={<Translate term="CEP da Residência" />}
            validation="required|max:25 "
            value={model.postalCode}
            inputProps={{
              className: 'theme-input'
            }}
            InputLabelProps={{
              className: 'theme-label',
              id: 'label-postal-code'
            }}
            onChange={setPostalCode}
          />
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <FieldAutocomplete
              hideUnselectIcon
              hideSearchIcon
              id="find-country"
              label={<Translate term="País" />}
              value={+model.country || 'none'}
              validation="requiredSelect"
              placeholder="Search..."
              className="chk-userAddressInternational__country"
              inputProps={{
                name: 'country',
                id: 'find-country',
                className: 'theme-input'
              }}
              InputLabelProps={{
                className: 'theme-label'
              }}
              onChange={setCountry}
              options={countries.map((c) => ({ label: c.country, value: c.id }))}
            />
          </FormControl>
        </Grid>
      </Grid>
    </div>
  );
};

export default International;
