import React from 'react';
import { useObservable } from 'react-use-observable';
import { getCdnImage } from 'functions';
import { cartService } from 'services/CartService';

const Component: React.FC = () => {
  const [image] = useObservable(() => {
    return cartService
      .getConfig()
      .take(1)
      .map((config) => config.logo);
  }, []);

  const [productTitle] = useObservable(() => {
    return cartService
      .getCart()
      .take(1)
      .map((cart) => cart.products[0].title);
  }, []);

  return <img src={getCdnImage(image)} alt={productTitle} />;
};

export const PixPanelProductImage = React.memo(Component);
