import React from 'react';
import { CurrencyFormat } from 'components/CurrencyFormat';
import { paymentService } from 'services/PaymentService';
import { useMappedObservable, useObservable } from 'react-use-observable';
import { paymentWaiter } from 'services/PaymentWaiterService';
import { PixCloseModal } from '../PixCloseModal';
import Button from '@material-ui/core/Button';
import { configService } from 'services/ConfigService';
import ShoppingImage from './ShoppingImage';
import { enStatusWaiter } from 'enums/enStatusWaiter';

const Component: React.FC = () => {
  const [value] = useObservable(() => {
    return paymentService.getCart().map((data) => data.payment[0].value);
  }, []);

  const [templateId] = useMappedObservable(
    () => configService.getConfig().take(1),
    (config) => Number(config.template.id),
    []
  );

  return (
    <>
      <div className="chk-pix-panel">
        <div id={`expired-panel-modal-template-${templateId}`} className="chk-payment-painel chk-pix-expired-panel-modal">
          <div className="chk-pix-modal-content chk-pix-expired-panel">
            <h3 className="chk-pix-modal-title">Estamos verificando a confirmação do seu pagamento</h3>
            <ShoppingImage />
            <div className="chk-pix-expired-panel-text">Assim que estiver tudo certo, você receberá mais informações no seu e-mail.</div>
            <div className="chk-pix-expired-panel-text">
              Caso ainda não tenha feito o pagamento da sua compra no{' '}
              <span>
                valor de <CurrencyFormat value={value} />
              </span>
              , é só gerar um novo Pix, ou escolher outra forma de pagamento.
            </div>
            <div className="chk-modal-actions">
              <Button id="generate-new-pix-button" className="chk-pix-button" onClick={() => paymentWaiter.changeWaiterStatusToRunning()} fullWidth>
                Gerar novo Pix
              </Button>
              <Button
                id="change-payment-method-button"
                className="chk-pix-button chk-pix-copy-button-text"
                onClick={() => paymentWaiter.changeWaiterStatus(enStatusWaiter.CANCELED)}
                fullWidth
              >
                Alterar método de pagamento
              </Button>
            </div>
            <PixCloseModal />
          </div>
        </div>
      </div>
    </>
  );
};

export const TimerPixExpiredPanel = React.memo(Component);
