import { makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import OrderBump from 'components/Cart/OrderBump';
import React from 'react';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import TabletMacIcon from '@material-ui/icons/TabletMac';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import { configService } from 'services/ConfigService';
import { useObservable } from 'react-use-observable';
import { enPagdivididoSteps } from '../..';
import NextStep from '../../NextStep';
import AB from 'shared/components/AB';

const useStyles = makeStyles((theme) => ({
  cardGuide: {
    width: '49%',
    height: 92,
    background: '#F9FAFB',
    border: '1px solid #B0BEC5',
    padding: 15,
    borderRadius: 4,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('md')]: {
      flexFlow: 'column nowrap',
      height: 'auto',
      border: 'none',
      width: '46%',
      padding: '28px 12px'
    },
    '&.small-cardguide': {
      flexFlow: 'column nowrap',
      height: 'auto',
      border: 'none',
      width: '46%',
      padding: '28px 12px'
    }
  },
  text: {
    margin: '0 0 0 15px',
    [theme.breakpoints.down('md')]: {
      margin: '15px 0 0 0',
      fontSize: '0.65rem',
      textAlign: 'center',
      lineHeight: '150%'
    },
    '&.small-text': {
      margin: '15px 0 0 0',
      fontSize: '0.60rem',
      textAlign: 'center',
      lineHeight: '150%'
    }
  },
  containerLogo: {
    background: '#F9FAFB',
    width: 50,
    height: 50,
    borderRadius: 50,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  containerStep: {
    margin: '16px 0',
    display: 'flex',
    justifyContent: 'space-between',
    '& svg': {
      fill: '#0D47A1'
    }
  },
  containerDescription: {
    display: 'flex',
    alignItems: 'center',
    padding: '16px 0',
    [theme.breakpoints.down('md')]: {
      borderBottom: '1px solid #ECEFF1'
    },
    '& svg': {
      fill: '#0D47A1'
    },
    '&.small-containerDescription': {
      borderBottom: '1px solid #ECEFF1'
    }
  },
  orderBump: {
    marginBottom: 16
  },
  description: {
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    flex: 1,
    marginLeft: 15
  }
}));

const Intro: React.FC = () => {
  const [config] = useObservable(() => configService.getConfig(), []);
  const classes = useStyles({});

  if (!config) {
    return null;
  }

  const templateId = config.template.id;

  return (
    <Grid container spacing={0}>
      <Grid className={templateId !== 18 ? `${classes.containerDescription} small-containerDescription` : classes.containerDescription} item xs={12}>
        <div className={classes.containerLogo}>
          <LocalAtmIcon />
        </div>
        <div className={classes.description}>
          <AB
            current={
              <p style={{ margin: 0 }}>
                Uma nova forma de comprar, com o parcelamento no boleto você faz uma requisição de crédito e finaliza sua compra
              </p>
            }
            experiment={
              <p style={{ margin: 0 }}>Uma nova forma de comprar, com o financiamento você faz uma requisição de crédito e finaliza sua compra</p>
            }
            experimentName="pagdividido"
          />
        </div>
      </Grid>
      <Grid className={classes.containerStep} container item xs={12}>
        <Grid className={templateId !== 18 ? `${classes.cardGuide} small-cardguide` : classes.cardGuide} item>
          <EditOutlinedIcon />
          <p className={templateId !== 18 ? `${classes.text} small-text` : classes.text}>Preencha os dados solicitados e faça uma proposta</p>
        </Grid>
        <Grid className={templateId !== 18 ? `${classes.cardGuide} small-cardguide` : classes.cardGuide} item>
          <TabletMacIcon />
          <p className={templateId !== 18 ? `${classes.text} small-text` : classes.text}>Receba a confirmação por SMS e finalize o processo</p>
        </Grid>
      </Grid>
      <Grid className={classes.orderBump} item xs={12}>
        <OrderBump />
      </Grid>
      <NextStep buttonText="Quero Continuar" toStep={enPagdivididoSteps.PERSONAL_INFO} />
    </Grid>
  );
};

export default React.memo(Intro);
