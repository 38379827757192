import { makeStyles } from '@material-ui/core';
import { ChevronLeft } from '@material-ui/icons';
import Translate from 'components/Translate';
import { ICountry } from 'interfaces/ICountry';
import { enTheme } from 'interfaces/IProductData';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useMappedObservable, useObservable } from 'react-use-observable';
import { cartService } from 'services/CartService';
import { configService } from 'services/ConfigService';
import { internationalService } from 'services/InternationalService';

import { paymentService } from 'services/PaymentService';
import { CountriesMenu } from 'shared/components/CountriesMenu';
import { getUrlCountryImage } from 'shared/utils/getUrlCountryImage';

interface IProps {
  title: string;
}
const useStyles = makeStyles({
  container: {
    border: '1px solid #d9d9d9',
    borderRadius: '2px 0 0 2px',
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    margin: '0.188rem 0',
    background: '#fff',

    '&.small': {
      height: '39.38px',
      margin: '0.25rem 0'
    },

    '@media screen and (max-width: 960px)': {
      margin: '0.75rem 0'
    }
  },

  ddiLabel: {
    display: 'none',

    '@media screen and (max-width: 960px)': {
      fontSize: '0.75rem',
      color: '#636871',
      display: 'block',
      paddingLeft: '0.2rem',
      marginBottom: '-3px'
    },

    '&.small': {
      display: 'none'
    }
  },

  containerImg: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 0.25rem',
    margin: '0.25rem 0'
  },

  chevron: {
    transform: 'rotate(-90deg)',
    zIndex: 0,

    '&.down': {
      transform: 'rotate(90deg)'
    }
  }
});

const DDI: React.FC<IProps> = React.memo(({ title }) => {
  const [config] = useObservable(() => configService.getConfig(), []);
  const [country] = useObservable(() => internationalService.getSelectedCountryByDDI(), []);
  const [isOpen, setIsOpen] = useState(false);
  const [disabled] = useMappedObservable(
    () => cartService.getCart(),
    (cart) => !!cart?.user?.lock,
    []
  );

  const ref = useRef();
  const classes = useStyles();

  useEffect(() => {
    if (!country?.DDI) {
      return;
    }
    paymentService.setData({ ddi: country.DDI });
  }, [country?.DDI]);

  const toggleMenu = useCallback(() => {
    setIsOpen((prev) => !prev);
  }, []);

  const closeMenu = useCallback(() => {
    setIsOpen(false);
  }, []);

  const setDDI = useCallback((option: ICountry) => {
    internationalService.setDDI(option);
    paymentService.setData({ ddi: option.DDI });
  }, []);

  if (!country || !config) {
    return null;
  }

  const defaultTemplate = Number(config?.template?.id) === enTheme.PADRAO;

  return (
    <div
      className={`${defaultTemplate ? classes.container : `${classes.container} small`} theme-input ${disabled ? 'disabledField' : ''}`}
      ref={ref}
      id={`selected-ddi-${country.isoAlpha3}`}
    >
      <span className={`${defaultTemplate ? classes.ddiLabel : `${classes.ddiLabel} small`} theme-label`}>
        <Translate term="DDI" />
      </span>
      <div className={classes.containerImg} onClick={!disabled && toggleMenu} style={{ cursor: !disabled ? 'pointer' : 'default' }}>
        <img src={getUrlCountryImage(country.isoAlpha2)} width="29px" height="20px" alt={country.name} />
        <span className={`theme-label`}>{country.DDI}</span>

        <ChevronLeft className={`${classes.chevron} ${isOpen ? 'down' : ''} theme-label`} />
      </div>
      <CountriesMenu isOpen={isOpen} closeMenu={closeMenu} title={title} anchor={ref} onSelectItem={setDDI} hasDDI />
    </div>
  );
});

export { DDI };
