import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { paymentService } from './PaymentService';
import { cartService } from 'services/CartService';
import { apiService } from 'shared/services';
import { ReplaySubject } from 'rxjs/ReplaySubject';
import { IPagDivididoEstimateResponse } from 'interfaces/IPagDividido';
import { empty } from 'rxjs/observable/empty';

export class PagDivididoService {
  private loading$ = new BehaviorSubject(false);
  private estimate$ = new ReplaySubject<IPagDivididoEstimateResponse>(1);
  private trigger$ = new ReplaySubject<number>(1);

  public watch() {
    return this.trigger$
      .switchMap(() => paymentService.getCart())
      .map((payment) => ({
        name: payment.name,
        phone: payment.phone,
        email: payment.email,
        document: payment.document,
        birthDate: payment.pagDividido.birthDate.replace(/^([0-9]{2})\/([0-9]{2})\/([0-9]{4})$/, '$3-$2-$1')
      }))
      .combineLatest(
        cartService.getCart().map((cart) => {
          return {
            contentId: cart.contentId,
            transactionKey: cart.transactionKey,
            cartPrice: cart.cartPrice
          };
        })
      )
      .distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b))
      .do(() => this.loading$.next(true))
      .debounceTime(1000)
      .switchMap(([payment, cart]) => {
        const data = {
          contentId: cart.contentId,
          transactionKey: cart.transactionKey,
          name: payment.name,
          email: payment.email,
          phone: payment.phone,
          document: payment.document,
          fullPrice: cart.cartPrice,
          birthDate: payment.birthDate
        };

        return apiService
          .post<IPagDivididoEstimateResponse>('/pagdividido/estimate', data)
          .catch(() => empty<IPagDivididoEstimateResponse>())
          .finally(() => {
            this.loading$.next(false);
          });
      })
      .do((data) => {
        this.estimate$.next(data);
        paymentService.setPaymentData(0, {
          pagDivididoOffer: null
        });
      })
      .map(() => {});
  }

  public getEstimate() {
    return this.estimate$.asObservable();
  }

  public trigger() {
    this.trigger$.next(1);
  }

  public isLoading() {
    return this.loading$.asObservable().distinctUntilChanged();
  }
}

// tslint:disable-next-line:variable-name
export const PagDivididoServiceFactory = () => new PagDivididoService();

export const pagDivididoService = PagDivididoServiceFactory();
