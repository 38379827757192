/* eslint-disable @typescript-eslint/no-unused-vars */
import { BaseComponent } from 'components/BaseComponent';
import { Observable } from 'rxjs/Observable';
import { Subscriber } from 'rxjs/Subscriber';
import { Subscription } from 'rxjs/Subscription';

function bindComponent<T>(this: Observable<T>, component: BaseComponent): Observable<T> {
  return this.lift(new BindComponentOperator(component));
}

Observable.prototype.bindComponent = bindComponent;

declare module 'rxjs/Observable' {
  interface Observable<T> {
    bindComponent: typeof bindComponent;
  }
}

class BindComponentOperator {
  constructor(private component: BaseComponent) {}

  public call(subscriber: Subscriber<any>, source: Observable<any>): Subscription {
    const subscription = source.subscribe(subscriber);

    if (this.component.subscriptions) {
      this.component.subscriptions.push(subscription);
    }

    return subscription;
  }
}
