/* eslint-disable max-lines */
import { BaseComponent, IStateBase } from 'components/BaseComponent';
import Grid from '@material-ui/core/Grid';
import React from 'react';
import { paymentService } from 'services/PaymentService';
import { ICartModel } from 'interfaces/ICartModel';
import FieldText from 'components/Field/Text';
import Translate from 'components/Translate';
import SuggestEmail from './SuggestEmail/index';
import { cartService } from 'services/CartService';
import CpfForm from '../Payment/PaymentForm/CpfForm';
import { enPaymentType } from 'enums/enPaymentType';
import { currencyService } from 'services/CurrencyService';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';
import Fade from '@material-ui/core/Fade';
import { Hidden } from '@material-ui/core';
import { configService } from 'services/ConfigService';
import { enPaymentMethod } from 'enums/enPaymentMethod';
import { enCurrency } from 'enums/enCurrency';
import { WithStyles } from 'shared/decorators/withStyles';
import { DDI } from './DDI';
import { ICountry } from 'interfaces/ICountry';
import { internationalService } from 'services/InternationalService';
import { IProductData, enTheme } from 'interfaces/IProductData';
import SaleRecoveryUserData from '../SaleRecoveryUserData';
import SaleRecoveryStudentAndPayerData from '../SaleRecoveryStudentAndPayerData';
import { sanitizePhone } from 'shared/utils/sanitizePhone';
import { enPersonType } from 'enums/enPersonType';
import { isBrazilianPhone } from 'shared/utils/isBrazilianPhone';

interface IState extends IStateBase {
  isUserDataEditLocked: boolean;
  model: ICartModel;
  currency: enCurrency;
  suggest: boolean;
  cart: IProductData;
  lock: boolean;
  personType: string;
  templateId: number;
  paymentMethod: enPaymentMethod;
  samePerson?: boolean;
  country: ICountry;
}

interface IProps {
  formSubmitted?: boolean;
  classes?: any;
}

@WithStyles(() => ({
  containerCellPhone: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    flexWrap: 'nowrap',
    padding: '0.25rem',
    marginTop: '0.5rem',

    '@media screen and (max-width: 960px)': {
      marginTop: '0.25rem'
    },

    '&.small': {
      marginTop: '0.875rem'
    }
  },
  itemsContainer: {
    display: 'flex',
    flexWrap: 'nowrap',
    marginTop: '-0.625rem'
  },

  containerItemDDI: {
    height: '49.6px',
    color: 'red',
    flex: 1,
    marginRight: '0.25rem',

    '&.small': {
      marginTop: '-6px',
      '@media screen and (max-width: 960px)': {
        marginTop: '0.125rem'
      }
    },

    '@media screen and (max-width: 960px)': {
      height: '54px'
    }
  },
  ItemSpan: {
    fontSize: '1rem',
    color: '#636871',

    '@media screen and (max-width: 960px)': {
      display: 'none',
      fontSize: '0.75rem'
    },

    '&.small': {
      fontSize: '0.75rem',
      '@media screen and (max-width: 960px)': {
        display: 'block',
        marginTop: '-6px'
      }
    }
  },

  itemCellPhone: {
    flex: 4
  }
}))
export default class UserForm extends BaseComponent<IState, IProps> {
  constructor(props: any) {
    super(props);
    this.state = {
      isUserDataEditLocked: false,
      suggest: false,
      currency: undefined,
      cart: undefined,
      personType: undefined,
      paymentMethod: undefined,
      samePerson: false,
      model: undefined,
      lock: false,
      templateId: null,
      country: null
    };
  }

  componentDidMount() {
    this.bindModel(paymentService.getCart());
    this.observeState({ currency: null }, currencyService.getCurrency());
    this.observeState({ cart: null }, cartService.getCart());
    this.observeState({ country: null }, internationalService.getSelectedCountryByDDI());

    paymentService
      .getCart()
      .subscribe((cart) => this.setState({ personType: cart.personType, paymentMethod: cart.payment[0].paymentMethod, samePerson: cart.samePerson }));

    cartService
      .getCart()
      .bindComponent(this)
      .filter((cart) => !!cart.user)
      .map((cart) => cart.user.lock)
      .subscribe((lock) => {
        this.setState({ lock });
      });

    cartService.isContractSaleRecovery().subscribe((isContractSaleRecovery) => this.setState({ isUserDataEditLocked: isContractSaleRecovery }));

    configService
      .getConfig()
      .take(1)
      .filter((config) => !!config?.template.id)
      .map((config) => config.template.id)
      .subscribe((templateId) => this.setState({ templateId }));
  }

  componentDidUpdate() {
    if (this.state.formSubmitted != this.props.formSubmitted) {
      this.setState({ formSubmitted: this.props.formSubmitted });
    }
  }

  autoSuggest = (email: any) => {
    this.setState({ suggest: email.includes('@') });
  };

  autoComplete = (value: any) => {
    const email = `${this.state.model.email.split('@')[0]}${value}`;

    paymentService.setData({ email });
    this.setState({ suggest: false });
  };

  disableSuggest = () => {
    setTimeout(() => this.setState({ suggest: false }), 300);
  };

  setEmail = (email: string) => {
    this.autoSuggest(email);
    paymentService.setData({ email });
  };

  setConfirmEmail = (confirmEmail: string) => {
    paymentService.setData({ confirmEmail });
  };

  setPhone = (phone: string) => {
    paymentService.setData({ phone });
  };

  setName = (name: string) => {
    paymentService.setData({ name });
  };

  setDocument = (document: string) => {
    paymentService.setPaymentData(0, { document });
  };

  switchPersonType = (e: any) => {
    e.preventDefault();
    paymentService.switchPersonType();

    paymentService.setInstallmentsDefault();
  };

  render() {
    if (!this.state.model) {
      return <div />;
    }

    const { model, cart, lock, personType, templateId, paymentMethod, country, samePerson, isUserDataEditLocked } = this.state;
    const { ead } = cart.config;
    const defaultTemplate = Number(templateId) === enTheme.PADRAO;

    if (isUserDataEditLocked && ead && templateId === enTheme.WIDGET) {
      paymentService.setPaymentData(0, { document: cart.user?.document });
      return <SaleRecoveryStudentAndPayerData payer={cart.user} student={cart.student} />;
    }

    if (isUserDataEditLocked) {
      return <SaleRecoveryUserData userData={cart.user} />;
    }

    const { classes } = this.props;

    const showAskDocumentForEad = ead && !samePerson && ![enPaymentMethod.CREDIT_CARD].includes(paymentMethod);

    const nameField = (
      <FieldText
        id="name"
        disabled={lock}
        label={<Translate term={ead ? 'Nome completo do responsável financeiro' : 'Nome Completo'} />}
        validation="required|name|max:100"
        value={model.name}
        mask="name"
        onChange={this.setName}
        inputProps={{
          className: `theme-input ${lock ? 'disabledField' : ''}`
        }}
        InputLabelProps={{
          className: 'theme-label',
          id: 'label-name'
        }}
      />
    );

    return (
      <div className="chk-user-form">
        <Grid container spacing={1}>
          <Grid item xs={12}>
            {cart.config.ead ? (
              <div className="tooltip-card">
                <Hidden xsDown>
                  <Tooltip
                    id="tooltip-financial-name"
                    placement="top"
                    classes={{
                      popper: 'card-popper',
                      tooltip: 'card-tooltip-text'
                    }}
                    disableTouchListener={true}
                    TransitionComponent={Fade}
                    TransitionProps={{ timeout: 600 }}
                    title={<Translate term="Este nome aparecerá na nota fiscal" />}
                  >
                    <div className="question-mark theme-button">?</div>
                  </Tooltip>
                </Hidden>
                {nameField}
              </div>
            ) : (
              nameField
            )}
          </Grid>

          {cart.config.paymentType != enPaymentType.FREE && !cart.config.ead && templateId != enTheme.HOTSITE && (
            <Grid item xs={12}>
              <CpfForm />
            </Grid>
          )}

          <Grid item xs={12} sm={12}>
            <div className="tooltip-email-information">
              <FieldText
                id="email"
                disabled={lock}
                label={<Translate term={ead ? 'E-mail do responsável financeiro' : 'E-mail'} />}
                validation="required|email|max:150"
                value={model.email}
                type="email"
                mask="nospace"
                onChange={this.setEmail}
                onPaste={(e) => e.preventDefault()}
                onBlur={this.disableSuggest}
                inputProps={{
                  className: `theme-input ${lock ? 'disabledField' : ''}`
                }}
                InputLabelProps={{
                  className: 'theme-label',
                  id: 'label-email'
                }}
              />
              {this.state.suggest && <SuggestEmail email={model.email.split('@')[1]} autoComplete={this.autoComplete} />}
            </div>
          </Grid>

          {cart.config.askEmailConfirmation && (
            <Grid item xs={12} sm={12}>
              <FieldText
                id="confirmation"
                autoComplete="off"
                disabled={lock}
                label={<Translate term={'Confirmar e-mail'} />}
                validation={`required|email|max:150|equal:${model.email}`}
                value={model.confirmEmail}
                type="email"
                mask="nospace"
                onChange={this.setConfirmEmail}
                onPaste={(e) => e.preventDefault()}
                inputProps={{
                  className: `theme-input ${lock ? 'disabledField' : ''}`
                }}
                InputLabelProps={{
                  className: 'theme-label',
                  id: 'label-email'
                }}
              />
            </Grid>
          )}

          <Grid
            container
            direction={'column'}
            className={defaultTemplate ? classes.containerCellPhone : `${classes.containerCellPhone} small`}
            sm={12}
          >
            <Grid container className={classes.itemsContainer}>
              <Grid item className={defaultTemplate ? classes.containerItemDDI : `${classes.containerItemDDI} small`}>
                <span className={defaultTemplate ? classes.ItemSpan : `${classes.ItemSpan} small`}>
                  <Translate term="DDI" className="theme-label" />
                </span>
                <DDI title={'Selecione o DDI'} />
              </Grid>
              <Grid item sm={10} className={classes.itemCellPhone}>
                <FieldText
                  id="cellphone"
                  disabled={lock}
                  label={<Translate term={ead ? 'Celular do responsável financeiro' : 'Celular'} />}
                  validation={isBrazilianPhone(country.DDI) ? 'required|min:10|max:11' : 'required|min:3|max:36'}
                  value={sanitizePhone(model.phone)}
                  mask={isBrazilianPhone(country.DDI) ? 'phone' : 'intlPhone'}
                  type="tel"
                  onChange={this.setPhone}
                  inputProps={{
                    className: `theme-input ${lock ? 'disabledField' : ''}`,
                    maxLength: isBrazilianPhone(country.DDI) ? 15 : 36
                  }}
                  InputLabelProps={{
                    className: 'theme-label',
                    id: 'label-phone'
                  }}
                />
              </Grid>
            </Grid>
          </Grid>

          {showAskDocumentForEad && (
            <Grid item xs={12}>
              <FieldText
                id={`document-financial-officer`}
                label={<Translate term="CPF ou CNPJ do responsável financeiro" />}
                validation="required"
                value={model.payment[0].document}
                mask={personType === enPersonType.FOREIGNER ? null : 'cpfCnpj'}
                inputProps={{
                  className: 'theme-input',
                  pattern: '[0-9]*',
                  inputMode: 'numeric',
                  maxLength: 100
                }}
                InputLabelProps={{
                  className: 'theme-label',
                  id: 'label-credit-card-document'
                }}
                onChange={this.setDocument}
              />
            </Grid>
          )}
        </Grid>
      </div>
    );
  }
}
