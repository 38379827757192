import * as React from 'react';

import { BaseComponent, IStateBase } from './BaseComponent';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog/Dialog';
import { Subject } from 'rxjs/Subject';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Translate from './Translate';
import { Fragment } from 'react';
import { alertService } from '../services/AlertService';

interface IState extends IStateBase {
  openned: boolean;
  message?: string;
  title?: string;
  code?: string;
  confirmation?: boolean;
}

export default class AlertComponent extends BaseComponent<IState> {
  private result$!: Subject<boolean>;

  constructor(props: any) {
    super(props);
    this.state = { openned: false, title: 'Alerta' };
  }

  componentDidMount() {
    alertService
      .getAlert()
      .concatMap((data) => {
        this.result$ = data.response;
        this.setState({
          openned: true,
          message: data.message,
          title: data.title,
          confirmation: data.confirmation,
          code: data.code
        });

        return data.response;
      })
      .subscribe(() => {});
  }

  onClose(ok: boolean) {
    this.setState({ openned: false });

    this.result$.next(ok);
    this.result$.complete();
  }

  render() {
    const { openned, title, message, confirmation, code } = this.state;

    if (!openned) {
      return <Fragment />;
    }

    return (
      <Dialog className="error-alert" open={openned} keepMounted onClose={this.onClose.bind(this, false)}>
        <DialogTitle>
          <Translate term={title} /> {code && <span className="code">#{code}</span>}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Translate term={message} />
          </DialogContentText>
        </DialogContent>
        <DialogActions className="actions">
          {confirmation && (
            <Button id="chk-cancel-button" onClick={this.onClose.bind(this, false)} color="primary">
              <Translate term="Cancelar" />
            </Button>
          )}
          <Button id="chk-confirm-button" onClick={this.onClose.bind(this, true)} color="primary">
            <Translate term="OK" />
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}
