import React from 'react';
import { IThankYou } from 'services/ThankYouService';
import { enProductType } from 'enums/enProductType';
import Info from './Info';
import Job from './Job';
import Ticket from './Ticket';
import Physical from './Physical';
import { InAnalyse } from './InAnalyse/index';

interface IProps {
  model: IThankYou;
}

export const Content = ({ model }: IProps) => {
  if (model.status === 8) {
    return <InAnalyse thankYou={model} />;
  }

  switch (model.productType) {
    case enProductType.PHYSICAL:
      return <Physical model={model} />;
    case enProductType.SERVICE:
      return <Job model={model} />;
    case enProductType.TICKET:
      return <Ticket model={model} />;
    default:
      return <Info model={model} />;
  }
};
