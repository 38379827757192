import { ReplaySubject } from 'rxjs/ReplaySubject';
import { Observable } from 'rxjs/Observable';
import { IPixel } from 'interfaces/IProductData';
import { parameterService } from 'services/ParameterService';
import { enParameters } from 'enums/enParameters';
import { TRACKER_URL } from 'settings';
import { Cookies } from 'react-cookie';

export enum enLegacyPixelPath {
  BANKSLIP = 'boleto',
  CHECKOUT = 'checkout',
  THANKYOU = 'thankyou',
  THANKYOU_BANKSLIP = 'obg_boleto'
}

/*
  legacyPixelService: Should be removed in not too distance future
*/
export class LegacyPixelService {
  private pixel$ = new ReplaySubject<IPixel>(1);
  private pixelPath$ = new ReplaySubject<enLegacyPixelPath>(1);

  private baseUrl = TRACKER_URL;

  track(path: enLegacyPixelPath): void {
    this.pixelPath$.next(path);
  }

  shouldShow(contentId: string): boolean {
    const cookie = new Cookies();

    const cookieKey = `pixel-shown-${contentId}`;

    if (cookie.get(cookieKey)) {
      return false;
    }

    cookie.set(cookieKey, true, {
      maxAge: 60 * 60 * 24 * 90
    });

    return true;
  }

  setPixel(pixel: IPixel): void {
    this.pixel$.next(pixel);
  }

  getPixelPathStream(): Observable<enLegacyPixelPath> {
    return this.pixelPath$.asObservable();
  }

  getPixelStream(): Observable<IPixel> {
    return this.pixel$.asObservable();
  }

  getPixel(): Observable<string> {
    return Observable.combineLatest(this.getPixelStream(), this.getPixelPathStream())
      .filter(([pixel]) => !!(pixel && pixel.campaign && pixel.userId))
      .filter(([pixel]) => this.shouldShow(pixel.contentId))
      .map(([pixel, path]) => {
        const campaign = pixel.campaign.replace('g/', '--');
        return `${this.baseUrl}/${path}/${pixel.userId}/${campaign}`;
      })
      .distinctUntilChanged();
  }

  getParameterPixel(): Observable<string> {
    return parameterService
      .get(enParameters.a)
      .filter((param) => !!param)
      .switchMap(() => this.pixel$.asObservable())
      .filter((pixel) => !!pixel.contentId)
      .map((pixel) => {
        return `https://my.eduzz.com/lp/edz/${pixel.contentId}/${pixel.userId}`;
      });
  }
}

// tslint:disable-next-line:variable-name
export const LegacyPixelServiceFactory = () => new LegacyPixelService();

export const legacyPixelService = LegacyPixelServiceFactory();
