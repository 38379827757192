export enum enPaymentMethod {
  CREDIT_CARD = 'creditCard',
  PAYPAL = 'paypal',
  BANKSLIP = 'bankslip',
  FREE = 'free',
  EDUZZ = 'eduzzWallet',
  PIX = 'pix',
  PAGDIVIDIDO = 'pagDividido',
  ONE_CLICK_BUY = 'oneClick',
  TERMINAL = 'terminal'
}
