import { IGoofy } from 'interfaces/IGoofy';
import { apiService } from 'shared/services';
import { Observable } from 'rxjs/Observable';
import * as Sentry from '@sentry/browser';
import { ENABLE_GOOFY, PREVIEW_MODE, GOOFY_URL, GOOFY_APPLICATION, ENV } from '../settings';

export class GoofyService {
  public track(data: IGoofy) {
    if (PREVIEW_MODE) {
      return;
    }

    data.application = `${GOOFY_APPLICATION}_${ENV}`;

    if (!ENABLE_GOOFY || !data.trackerId) {
      console.log('GOOFY SAMPLE', data);
      return;
    }

    apiService
      .post(`${GOOFY_URL}`, data, null)
      .retry(2)
      .catch((err) => {
        Sentry.captureException(err);
        return Observable.empty();
      })
      .subscribe(() => {
        Sentry.addBreadcrumb({
          message: `Goofy: ${data.step}`,
          category: 'goofy',
          level: Sentry.Severity.Info,
          data: null
        });
      });
  }
}
// tslint:disable-next-line:variable-name
export const GoofyServiceFactory = () => new GoofyService();

export const goofyService = GoofyServiceFactory();

export default goofyService;
