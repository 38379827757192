import React, { Fragment } from 'react';
import Translate from 'components/Translate';
import { enPaymentType } from 'enums/enPaymentType';
import { useObservable } from 'react-use-observable';
import { configService } from 'services/ConfigService';

const AskAddressMessage: React.FC = React.memo(() => {
  const [paymentType] = useObservable(() => configService.getMappedConfig((c) => c.paymentType), []);

  if (!paymentType) {
    return null;
  }

  if (paymentType === enPaymentType.FREE) {
    return (
      <Fragment>
        <h1 className="title theme-label" id="thankyou-title">
          <Translate term="Já é quase seu!" />
        </h1>
        <p className="label theme-label">
          <Translate term="O Download está quase pronto, complete o breve formulário abaixo para continuar" />
        </p>
      </Fragment>
    );
  }

  return (
    <Fragment>
      <h1 className="title theme-label" id="thankyou-title">
        <Translate term="Obrigado pela Compra!" />
      </h1>
      <p className="label theme-label">
        <Translate term="O seu pagamento está em processamento, complete o breve formulário abaixo para continuar" />
      </p>
    </Fragment>
  );
});

export default AskAddressMessage;
