import React from 'react';
import { BaseComponent, IStateBase } from 'components/BaseComponent';
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import Grid from '@material-ui/core/Grid';
import CityState from '../CityState';
import { getCeps } from 'services/LocationService';
import { ISearchCep } from 'interfaces/ISearchCep';
import TableCep from './TableCep/index';
import FieldText from 'components/Field/Text';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import { SvgIcon } from 'components/SvgIcon';
import { WithStyles } from 'shared/decorators/withStyles';
import { Translate } from 'components/Translate';
import { styles } from './styles';

interface IState extends IStateBase {
  model: ISearchCep;
  open: boolean;
  msgErro: string;
  infos: any;
}

interface IProps {
  onClose: (postalCode?: string) => void;
  isFullScreen: boolean;
}
@WithStyles(styles)
export default class SearchCEP extends BaseComponent<IState, IProps> {
  constructor(props: any) {
    super(props);

    this.state = { model: {}, open: false, msgErro: '', infos: {} };
  }

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = (postalCode?: string) => {
    this.props.onClose(postalCode);
    this.setState({ open: false });
  };

  close = () => {
    this.handleClose();
  };

  setProvince = (data: any) => {
    const model = { ...this.state.model, city: data.city, province: data.province };
    this.setState({ model });
  };

  setStreet = (street: string) => {
    this.setState({ model: { ...this.state.model, street } });
  };

  search = () => {
    getCeps(this.state.model)
      .loader()
      .bindComponent(this)
      .subscribe(
        (result) => {
          if (result.length == 0) {
            this.setState({ msgErro: 'CEP não encontrado :(', infos: result });
            return;
          }
          this.setState({ infos: result });
        },
        (err) => {
          console.log(err);
        }
      );
  };

  render() {
    if (!this.state.model) {
      return <div />;
    }

    const { open, model } = this.state;

    return (
      <div className={`${this.props.classes.component} search-cep`}>
        <a id="searc-cep" role="button" className="chk-searchCEP__button theme-label" onClick={this.handleOpen}>
          <Translate term="Não sei meu CEP" />
        </a>
        <Modal
          aria-labelledby="Buscar CEP"
          aria-describedby="Preencha os dados de endereço para obter seu CEP"
          open={open}
          onClose={this.handleClose}
          className={this.props.classes.component}
          disableBackdropClick
        >
          <div className="chk-searchCEP-modal">
            <DialogTitle className="chk-searchCEP__modalTitle" id="simple-dialog-title">
              <span onClick={this.close} className="chk-searchCEP__closeButton">
                <SvgIcon name="closeIcon" />
              </span>
              Pesquisa de CEP
            </DialogTitle>
            <DialogContent style={{ overflow: 'visible' }}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <CityState
                    prefixId="mainForm"
                    template={18}
                    value={{ city: model.city, province: model.province }}
                    disableAutocompleteOnProvince
                    onChange={this.setProvince}
                  />
                </Grid>
                {model.city && (
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid className="grow" item align-content="stretch">
                        <FieldText
                          id="street"
                          label="Endereço"
                          validation="required"
                          value={model.street}
                          onChange={this.setStreet}
                          inputProps={{
                            className: 'theme-input'
                          }}
                          InputLabelProps={{
                            className: 'theme-label',
                            id: 'label-search-postal-code-street'
                          }}
                        />
                      </Grid>
                      <Grid item>
                        <Button
                          id="chk-searchcep-button"
                          onClick={this.search}
                          size="large"
                          color="primary"
                          className="chk-searchCEP__confirmButton theme-button"
                        >
                          Buscar
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </Grid>

              {this.state.infos.length > 0 ? (
                <TableCep handleClose={this.handleClose} data={this.state.infos} />
              ) : (
                <p className={'chk-msg-erro'}>{this.state.msgErro}</p>
              )}
            </DialogContent>
          </div>
        </Modal>
      </div>
    );
  }
}
