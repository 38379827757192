export const styles: any = {
  '@global': {
    '.theme_18': {
      '& .chk-searchCEP-modal': {
        width: '550px',
        margin: '60px auto',
        overflow: 'visible',

        '@media screen and (max-width: 480px)': {
          width: '100vw',
          maxHeight: '100vh',
          height: '100vh',
          margin: 0
        },

        '& .chk-searchCEP__closeButtonGrid': {
          backgroundColor: '#FFF',
          position: 'fixed',
          zIndex: 1,
          width: '535px',

          '@media screen and (max-width: 480px)': {
            width: '100%'
          }
        },

        '& .chk-cityState__city': {
          marginTop: '8px'
        },

        '& .chk-cityState__state': {
          marginTop: '8px',
          fontSize: '1rem'
        },

        '& .list-on-bottom': {
          margin: '0 0 8px 0'
        }
      }
    },

    '.theme_14, .theme_16': {
      '& .chk-searchCEP-modal': {
        width: '550px',
        margin: '60px auto',
        overflow: 'visible',

        '@media screen and (max-width: 480px)': {
          width: '100vw',
          maxHeight: '100vh',
          height: '100vh',
          margin: 0
        },

        '& .chk-searchCEP__closeButtonGrid': {
          backgroundColor: '#FFF',
          position: 'fixed',
          width: '535px',

          '@media screen and (max-width: 480px)': {
            width: '100%'
          }
        }
      },

      '& .list-on-bottom': {
        margin: '0 0 8px 0'
      }
    }
  },
  component: {
    padding: '4px 0',

    '& .chk-searchCEP-modal': {
      position: 'relative',
      backgroundColor: 'white',
      overflow: 'hidden'
    },

    '& .chk-searchCEP__modalTitle': {
      '& h2': {
        fontSize: '1.125rem'
      }
    },

    '& .chk-searchCEP__dialogActions': {
      margin: '16px 0 0 0',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center'
    },

    '& .chk-searchCEP__closeButton': {
      top: 0,
      right: 0,
      position: 'absolute',
      padding: '20px 16px 0 0',
      cursor: 'pointer',

      '& svg': {
        width: '8px'
      }
    },

    '& .chk-searchCEP__confirmButton': {
      fontWeight: 600,
      letterSpacing: '0.5px',
      borderRadius: '3px',
      color: '#009358',
      fontSize: '0.875rem',
      padding: '7px 18px',
      margin: '16px 0 0 10px',

      '@media screen and (max-width: 480px)': {
        width: '100%',
        marginLeft: 0
      }
    },

    '& .chk-msg-erro': {
      color: 'red'
    },

    '& .field-select': {
      display: 'flex',
      alignItems: 'center',
      fontSize: '1rem',
      border: '1px solid #d9d9d9',
      padding: '0px 5px 0px 8px',
      minHeight: '43px'
    },

    '& .chk-tableCEP__addressList': {
      '& .chk-tableCEP__address': {
        cursor: 'pointer'
      }
    },

    '& .chk-cityState__state label, & .chk-cityState__city label, & .field.normal label': {
      transform: 'translate(0, 0) scale(0.75)'
    },

    '& .chk-cityState__state, & .chk-cityState__city': {
      '& input': {
        border: '1px solid #d9d9d9',
        padding: '10px 8px'
      }
    }
  }
};
