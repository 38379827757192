import { API_TIMEOUT, API_ENDPOINT, ENV } from 'settings';

import { ApiError } from 'shared/errors/ApiError';
import { Observable } from 'rxjs/Observable';
import axios, { AxiosRequestConfig, Method } from 'axios';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { of } from 'rxjs/observable/of';
import { RSA_PUB_KEY } from '../../settings';
import { v4 } from 'uuid';
import CryptoJS from 'crypto-js';
import * as Sentry from '@sentry/browser';

export interface IApiOpts {
  local?: boolean;
  maxAttempts?: number;
}

const internetStatus$ = new BehaviorSubject(true);

window.addEventListener('offline', () => {
  internetStatus$.next(false);
});

window.addEventListener('online', () => {
  internetStatus$.next(true);
});

const encrypt = new window.JSEncrypt();
encrypt.setPublicKey(atob(RSA_PUB_KEY));

export function getInternetStatus(): Observable<boolean> {
  return internetStatus$.asObservable().distinctUntilChanged();
}

function request<T>(method: Method, url: string, data: any = null, extra: AxiosRequestConfig = {}, opt: IApiOpts = {}): Observable<T> {
  extra = extra || {};

  extra.headers = extra.headers || { 'Content-Type': 'application/json' };

  if (!opt.local && !url.match(/^https?:\/\//)) {
    url = `${API_ENDPOINT}${url}`;
  }

  let attempts = 0;
  const maxAttempts = opt.maxAttempts || 60;

  const key = v4().replace(/-/gi, '');
  const iv = CryptoJS.lib.WordArray.random(16);
  const cryptedKey = encrypt.encrypt(key);

  if (url.startsWith(API_ENDPOINT) && ['post', 'put'].includes(method.toLowerCase())) {
    Sentry.addBreadcrumb({
      message: 'Request Decrypted',
      category: 'info',
      level: Sentry.Severity.Info,
      data: {
        url,
        payload: data
      }
    });

    const stringData = JSON.stringify(data);

    const cryptedData = CryptoJS.AES.encrypt(stringData, CryptoJS.enc.Utf8.parse(key), {
      iv,
      padding: CryptoJS.pad.Pkcs7,
      mode: CryptoJS.mode.CBC
    });

    if (ENV === 'prod') {
      data = {
        payload: cryptedData.toString()
      };
    }
  }

  if (ENV === 'prod' && url.startsWith(API_ENDPOINT)) {
    extra.headers = extra.headers || {};
    extra.headers['X-CryptKey'] = btoa(JSON.stringify([cryptedKey, iv.toString()]));
  }

  const decrypt = (payload: string) => {
    const decryptedData = CryptoJS.AES.decrypt(payload, CryptoJS.enc.Utf8.parse(key), {
      iv,
      padding: CryptoJS.pad.Pkcs7,
      mode: CryptoJS.mode.CBC
    }).toString(CryptoJS.enc.Utf8);

    const decrypted = JSON.parse(decryptedData);

    Sentry.addBreadcrumb({
      message: 'Response Decrypted',
      category: 'info',
      level: Sentry.Severity.Info,
      data: {
        url,
        payload: decrypted
      }
    });

    return decrypted;
  };

  return of(true)
    .switchMap(() => {
      attempts++;

      return Observable.fromPromise(
        axios.request({
          url,
          method,
          timeout: API_TIMEOUT,
          params: method === 'GET' ? data : null,
          data: ['POST', 'PUT'].includes(method) ? data : null,
          responseType: 'json',
          ...extra
        })
      );
    })
    .map((response) => {
      Sentry.addBreadcrumb({
        message: 'Response from API',
        category: 'info',
        level: Sentry.Severity.Info,
        data: response
      });

      internetStatus$.next(true);

      return response.data;
    })
    .retryWhen((err$) => {
      return err$.switchMap((err) => {
        console.log(err.message);
        if (err.message === 'no-internet' || (err.message === 'Network Error' && attempts < maxAttempts)) {
          internetStatus$.next(false);
          return Observable.of(true).delay(2000);
        }

        return Observable.throw(err);
      });
    })
    .map((data) => {
      if (!data.payload) {
        return data;
      }

      return decrypt(data.payload);
    })
    .catch((err) => {
      const code = (err.response && err.response.status) || 0;

      if ([204].includes(code)) {
        return Observable.of(null);
      }

      if (err.response && err.response.data && err.response.data.payload) {
        err.response.data = decrypt(err.response.data.payload);
      }

      return Observable.throw(new ApiError(err, err.response));
    });
}

export function get<T = any>(url: string, params?: any, extra: AxiosRequestConfig = {}, opt: IApiOpts = {}): Observable<T> {
  return request('GET', url, params, extra, opt);
}

export function post<T = any>(url: string, body: any, extra: AxiosRequestConfig = {}, opt: IApiOpts = {}): Observable<T> {
  return request('POST', url, body, extra, opt);
}

export function put<T = any>(url: string, body: any, extra: AxiosRequestConfig = {}, opt: IApiOpts = {}): Observable<T> {
  return request('PUT', url, body, extra, opt);
}

export function del<T = any>(url: string, params?: any, extra: AxiosRequestConfig = {}, opt: IApiOpts = {}): Observable<T> {
  return request('DELETE', url, params, extra, opt);
}
