import React from 'react';
import { BaseComponent, IStateBase } from 'components/BaseComponent';
import contentDeliveryService, { IMessage } from 'services/ContentDeliveryService';
import Dialog, { DialogProps } from '@material-ui/core/Dialog/Dialog';
import { SvgIcon } from 'components/SvgIcon';
import { WithStyles } from 'shared/decorators/withStyles';

interface IState extends IStateBase {
  data: IMessage;
  open: boolean;
}

interface IProps {
  isDefault: boolean;
}

@WithStyles(() => ({
  '@global': {
    '@keyframes left-show': {
      '0%': {
        right: '-100%'
      },

      '25%': {
        right: '-100%'
      },

      '100%': {
        right: '10px'
      }
    }
  },
  component: {
    '& .dialog__contentDelivery': {
      position: 'absolute'
    },

    '& .icon__contentDelivery': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      margin: '20px',

      '& svg': {
        width: '80px',
        height: 'auto'
      }
    },

    '& .title__contentDelivery': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: '1.25em',
      fontWeight: 500,
      color: '#12182B'
    },

    '& .message__contentDelivery': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      margin: '20px',
      color: '#182542',
      fontWeight: 300
    },

    '& .btn__contentDelivery': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',

      maxWidth: '300px',
      padding: '20px',
      margin: '0 auto',
      marginBottom: '20px',
      color: '#fff',
      fontSize: '0.85em',
      fontWeight: 600,
      backgroundColor: '#F5920F',
      textTransform: 'uppercase',
      textDecoration: 'none',
      borderRadius: '6px'
    },

    '& .exit__contentDelivery': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',

      color: '#7E7E7E',
      textDecoration: 'underline',
      fontSize: '0.9em',
      marginBottom: '20px',

      cursor: 'pointer'
    },

    '& .paper__contentDelivery': {
      padding: '24px',
      maxHeight: 'none',

      '&.toast': {
        margin: '0 !important',
        position: 'absolute',
        top: '10px',
        right: '10px',
        animation: 'left-show 1s linear',

        '& .icon__contentDelivery': {
          display: 'none'
        },

        '& .title__contentDelivery': {
          fontSize: '1.1em',
          justifyContent: 'start'
        },

        '& .message__contentDelivery': {
          justifyContent: 'start',
          textAlign: 'left',
          margin: '10px 10px 10px 0'
        },

        '& .actions__contentDelivery': {
          display: 'flex',
          flexDirection: 'row',

          '& .btn__contentDelivery': {
            justifyContent: 'flex-start',
            alignItems: 'flex-start',

            maxWidth: 'unset',
            padding: 0,
            margin: 0,
            marginBottom: 0,

            color: '#F5920F',
            fontSize: '0.9em',
            backgroundColor: 'transparent',
            textTransform: 'none',
            textDecoration: 'underline',

            borderRadius: '6px',
            paddingRight: '10px'
          },

          '& .exit__contentDelivery': {
            margin: 0
          }
        }
      }
    }
  }
}))
export default class ContentDelivery extends BaseComponent<IState, IProps> {
  constructor(props: any) {
    super(props);
    this.state = {
      data: {
        // eslint-disable-next-line camelcase
        tra_key: null,
        url: null,
        checkout: {
          // eslint-disable-next-line camelcase
          display_message: null,
          // eslint-disable-next-line camelcase
          display_show: false
        }
      },
      open: false
    };
  }

  componentDidMount() {
    contentDeliveryService.listen().subscribe((data) => {
      if (data) {
        this.setState({ open: true, data });
      }
    });
  }

  componentDidUpdate() {
    if (!this.props.isDefault) {
      /*
        Github: Prevent overflow: hidden #7567 - Closed without a solution
        https://github.com/mui-org/material-ui/issues/7567
      */
      setTimeout(() => (document.body.style.overflow = 'auto'), 0);
    }
  }

  handleClose = (): void => {
    this.setState({ open: false });
  };

  toastSettings = (): Partial<DialogProps> => {
    if (!this.props.isDefault) {
      return {
        hideBackdrop: true,
        disableBackdropClick: true,
        style: {
          position: 'absolute',
          width: '100%',
          maxWidth: '600px',
          maxHeight: '155px',
          left: 'auto'
        },
        PaperProps: {
          className: 'paper__contentDelivery toast'
        },
        BackdropProps: {
          invisible: true
        }
      };
    }

    return {
      PaperProps: {
        className: 'paper__contentDelivery'
      }
    };
  };

  render() {
    const { data, open } = this.state;

    if (!data.tra_key) {
      return null;
    }

    if (!data.checkout.display_show) {
      return null;
    }

    return (
      <Dialog
        className={this.props.classes.component}
        open={open}
        onClose={this.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        {...this.toastSettings()}
      >
        <div className="icon__contentDelivery">
          <SvgIcon name="boxDelivery" />
        </div>
        <div className="title__contentDelivery">Oba! O seu conteúdo já está disponível</div>
        <div className="message__contentDelivery">
          {!data.checkout.display_message
            ? 'Faça o download do seu conteúdo agora mesmo, também enviamos um e-mail com o seu conteúdo caso queira acessar mais tarde.'
            : data.checkout.display_message}
        </div>
        <div className="actions__contentDelivery">
          <a href={data.url} rel="noopener noreferrer" target="_blank" className="btn__contentDelivery">
            Acessar agora mesmo!
          </a>
          <a onClick={this.handleClose} className="exit__contentDelivery">
            Vou acessar pelo meu e-mail
          </a>
        </div>
      </Dialog>
    );
  }
}
