/* eslint-disable max-len */
import React from 'react';
import { SvgIcon } from 'components/SvgIcon';
import { IThankYou } from 'services/ThankYouService';
import { enPaymentMethod } from 'enums/enPaymentMethod';
import Translate from 'components/Translate';
import { SupportContactText } from '../../Text/SupportContactText';

interface IProps {
  model: IThankYou;
}

const Ticket: React.FC<IProps> = ({ model }) => {
  const textInformation = {
    [enPaymentMethod.BANKSLIP]: (
      <p>
        <Translate
          hasHTML
          term="Você receberá seu ingresso no seu <b>e-mail</b> assim que o pagamento do boleto for confirmado. Enquanto isso, confirme os seus dados de compra."
        />
      </p>
    ),
    [enPaymentMethod.PAGDIVIDIDO]: (
      <p>
        <Translate
          hasHTML
          term="Fique atento, você receberá um <b>SMS</b> para finalizar o processo. Após finalizado você receberá no seu <b>e-mail</b> o ingresso do evento. Enquanto isso, confirme os seus dados de compra."
        />
      </p>
    )
  };
  return (
    <React.Fragment>
      <div className={'message'}>
        {textInformation[model.paymentResult.paymentMethod] ?? (
          <p>
            <Translate
              hasHTML
              term="Estamos preparando com carinho a entrega do seu ingresso. Você receberá no seu <b>e-mail</b> o ingresso do evento. Enquanto isso, confirme os seus dados de compra."
            />
          </p>
        )}
      </div>
      <div className={'data'}>
        <div className={'user'}>
          <p>
            <SvgIcon name="user" />
            <span>{model.studdent ? model.studdent.name : model.user.name}</span>
          </p>
          <p>
            <SvgIcon name="email" />
            <span>{model.studdent ? model.studdent.email : model.user.email}</span>
          </p>
        </div>
      </div>
      <div className="alert">
        <SupportContactText contactEmail={model.content.producerEmail} />
      </div>
    </React.Fragment>
  );
};

export default React.memo(Ticket);
