import { enLang } from 'interfaces/IProductData';
import { Observable } from 'rxjs/Observable';
import { translateService } from 'services/TranslateService';
import { paymentService } from './PaymentService';
import { currencyService } from './CurrencyService';
import { BehaviorSubject } from 'rxjs';
import { parameterService } from './ParameterService';
import { enParameters } from 'enums/enParameters';
import { ICountry } from 'interfaces/ICountry';
import countriesList from '../data/countries.json';
import { enCurrency } from 'enums/enCurrency';
import { GEOIP_COUNTRY_CODE, IS_ECOMMERCE } from 'settings';
import { cartService } from './CartService';

const countries = countriesList as ICountry[];

export class InternationalService {
  private country$ = new BehaviorSubject<ICountry>(countries.find((country) => country.isoAlpha3 === 'BRA'));
  private countryDDI$ = new BehaviorSubject<ICountry>(countries.find((country) => country.DDI === '+55'));

  constructor() {
    if (IS_ECOMMERCE) {
      cartService.setPersonTypeByUserData();
      return;
    }

    const countryParameter = parameterService.getSync(enParameters.country);
    this.setCountryByISO(countryParameter || this.getCountryIsoAlpha3ByIsoAlpha2(GEOIP_COUNTRY_CODE) || 'BRA');

    const currencyParameter = parameterService.getSync(enParameters.currency) as enCurrency;
    if (!countryParameter && !!currencyParameter) {
      this.setCountryByCurrency(currencyParameter);
    }
  }

  public getLang(): Observable<enLang> {
    return this.country$
      .asObservable()
      .map((c) => c?.lang || enLang.EN_US)
      .distinctUntilChanged();
  }

  public getCountries(): ICountry[] {
    return countries;
  }

  public getSelectedCountry() {
    return this.country$.asObservable();
  }

  public getSelectedCountryByDDI() {
    return this.countryDDI$;
  }

  public getCountryIsoAlpha3ByIsoAlpha2(countryIsoAlpha2: string) {
    return countries.find((c) => c.isoAlpha2 === countryIsoAlpha2)?.isoAlpha3;
  }

  public setCountryByCurrency(currency: enCurrency) {
    const country = countries.find((c) => c.currency.code === currency);

    if (!country) {
      const countryDefault = countries[0];

      this.setCountryCurrencyTranslate(countryDefault);
      return;
    }

    this.setCountryCurrencyTranslate(country);
  }

  public setCountryByISO(countryIsoAlpha3: string) {
    const country = countries.find((c) => c.isoAlpha3 === countryIsoAlpha3);

    if (!country) {
      const countryDefault = countries[0];

      this.setCountryCurrencyTranslate(countryDefault);
      return;
    }
    this.setDDI(country);
    this.setCountryCurrencyTranslate(country);
  }

  public setDDI(option: ICountry) {
    const ddi = countries.find((item) => item.DDI === option.DDI);
    this.countryDDI$.next(ddi);
  }

  private setCountryCurrencyTranslate(country: ICountry) {
    this.country$.next(country);
    this.setPersonTypeByLang(country?.lang || enLang.EN_US);
    currencyService.setCurrency(country.currency.code);
    translateService.setLang(country?.lang || enLang.EN_US);
    paymentService.setData({ selectedCountry: country.isoAlpha3 });
  }

  private setPersonTypeByLang(lang: enLang) {
    if (lang === enLang.PT_BR) {
      paymentService.setData({ personType: 'F' });
    } else {
      paymentService.setData({ personType: 'E' });
    }
  }
}

// tslint:disable-next-line:variable-name
export const InternationalServiceFactory = () => new InternationalService();

export const internationalService = InternationalServiceFactory();
