import * as React from 'react';
import Grid from '@material-ui/core/Grid';

import { BaseComponent, IStateBase } from 'components/BaseComponent';
import { paymentService } from 'services/PaymentService';
import { ICartModel } from 'interfaces/ICartModel';
import FieldText from 'components/Field/Text';
import Translate from 'components/Translate';
import { IProductConfig } from 'interfaces/IProductData';
import { parameterService } from 'services/ParameterService';
import { enParameters } from 'enums/enParameters';
import { cartService } from 'services/CartService';
import { currencyService } from 'services/CurrencyService';
import { enPaymentType } from 'enums/enPaymentType';
import { enCurrency } from 'enums/enCurrency';

interface IState extends IStateBase {
  model: ICartModel;
  config: IProductConfig;
  isPersonF: boolean;
  isPersonJ: boolean;
  currency: enCurrency;
  sdoc: boolean;
  askDocument: boolean;
  lock: boolean;
}

export default class CpfForm extends BaseComponent<IState> {
  constructor(props: any) {
    super(props);
    this.state = {
      isPersonF: false,
      isPersonJ: false,
      currency: undefined,
      lock: false,
      model: undefined,
      config: undefined,
      sdoc: false,
      askDocument: true
    };
  }

  componentDidMount() {
    this.bindModel(paymentService.getCart());
    this.observeState({ config: null }, cartService.getConfig());
    this.observeState(
      { isPersonF: false },
      parameterService.get(enParameters.df).map((df) => !!df)
    );
    this.observeState(
      { isPersonJ: false },
      parameterService.get(enParameters.dj).map((dj) => !!dj)
    );
    this.observeState({ currency: null }, currencyService.getCurrency());
    this.observeState(
      { askDocument: false },
      cartService.getConfig().map((config) => !!config.askDocument)
    );
    this.observeState(
      { sdoc: false },
      parameterService.get(enParameters.sdoc).map((sdoc) => !!sdoc)
    );

    cartService
      .getCart()
      .bindComponent(this)
      .filter((cart) => !!cart.user)
      .map((cart) => cart.user.lock)
      .subscribe((lock) => {
        this.setState({ lock });
      });
  }

  setDocument = (document: any) => {
    paymentService.setData({ document });
  };

  render() {
    if (!this.state || !this.state.model) {
      return null;
    }

    const { config, model, isPersonF, isPersonJ, lock, sdoc, askDocument } = this.state;

    if (model.personType === 'E' && config.paymentType !== enPaymentType.FREE) {
      return (
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <FieldText
              id="document"
              disabled={lock}
              label={<Translate term="Documento" />}
              value={model.document}
              validation={model.personType != 'E' ? 'required|' : 'required|min:3|max:95'}
              inputProps={{
                className: `theme-input ${lock ? 'disabledField' : ''}`,
                pattern: '[a-zA-Z0-9]*',
                inputMode: 'text'
              }}
              InputLabelProps={{
                className: 'theme-label'
              }}
              onChange={this.setDocument}
            />
          </Grid>
        </Grid>
      );
    }

    if (!sdoc && !askDocument) {
      return null;
    }
    const isAnyPerson = !isPersonF && !isPersonJ;

    const ead = config.ead;

    const rule = (model.document || '').length > 11 ? 'cnpj' : 'cpf';

    let label = 'CPF ou CNPJ';

    if (!isAnyPerson && isPersonF) {
      label = 'CPF';
    }

    if (!isAnyPerson && !isPersonF) {
      label = 'CNPJ';
    }

    if (model.personType === 'E') {
      label = 'Document ID / VAT / TAX ID';
    }

    let suffix = ead && model.personType != 'E' ? ' do responsável financeiro' : '';

    label = `${label}${suffix}`;

    return (
      <div>
        {model.personType != 'E' ? (
          <Grid container spacing={0}>
            <Grid item xs={12}>
              {isAnyPerson && (
                <FieldText
                  id="document"
                  disabled={lock}
                  label={<Translate term={label} />}
                  validation={model.personType != 'E' ? 'required|' + rule : 'required|min:3|max:95'}
                  value={model.document}
                  mask={model.personType != 'E' ? 'cpfCnpj' : null}
                  inputProps={{
                    className: `theme-input ${lock ? 'disabledField' : ''}`,
                    pattern: '[0-9]*',
                    inputMode: 'numeric',
                    type: 'tel'
                  }}
                  InputLabelProps={{
                    className: 'theme-label',
                    id: 'label-credit-card-document'
                  }}
                  onChange={this.setDocument}
                />
              )}
              {isPersonF && (
                <FieldText
                  id="document"
                  disabled={lock}
                  label={<Translate term={label} />}
                  validation={model.personType != 'E' ? 'required|cpf' : 'required|min:3|max:95'}
                  value={model.document}
                  mask={model.personType != 'E' ? 'cpf' : null}
                  inputProps={{
                    className: `theme-input ${lock ? 'disabledField' : ''}`,
                    pattern: '[0-9]*',
                    inputMode: 'numeric',
                    type: 'tel'
                  }}
                  InputLabelProps={{
                    className: 'theme-label'
                  }}
                  onChange={this.setDocument}
                />
              )}
              {isPersonJ && (
                <FieldText
                  id="document"
                  disabled={lock}
                  label={<Translate term={label} />}
                  validation={model.personType != 'E' ? 'required|cnpj' : 'required|min:3|max:95'}
                  value={model.document}
                  mask={model.personType != 'E' ? 'cnpj' : null}
                  inputProps={{
                    className: `theme-input ${lock ? 'disabledField' : ''}`,
                    pattern: '[0-9]*',
                    inputMode: 'numeric',
                    type: 'tel'
                  }}
                  InputLabelProps={{
                    className: 'theme-label'
                  }}
                  onChange={this.setDocument}
                />
              )}
            </Grid>
          </Grid>
        ) : null}
      </div>
    );
  }
}
