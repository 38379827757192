export const phoneMask = {
  apply: (value: string) => {
    if (!value) return value;

    value = value.replace(/\D/g, '');

    let regexp = /^(\d{0,2})(\d{0,4})(\d{0,4}).*/;

    if (value.length > 10) {
      regexp = /^(\d{0,2})(\d{0,5})(\d{0,4}).*/;
    }
    let result = '($1$2$3';

    if (value.length > 2) {
      result = '($1) $2-$3';
    }
    return value.replace(regexp, result).replace(/-$/, '');
  },
  clean: (value: string) => value.replace(/\D/gi, '').substring(0, 20)
};
