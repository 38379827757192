import MenuItem from '@material-ui/core/MenuItem/MenuItem';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import Fuse from 'fuse.js';
import React from 'react';
import Autosuggest, { ChangeEvent, RenderSuggestionParams, SuggestionSelectedEventData, SuggestionsFetchRequestedParams } from 'react-autosuggest';
import { WithStyles } from 'shared/decorators/withStyles';

import FieldBase, { IPropsFieldBase, IStateFieldBase } from '../Base';
import Input from './Input';
import SuggestionsContainer from './SuggestionsContainer';
import IconButton from '@material-ui/core/IconButton/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment/InputAdornment';

interface IOption {
  value: any;
  label: string;
}

interface IState extends IStateFieldBase {
  value: string;
  term: string;
  suggestions: IProps['options'][0][];
  index?: Fuse;
}

interface IProps extends IPropsFieldBase {
  options: IOption[];
  hideUnselectIcon?: boolean;
  hideSearchIcon?: boolean;
  maxItems?: number;
}

@WithStyles(() => ({
  container: {
    position: 'relative'
  },
  suggestionsContainerOpen: {
    position: 'absolute',
    backgroundColor: 'white',
    zIndex: 20,
    left: 0,
    right: 0,
    top: 50,
    height: '200px',
    overflow: 'auto'
  },
  suggestionsContainerOpenWithLabel: {
    top: 65
  },
  suggestion: {
    display: 'block'
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none'
  },
  adornment: {
    marginRight: -15
  }
}))
export default class FieldAutocomplete extends FieldBase<IProps, IState> {
  static getDerivedStateFromProps(nextProps: IProps, currentState: IState): IState {
    let term = currentState.term;

    if (nextProps.value !== currentState.value || term === undefined) {
      term = (nextProps.options.find((o) => o.value === nextProps.value) || { label: undefined }).label;
    }

    return {
      ...currentState,
      ...FieldBase.getDerivedStateFromProps(nextProps, currentState),
      value: nextProps.value,
      term
    };
  }

  constructor(props: IProps) {
    super(props);
    this.state = { ...this.state, term: '', suggestions: [] };
  }

  getSuggestionValue = (suggestion: IProps['options'][0]) => {
    return suggestion.label;
  };

  handleChange = (event: React.FormEvent<any>, params?: ChangeEvent) => {
    setTimeout(() => {
      this.setState({ term: params.newValue });
    }, 0);
  };

  handleBlur = () => {
    const { value, options } = this.props;

    this.setState({ submitted: true });

    if (!this.state.term) {
      return this.handleClearValue();
    }

    const term: string = (options.find((o) => o.value === value) || { label: null }).label;
    this.setState({ term, submitted: true });
  };

  handleSelected = (event: React.FormEvent<any>, data: SuggestionSelectedEventData<IProps['options'][0]>) => {
    super.onChange(data.suggestion.value);
  };

  handleSuggestionsFetchRequested = ({ value }: SuggestionsFetchRequestedParams) => {
    const options = {
      shouldSort: true,
      threshold: 0.8,
      distance: 5,
      maxPatternLength: 32,
      minMatchCharLength: 1,
      keys: ['value', 'label']
    };

    const index = new Fuse(this.props.options, options);

    const suggestions = index.search<IOption>(value).slice(0, this.props.maxItems || 10);

    this.setState({ suggestions });
  };

  handleSuggestionsClearRequested = () => {
    this.setState({ suggestions: [] });
  };

  handleClearValue = () => {
    this.setState({ term: null });
    super.onChange(null);
  };

  shouldRenderSuggestions = () => {
    return true;
  };

  render() {
    const { term, suggestions, config, selectListToTop } = this.state;
    const { classes, placeholder, disabled, label } = this.props;
    const { hideUnselectIcon, hideSearchIcon, ...otherProps } = this.props;
    const inputStyle = config ? config.template.inputStyle : 'normal';

    return (
      <div ref={this.inputRef} className={`list-on-${selectListToTop ? 'top' : 'bottom'}`}>
        {super.render()}

        <Autosuggest
          suggestions={suggestions}
          theme={{
            container: classes.container,
            suggestionsContainerOpen: `${classes.suggestionsContainerOpen} ${label ? classes.suggestionsContainerOpenWithLabel : ''}`,
            suggestionsList: classes.suggestionsList,
            suggestion: classes.suggestion
          }}
          highlightFirstSuggestion={true}
          renderSuggestionsContainer={SuggestionsContainer}
          shouldRenderSuggestions={this.shouldRenderSuggestions}
          onSuggestionsFetchRequested={this.handleSuggestionsFetchRequested}
          onSuggestionsClearRequested={this.handleSuggestionsClearRequested}
          getSuggestionValue={this.getSuggestionValue}
          renderSuggestion={this.renderSuggestion}
          onSuggestionSelected={this.handleSelected}
          renderInputComponent={Input}
          inputProps={{
            ...(otherProps as any),
            errorMessage: this.errorMessage,
            disableUnderline: inputStyle != 'material',
            classes,
            placeholder: placeholder || 'Pesquisar...',
            required: this.isRequired,
            value: term || '',
            onBlur: this.handleBlur,
            onChange: this.handleChange,
            endAdornment: !term ? (
              hideSearchIcon ? null : (
                <InputAdornment position="end">
                  <IconButton disabled={true} className={classes.adornment}>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              )
            ) : hideUnselectIcon ? null : (
              <InputAdornment position="end" onClick={this.handleClearValue}>
                <IconButton disabled={disabled} className={classes.adornment}>
                  <CloseIcon />
                </IconButton>
              </InputAdornment>
            )
          }}
        />
      </div>
    );
  }

  renderSuggestion(suggestion: IProps['options'][0], { query, isHighlighted }: RenderSuggestionParams) {
    const matches = match(suggestion.label, query);
    const parts = parse(suggestion.label, matches);

    return (
      <MenuItem selected={isHighlighted} component="div">
        <div>
          {parts.map((part, index) => {
            return part.highlight ? (
              <span key={String(index)} style={{ fontWeight: 300 }}>
                {part.text}
              </span>
            ) : (
              <strong key={String(index)} style={{ fontWeight: 500 }}>
                {part.text}
              </strong>
            );
          })}
        </div>
      </MenuItem>
    );
  }
}
