import React from 'react';
import { BaseComponent, IStateBase } from 'components/BaseComponent';
import Grid from '@material-ui/core/Grid/Grid';
import PreviousStep from 'components/Cart/PreviousStep';
import { IProductData } from 'interfaces/IProductData';
import { cartService } from 'services/CartService';
import Translate from 'components/Translate';
import { enStep } from 'components/Cart/Templates/Mobile/MultiStep';
import Coupon from 'components/Cart/Coupon';
import { ICartModel } from 'interfaces/ICartModel';
import { paymentService } from 'services/PaymentService';
import CouponPrice from 'components/Cart/Coupon/CouponPrice';
import { Subscription } from 'components/Cart/Price/Subscription';
import { enPaymentType } from 'enums/enPaymentType';
import FreeShipping from 'components/Cart/Price/FreeShipping';
import { currencyService } from 'services/CurrencyService';
import { enCurrency } from 'enums/enCurrency';
import ProductPrice from 'shared/components/ProductPrice';

interface IState extends IStateBase {
  cart: IProductData;
  payment: ICartModel;
  currency: enCurrency;
  step: enStep;
  isMultipleCards: boolean;
}

export default class HeaderStep extends BaseComponent<IState> {
  componentDidMount() {
    this.observeState({ cart: null }, cartService.getCart());
    this.observeState({ payment: null }, paymentService.getCart());
    this.observeState({ currency: enCurrency.BRL }, currencyService.getCurrency());
    this.observeState({ isMultipleCards: false }, paymentService.isMultipleCreditCards());
  }

  render() {
    if (!this.state) {
      return null;
    }

    const { cart, payment } = this.state;
    const { paymentType } = cart.config;

    return (
      <Grid className="header" container alignItems="center">
        <Grid item xs={6}>
          <PreviousStep step={this.props.step} setStep={this.props.setStep} />
          {this.props.step == enStep.payment && <Coupon />}
        </Grid>
        <Grid item xs={6} className={paymentType == enPaymentType.FREE ? 'free' : ''}>
          <CouponPrice />

          {paymentType === enPaymentType.FREE && <Translate term={'Gratuito'} />}

          {paymentType !== enPaymentType.SUBSCRIPTION && <ProductPrice />}

          <FreeShipping config={cart.config} />

          {paymentType === enPaymentType.SUBSCRIPTION && <Subscription payment={payment} cart={cart} />}
        </Grid>
      </Grid>
    );
  }
}
