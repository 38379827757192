import React from 'react';

interface IProps {
  url?: string;
}

const YoutubeEmbed: React.FC<IProps> = React.memo((props) => {
  const getHash = (url: string) => {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);

    if (match && match[2].length == 11) {
      return match[2];
    } else {
      return 'error';
    }
  };

  if (!props.url) {
    return null;
  }

  return (
    <div className="chk-youtube" style={{ width: 'auto' }}>
      <iframe
        width="100%"
        height="100%"
        style={{ border: 'none' }}
        title="youtubeframe"
        src={`https://www.youtube.com/embed/${getHash(props.url)}?autoplay=1&controls=0&showinfo=0&rel=0`}
      />
    </div>
  );
});

export default YoutubeEmbed; /*;
    const match = url.match(regExp);

    if (match && match[2].length === 11) {
      return match[2];
    } else {
      return 'error';
    }
  };

  if (props.url) {
    return null;
  }

  return (
    <div className="chk-youtube" style={{ width: 'auto' }}>
      <iframe
        width="100%"
        height="100%"
        style={{ border: 'none' }}
        title="youtubeframe"
        src={`https://www.youtube.com/embed/${getHash(props.url)}?autoplay=1&controls=0&showinfo=0&rel=0`}
      />
    </div>
  );
});

export default YoutubeEmbed;
*/

/*
import React from 'react';

interface IProps {
  url?: string;
}

const YoutubeEmbed: React.FC<IProps> = React.memo(props => {
  const getHash = (url: string) => {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/
