import { BaseComponent, IStateBase } from 'components/BaseComponent';
import { ICartModel } from 'interfaces/ICartModel';
import { IProductData } from 'interfaces/IProductData';
import Button from '@material-ui/core/Button/Button';
import Grid from '@material-ui/core/Grid/Grid';
import React from 'react';
import { cartService } from 'services/CartService';
import { paymentService } from 'services/PaymentService';
import { enStep, IStepProps } from './index';
import UserAddressForm from 'components/UserAddressForm';
import { FieldValidation } from 'components/Field';
import Translate from 'components/Translate';
import { enPaymentType } from 'enums/enPaymentType';
import PaymentButton from 'components/Cart/PaymentButton';
import HeaderTitle from './HeaderTitle';
import HeaderStep from './HeaderStep';
import FormHeader from 'components/Cart/FormHeader';

interface IState extends IStateBase {
  cart: IProductData;
  model: ICartModel;
}

export interface IProps extends IStepProps {
  onPay: () => void;
  step: number;
}

export default class StepAddress extends BaseComponent<IState, IProps> {
  componentDidMount() {
    this.observeState({ cart: null }, cartService.getCart());
    this.bindModel(paymentService.getCart());
  }

  onValidate(errors: any) {
    this.setState({ validation: errors || {} });
  }

  isValid(): boolean {
    const validation = this.state.validation || {};
    return Object.keys(validation).length === 0;
  }

  goToNextStep = async () => {
    if (await this.isFormValid()) {
      this.props.setStep(enStep.payment);
    }
  };

  finish = async () => {
    if (await this.isFormValid()) {
      this.props.onPay();
    }
  };

  render() {
    if (!this.state) {
      return '';
    }

    const { formSubmitted, cart } = this.state;

    const disabledButton = !this.isValid() && formSubmitted;

    return (
      <div className="step">
        <FormHeader />
        <FieldValidation.Provider value={this.registerFields}>
          <Grid className="grid" container spacing={1}>
            <HeaderTitle shouldRenderModal={true} />
            <HeaderStep step={this.props.step} setStep={this.props.setStep} />
            <Grid item xs={12}>
              <UserAddressForm />
            </Grid>
            <Grid item xs={12}>
              {cart.config.paymentType === enPaymentType.FREE ? (
                <PaymentButton finish={this.finish} disabledButton={disabledButton} messageButton="Obter gratuitamente" />
              ) : (
                <Button
                  id="chk-next-button"
                  onClick={this.goToNextStep}
                  fullWidth
                  variant="contained"
                  size="large"
                  color="primary"
                  className="chk-stepUser__btnContinue theme-button"
                  disabled={disabledButton}
                >
                  <Translate term="Continuar" />
                </Button>
              )}
            </Grid>
          </Grid>
        </FieldValidation.Provider>
      </div>
    );
  }
}
