import 'raf/polyfill';
import 'core-js/es6/promise';
import 'core-js/es6/array';
import 'core-js/es6/map';
import 'core-js/es6/object';
import 'core-js/es6/set';
import 'core-js/es6/string';
import 'core-js/es7/array';
import 'core-js/es7/object';
import 'core-js/es7/set';
import 'core-js/es7/string';

import './shared/operators/bindComponent';
import './shared/operators/cache';
import './shared/operators/logError';
import 'rxjs/add/observable/combineLatest';
import 'rxjs/add/observable/empty';
import 'rxjs/add/observable/never';
import 'rxjs/add/observable/fromEvent';
import 'rxjs/add/observable/fromPromise';
import 'rxjs/add/observable/of';
import 'rxjs/add/observable/interval';
import 'rxjs/add/observable/throw';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/combineLatest';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/delay';
import 'rxjs/add/operator/distinctUntilChanged';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/filter';
import 'rxjs/add/operator/first';
import 'rxjs/add/operator/finally';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/retry';
import 'rxjs/add/operator/retryWhen';
import 'rxjs/add/operator/skip';
import 'rxjs/add/operator/startWith';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/take';
import 'rxjs/add/operator/takeWhile';

import 'typeface-lato';

import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { hotJarService } from './services/HotJar';
import { findGetParameter } from './functions';

import * as Sentry from '@sentry/browser';

import { SENTRY_DSN, STANDALONE_MODE, IS_DEVELOPMENT, CACHED_MOUNT, GOOFY_ID, CART_SSID } from 'settings';

import App from './App';
import { goofyService } from 'services/GoofyService';
import { translateService } from 'services/TranslateService';

console.log(
  ' %c      ',
  `
  display: block;
  font-size: 66px;
  line-height: 66px;
  text-align: center;
  border-radius: 5px;
  background: #333 url(https://www.eduzz.com/sun/assets/img/logoSun.png) no-repeat;
`
);

(() => {
  const loadedAt = new Date().getTime();

  setInterval(() => {
    const diffInMinutes = (new Date().getTime() - loadedAt) / 1000 / 60;

    const maxTime = 60 * 5; // 5 hours

    if (diffInMinutes >= maxTime) {
      setTimeout(() => {
        window.location.reload();
      }, 100);
    }
  }, 1000);
})();

window.hj =
  window.hj ||
  function () {
    (window.hj.q = window.hj.q || []).push(arguments);
  };

(async () => {
  const isUpsell = findGetParameter('u').toString() === '1';

  const path = window.location.pathname;
  const isValidCheckoutId = path.match(/^\/(\d+|c_[a-f0-9]|[A-Za-z0-9]+)/);

  if (isValidCheckoutId) {
    goofyService.track({
      flow: 'sale',
      trackerId: GOOFY_ID,
      step: 'loaded',
      data: {
        development: !!IS_DEVELOPMENT,
        upsell: isUpsell,
        href: window.location.href,
        generatedTransactionKey: CART_SSID
      }
    });
  }

  if (window.location !== window.parent.location && isUpsell) {
    goofyService.track({
      flow: 'sale',
      trackerId: GOOFY_ID,
      step: 'cancelled',
      data: {}
    });

    setTimeout(() => {
      window.top.location.href = window.location.href;
    }, 1500);

    return;
  }

  console.log('starting app');

  if (STANDALONE_MODE) {
    console.log('Standalone mode');
  }

  if (SENTRY_DSN) {
    Sentry.init({
      dsn: SENTRY_DSN,
      sampleRate: 0.2,
      whitelistUrls: [/eduzz\.com/, /eduzzcdn\.com/],
      ignoreErrors: [
        'Non-Error promise rejection captured',
        "Can't find variable: $",
        '$ is not defined',
        'jQuery',
        'reCAPTCHA has already been rendered in this element',
        'Blocked script',
        "'sandbox' is an invalid sandbox flag",
        'Unexpected token',
        'Block-scoped declarations',
        'missing ) after argument list',
        'Unexpected identifier',
        'Erro ao acessar servidores, verifique sua conexão com a internet e tente novamente',
        'Não foi possível se comunicar com nossos servidores. Tente novamente mais tarde',
        'No error message'
      ]
    });

    window.SENTRY = Sentry;

    console.log('sentry enabled');

    Sentry.addBreadcrumb({
      message: 'Cart initialized',
      category: 'init',
      level: Sentry.Severity.Info,
      data: {
        IS_DEVELOPMENT,
        GOOFY_ID: window.GOOFY_ID,
        SSID: window.SSID,
        CACHED: CACHED_MOUNT,
        CACHE_ID: window.CACHE_ID,
        IS_ECOMMERCE: window.IS_ECOMMERCE
      }
    });
  }

  hotJarService
    .log('IS_DEVELOPMENT', IS_DEVELOPMENT)
    .log(CACHED_MOUNT ? 'CACHED' : 'NOT_CACHED')
    .log(window.IS_ECOMMERCE ? 'ECOMMERCE' : 'NO_ECOMMERCE');

  translateService.load();

  ReactDOM.render(<App />, document.getElementById('root'));
})();
