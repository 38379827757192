import { WithStyles } from './shared/decorators/withStyles';

export const appStyle = WithStyles(() => ({
  '@global': {
    'html.theme_16, html.theme_thankyou_16': {
      background: 'transparent !important',

      '& body': {
        background: 'transparent !important'
      }
    },

    '.field-error': {
      display: 'none',
      '&.error': {
        display: 'block'
      }
    },

    '.field.normal': {
      margin: 0,

      '& input, & select, & .select-input': {
        padding: '10px 8px',
        borderRadius: '2px',
        border: '1px solid #d9d9d9'
      },

      '&label[data-shrink="false"]': {
        transform: 'translate(8px, 30px) scale(1)'
      }
    },

    '.list-on-top .autocomplete-container': {
      top: 'auto !important',
      bottom: 'calc(100% - 24px) !important'
    },

    '.grow': {
      flexGrow: 1
    },

    '.card-popper .card-tooltip': {
      backgroundColor: 'transparent !important'
    },

    '.card-popper .card-tooltip-text': {
      maxWidth: '90vw'
    },

    '.tooltip-card': {
      display: 'flex',
      flexDirection: 'row-reverse',
      position: 'relative',

      '& .question-mark': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        height: '20px',
        width: '20px',
        borderRadius: '100px',
        margin: '0',
        backgroundColor: 'blue',
        position: 'absolute',
        zIndex: 1,
        top: 3,
        '@media screen and (max-width: 640px)': {
          top: -10
        }
      }
    },
    'html.theme_14 .tooltip-card, .chk-oneClickBuy__content .tooltip-card': {
      '& .question-mark': {
        top: -3
      }
    },

    '.offline-alert': {
      backgroundColor: 'rgba(220, 63, 83, 0.7)',
      position: 'fixed',
      bottom: 0,
      right: 0,
      left: 0,
      width: '100%',
      padding: '10px',
      fontSize: '18px',
      color: 'white',
      textAlign: 'center',
      zIndex: 1000
    },

    '.warning-alert': {
      backgroundColor: 'rgba(220, 191, 63, 0.7)',
      position: 'fixed',
      bottom: 0,
      right: 0,
      left: 0,
      width: '100%',
      padding: '10px',
      fontSize: '18px',
      color: 'white',
      textAlign: 'center',
      zIndex: 1000
    },
    '.MuiRadio-colorSecondary.Mui-checked': {
      color: '#3960A3'
    },

    '.error-alert': {
      '& .code': {
        float: 'right',
        margin: '0 0 0 10px',
        fontSize: '0.6rem',
        fontWeight: 'bold',
        color: '#999'
      }
    },

    '.theme_18': {
      '& .theme-language-bar': {
        borderBottom: '1px solid #e8e7e7'
      }
    },

    'li.planItem': {
      minHeight: '75px',
      height: 'auto',
      color: '#4e4e4e',
      padding: '0 15px',

      '& .planName': {
        wordBreak: 'break-word',
        whiteSpace: 'normal',
        textTransform: 'capitalize',
        fontWeight: 'bold'
      }
    },

    '.planName': {
      wordBreak: 'break-word',
      whiteSpace: 'normal',
      textTransform: 'capitalize',
      fontWeight: 'bold'
    },

    '.informationsPlan': {
      wordBreak: 'break-word',
      whiteSpace: 'normal'
    }
  }
}));
