/* eslint-disable @typescript-eslint/no-unused-vars */
import { enPaymentMethod } from 'enums/enPaymentMethod';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { apiService } from 'shared/services';
import { IPaymentResult } from 'interfaces/IPaymentResult';
import { timer } from 'rxjs/observable/timer';
import { enStatusWaiter } from 'enums/enStatusWaiter';
import { Observable } from 'rxjs';
import { symbolUrl } from 'shared/utils/symbolUrl';
import { delayWhen, tap } from 'rxjs/operators';

export interface IResultWaiter {
  type: enPaymentMethod;
  result: any;
  time?: number;
  cancelUrl?: string;
  timeout?: number;
}

export interface IWaiter {
  waiting: boolean;
  status: enStatusWaiter;
  expiresAt: number;
}

class PaymentWaiter {
  private startTime: number = null;
  private config$ = new BehaviorSubject<IResultWaiter | undefined>(undefined);
  private waiter$ = new BehaviorSubject<IWaiter>({ waiting: false, status: enStatusWaiter.IDLE, expiresAt: 0 });
  public waitPayment(type: enPaymentMethod, result: IPaymentResult) {
    const expiresAt = new Date().getTime() + result.timeout * 1000;

    this.config$.next({ type, time: result.timeout - 10, result, cancelUrl: result.cancelUrl });

    this.waiter$.next({ waiting: true, status: enStatusWaiter.RUNNING, expiresAt });

    this.startTime = Math.ceil(new Date().getTime() / 1000);

    const date = new Date().getTime();
    const url = `${result.checkUrl}${symbolUrl(result.checkUrl)}time=${date}`;

    return apiService
      .get<{ status: enStatusWaiter }>(url, undefined, undefined, {
        maxAttempts: 1
      })
      .combineLatest(this.getWaiterStatus().take(1))
      .pipe(
        tap(() => {
          let diff = 0;

          const now = Math.ceil(new Date().getTime() / 1000);
          diff = now - this.startTime;

          if (diff >= result.timeout) {
            this.changeWaiterStatus(enStatusWaiter.WAITING);
          }
        })
      )
      .map(([apiResponse, clientStatus]) => {
        const { status: apiStatus } = apiResponse;
        const statusToStopWaiting = [enStatusWaiter.PAID, enStatusWaiter.CANCELED, enStatusWaiter.TIMEOUT, enStatusWaiter.IDLE];
        const statusToContinueWaiting = [enStatusWaiter.RUNNING, enStatusWaiter.WAITING];

        if (statusToStopWaiting.includes(clientStatus)) {
          return clientStatus;
        }

        if (statusToStopWaiting.includes(apiStatus)) {
          return apiStatus;
        }

        if (statusToContinueWaiting.includes(clientStatus)) {
          throw clientStatus;
        }

        return apiStatus;
      })
      .retryWhen((errors) => {
        return errors.pipe(delayWhen(() => timer(3000)));
      });
  }

  public resetWaiter() {
    this.config$.next(undefined);
    this.waiter$.next({ waiting: false, status: enStatusWaiter.IDLE, expiresAt: 0 });
  }

  public changeWaiterStatus(status: enStatusWaiter) {
    const currentWaiterValue = this.waiter$.value;
    this.waiter$.next({ ...currentWaiterValue, status, waiting: true });
  }

  public changeWaiterStatusToRunning() {
    const currentWaiterValue = this.waiter$.value;
    const config = this.config$.value;

    this.startTime = Math.ceil(new Date().getTime() / 1000);
    const expiresAt = new Date().getTime() + config.result.timeout * 1000;
    this.waiter$.next({ ...currentWaiterValue, status: enStatusWaiter.RUNNING, waiting: true, expiresAt });
  }

  public getWaiter(): Observable<IWaiter & IResultWaiter> {
    return this.config$
      .combineLatest(this.waiter$)
      .map(([config, waiter]) => {
        if (!config) {
          return null;
        }

        return {
          waiting: waiter?.waiting,
          status: waiter.status,
          expiresAt: waiter.expiresAt,
          cancelUrl: config.cancelUrl,
          maxTime: config.time,
          type: config.type,
          result: config.result
        };
      })
      .distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b));
  }

  public getWaiterStatus(): Observable<enStatusWaiter> {
    return this.waiter$.map((waiter) => waiter.status);
  }
}

export const paymentWaiter = new PaymentWaiter();
