import * as loader from 'shared/operators/loader';
import * as React from 'react';

import { IStateBase } from './BaseComponent';

interface IState extends IStateBase {
  show: boolean;
}

let timeout: any = null;

const element = document.getElementById('loader');

class LoaderComponent extends React.Component<{}, IState> {
  constructor(props: any) {
    super(props);

    loader.setup(this);
    this.state = { show: false };
  }

  public show(): void {
    if (this.state.show) {
      return;
    }
    this.setState({ show: true });
  }

  public hide(): void {
    if (!this.state.show) {
      return;
    }

    this.setState({ show: false });
  }

  componentDidMount() {
    if (!element) {
      return;
    }

    setTimeout(
      () => {
        const className = element.className || '';

        element.className = className.replace('starting', '');
        console.log('removed starting loader class');
      },
      window.CACHED_MOUNT ? 3000 : 0
    );
  }

  render(): any {
    const { show } = this.state;

    if (!element) {
      return;
    }

    clearTimeout(timeout);

    timeout = setTimeout(() => {
      const className = (element.className || '').replace('hide', '');

      if (!show) {
        element.className = `${className} hide`;
      } else {
        element.className = className;
      }
    }, 50);

    return null;
  }
}

export default React.memo(LoaderComponent);
