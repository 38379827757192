import React, { ReactNode, useMemo } from 'react';

interface IABProps {
  current: ReactNode;
  experiment: ReactNode;
  experimentName: string;
}

const AB: React.FC<IABProps> = ({ experiment, current, experimentName }) => {
  const isA = useMemo(() => window.AB === 'A', []);
  const currentExperiment = useMemo(() => window.EXPERIMENT, []);

  return <>{isA || experimentName !== currentExperiment ? current : experiment}</>;
};

export default React.memo(AB);
