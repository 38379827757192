/* eslint-disable camelcase */
import './cardValidity';
import './cnpj';
import './cpf';
import './email';
import './installments';
import './name';
import './requiredSelect';
import './uniqueCard';
import './birthdate';
import './equal';
import './cardNumber';
import './installmentsCardBrand';

import validator from 'validatorjs';
import { translateService } from 'services/TranslateService';
export const messages: any = {
  same: 'A confirmação não coincide.',
  confirmed: 'A confirmação não coincide.',
  after: 'Deve ser depois que :after.',
  after_or_equal: 'Deve ser igual ou depois que :after_or_equal.',
  email: 'Endereço de e-mail inválido',
  date: 'Data inválida',
  in: 'Inválido',
  integer: 'É obrigatório ter um número',
  required: 'Este campo é obrigatório',
  required_if: 'Este campo é obrigatório',
  cpf: 'CPF Inválido',
  cnpj: 'CNPJ Inválido',
  equal: 'O e-mail digitado precisa ser idêntico ao anterior',
  card_number: 'Número de cartão inválido',
  card_validity: 'Validade inválida',
  unique_card: 'Os cartões devem ser diferentes',
  name: 'Precisamos do nome e sobrenome',
  installments: 'Selecione uma parcela',
  requiredSelect: 'Este campo é obrigatório',
  birthdate: 'Data inválida',
  installments_card_brand: 'Para parcelas maiores de 12x os cartões aceitos são exclusivos. Utilize Visa, Master ou Elo.'
};

interface IValidationResult {
  valid: boolean;
  message?: string;
}

export function validate(value: any, rule: string): IValidationResult {
  const isNotEnUSLanguage = translateService.getLang() !== 'enus';

  if (!rule) return { valid: true };

  const result = new validator(
    { value },
    { value: rule },
    {
      ...messages,
      ...(isNotEnUSLanguage && {
        min: {
          numeric: 'Valor mínimo :min',
          string: 'Mínimo :min caracteres'
        },
        max: {
          numeric: 'Valor máximo :max',
          string: 'Máximo :max caracteres'
        }
      })
    }
  );

  if (result.passes()) {
    return { valid: true };
  }

  const allErrors = result.errors.all();
  return { valid: false, message: allErrors.value[0] };
}
