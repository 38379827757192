import UserInfos from 'components/Cart/Templates/Mobile/OneClickBuy/UserInfos';
import React, { useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { cartService } from 'services/CartService';
import { useMappedObservable } from 'shared/hooks/useMappedObservable';
import { configService } from 'services/ConfigService';
import { enTheme } from 'interfaces/IProductData';
import Translate from 'components/Translate';
import { getCountries } from 'services/LocationService';
import { useObservable } from 'react-use-observable';

const labels = {
  street: 'Endereço',
  district: 'Bairro',
  province: 'Estado',
  city: 'Cidade',
  countryName: 'País'
};

interface IAddressData {
  street: string;
  province: string;
  city: string;
  countryName?: string;
  district?: string;
}

const useStyles = makeStyles({
  container: {
    padding: '0 0 16px 0',
    margin: '0 0 16px 0',
    width: '100%'
  },
  divider: {
    '@media screen and (min-width: 960px)': {
      borderBottom: '1px solid #cecece'
    }
  }
});

const SaleRecoveryAddressData: React.FC = React.memo(() => {
  const classes = useStyles({});

  const [countries] = useObservable(() => getCountries(), []);

  const [user] = useMappedObservable(
    () => cartService.getCart(),
    (cart) => cart?.user,
    []
  );

  const [templateId] = useMappedObservable(
    () => configService.getConfig(),
    (config) => config.template.id,
    []
  );

  const getInfos = useCallback(() => {
    const address: IAddressData = {
      street: user.street,
      district: null,
      province: user.province,
      city: user.city,
      countryName: user.countryName
    };

    if (user.personType !== 'E') {
      address.district = user.district;
      address.countryName = 'Brasil';
    }

    const infos = Object.keys(address).map((field) => {
      if (field === 'countryName' && !address[field] && user.countryId) {
        const country = countries.find((c) => c.id === user.countryId);
        return country ? `${labels[field]}: ${country.country}` : null;
      }

      return !!address[field] && `${labels[field]}: ${address[field]}`;
    });

    return infos.filter((value) => !!value);
  }, [user, countries]);

  if (!user) {
    return null;
  }

  const showDivider = Number(templateId) === enTheme.PADRAO;

  return (
    <div className={`${classes.container} ${showDivider ? classes.divider : ''}`}>
      <h3>
        <Translate term="Dados de endereço" />
      </h3>
      <UserInfos infos={getInfos()} />
    </div>
  );
});

export default SaleRecoveryAddressData;
