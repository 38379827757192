import React, { Fragment } from 'react';
import { BaseComponent, IStateBase } from 'components/BaseComponent';
import { IProductData } from 'interfaces/IProductData';
import { cartService } from 'services/CartService';
import { paymentService } from 'services/PaymentService';
import ShopbackClick from './ShopbackClick';
import ShopbackPaid from './ShopbackPaid';

interface IState extends IStateBase {
  cart: IProductData;
  paymentSent: boolean;
}

export default class Shopback extends BaseComponent<IState> {
  constructor(props: any) {
    super(props);
    this.state = { cart: null, paymentSent: false };
  }

  componentDidMount() {
    const paymentObservable = paymentService
      .hasPaymentSent()
      .filter((s) => s)
      .take(1);

    const cartObservable = cartService
      .getCart()
      .take(1)
      .do(() => {
        console.log('shopback load start');
      });

    this.observeState({ cart: null }, cartObservable);
    this.observeState({ paymentSent: false }, paymentObservable);
  }

  render() {
    if (!this.state.cart) {
      return null;
    }

    const { paymentSent, cart } = this.state;

    return (
      <Fragment>
        {cart && <ShopbackClick />}
        {paymentSent && <ShopbackPaid price={cart.price} transactionKey={cart.transactionKey} />}
      </Fragment>
    );
  }
}
