import { makeStyles, MenuItem } from '@material-ui/core';
import { ICountry } from 'interfaces/ICountry';
import React from 'react';
import { CountryItem } from './CountryItem';

interface IProps {
  options: ICountry[];
  country: ICountry;
  filterCountries: (option: ICountry) => boolean;
  selectOption: (option: ICountry) => void;
  hasDDI?: boolean;
}

const useStyles = makeStyles({
  container: {
    padding: 8
  }
});

const CountriesList: React.FC<IProps> = React.memo(({ options, country, filterCountries, selectOption, hasDDI = false }) => {
  const classes = useStyles();
  const id = hasDDI ? 'ddi-' : 'country-';
  return (
    <>
      {options
        .filter((option) => filterCountries(option))
        .map((option, i) => (
          <MenuItem key={i} selected={country.name === option.name} onClick={() => selectOption(option)} className={classes.container}>
            <CountryItem
              isoAlpha2={option.isoAlpha2}
              nativeName={option.nativeName}
              name={option.name}
              DDI={hasDDI && option.DDI}
              id={`${id}${option.isoAlpha3}`}
            />
          </MenuItem>
        ))}
    </>
  );
});

export { CountriesList };
