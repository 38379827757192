import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import React, { useCallback, useContext, useEffect } from 'react';
import { PagDivididoContext, enPagdivididoSteps } from '..';
import { FieldValidation } from 'components/Field';
import { PayContext } from 'components/Cart';
import { pagDivididoService } from 'services/PagDivididoService';
import { useObservable } from 'react-use-observable';

interface INextStep {
  buttonText: string;
  toStep?: enPagdivididoSteps;
  finalStep?: boolean;
  allowBack?: boolean;
}

const NextStep: React.FC<INextStep> = ({ buttonText, toStep, finalStep, allowBack }) => {
  const { onSetStep, onBack, validateParent } = useContext(PagDivididoContext);
  const pay = useContext(PayContext);

  const context = useContext(FieldValidation);
  const [isLoading] = useObservable(() => pagDivididoService.isLoading(), []);

  const changeState = useCallback(async () => {
    const isParentValid = await validateParent(true);
    const valid = isParentValid && context.isValid(true);

    if (!valid) {
      return;
    }

    if (finalStep) {
      pay();
    }

    if (toStep) {
      onSetStep(toStep);
    }
  }, [toStep, onSetStep, context, finalStep, pay, validateParent]);

  useEffect(() => {
    context.update();
  }, [context]);

  return (
    <Grid container item style={toStep === enPagdivididoSteps.INTRO ? { marginTop: 16 } : {}}>
      {!allowBack && (
        <Grid item xs={12}>
          <Button disabled={!context.isValid()} fullWidth className={'theme-button next-step-button'} onClick={changeState}>
            {buttonText}
          </Button>
        </Grid>
      )}
      {allowBack && (
        <Grid direction="row" alignItems="center" justify="flex-end" item container xs={12}>
          <Button className="back next-step-button" onClick={() => onBack()}>
            Voltar
          </Button>
          <Button
            id={finalStep ? 'btn-finished-pay' : 'btn-continue'}
            disabled={!context.isValid() && isLoading}
            className={'theme-button next-step-button'}
            onClick={changeState}
          >
            {buttonText}
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

export default React.memo(NextStep);
