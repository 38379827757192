import React from 'react';
import { BaseComponent, IStateBase } from 'components/BaseComponent';
import { pixelService } from 'services/PixelService';
import LegacyPixel from 'components/Pixel/LegacyPixel';
import { WithStyles } from 'shared/decorators/withStyles';

interface IState extends IStateBase {
  hasLegacy: boolean;
  addAffiliatePixel: string;
  urlAfiliate: string;
  urlProducer: string;
}

@WithStyles(() => ({
  component: {
    position: 'absolute'
  }
}))
export default class Pixel extends BaseComponent<IState> {
  state = {
    hasLegacy: true,
    addAffiliatePixel: '',
    urlProducer: '',
    urlAfiliate: ''
  };

  componentDidMount() {
    pixelService
      .init()
      .bindComponent(this)
      .filter((pixel) => {
        /*
          hasLegacy: Should be removed in not too distance future
        */
        const scripts = Object.values(pixel.scripts).filter((value) => value !== null);

        return scripts.length !== 0 || !!pixel.urlProducer || !!pixel.urlAfiliate;
      })
      .subscribe((pixel) => {
        const { scripts, urlProducer = '', urlAfiliate = '' } = pixel;
        this.setState({ urlProducer, urlAfiliate, hasLegacy: false });

        if (!scripts) {
          return;
        }

        if (scripts.analytics) {
          this.scriptPixel('ANALYTICS_PIXEL_SCRIPT', scripts.analytics, pixel?.path);
        }

        if (scripts.tagmanager) {
          this.scriptPixel('TAGMANAGER_PIXEL_SCRIPT', scripts.tagmanager, pixel?.path);
        }

        if (scripts.hubspot) {
          this.scriptPixel('HUBSPOT_PIXEL_SCRIPT', scripts.hubspot, pixel?.path);
        }

        if (scripts?.analyticsG4) {
          this.scriptPixel('ANALYTICSG4_PIXEL_SCRIPT', scripts.analyticsG4, pixel?.path);
        }

        if (scripts?.tiktok) {
          this.scriptPixel('TIKTOK_PIXEL_SCRIPT', scripts.tiktok, pixel?.path);
        }
      });

    pixelService
      .setAffiliationCookie()
      .bindComponent(this)
      .subscribe((addAffiliatePixel) => {
        this.setState({ addAffiliatePixel });
      });
  }

  scriptPixel = (id: string, script: any, path?: string) => {
    if (!path.includes('bankslip-view')) {
      this.removeScriptsBody(`${id}_BODY`);
    }

    if (script.head) {
      let headElementScript = document.getElementById(`${id}_HEAD`);

      if (!headElementScript) {
        headElementScript = document.createElement('script');
        headElementScript.setAttribute('id', `${id}_HEAD`);
      }

      headElementScript.innerHTML = script.head;
      document.head.appendChild(headElementScript);
    }

    const bodyId = `${id}_BODY_${path}`;
    if (!!document.getElementById(bodyId)) {
      return;
    }

    const bodyElementScript = document.createElement('script');

    bodyElementScript.setAttribute('id', bodyId);
    bodyElementScript.setAttribute('data-type', `${id}_BODY`);

    bodyElementScript.innerHTML = script.body;
    document.body.appendChild(bodyElementScript);
  };

  removeScriptsBody = (dataType: string) => {
    document.querySelectorAll(`[data-type=${dataType}]`).forEach((elem) => {
      elem.remove();
    });
  };

  iframePixel = (urlIFrame: string, type: string) => {
    if (!!urlIFrame) {
      return <iframe name="pixelB" style={{ position: 'fixed' }} id={`pixel-script-${type}`} src={urlIFrame} width="1" height="1" frameBorder="0" />;
    }
    return <></>;
  };

  render() {
    const { hasLegacy, addAffiliatePixel, urlProducer, urlAfiliate } = this.state;

    return (
      <div className={this.props.classes.component}>
        {!hasLegacy ? (
          <React.Fragment>
            {addAffiliatePixel && (
              <iframe name="pixelA" style={{ position: 'fixed' }} id="pixel-mark-affiliate" src={addAffiliatePixel} width="1" height="1" />
            )}
            {this.iframePixel(urlProducer, 'producer')}
            {this.iframePixel(urlAfiliate, 'afiliate')}
          </React.Fragment>
        ) : (
          <LegacyPixel />
        )}
      </div>
    );
  }
}
