import { Observable } from 'rxjs/Observable';
import * as api from 'shared/services/api';
import { cartService } from 'services/CartService';
import { IProductData, IInstallment } from '../interfaces/IProductData';
import { parameterService } from './ParameterService';
import { paymentService } from './PaymentService';

export interface IInstallments {
  [key: string]: {
    installments: IInstallment[];
    maxInstallments: number;
    minValue: number;
  };
}

const cache: {
  [key: string]: IInstallments;
} = {};

export const warmapCache = (product: Partial<IProductData>) => {
  if (!product.installments || product.installments.length === 0) {
    return;
  }

  const key = JSON.stringify([product.price]);

  cache[key] = {
    [product.price]: {
      installments: product.installments,
      maxInstallments: product.installments[product.installments.length - 1].installment,
      minValue: product.installments[product.installments.length - 1].value
    }
  };
};

export const getInstallments = (values: number[]): Observable<IInstallments> => {
  const key = JSON.stringify(values);

  if (cache[key]) {
    return Observable.of(cache[key]);
  }

  return cartService
    .getCart()
    .combineLatest(parameterService.getAll(), paymentService.getCart())
    .take(1)
    .switchMap(([cart, parameters, payment]) => {
      return api.post<IInstallments>(`/installments/generate/${cart.contentId}`, {
        values,
        parameters,
        personType: payment.personType || 'F'
      });
    })
    .map((data) => {
      cache[key] = data;
      return data;
    });
};

export const calcDefaultNumberInstallments = (installments: number) => {
  return installments > 12 ? 12 : installments;
};
