import Grid from '@material-ui/core/Grid';
import React, { useContext, useMemo } from 'react';
import { enPagdivididoSteps, PagDivididoContext } from '..';

interface IProps {
  step: enPagdivididoSteps;
  title: string;
}

const StepTitle: React.FC<IProps> = ({ step, title }) => {
  const { getStepNumber } = useContext(PagDivididoContext);
  const stepNumber = useMemo(() => getStepNumber(step), [step, getStepNumber]);

  return (
    <Grid style={{ marginTop: 16 }} item xs={12} className="step-title">
      <span>{stepNumber}</span>
      {title}
    </Grid>
  );
};

export default React.memo(StepTitle);
