import 'services/ThemeService';
import 'toast-eduzz/dist/toast-eduzz.min.css';

import AlertComponent from 'components/AlertComponent';
import LoaderComponent from 'components/LoaderComponent';
import Scripts from 'components/Scripts';
import { blue } from '@material-ui/core/colors';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import React, { Fragment } from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';

import CartPage from './components/Cart';
import ToastProducer from './components/ToastProducer';
import CssBaseline from '@material-ui/core/CssBaseline';
import ErrorComponent from './components/Error';
import Captcha from './components/Captcha';
import InternetStatus from 'components/InternetStatus';
import Shopback from 'components/Scripts/Shopback';

import * as Sentry from '@sentry/browser';
import ShopbackClick from 'components/Scripts/Shopback/ShopbackClick';
import Pixel from './components/Pixel';
import { CHECKOUT_URL_PARAMS, IS_SHOPIFY, PREVIEW_MODE } from 'settings';
import ThankYouWithUpsell from 'components/ThankYouWithUpsell/index';
import { appStyle } from './App.style';
import { checkLocalStorage } from 'functions';
const theme = createMuiTheme({
  typography: {
    fontFamily: 'Lato, Helvetica, Arial, sans-serif',
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 700
  },
  palette: {
    primary: {
      50: '#e8f5e9',
      100: '#c8e6c9',
      200: '#a5d6a7',
      300: '#81c784',
      400: '#66bb6a',
      500: '#4caf50',
      600: '#43a047',
      700: '#388e3c',
      800: '#2e7d32',
      900: '#1b5e20',
      A100: '#b9f6ca',
      A200: '#69f0ae',
      A400: '#00e676',
      A700: '#00c853',
      contrastText: '#fff'
    },
    secondary: blue,
    background: {
      default: '#fff'
    }
  },
  overrides: {
    MuiButton: {
      root: {
        boxShadow: 'none',
        '&:active': {
          boxShadow: 'none'
        },
        '&:focus': {
          boxShadow: 'none'
        },
        textTransform: 'none'
      },
      contained: {
        boxShadow: 'none',
        '&:active': {
          boxShadow: 'none'
        },
        '&:focus': {
          boxShadow: 'none'
        }
      }
    },
    MuiTooltip: {
      touch: {
        fontSize: '14px',
        fontWeight: 700
      },
      tooltipPlacementBottom: {
        margin: '8px 0'
      }
    }
  }
});
interface IState {
  loaded: boolean;
}

@appStyle
class App extends React.Component<any, IState> {
  constructor(props: any) {
    super(props);
    this.state = { loaded: false };
  }

  componentDidMount() {
    this.setState({ loaded: true });
    this.setCheckoutlUrl();
  }

  toHome = () => {
    return <Redirect to="/" />;
  };

  setCheckoutlUrl = () => {
    const url = window.location.href;
    if (checkLocalStorage()) {
      localStorage.setItem(CHECKOUT_URL_PARAMS, url);
    }
  };

  render() {
    const { loaded } = this.state;

    return (
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        {!PREVIEW_MODE && (
          <Fragment>
            <AlertComponent />
            <InternetStatus />
            <LoaderComponent />
            <Scripts />
            <Captcha />
            <ToastProducer />
            <Shopback />
            <Pixel />
          </Fragment>
        )}
        <div id="mainContent" className="content">
          {loaded && (
            <Router>
              <Switch>
                <Route exact path="/thankyou/:id/:key" component={ThankYouWithUpsell} />
                <Route
                  exact
                  path="/pixel/:id"
                  render={
                    // tslint:disable-next-line:jsx-no-lambda
                    () => {
                      let iframe = false;

                      try {
                        iframe = window.location.href !== window.top.location.href;
                      } catch (err) {
                        iframe = true;
                      }

                      if (window.location.href.includes('/pixel/preload')) {
                        return null;
                      }

                      if (!iframe) {
                        window.location.href = window.location.href.replace('/pixel/', '/');
                        return null;
                      }

                      return <ShopbackClick />;
                    }
                  }
                />
                <Route
                  path="/:id"
                  render={
                    // tslint:disable-next-line:jsx-no-lambda
                    (props: any) => {
                      if (
                        props.match.url.match(/^\/([0-9]+|c_[a-f0-9]{32})?([0-9]+|r_[a-f0-9]{32}|[a-zA-Z0-9]{8,})?(\/?askaddress\/?|\/?)?$/gi) ||
                        IS_SHOPIFY
                      ) {
                        return <CartPage />;
                      }

                      Sentry.captureException(new Error(`Invalid route`));

                      return <ErrorComponent />;
                    }
                  }
                />
                <Route exact path="/" component={ErrorComponent} />
                <Route render={this.toHome} />
              </Switch>
            </Router>
          )}
        </div>
      </MuiThemeProvider>
    );
  }
}

export default App;
