import { makeStyles } from '@material-ui/core';
import UserInfos from 'components/Cart/Templates/Mobile/OneClickBuy/UserInfos';
import Translate from 'components/Translate';
import { IUser } from 'interfaces/IProductData';
import React from 'react';
import { cartService } from 'services/CartService';

interface IProps {
  user: IUser;
}

const useStyles = makeStyles({
  container: {
    marginBottom: '1rem'
  },
  details: {
    margin: 0,
    color: '#505050',
    fontSize: '0.75rem'
  },
  editInfosButton: {
    fontSize: '0.75rem',
    color: '#F59211',
    cursor: 'pointer',
    textDecoration: 'underline'
  }
});

const OrderDetails: React.FC<IProps> = React.memo(({ user }) => {
  const classes = useStyles({});
  const editInfos = () => {
    cartService.enableAskAddress().subscribe();
    cartService.disableOneClickBuy().subscribe();
  };

  return (
    <div className={classes.container}>
      <div id="ocb-details">
        <h6 className={classes.details}>
          <Translate term="Detalhes do Pedido" />
        </h6>
        <UserInfos infos={[user.name, user.email]} />
      </div>
      <div className={`theme-label ${classes.editInfosButton}`}>
        <a id="chk-editinfo-button" role="button" onClick={editInfos}>
          <Translate term="Editar dados" />
        </a>
      </div>
    </div>
  );
});

export default OrderDetails;
