import UserInfos from 'components/Cart/Templates/Mobile/OneClickBuy/UserInfos';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useMappedObservable } from 'shared/hooks/useMappedObservable';
import { configService } from 'services/ConfigService';
import { enTheme, IUser } from 'interfaces/IProductData';
import Translate from 'components/Translate';

const useStyles = makeStyles({
  container: {
    padding: '0 0 16px 0',
    margin: '0 0 16px 0',
    width: '100%'
  },
  divider: {
    '@media screen and (min-width: 960px)': {
      borderBottom: '1px solid #cecece'
    }
  },
  subtitle: {
    margin: '0 0 0.6rem 0'
  },
  noMarginBottom: {
    marginBottom: '0px'
  }
});

interface IProps {
  userData: IUser;
  subtitle?: string | null;
  showTitle?: boolean;
  showDivider?: boolean;
  noMarginBottom?: boolean;
}

const SaleRecoveryUserData: React.FC<IProps> = React.memo(
  ({ userData, showDivider, subtitle = null, showTitle = true, noMarginBottom = false }: IProps) => {
    const classes = useStyles({});

    const [templateId] = useMappedObservable(
      () => configService.getConfig(),
      (config) => config.template.id,
      []
    );

    if (!userData) {
      return null;
    }

    const { name, email } = userData;
    showDivider = showDivider ?? Number(templateId) === enTheme.PADRAO;

    const containerClasses = `
      ${classes.container}
      ${showDivider ? classes.divider : ''}
      ${noMarginBottom ? classes.noMarginBottom : ''}
    `.trim();

    return (
      <div className={containerClasses}>
        {showTitle && (
          <h3>
            <Translate term="Detalhes do pedido" />
          </h3>
        )}
        {subtitle && (
          <h4 className={classes.subtitle}>
            <Translate term={subtitle} />
          </h4>
        )}
        <UserInfos infos={[name, email]} />
      </div>
    );
  }
);

export default SaleRecoveryUserData;
