import { BaseComponent, IStateBase } from 'components/BaseComponent';
import * as React from 'react';
import PaymentForm from './PaymentForm';
import { paymentService } from 'services/PaymentService';
import { cartService } from 'services/CartService';
import { ICartModel } from 'interfaces/ICartModel';
import Button from '@material-ui/core/Button/Button';
import Translate from 'components/Translate';
import { IProductData, IProductConfig } from 'interfaces/IProductData';
import Purchaser from './PaymentForm/Purchaser/index';
import { WithStyles } from 'shared/decorators/withStyles';
import PaymentMethod from 'components/Cart/PaymentMethod';

interface IProps {
  index: number;
  cardFlagLimit?: number;
  hidePaymentMessage?: boolean;
  removeCreditCard?: any;
  validateForm: (submit: boolean) => Promise<boolean>;
}

interface IState extends IStateBase {
  cart: ICartModel;
  config: IProductConfig;
  productData: IProductData;
  isUserDataEditLocked: boolean;
}

@WithStyles(() => ({
  component: {
    padding: '2%',

    '& .method-label': {
      textAlign: 'center',
      display: 'block',
      margin: '0 auto 10px',
      fontSize: '0.75rem',
      textTransform: 'uppercase',
      lineHeight: '1.6rem'
    },

    '& .chk-multiple-cards-header': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: '2%',
      marginTop: '2%',

      '& .chk-remove-card': {
        backgroundColor: 'lightgray',
        borderRadius: '4px',
        color: 'white',
        fontSize: '0.7em',
        textTransform: 'capitalize',
        padding: '0px 8px'
      },

      '& .chk-remove-card-mobile': {
        backgroundColor: 'lightgray',
        borderRadius: '100%',
        minHeight: '25px',
        minWidth: '25px',
        padding: 0,

        '& svg': {
          width: '8px'
        }
      },

      '& .payment-label': {
        textTransform: 'uppercase',
        fontSize: '.666rem',
        fontWeight: 'bold'
      }
    },

    '& .brand-icon': {
      position: 'absolute',
      width: '32px',
      height: '32px',
      marginLeft: '8px',
      zIndex: 1
    },

    '& .theme-input.credit-card-number': {
      paddingLeft: '50px !important'
    }
  }
}))
export default class Payment extends BaseComponent<IState, IProps> {
  componentDidMount() {
    this.observeState({ cart: null }, paymentService.getCart());
    this.observeState({ productData: null }, cartService.getCart());
    this.observeState({ config: null }, cartService.getConfig());

    cartService.isContractSaleRecovery().subscribe((isContractSaleRecovery) => this.setState({ isUserDataEditLocked: isContractSaleRecovery }));
  }

  removeCreditCard = () => {
    this.props.removeCreditCard();
    paymentService.removePayment(this.props.index);
  };

  render() {
    if (!this.state) {
      return <div />;
    }

    const { cart, productData, config, isUserDataEditLocked } = this.state;
    const { index, classes } = this.props;
    const { enableBankslip, enablePaypal, enablePix, enableCreditcard, enablePagDividido } = productData.config;
    const numberCreditCards = cart.payment.length;

    const enables = [enableBankslip, enablePaypal, enablePix, enableCreditcard, enablePagDividido];

    const hideMessage = numberCreditCards === 1 || enables.filter((e) => e).length === 1;

    return (
      <div className={`${classes.component} chk-payment ${index % 2 != 0 ? 'add-background' : ''}`}>
        {hideMessage && <PaymentMethod index={index} />}

        {numberCreditCards > 1 && (
          <div className="chk-multiple-cards-header">
            <span className="chk-label payment-label">
              <Translate term="Cartão de Crédito" /> #{index + 1}
            </span>
            <Button id="chk-remove-button" className="chk-remove-card theme-button" onClick={this.removeCreditCard}>
              <Translate term="Remover" />
            </Button>
          </div>
        )}
        {config.ead && !isUserDataEditLocked && <Purchaser />}
        <PaymentForm validateForm={this.props.validateForm} index={index} />
      </div>
    );
  }
}
