import { makeStyles } from '@material-ui/core';
import React from 'react';

import { getUrlCountryImage } from 'shared/utils/getUrlCountryImage';

interface IProps {
  name: string;
  nativeName: string;
  isoAlpha2: string;
  DDI?: string;
  id: string;
}

const useStyles = makeStyles({
  container: {
    padding: '10px 14px',
    width: 400,
    display: 'flex',
    gap: '0.5rem',

    '& .flag-container': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',

      '& span': {
        fontWeight: 'bold',
        minWidth: '2.75rem'
      },

      '& .flag': {
        width: 30,
        height: 20,
        borderRadius: 3,
        marginRight: 8,

        '& img': {
          width: '100%',
          height: '100%',
          borderRadius: 3
        }
      }
    }
  },

  countryName: {
    whiteSpace: 'break-spaces'
  }
});

const CountryItem: React.FC<IProps> = React.memo(({ name, nativeName, isoAlpha2, id, DDI = null }) => {
  const classes = useStyles({});

  return (
    <div className={classes.container} id={id}>
      <div className="flag-container">
        <img className="flag" src={getUrlCountryImage(isoAlpha2)} alt={name} />
        {DDI && <span className="ddi">{DDI}</span>}
      </div>

      <div className={classes.countryName}>{`${name} | ${nativeName}`}</div>
    </div>
  );
});

export { CountryItem };
