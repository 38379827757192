/* eslint-disable max-len */
import * as React from 'react';

function ShoppingImage() {
  return (
    <svg width={250} height={130} viewBox="0 0 230 153" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M229.472 139.998c-1.669-3.189-8.459-5.752-11.495-6.334-16.214-3.096-33.195-2.662-49.619-2.613-17.884.056-34.945 2.923-52.686 4.254-9.226.687-18.972-.551-28.217-.873a4019.497 4019.497 0 01-45.66-1.858c-13.123-.619-26.74.762-38.34 8.006-1.317.824-2.647 1.821-3.24 3.3-3.76 9.288 43.094 8.112 49.198 8.273 30.795.798 61.609 1.238 92.404.377 22.88-.619 46.762-.582 69.475-3.814 5.021-.718 11.854-.972 16.195-3.932 2.424-1.647 2.771-3.281 1.985-4.786z"
        fill="#CDEBFA"
      />
      <path
        d="M189.964 134.518a5.418 5.418 0 005.422-5.414 5.419 5.419 0 00-5.422-5.415 5.419 5.419 0 00-5.423 5.415 5.419 5.419 0 005.423 5.414zM60.886 81.924c7.156-8.615 5.962-21.39-2.666-28.535s-21.423-5.953-28.579 2.662c-7.155 8.615-5.962 21.39 2.666 28.535 8.628 7.144 21.424 5.952 28.58-2.662z"
        fill="#F0FAFF"
      />
      <path
        d="M22.35 99.681a1.891 1.891 0 01-1.172 1.752 1.907 1.907 0 01-2.069-.411 1.894 1.894 0 011.343-3.237 1.9 1.9 0 011.898 1.896zM218.686 82.046a2.225 2.225 0 01-3.8 1.572 2.224 2.224 0 01-.482-2.422 2.219 2.219 0 012.056-1.372 2.228 2.228 0 012.226 2.222zM119.62 7.42a2.1 2.1 0 002.102-2.1 2.1 2.1 0 00-2.102-2.1 2.1 2.1 0 00-2.103 2.1 2.1 2.1 0 002.103 2.1z"
        fill="#039BE5"
      />
      <path
        d="M75.316 132.672a4.75 4.75 0 000-6.725 4.768 4.768 0 00-6.736 0 4.75 4.75 0 000 6.725 4.767 4.767 0 006.736 0zM199.138 40.413c9.394-3.561 14.118-14.051 10.551-23.43-3.566-9.38-14.072-14.096-23.466-10.535-9.394 3.56-14.118 14.05-10.551 23.43 3.566 9.38 14.073 14.096 23.466 10.535z"
        fill="#F0FAFF"
      />
      <path
        d="M191.487 102.75V29.098a12.21 12.21 0 00-3.584-8.636 12.232 12.232 0 00-8.65-3.575H53.159a12.239 12.239 0 00-8.647 3.577 12.2 12.2 0 00-3.582 8.634v73.652a12.191 12.191 0 003.58 8.637 12.245 12.245 0 008.65 3.578h126.093a12.25 12.25 0 008.651-3.577 12.208 12.208 0 003.583-8.638z"
        fill="#CDEBFA"
      />
      <path
        d="M187.781 102.825V29.028c0-4.643-3.77-8.408-8.421-8.408H53.83c-4.651 0-8.421 3.764-8.421 8.408v73.797c0 4.643 3.77 8.408 8.42 8.408H179.36c4.651 0 8.421-3.765 8.421-8.408z"
        fill="#fff"
      />
      <path
        d="M112.813 32.08h-52.52a4.663 4.663 0 00-4.667 4.66v56.354c0 2.573 2.09 4.66 4.667 4.66h52.52a4.663 4.663 0 004.667-4.66V36.74c0-2.574-2.09-4.66-4.667-4.66zM176.077 32.08h-48.702a4.662 4.662 0 00-4.666 4.66v28.394a4.663 4.663 0 004.666 4.659h48.702a4.663 4.663 0 004.667-4.66V36.74c0-2.574-2.09-4.66-4.667-4.66zM176.077 75.047h-48.702a4.663 4.663 0 00-4.666 4.659V93.03a4.663 4.663 0 004.666 4.66h48.702a4.663 4.663 0 004.667-4.66V79.706c0-2.573-2.09-4.66-4.667-4.66z"
        stroke="#D8DCFD"
        strokeWidth={0.96}
        strokeMiterlimit={10}
      />
      <path
        d="M107.594 75.207H65.442a1.93 1.93 0 00-1.931 1.928v8.456a1.93 1.93 0 001.93 1.928h42.153a1.93 1.93 0 001.931-1.928v-8.456a1.93 1.93 0 00-1.931-1.928z"
        fill="#FFF4D4"
      />
      <path
        d="M90.62 87.101a3.398 3.398 0 01-1.524-.335 2.662 2.662 0 01-1.05-.938 2.505 2.505 0 01-.38-1.365c0-.508.126-.96.38-1.357a2.571 2.571 0 011.05-.938c.452-.223.96-.335 1.523-.335.57 0 1.078.112 1.524.335.452.223.802.536 1.05.938.254.397.38.849.38 1.357 0 .507-.126.963-.38 1.365a2.58 2.58 0 01-1.05.938c-.446.224-.954.335-1.524.335zm0-1.635c.24 0 .44-.087.594-.26.155-.18.232-.428.232-.743 0-.316-.077-.56-.232-.734a.762.762 0 00-.595-.26.762.762 0 00-.594.26c-.155.173-.232.418-.232.734s.077.563.232.743c.155.173.353.26.594.26zm5.982 7.46l-1.765 5.082h-2.044l-.77-2.35-.8 2.35H89.18l-1.766-5.082h1.988l.874 2.685.91-2.685h1.784l.882 2.713.91-2.713h1.84z"
        fill="#fff"
      />
      <path
        d="M107.004 37.88H66.032a2.519 2.519 0 00-2.521 2.517v27.511a2.519 2.519 0 002.52 2.517h40.973a2.519 2.519 0 002.521-2.517v-27.51a2.519 2.519 0 00-2.521-2.518z"
        fill="#FFCA28"
      />
      <path
        d="M93.306 49.839h-2.848v-2.142a4.57 4.57 0 00-1.062-2.992 4.582 4.582 0 00-2.757-1.582 4.496 4.496 0 00-4.727 2.56 4.476 4.476 0 00-.412 1.858v2.293h-2.848a.408.408 0 00-.407.406v9.817a3.99 3.99 0 002.472 3.697c.487.201 1.008.304 1.534.303h7.45a4.013 4.013 0 002.837-1.17 4 4 0 001.175-2.83v-9.811a.406.406 0 00-.407-.407zm-10.99-2.298a3.657 3.657 0 011.382-2.86 3.671 3.671 0 013.102-.706 3.755 3.755 0 012.842 3.679v2.18h-7.326V47.54zm10.582 12.516c0 .852-.339 1.67-.942 2.272a3.22 3.22 0 01-2.276.941H82.25a3.22 3.22 0 01-2.275-.941 3.21 3.21 0 01-.943-2.272v-9.404h2.446v2.919a1.216 1.216 0 00.102 2.308 1.22 1.22 0 001.531-.79 1.215 1.215 0 00-.791-1.53v-2.907h7.321v2.919a1.217 1.217 0 00.075 2.318 1.22 1.22 0 001.532-.79 1.215 1.215 0 00-.791-1.528v-2.92h2.44v9.41-.005z"
        fill="#fff"
      />
      <path
        d="M107.594 43.89a4.77 4.77 0 004.774-4.767 4.77 4.77 0 00-4.774-4.766 4.77 4.77 0 00-4.773 4.766 4.77 4.77 0 004.773 4.766z"
        fill="#039BE5"
      />
      <path d="M105.637 39.166l1.786 1.301 2.129-2.694" stroke="#fff" strokeWidth={0.99} strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M39.846 36.874c6.245 0 11.307-5.055 11.307-11.29 0-6.234-5.062-11.289-11.307-11.289-6.244 0-11.306 5.055-11.306 11.29 0 6.234 5.062 11.289 11.306 11.289z"
        fill="#072D83"
      />
      <path
        d="M43.692 22.677a2.352 2.352 0 00-3.32.037l-.595.61-.617-.605a2.344 2.344 0 00-3.315.027 2.34 2.34 0 00.027 3.31l2.28 2.244a2.336 2.336 0 003.33-.038l2.248-2.27a2.344 2.344 0 00-.038-3.315z"
        fill="#fff"
      />
      <path
        d="M210.539 60.352h-10.781a2.601 2.601 0 01-2.607-2.597c.001-.69.276-1.352.765-1.84a2.615 2.615 0 011.842-.764h10.781a2.61 2.61 0 011.843.763 2.6 2.6 0 01-1.843 4.438z"
        fill="#6473F9"
      />
      <path
        d="M202.954 57.76a.654.654 0 01-.172.511.677.677 0 01-.766.16.673.673 0 01-.397-.671.671.671 0 011.335 0zM205.808 57.749a.662.662 0 01-.665.664.666.666 0 01-.665-.664.666.666 0 011.33 0zM208.667 57.743a.664.664 0 01-1.136.47.666.666 0 01-.144-.724.664.664 0 011.085-.215.663.663 0 01.195.47z"
        fill="#fff"
      />
      <path
        d="M120.417 142.361c2.224 0 4.028-1.8 4.028-4.022a4.025 4.025 0 00-4.028-4.021 4.025 4.025 0 00-4.029 4.021 4.025 4.025 0 004.029 4.022zM96.618 142.361c2.225 0 4.028-1.8 4.028-4.022a4.024 4.024 0 00-4.028-4.021 4.024 4.024 0 00-4.028 4.021 4.025 4.025 0 004.028 4.022z"
        fill="#9EB5D9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M145.804 78.589l8.533-1.725.23 1.136-8.549 1.729h-.005a4.008 4.008 0 00-2.025 1.025 3.958 3.958 0 00-1.135 1.952l-7.693 31.947H92.257a6.554 6.554 0 01-3.894-1.282 6.5 6.5 0 01-2.36-3.339l-.006-.019-4.385-19.075 59.182-4.637.932-3.868a5.119 5.119 0 011.467-2.523 5.166 5.166 0 012.605-1.32l.006-.001zm-5.295 8.897l-57.465 4.503 4.074 17.725a5.348 5.348 0 001.934 2.725 5.405 5.405 0 003.205 1.055h41.99l6.262-26.008z"
        fill="#0D47A1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M138.735 100.23l-6.544 26.86-.001.003a10.63 10.63 0 01-10.349 8.2H90.996v-1.159h30.845a9.472 9.472 0 009.221-7.308l.001-.003 6.175-25.344-52.093 3.043-.068-1.158 53.658-3.134z"
        fill="#0D47A1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M123.271 114.195l5.102-26.206 1.138.221-5.102 26.206-1.138-.221zM97.754 114.373l-2.783-23.654 1.152-.136 2.783 23.655-1.152.135zM111.433 114.306V89.491h1.159v24.815h-1.159z"
        fill="#0D47A1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M179.151 50.298a5.219 5.219 0 015.218 5.218v4.407a5.22 5.22 0 01-4.43 5.159l-.004.064h-6.725c-1.144-1.118.149-8.697.955-11.209.114-.355.224-.61.332-.784a5.22 5.22 0 014.654-2.855z"
        fill="#191847"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M179.05 61.487c-1.502.905-2.978 1.342-3.67 1.164-1.737-.448-1.897-6.802-.553-9.307 1.344-2.505 8.253-3.596 8.601 1.367.121 1.722-.603 3.26-1.671 4.514l1.916 8.93h-5.566l.943-6.668z"
        fill="#D4A181"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M184.164 54.74a12.71 12.71 0 00-.473-.453l.008-.18s-1.747-3.577-4.433-3.577c-2.683 0-3.531 1.248-4.253 2.308l-.002.003c.378 1.294 1.515 1.818 2.801 2.41.553.256 1.134.523 1.694.87a1.276 1.276 0 11.746 2.265c-.256 2.755-.153 5.9.611 6.647h6.725c.193-3.354-.675-4.311-1.561-5.289-.834-.92-1.686-1.858-1.686-4.83a36.148 36.148 0 00-.177-.175z"
        fill="#191847"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M192.623 110.152s-8.345 2.334-14.4 2.334-11.248-3.595-11.248-3.595 8.167-2.396 13.451-2.396c5.285 0 12.197 3.657 12.197 3.657z"
        fill="#1F28CF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M173.407 114.729c.054-.081.099-.169.135-.264.743-1.966 12.655-23.182 13.841-27.061h-13.41c-.902 2.95-4.963 21.456-5.535 25.149-1.803 5.824-5.515 24.687-5.989 25.994-.499 1.375 2.306 2.807 3.054 1.117 1.189-2.689 4.024-10.13 5.286-14.673 1.183-4.26 2.146-8.092 2.618-10.262z"
        fill="#BA8B72"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M184.379 117.319c5.445 1.376 25.364 3.864 26.731 4.255 1.406.401 2.639-2.497.901-3.125-2.765-.999-10.386-3.307-15.006-4.249-3.716-.758-7.107-1.376-9.384-1.738.193-6.395 1.074-21.743.496-25.058h-11.865c.554 3.179 5.239 26.372 6.109 28.694.277.829 1.101 1.195 2.018 1.221z"
        fill="#D4A181"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M211.684 119.728c.186-1.052.603-2.262 1.668-2.18.047.004.091.009.132.016.617.109 1.766.524 1.766.524l-2.271 12.881s-1.749.21-1.69-.896c.058-1.105.078-1.839.078-1.839l-1.422-5.691a1 1 0 01.755-1.219l.23-.051s.644-.919.754-1.545zM164.3 139.603c1.068 0 2.333.202 2.436 1.265.005.046.007.091.007.132 0 .627-.209 1.83-.209 1.83h-13.08s-.51-1.685.589-1.82a78.313 78.313 0 001.825-.242l5.358-2.389a1.002 1.002 0 011.332.532l.089.218s1.017.474 1.653.474zM188.079 87.404s-7.843-2.244-14.842 0c-1.623 7.407-5.568 10.346-6.262 21.702 7.885 3.918 17.626-2.794 25.742 1.026 2.319-5.344-.927-18.214-4.638-22.728z"
        fill="#0D47A1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M156.427 76.95l9.157 1.25-1.643 4.102-7.43-2.946c-3.202.316-4.751.24-4.645-.228.089-.394.429-.513.743-.623.244-.086.472-.166.552-.365.183-.457-.858-1.403-1.884-2.258-1.026-.856-.338-1.013.095-1 1.539.36 3.224 1.05 5.055 2.069zm44.547 22.492c-.68-1.379-6.626-19.123-6.626-19.123l-5.079.02s8.353 18.796 8.711 19.682c.465 1.152-.325 2.825-.86 3.96-.083.175-.16.338-.225.484.743.332 1.1-.069 1.476-.493.425-.478.874-.984 1.933-.492.408.19.795.415 1.173.635 1.306.759 2.505 1.456 4.137.449.259-.16.542-.761-.143-1.23-1.706-1.17-4.165-3.218-4.497-3.892z"
        fill="#D4A181"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M180.438 64.213l-3.976.265c-.979 8.86-6.313 14.455-18.255 12.56l-1.587 11.41c11.162 1.442 20.951-3.027 22.957-15.126.519-3.132.929-6.392.861-9.109z"
        fill="#9EB5D9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M200.277 97.144c-2.76-14.688-8.587-26.052-17.48-34.09h-.394l-.004.003-6.024 1.38c0 7.236-1.61 12.643-3.158 17.842-.668 2.245-1.325 4.45-1.835 6.748h16.27c.229 2.633.37 5.338.443 8.117h12.182z"
        fill="#9EB5D9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M187.652 89.027c-.467-5.359-1.3-10.415-2.678-15.167.302 5.082.386 11.387-.629 15.167h3.307z"
        fill="#000"
        fillOpacity={0.1}
      />
    </svg>
  );
}

export default ShoppingImage;
