import * as React from 'react';

import { enPaymentMethod } from 'enums/enPaymentMethod';
import Grid from '@material-ui/core/Grid';
import { SvgIcon } from 'components/SvgIcon';
import { makeStyles } from '@material-ui/core/styles';
import { useObservable } from 'react-use-observable';
import { configService } from 'services/ConfigService';
import Translate from 'components/Translate';
interface IProps {
  title: string | React.ReactNode;
  src: string;
  value: enPaymentMethod;
  id?: string;
  onClick?: () => void;
  active?: boolean;
  classes?: any;
}

const useStyles = makeStyles((theme) => ({
  component: {
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    marginBottom: 2,
    alignItems: 'center',
    minWidth: 68,
    height: 65,
    borderRadius: 4,
    padding: '2px',
    border: '1px solid #ccc',
    cursor: 'pointer',
    transition: 'all 0.3s ease 0s',
    filter: 'grayscale(100%)',
    '&.default': {
      [theme.breakpoints.up('md')]: {
        padding: '8px',
        minWidth: 'unset'
      }
    },
    '&.active': {
      filter: 'unset',
      borderColor: '#3960A3',
      color: '#3960A3'
    },
    '& + div': {
      marginLeft: '14px'
    },
    '&:hover': {
      opacity: 0.8
    }
  },
  paymentMethod: {
    flexFlow: 'column wrap',
    '&.default': {
      [theme.breakpoints.up('md')]: {
        flexFlow: 'row nowrap'
      }
    }
  },
  textMethod: {
    '&.default': {
      [theme.breakpoints.up('md')]: {
        width: 'auto',
        justifyContent: 'flex-start'
      }
    }
  },
  typograph: {
    overflowWrap: 'anywhere',
    whiteSpace: 'pre-wrap',
    textAlign: 'center',
    fontSize: '0.65rem',
    lineHeight: 1.2,
    '&.default': {
      [theme.breakpoints.up('md')]: {
        fontSize: '0.875rem',
        textAlign: 'left',
        whiteSpace: 'pre'
      }
    }
  },
  image: {
    alignSelf: 'center',
    marginRight: 8,
    '&.default': {
      [theme.breakpoints.up('md')]: {
        width: 'auto'
      }
    }
  },
  svgFix: {
    '& svg': {
      height: 20
    }
  }
}));
const Method: React.FC<IProps> = ({ title, src, active = false, id, onClick, value }) => {
  const [config] = useObservable(() => configService.getConfig(), []);
  const classes = useStyles({});
  const svgFix = [enPaymentMethod.EDUZZ, enPaymentMethod.PAYPAL].includes(value) ? classes.svgFix : '';

  if (!config) {
    return null;
  }

  const { id: templateId } = config.template;

  return (
    <div onClick={onClick} id={id} className={`${classes.component} ${active ? 'active' : ''} ${Number(templateId) === 18 ? 'default' : ''}`}>
      <Grid className={`${classes.paymentMethod} ${Number(templateId) === 18 ? 'default' : ''}`} direction="column" container>
        <Grid
          className={`${classes.image} ${svgFix} theme-payment-method-icons ${Number(templateId) === 18 ? 'default' : ''}`}
          container
          item
          justify="center"
          alignItems="center"
          style={{ alignSelf: 'flex-start' }}
        >
          <SvgIcon name={src} />
        </Grid>
        <Grid className={`${classes.textMethod} ${Number(templateId) === 18 ? 'default' : ''}`} item container justify="center" alignItems="center">
          <span className={`${classes.typograph} ${Number(templateId) === 18 ? 'default' : ''}`}>
            {typeof title === 'string' ? <Translate term={title} /> : { title }}
          </span>
        </Grid>
      </Grid>
    </div>
  );
};

export default React.memo(Method);
