import React from 'react';

import FieldBase from './Base';

export interface IFieldValidationContext {
  bind: (field: FieldBase<any, any>) => void;
  unbind: (field: FieldBase<any, any>) => void;
  isValid: (submitted?: boolean) => boolean;
  isFormValid?: boolean;
  isSubmitted: () => boolean;
  getValidationMessages: () => {
    [key: string]: string;
  };
  update: () => void;
}

// tslint:disable-next-line:variable-name
export const FieldValidation = React.createContext<IFieldValidationContext>(null);
