import React from 'react';
import ReactDOM from 'react-dom';
import { BaseComponent } from 'components/BaseComponent';

interface IProps {
  transactionKey: string;
  price: number;
}

export default class ShopbackPaid extends BaseComponent<{}, IProps> {
  componentDidMount() {
    const script = document.createElement('script');

    script.innerHTML = `
      window._st_account = 12;
      window._cv_data = {
        'order_id': '${this.props.transactionKey}',
        'valor': ${this.props.price}
      };
      (function () {
        var ss = document.createElement('script');
        ss.type = 'text/javascript';
        ss.async = true;
        ss.src = '//app.shoptarget.com.br/js/tracking.js';
        var sc = document.getElementsByTagName('script')[0];
        sc.parentNode.insertBefore(ss, sc);
      })();
    `;

    // eslint-disable-next-line react/no-find-dom-node
    ReactDOM.findDOMNode(this).appendChild(script);
  }

  render() {
    return <div />;
  }
}
