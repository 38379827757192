import { BaseComponent } from 'components/BaseComponent';
import { IProductData } from 'interfaces/IProductData';
import React from 'react';
import { SvgIcon } from 'components/SvgIcon';
import Logo from './Logo';
import Grid from '@material-ui/core/Grid/Grid';
import { unescapeHtml } from 'functions';

interface IProps {
  cart: IProductData;
}

export default class Header extends BaseComponent<any, IProps> {
  closeWidget() {
    window.parent.postMessage('EdzWidgetClose', '*');
  }

  render() {
    const { cart } = this.props;

    return (
      <Grid item xs={12} className="chk-multiStep__productName theme-header-bg-image theme-header-bg theme-header">
        <button id="chk-closewidget-button" className="chk-multiStep__closeForm theme-button" onClick={this.closeWidget}>
          <SvgIcon name="closeIcon" />
        </button>
        <Logo image={cart.config.logo} />
        <h2 className="product-name">{unescapeHtml(cart.content.title)}</h2>
      </Grid>
    );
  }
}
