import Button from '@material-ui/core/Button/Button';
import Fade from '@material-ui/core/Fade';
import Grid from '@material-ui/core/Grid/Grid';
import { BaseComponent, IStateBase } from 'components/BaseComponent';
import FieldText from 'components/Field/Text';
import { SvgIcon } from 'components/SvgIcon';
import Translate from 'components/Translate';
import { enPaymentType } from 'enums/enPaymentType';
import { ICouponModel } from 'interfaces/ICouponModel';
import { IProductData } from 'interfaces/IProductData';
import React from 'react';
import { cartService } from 'services/CartService';
import { paymentService } from 'services/PaymentService';
import { WithStyles } from 'shared/decorators/withStyles';

interface IState extends IStateBase {
  cart: IProductData;
  model: ICouponModel;
  openForm: boolean;
  showBtnCoupon: boolean;
  showErrorMessage: boolean;
}

@WithStyles(() => ({
  component: {
    '& .chk-coupon': {
      display: 'flex',
      paddingLeft: 0,
      alignItems: 'flex-end',

      '&.chk-coupon-code-content': {
        alignItems: 'center'
      },

      '& .theme-input': {
        margin: 0
      },

      '& .chk-coupon-code': {
        fontSize: '0.8em'
      },

      '& Button': {
        borderRadius: '0 5px 5px 0',
        boxShadow: 'none',
        minWidth: '20px',
        padding: 0,
        width: '60px',

        '& svg': {
          fill: '#FFF',
          width: '15px'
        }
      },

      '& .chk-close': {
        display: 'flex',
        backgroundColor: '#cecece',
        height: '20px',
        width: '20px',
        margin: '0 0 0 5px',
        borderRadius: '50px',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',

        '&:hover': {
          backgroundColor: '#b3b3b3'
        },

        '& svg': {
          width: '8px'
        }
      }
    },

    '& .chk-coupon-code-label': {
      margin: 0
    },

    '& .chk-button-coupon': {
      cursor: 'pointer',
      fontSize: '0.7em',
      marginTop: '2%',
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      '& span': {
        marginLeft: '5px',
        color: '#3960A3 !important'
      }
    },

    '& .chk-coupon-error span': {
      fontSize: '0.75rem',
      color: '#f44336',
      lineHeight: '1rem',
      display: 'inlineBlock'
    }
  }
}))
export default class Coupon extends BaseComponent<IState> {
  constructor(props: any) {
    super(props);

    this.state = {
      cart: null,
      model: null,
      openForm: false,
      showBtnCoupon: true,
      showErrorMessage: false
    };
  }

  componentDidMount() {
    this.bindModel(paymentService.getCoupon());
    this.observeState({ cart: null }, cartService.getCart());

    cartService
      .getCart()
      .bindComponent(this)
      .subscribe((cart) => {
        if (cart.coupon) {
          this.setState({
            showBtnCoupon: false
          });
        }
      });
  }

  openCouponForm = () => {
    this.setState({
      openForm: true,
      showBtnCoupon: false
    });
  };

  showBtnCoupon = () => {
    this.setState({
      openForm: false,
      showBtnCoupon: true
    });
  };

  showCode = () => {
    this.setState({
      openForm: false,
      showBtnCoupon: false
    });

    setTimeout(() => {
      this.hideErrorMessage();
    }, 3000);
  };

  showErrorMessage = () => {
    this.setState({
      showErrorMessage: true
    });
  };

  hideErrorMessage = () => {
    this.setState({
      showErrorMessage: false
    });
  };

  setCoupon = () => {
    this.hideErrorMessage();

    const code = this.state.model.code;

    if (!code) {
      if (this.state.cart.coupon) {
        this.setState({
          openForm: false
        });

        return;
      }

      this.setState({
        showBtnCoupon: true,
        openForm: false
      });

      return;
    }

    cartService
      .setCoupon(code)
      .loader()
      .bindComponent(this)
      .subscribe(
        () => {
          this.setState({
            openForm: false
          });
        },
        () => {
          this.setState({
            showErrorMessage: true
          });

          setTimeout(() => {
            this.hideErrorMessage();
          }, 2000);
        }
      );
  };

  removeCoupon = () => {
    cartService
      .removeCoupon()
      .loader()
      .bindComponent(this)
      .subscribe(() => {
        this.showBtnCoupon();
      });
  };

  setCode = (code: any) => {
    this.setModel({ code });
  };

  handleKeyPress = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      this.setCoupon();
    }
  };

  render() {
    const { cart, model, openForm, showBtnCoupon, showErrorMessage } = this.state;

    if (!cart || !cart.hasValidCoupons || cart.config.paymentType == enPaymentType.DONATION) {
      return null;
    }

    return (
      <div className={this.props.classes.component}>
        {openForm ? (
          <Fade in={openForm}>
            <div className={'chk-coupon'}>
              <FieldText
                id="code"
                label={<Translate term="Código do cupom" />}
                value={model.code || ''}
                inputProps={{
                  className: 'theme-input field normal',
                  onKeyPress: this.handleKeyPress.bind(this)
                }}
                InputLabelProps={{
                  className: 'theme-label',
                  id: 'label-coupon'
                }}
                onChange={this.setCode}
              />
              <Button id="chk-coupon-button" variant="contained" size="medium" color="primary" className="theme-button" onClick={this.setCoupon}>
                <SvgIcon name="checkIcon" />
              </Button>
            </div>
          </Fade>
        ) : !!cart.coupon && !showBtnCoupon ? (
          <div>
            <h6 className="chk-coupon-code-label">
              <Translate term="Cupom adicionado:" />
            </h6>
            <Grid className={'chk-coupon chk-coupon-code-content'} container direction={'row'} alignItems={'center'}>
              <div className={'chk-coupon-code'}>{cart.coupon.code}</div>
              <div className="chk-close theme-button" aria-label="Close" onClick={this.removeCoupon}>
                <SvgIcon name="closeIcon" />
              </div>
            </Grid>
          </div>
        ) : cart.hasValidCoupons && !cart?.coupon ? (
          <div onClick={this.openCouponForm} className="chk-button-coupon theme-label">
            <SvgIcon name="coupon" />
            <Translate term="Cupom de Desconto?" />
          </div>
        ) : null}
        {showErrorMessage ? (
          <span className={'chk-coupon-error'}>
            <Translate term="Cupom inválido ou inexistente" />
          </span>
        ) : null}
      </div>
    );
  }
}
