import React, { PureComponent } from 'react';

interface IProps {
  data: any;
  onChoose: (data: any) => void;
}

export default class ResultItem extends PureComponent<IProps> {
  onChoose = () => {
    this.props.onChoose(this.props.data);
  };

  render() {
    const { data } = this.props;

    return (
      <div id="chk-tablecep-button" className="chk-tableCEP__address theme-button-outline" onClick={this.onChoose}>
        <div>
          <span className="chk-tableCEP__label">{data.cep}: </span>
          <span>
            {data.logradouro} - {`${data.complemento ? `${data.complemento} -` : ''}`} {data.bairro}
          </span>
        </div>
      </div>
    );
  }
}
