import React, { useMemo } from 'react';
import International from './International/index';
import Br from './Br/index';
import { IUserAddressModel } from 'interfaces/IUserAddressModel';
import { useMappedObservable } from '../../../shared/hooks/useMappedObservable';
import { cartService } from '../../../services/CartService';

export const AddressContext =
  React.createContext<{
    data: IUserAddressModel;
    templateId: number;
    setData: (data: Partial<IUserAddressModel>) => void;
  }>(null);

interface IProps {
  data: IUserAddressModel;
  personType: 'E' | 'F' | 'J';
  onChange: (data: Partial<IUserAddressModel>) => void;
}

const ContextAddress: React.FC<IProps> = ({ data, onChange, personType }) => {
  const contextValue = useMemo(
    () => ({
      data,
      setData: onChange,
      templateId: 18
    }),
    [data, onChange]
  );

  const [allowForeignShipping] = useMappedObservable(
    () => cartService.getConfig(),
    (config) => config.allowForeignShipping,
    []
  );

  const isForeigner = personType === 'E';

  return <AddressContext.Provider value={contextValue}>{isForeigner && allowForeignShipping ? <International /> : <Br />}</AddressContext.Provider>;
};

export default ContextAddress;
