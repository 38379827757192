export const styles = {
  component: {
    display: 'flex',
    alignContent: 'center',
    alignItems: 'center',

    '& span': {
      marginLeft: 10
    },

    '& .progressMessage': {
      color: '#fff',
      letterSpacing: '1px',
      paddingLeft: '15px'
    },

    '& .errorMessage': {
      color: '#fff',
      fontSize: '0.75em'
    }
  }
};
