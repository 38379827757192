import React from 'react';
import ReactDOM from 'react-dom';
import { IScript } from 'interfaces/IScript';
import { BaseComponent } from 'components/BaseComponent';

interface IProps {
  script: IScript;
}

export default class Funnelytics extends BaseComponent<{}, IProps> {
  componentDidMount() {
    const script = document.createElement('script');

    script.innerHTML = `
    (function(funnel) {
      var insert = document.getElementsByTagName("script")[0], script = document.createElement("script");
      script.addEventListener("load", function() {
        window.funnelytics.init(funnel, false);
      });
      script.src = "https://api.funnelytics.io/assets/scripts/track.js";
      script.type = "text/javascript";
      script.async = true;
      insert.parentNode.insertBefore(script, insert);
    })("${this.props.script.value}");`;

    // eslint-disable-next-line react/no-find-dom-node
    ReactDOM.findDOMNode(this).appendChild(script);
  }

  render() {
    return <div />;
  }
}
