import React from 'react';
import { memo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { enLegacyPixelPath, legacyPixelService } from 'services/LegacyPixelService';
import { enPixelPath, pixelService } from 'services/PixelService';
import BankSlipInput from 'shared/components/BankslipInput';
import Button from '@material-ui/core/Button';
import { GOOFY_ID } from 'settings';
import goofyService from 'services/GoofyService';

interface IProps {
  Url: string;
  Code: string;
  ThankYouUrl: string;
}

const useStyles = makeStyles((theme) => ({
  bankslip: {
    alignItems: 'flex-end',
    display: 'flex',
    justifyContent: 'flex-end',
    flex: 1,
    [theme.breakpoints.down('md')]: {
      flexFlow: 'row wrap',
      marginTop: '10px',
      justifyContent: 'flex-end'
    },
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center'
    },
    '& p': {
      color: '#596375',
      fontSize: '14px',
      margin: 0,
      width: '100%'
    }
  },
  inputCode: {
    marginRight: '10px',
    [theme.breakpoints.down('sm')]: {
      marginRight: 0,
      width: '100%'
    }
  },
  buttonBankslip: {
    backgroundColor: '#367BD9',
    color: '#FFF',
    fontSize: '12px',
    fontWeight: 500,
    minWidth: 160,
    padding: '9px 21px',
    [theme.breakpoints.down('sm')]: {
      flex: 1,
      marginTop: '10px'
    },
    '&:hover': {
      backgroundColor: 'rgba(54,123,217, 0.58)'
    }
  }
}));

const BankSlip = memo((props: IProps) => {
  const style = useStyles(props);
  const { Url, Code } = props;
  const isTheLocal = Url.match(/pay\.dlocal\.com/) || Url.match(/live\.adyen\.com/);

  const onDownloadBankslip = () => {
    legacyPixelService.track(enLegacyPixelPath.BANKSLIP);

    pixelService.setPath(enPixelPath.BANKSLIP_VIEW);

    goofyService.track({
      flow: 'sale',
      trackerId: GOOFY_ID,
      step: 'bankslip_viewed',
      data: {}
    });

    window.top.location.href = Url;
  };

  const onCopyCod = () => {
    legacyPixelService.track(enLegacyPixelPath.BANKSLIP);

    pixelService.setPath(enPixelPath.BANKSLIP_VIEW);
  };

  return (
    <div className={style.bankslip}>
      {!isTheLocal && (
        <div className={style.inputCode}>
          <BankSlipInput onClick={onCopyCod} bankslipCode={Code} />
        </div>
      )}
      <Button className={style.buttonBankslip} onClick={onDownloadBankslip} variant="text" disableFocusRipple>
        Baixar Boleto
      </Button>
    </div>
  );
});

export default BankSlip;
