/* eslint-disable camelcase */
import { apiService } from 'shared/services';
import * as Sentry from '@sentry/browser';
import { PREVIEW_MODE } from 'settings';

interface ILoadTime {
  project: string;
  production: boolean;
  page: string;
  contentId: number;
  url: string;
  startTime: number;
  data: { [key: string]: any };
}

export class LoadTimeService {
  public track(contentId: string, cached: boolean, producerId: number) {
    if (!window.START_TIME || window.DEVICE === 'other' || PREVIEW_MODE) {
      return;
    }

    const cid = Number(contentId) || null;

    const devices = {
      desktop: 'Desktop',
      cellphone: 'Celular'
    };

    let title = devices[window.DEVICE] || 'Outros';

    title += cached ? ' Cache' : '';

    const data: ILoadTime = {
      project: 'sun',
      production: !window.IS_DEVELOPMENT,
      page: title,
      contentId: cid,
      url: window.location.href,
      startTime: window.START_TIME,
      data: {
        goofy_id: window.GOOFY_ID,
        cached,
        producerId
      }
    };

    apiService
      .post('/loadTime', data, null, {
        local: true
      })
      .subscribe(() => {
        Sentry.addBreadcrumb({
          message: 'Load Time Sent',
          category: 'loadTime',
          level: Sentry.Severity.Info,
          data: null
        });
      });
  }
}

// tslint:disable-next-line:variable-name
export const LoadTimeServiceFactory = () => new LoadTimeService();

export const loadTimeService = LoadTimeServiceFactory();

export default loadTimeService;
