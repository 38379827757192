import React from 'react';
import { IUser } from 'interfaces/IProductData';
import { Grid } from '@material-ui/core';
import SaleRecoveryUserData from '../SaleRecoveryUserData';

interface IProps {
  student: IUser;
  payer: IUser;
}

const SaleRecoveryStudentAndPayerData: React.FC<IProps> = React.memo(({ student, payer }: IProps) => {
  const samePerson = payer.id === student?.id;

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <SaleRecoveryUserData userData={student} subtitle="Dados do Aluno" noMarginBottom showDivider={samePerson} />
        {!samePerson && <SaleRecoveryUserData userData={payer} subtitle="Dados do Pagador" showTitle={false} />}
      </Grid>
    </Grid>
  );
});

export default SaleRecoveryStudentAndPayerData;
