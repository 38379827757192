import validator from 'validatorjs';

const validate = (validity: string): boolean => {
  if (!validity.match(/\d{2}\/\d{2}/)) {
    return false;
  }

  const [month, year] = validity.split('/').map((v) => Number(v));

  if (month <= 0 || month > 12) {
    return false;
  }

  const fullYear = year + 2000;
  const date = new Date(fullYear, month);

  return date >= new Date();
};

validator.register('card_validity', validate, 'invalid_validity');
