import React from 'react';
import { BaseComponent, IStateBase } from 'components/BaseComponent';
import Modal from '@material-ui/core/Modal';
import { WithStyles } from 'shared/decorators/withStyles';
import { Sidebar } from 'components/Cart/Sidebar';
import { IProductData, IProductConfig } from 'interfaces/IProductData';
import { ICartModel } from 'interfaces/ICartModel';
import { cartService } from 'services/CartService';
import { paymentService } from 'services/PaymentService';
import Translate from 'components/Translate';
import { Hidden } from '@material-ui/core';
import { enProductType } from 'enums/enProductType';
import { parameterService } from 'services/ParameterService';
import { enParameters } from 'enums/enParameters';

interface IState extends IStateBase {
  open: boolean;
  cart: IProductData;
  config: IProductConfig;
  model: ICartModel;
  changeCard: boolean;
}

interface IProps {
  classes?: any;
}

@WithStyles(() => ({
  component: {},
  '@global': {
    '.modal-open': {
      overflowY: 'hidden',
      position: 'fixed'
    },
    '.details-product-button': {
      cursor: 'pointer',
      textDecoration: 'underline'
    },
    '.modal-details': {
      alignItems: 'center',
      minHeight: '-webkit-fill-available',
      height: '100vh',
      display: 'flex',
      flexDirection: 'column',
      margin: '10px auto',
      paddingBottom: '5px',
      overflow: 'auto',
      width: '100vw',
      zIndex: 5560,
      '& .modalHeader': {
        backgroundColor: '#FFF',
        display: 'flex',
        height: 'auto',
        alignContent: 'center',
        justifySelf: 'center',
        padding: '0 10px 0',
        flexDirection: 'column',
        position: 'absolute',
        width: '100%',

        '& #modal-logo-content': {
          height: '70px',
          width: '70px'
        }
      },

      '& .product-name': {
        display: 'block',
        margin: 0,
        textAlign: 'center',
        wordWrap: 'break-word',
        width: '100%',
        fontSize: '1rem',
        fontWeight: 600
      },
      '& .modalTitle': {
        '& h2': {
          fontSize: '1.125rem'
        }
      }
    }
  },
  changeCard: {
    textTransform: 'uppercase',
    margin: 0
  }
}))
export default class ShowDetails extends BaseComponent<IState, IProps> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      open: false,
      cart: null,
      config: null,
      model: null,
      changeCard: false
    };
  }

  handleOpen = () => {
    this.setState({ open: true });
    const el = document.getElementsByTagName('body');
    if (el[0]) {
      el[0].className = 'modal-open';
    }
  };

  handleClose = () => {
    this.setState({ open: false });
    const el = document.getElementsByTagName('body');
    if (el[0]) {
      el[0].classList.remove('modal-open');
    }
  };

  componentDidMount = () => {
    this.observeState({ cart: null }, cartService.getCart());
    this.observeState({ config: null }, cartService.getConfig());
    this.bindModel(paymentService.getCart());
    parameterService.get(enParameters.changeCard).subscribe((changeCard) => this.setState({ changeCard: Boolean(changeCard) }));

    cartService
      .getCart()
      .take(1)
      .subscribe((cart) => this.setState({ cart }));
  };

  render() {
    if (!this.state.cart) {
      return '';
    }

    const { open, cart } = this.state;
    const { classes } = this.props;

    return (
      <div className={classes.component}>
        <Hidden smUp>
          {cart.config.productType !== enProductType.TICKET && (
            <div>
              <a role="button" className="theme-label details-product-button" onClick={this.handleOpen}>
                <Translate term="Detalhes do produto" />
              </a>
            </div>
          )}
        </Hidden>
        <Modal
          aria-labelledby="Detalhes do Produto"
          aria-describedby="Detalhes do Produto"
          open={open}
          onClose={this.handleClose}
          className="modal-details"
        >
          <>
            <Sidebar cart={cart} handleClose={this.handleClose} />
          </>
        </Modal>
      </div>
    );
  }
}
