import { Observable } from 'rxjs/Observable';
import { TRANSLATIONS, STANDALONE_MODE } from 'settings';
import { apiService } from 'shared/services';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { ITranslation, IErrorData } from 'interfaces/ITranslation';
import { enLang } from 'interfaces/IProductData';
import { ReplaySubject } from 'rxjs/ReplaySubject';

export class TranslateService {
  private lang$ = new BehaviorSubject<enLang>(enLang.PT_BR);
  private translations$ = new ReplaySubject<ITranslation>(1);

  public load() {
    if (TRANSLATIONS) {
      this.translations$.next(TRANSLATIONS as any);
      return;
    }

    const url = STANDALONE_MODE ? 'https://sun.eduzz.com/translations' : '/translations';

    apiService
      .get(url, null, null, {
        local: true
      })
      .map((translations) => {
        this.translations$.next(translations);
      })
      .subscribe();
  }

  public setLang(lang: enLang) {
    this.lang$.next(lang);
  }

  public getTranslations() {
    return this.translations$.asObservable();
  }
  public getLang() {
    return this.lang$.value;
  }

  public getTextStream(key: string): Observable<string> {
    return Observable.combineLatest(this.translations$.asObservable(), this.lang$.asObservable())
      .map(([translations, lang]) => {
        const index = `${translations.messages.indexOf(key)}`;

        if (translations && translations.texts && translations.texts[lang] && translations.texts[lang][index]) {
          const textIndex = translations.texts[lang][index];
          return translations.messages[textIndex];
        }

        return key;
      })
      .distinctUntilChanged();
  }

  public getErrorData(code: string): Observable<IErrorData> {
    return this.translations$.take(1).map((translations) => {
      const lang = this.lang$.value;

      let translationCode = code;
      let forceReport = false;

      if (!code || !translations?.errors || !translations.errors[lang] || !translations.errors[lang][code]) {
        translationCode = 'DEF_B';

        forceReport = true;
      }

      const [messageIndex, titleIndex, shouldReport] = translations.errors[lang][translationCode];

      const title = translations.messages[titleIndex] ?? '';
      const message = translations.messages[messageIndex] ?? 'Não foi possível concluir sua compra no momento.';

      return {
        code,
        title,
        message,
        shouldReport: forceReport || !!shouldReport
      };
    });
  }
}

// tslint:disable-next-line:variable-name
export const TranslateServiceFactory = () => new TranslateService();

export const translateService = TranslateServiceFactory();
