import React from 'react';
import { IProductData } from 'interfaces/IProductData';
import Br from './Br';
import International from './International';
import { IStateBase, BaseComponent } from 'components/BaseComponent';
import { currencyService } from 'services/CurrencyService';
import { enCurrency } from 'enums/enCurrency';
import { isInternationalCurrency } from 'shared/utils/isInternationalCurrency';

interface IPriceProps {
  cart: IProductData;
}

interface IState extends IStateBase {
  currency?: enCurrency;
}

export default class WithoutInstallment extends BaseComponent<IState, IPriceProps> {
  componentDidMount() {
    this.observeState({ currency: null }, currencyService.getCurrency());
  }

  render() {
    if (!this.state) {
      return null;
    }

    const { currency } = this.state;

    return !isInternationalCurrency(currency) ? <Br cart={this.props.cart} /> : <International cart={this.props.cart} />;
  }
}
