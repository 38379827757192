/* eslint-disable max-lines */

import { Observable } from 'rxjs/Observable';
import { cartService } from 'services/CartService';
import { getCdnImage } from 'functions';
import { parameterService } from './ParameterService';
import { enParameters } from 'enums/enParameters';
import get from 'lodash/get';
import { configService } from './ConfigService';

export class ThemeService {
  private styleElement: HTMLStyleElement;
  private postMessage$ = Observable.fromEvent(window, 'message')
    .filter((event: MessageEvent) => {
      return typeof event.data === 'string' && event.data.substring(0, 9) === 'template:';
    })
    .map((event: MessageEvent) => JSON.parse(event.data.substring(9)));

  constructor() {
    this.styleElement = document.createElement('style');
    this.styleElement.id = 'theme-styles';
    document.body.appendChild(this.styleElement);

    this.setDefaultTheme();
    this.listenPostMessage();
  }

  setDefaultTheme() {
    cartService
      .getCart()
      .combineLatest(parameterService.get(enParameters.config).take(1))
      .map(([cart, configParameter]) => {
        if (configParameter) {
          try {
            const config = JSON.parse(atob(configParameter));
            return config;
          } catch (error) {}
        }

        const config = cart.config.template.styles;

        if (!config) {
          return config;
        }

        const defaultLanguageBar = {
          bgColor: 'rgba(255, 255, 255, 1)',
          font: {
            color: '192542'
          }
        };
        const defaultToastMessages = {
          bgColor: 'FFFFFF',
          font: {
            color: '596375'
          }
        };

        const defaultExitPopUpButton = {
          backgroundColor: '222222',
          backgroundColorHover: '333333',
          text: 'Recebis sus discontis agoris',
          textColor: 'EDEDED',
          textSize: 20
        };

        const defaultExitPopUpTimer = {
          backgroundColor: '333333',
          title: 'Oferta Imperdível',
          titleColor: 'FFFFFF',
          titleSize: 14
        };

        const newConfig = {
          ...config,
          languageBar: cart.config.template.styles
            ? cart.config.template.styles.languageBar
              ? cart.config.template.styles.languageBar
              : defaultLanguageBar
            : defaultLanguageBar,
          toastMessages: cart.config.template.styles
            ? cart.config.template.styles.toastMessages
              ? cart.config.template.styles.toastMessages
              : defaultToastMessages
            : defaultToastMessages,
          exitPopUpButton: cart.config.exitModal ? cart.config.exitModal.button.theme : defaultExitPopUpButton,
          exitPopUpTimer: cart.config.exitModal ? cart.config.exitModal.timer.theme : defaultExitPopUpTimer
        };

        return newConfig;
      })
      .distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b))
      .subscribe((styles) => {
        this.updateStyles(styles);
      });
  }

  listenPostMessage() {
    this.postMessage$
      .combineLatest(cartService.getConfig().take(1))
      .map(([postMessage, config]) => {
        (config as any).template.styles = postMessage;
        (config as any).template.media = {
          bannerCenter: postMessage.bannerCenter,
          bannerRight: postMessage.bannerRight,
          bannerRightUrl: postMessage.bannerRightUrl ?? null,
          bannerTop: postMessage.bannerTop,
          bannerTopUrl: postMessage.bannerTopUrl ?? null,
          bannerYoutube: postMessage.bannerYoutube,
          exitPopUpButton: get(postMessage, 'exitPopUp.button.theme', '')
        };

        (config as any).template.styles = {
          ...config.template.styles,
          background: {
            ...postMessage.background,
            cdnImage: postMessage.background.image
          }
        };

        (config as any).template.styles = {
          ...config.template.styles,
          background: {
            ...postMessage.background,
            cdnImage: postMessage.background.image
          }
        };

        (config as any).template.shortageTimer = postMessage.shortageTimer;

        return config;
      })
      .subscribe((data: any) => {
        configService.setReplacerConfig('template', data.template);
        this.updateStyles(data.template.styles);
      });
  }

  updateStyles(config: any) {
    if (window.TEMPLATE_CONFIG) {
      config = window.TEMPLATE_CONFIG;
    }

    if (!config || Object.keys(config).length === 0) {
      return;
    }

    const themeBackgroundImage = get(config, 'background.cdnImage', null)
      ? config.background.cdnImage.includes('data:')
        ? config.background.cdnImage
        : getCdnImage(get(config, 'background.cdnImage', null))
      : getCdnImage(get(config, 'background.image', null));

    const themeBackgroundImageWidget = get(config, 'bannerCenter', null)
      ? config.bannerCenter.includes('data:')
        ? config.bannerCenter
        : getCdnImage(get(config, 'bannerCenter', null))
      : getCdnImage(get(config, 'bannerCenter', null));

    this.styleElement.innerHTML = `
      .theme-label {
        color: #${get(config, 'labels.font.color', '')} !important;
      }

      .theme-label label, .theme-label a {
        color: #${get(config, 'labels.font.color', '')} !important;
      }

      .theme-background-color {
        background-color: #${get(config, 'background.color', '')} !important;
      }

      .theme-background-image {
        background-image: url(${themeBackgroundImage}) !important;
      }

      .theme-header-bg {
        background-color: #${get(config, 'header.bgcolor', '')} !important;
      }

      .theme-header-bg-image {
        background-image: url(${themeBackgroundImageWidget || ''}) !important;
      }

      .theme-header {
        color: #${get(config, 'header.font.color', '')} !important;
      }

      .theme-icon, .theme-icon svg {
        fill: #${get(config, 'buttons.bgcolor', '')} !important;
      }

      .theme-button {
        background-color: #${get(config, 'buttons.bgcolor', '')} !important;
        color: #${get(config, 'buttons.font.color', '')} !important;
      }

      .theme-button svg {
        stroke: #${get(config, 'buttons.font.color', '')} !important;
        fill: #${get(config, 'buttons.font.color', '')} !important;
      }

      .theme-button-color svg{
        fill: #${get(config, 'buttons.font.color', '')} !important;
      }

      .theme-backStep-button{
        color: #${get(config, 'buttons.bgcolor', '')} !important;
      }

      .theme-backStep-button svg{
        fill: #${get(config, 'buttons.bgcolor', '')} !important;
      }

      .theme-button-outline{
        border-color:#${get(config, 'buttons.bgcolor', '')} !important;
        color: #${get(config, 'buttons.bgcolor', '')} !important;
      }

      .theme-button:hover {
        background-color: #${get(config, 'buttons.bgcolorhover', '')} !important;
      }

      .theme-input {
        background-color: #${get(config, 'inputs.bgcolor', '')} !important;
        color: #${get(config, 'inputs.font.color', '')} !important;
        border-color: #${get(config, 'inputs.border.color', '')} !important;
        border-width: ${get(config, 'inputs.border.size', '')}px !important;
        border-style: ${get(config, 'inputs.border.style', '')} !important;
      }

      .theme-input::-webkit-outer-spin-button,
      .theme-input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      input[type=number] {
        -moz-appearance: textfield;
      }

      .theme-input-select {
        background-color: #${get(config, 'inputs.bgcolor', '')} !important;
        color: #${get(config, 'inputs.font.color', '')} !important;
      }

      input:disabled {
        color: currentColor !important;
        cursor: default;
        background-color: #d9d9d9 !important;
      }

      .toastIcon {
        background-color: transparent !important;
      }

      .field-select {
        background-color: #${get(config, 'inputs.bgcolor', '')} !important;
        color: #${get(config, 'inputs.font.color', '')} !important;
        border-color: #${get(config, 'inputs.border.color', '')} !important;
      }

      .theme-orderbump {
        border-color: #${get(config, 'buttons.bgcolor', '')} !important;
      }

      .theme-orderbump-selector {
        background-color: #${get(config, 'buttons.bgcolor', '')} !important;
      }

      .theme-orderbump-highlight, .theme-orderbump-highlight span {
        color: #${get(config, 'buttons.bgcolor', '')} !important;
      }

      ${
        config.shortageTimer &&
        `.scarcity-timer {
          background-color: ${config.shortageTimer.bgColor} !important;
        }

        .scarcity-timer .time, .scarcity-timer .timerDescription {
          color: ${config.shortageTimer.font.color} !important;
        }

        .scarcity-timer .timerDescription svg {
          fill: ${config.shortageTimer.font.color} !important;
        }
        `
      }

      ${
        config.languageBar &&
        `.theme-language-bar {
          background-color: ${config.languageBar.bgColor} !important;
          color: #${config.languageBar.font.color} !important;
          opacity: ${config.languageBar.opacity} !important;
        }

        .theme-language-bar svg {
          fill: #${config.languageBar.font.color} !important;
        }

        .theme-language-bar-selected {
          color: #${config.languageBar.font.color} !important;
        }`
      }

      ${
        config.exitPopUp &&
        `.theme-exit-pop-up-button {
            background-color: #${config.exitPopUp.button.theme.backgroundColor} !important;
            color: #${config.exitPopUp.button.theme.textColor} !important;
            font-size: ${config.exitPopUp.button.theme.textSize}px !important;
          }

          .theme-exit-pop-up-button:hover {
            background-color: #${config.exitPopUp.button.theme.backgroundColorHover} !important;
          }
          .theme-exit-pop-up-header {
            background-color: #${config.exitPopUp.timer.theme.backgroundColor} !important;
          }
          .theme-exit-pop-up-title {
            color: #${config.exitPopUp.timer.theme.titleColor} !important;
            font-size: ${config.exitPopUp.timer.theme.titleSize}px !important;
          }
          .theme-exit-pop-up-timer {
            color: #${config.exitPopUp.timer.theme.titleColor} !important;
          }

      `
      }

      ${
        config.exitPopUpButton &&
        `.theme-exit-pop-up-button {
              background-color: #${config.exitPopUpButton.backgroundColor} !important;
              color: #${config.exitPopUpButton.textColor} !important;
              font-size: ${config.exitPopUpButton.textSize}px !important;
            }

            .theme-exit-pop-up-button:hover {
              background-color: #${config.exitPopUpButton.backgroundColorHover} !important;
            }`
      }

      ${
        config.exitPopUpTimer &&
        `.theme-exit-pop-up-header {
            background-color: #${config.exitPopUpTimer.backgroundColor} !important;
            display: flex;
            flex-direction: column;
          }
          .theme-exit-pop-up-title {
            color: #${config.exitPopUpTimer.titleColor} !important;
            font-size: #${config.exitPopUpTimer.titleSize} !important;
          }
          .theme-exit-pop-up-timer {
            color: #${config.exitPopUpTimer.titleColor} !important;
            width: 100%;
            text-align: center;
          }
          `
      }

      ${
        config.toastMessages &&
        `.toastContainer {
          background-color: #${config.toastMessages.bgColor} !important;
        }

        .toastContainer .toastContent {
          color: #${config.toastMessages.font.color} !important;
        }

        .toastContainer .toastIcon svg {
          fill: #${config.toastMessages.font.color} !important;
          stroke: #${config.toastMessages.font.color} !important;
        }

        .toastClose svg {
          fill: #${config.toastMessages.font.color} !important;
        }

      `
      }

      .disabledField {
        color: currentColor !important;
        background-color: #d9d9d9 !important;
      };


      `;
  }
}

export const themeService = new ThemeService();
