import * as queryString from 'query-string';

export class PostBackService {
  static createForm(params: any, url: string, method: string) {
    const paramsArray: any = Object.keys(params).map((key) => {
      return {
        key,
        value: params[key]
      };
    });

    return `<form id="post-get-id" action=${url} method=${method} target="post-params" hidden>
        ${paramsArray.map((input: any) => `<input type='text' name=${input.key} value=${input.value} />`).join('')}
        </form>
    `;
  }

  postParams(params: any, url: string, key: string) {
    if (!`${url}`.match(/https?:\/\//)) {
      return;
    }

    if (params.paramPost && !Array.isArray(params.paramPost)) {
      params.paramPost.chave = key;
      const formHTML = PostBackService.createForm(params.paramPost, url, 'post');
      document.querySelector('#post-get').innerHTML = formHTML;
      const form: any = document.querySelector('#post-get-id');
      form.submit();
    }
  }

  getParams(params: any, url: string, key: string) {
    if (!`${url}`.match(/https?:\/\//)) {
      return '';
    }

    if (params.paramGet && !Array.isArray(params.paramGet)) {
      let validParams = {};

      params.paramGet.chave = key;

      Object.keys(params.paramGet).forEach((prop) => {
        if (params.paramGet[prop]) {
          validParams[prop] = params.paramGet[prop];
        }
      });

      return `${url}?${queryString.stringify(validParams)}`;
    }

    return url;
  }
}
// tslint:disable-next-line:variable-name
export const PostBackServiceFactory = () => new PostBackService();

export const postBackService = PostBackServiceFactory();
