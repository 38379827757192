import Checkbox from '@material-ui/core/Checkbox/Checkbox';
import Translate from 'components/Translate';
import React, { ChangeEvent, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel/FormControlLabel';
import { makeStyles } from '@material-ui/styles';
import { cartService } from 'services/CartService';
import { useObservable } from 'react-use-observable';
import { parameterService } from 'services/ParameterService';
import { enParameters } from 'enums/enParameters';
import goofyService from 'services/GoofyService';
import { GOOFY_ID } from 'settings';

const useStyles = makeStyles({
  container: {
    marginTop: '.875rem'
  },
  errorLabel: {
    marginTop: 0,
    marginBottom: 0,
    color: '#f44336',
    fontSize: '0.75rem',
    fontFamily: 'Lato, Helvetica, Arial, sans-serif',
    fontWeight: 400,
    paddingLeft: '1.42rem'
  },
  wrapperSubscriptionTerms: {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.25rem',
    alignItems: 'center',
    '& a': {
      color: '#3960A3'
    }
  }
});
const AcceptTerms: React.FC = React.memo(() => {
  const [isChecked] = useObservable(() => cartService.getAcceptedSubscriptionTerm(), []);
  const [isUpsell] = useObservable(() => parameterService.get(enParameters.u), []);
  const classes = useStyles({});

  const handleChangeSubscriptionTerm = (_: ChangeEvent<HTMLInputElement>, isChecked: boolean) => {
    cartService.setAcceptedSubscriptionTerm(isChecked);

    goofyService.track({
      flow: 'sale',
      trackerId: GOOFY_ID,
      step: 'checked_subscription_term',
      data: { acceptedSubscriptionTerm: isChecked }
    });
  };

  useEffect(() => {
    if (isUpsell) {
      cartService.setAcceptedSubscriptionTerm(true);

      goofyService.track({
        flow: 'sale',
        trackerId: GOOFY_ID,
        step: 'checked_subscription_term',
        data: { acceptedSubscriptionTerm: true },
        subtract: 5000
      });
    }
  }, [isUpsell]);

  return (
    <Grid className={`chk-creditCardForm__saveCard checkbox_singleStep ${classes.container}`} item xs={12}>
      <FormControlLabel
        control={<Checkbox id="checkbox-subscription-terms" className="checkbox" checked={isChecked} onChange={handleChangeSubscriptionTerm} />}
        label={
          <div className={classes.wrapperSubscriptionTerms}>
            <Translate
              hasHTML
              // eslint-disable-next-line max-len
              term="Concordo que li e aceito os <a id='chk-accept-term' rel='noopener noreferrer' target='_blank' href='https://www.eduzz.com/termos-e-assinatura-e-cancelamento'>TERMOS DE ASSINATURA E CANCELAMENTO</a>"
            />
          </div>
        }
      />
      {!isChecked && (
        <p className={classes.errorLabel} id="chk-accept-term-error-label">
          <Translate term="Este campo é obrigatório" />
        </p>
      )}
    </Grid>
  );
});

export default AcceptTerms;
