import { BaseComponent, IStateBase } from 'components/BaseComponent';
import { IProductData } from 'interfaces/IProductData';
import React from 'react';
import { cartService } from 'services/CartService';
import { paymentService } from 'services/PaymentService';
import Translate from 'components/Translate';
import { enPaymentType } from 'enums/enPaymentType';
import FieldText from 'components/Field/Text';

interface IState extends IStateBase {
  cart: IProductData;
}

export default class OpenValue extends BaseComponent<IState, any> {
  componentDidMount() {
    this.observeState({ cart: null }, cartService.getCart());
  }

  setValueOnCart = (value: any) => {
    const newValue = { value: Number(value.replace(/\D+/gi, '')) / 100 };
    const price = { price: Number(value.replace(/\D+/gi, '')) / 100 };

    cartService.setCart(this.state.cart, price);
    paymentService.setPaymentData(0, newValue);
  };

  render() {
    if (!this.state) {
      return '';
    }
    const { cart } = this.state;

    return (
      <div>
        {cart.config.paymentType == enPaymentType.DONATION && (
          <div className="donation-input">
            <FieldText
              id={'donation-value'}
              label={<Translate term="Valor" />}
              validation="required|min:5"
              value={cart.price}
              mask={'money'}
              inputProps={{
                pattern: '[0-9]*',
                inputMode: 'numeric',
                className: 'theme-input'
              }}
              InputLabelProps={{
                className: 'theme-label'
              }}
              onChange={this.setValueOnCart}
            />
          </div>
        )}
      </div>
    );
  }
}
