import React from 'react';
import ReactDOM from 'react-dom';
import { IScript } from 'interfaces/IScript';
import { BaseComponent } from 'components/BaseComponent';

interface IProps {
  script: IScript;
}

export default class Tawk extends BaseComponent<{}, IProps> {
  componentDidMount() {
    const script = document.createElement('script');
    let key = this.props.script.value;

    const temp = key.split('/');
    if (temp.length === 1) {
      key += '/default';
    }

    if (String(key).indexOf('tawk.to') >= 0) {
      key = `${temp[temp.length - 2]}/${temp[temp.length - 1]}`;
    }

    script.innerHTML = `
        var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
        (function(){
        var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
        s1.async=true;
        s1.src='https://embed.tawk.to/${key}';
        s1.charset='UTF-8';
        s1.setAttribute('crossorigin','*');
        s0.parentNode.insertBefore(s1,s0);
    })();`;

    // eslint-disable-next-line react/no-find-dom-node
    ReactDOM.findDOMNode(this).appendChild(script);

    const tout = window.setInterval(() => {
      const el = document.getElementById('tawkchat-container');
      if (!el) {
        return;
      }
      const style = el.getAttribute('style');
      el.setAttribute('style', style.replace('z-index: 999999999', 'z-index: 5555'));
      window.clearInterval(tout);
    }, 50);
  }

  render() {
    return <div />;
  }
}
