import React from 'react';
import { BaseComponent, IStateBase } from 'components/BaseComponent';
import Button from '@material-ui/core/Button/Button';
import { GOOFY_ID } from 'settings';
import Translate from 'components/Translate';
import CircularProgress from '@material-ui/core/CircularProgress';
import { SvgIcon } from 'components/SvgIcon';
import { legacyPixelService, enLegacyPixelPath } from 'services/LegacyPixelService';
import { enPixelPath, pixelService } from 'services/PixelService';
import { goofyService } from 'services/GoofyService';

interface IState extends IStateBase {
  downloading: boolean;
}

interface IProps {
  bankslipUrl: string;
  thankyouUrl?: string;
  affiliateId: number;
}

export default class Download extends BaseComponent<IState, IProps> {
  constructor(props: any) {
    super(props);
    this.state = { downloading: false };
  }

  handleClick = () => {
    /*
      legacyPixelService: Should be removed in not too distance future
    */
    legacyPixelService.track(enLegacyPixelPath.BANKSLIP);

    pixelService.setPath(enPixelPath.BANKSLIP_VIEW);

    this.setState({ downloading: true });
    setTimeout(() => this.setState({ downloading: false }), 4000);

    goofyService.track({
      flow: 'sale',
      trackerId: GOOFY_ID,
      step: 'bankslip_viewed',
      data: {}
    });

    this.handleRedirect();

    if (this.props.bankslipUrl.match(/pay\.dlocal\.com/)) {
      window.location.href = this.props.bankslipUrl;
      return;
    }

    if (this.props.bankslipUrl.match(/live\.adyen\.com/)) {
      window.location.href = this.props.bankslipUrl;
      return;
    }

    if (this.props.bankslipUrl.startsWith('https://boleto.eduzz.com')) {
      window.location.href = this.props.bankslipUrl;
      return;
    }

    window.location.href = `${this.props.bankslipUrl}`;
  };

  handleRedirect = () => {
    if (!!this.props.thankyouUrl) {
      setTimeout(() => (window.location.href = this.props.thankyouUrl), 6000);
    }
  };

  render() {
    return (
      <div className={'bankslip-download'}>
        {!this.state.downloading ? (
          <Button onClick={this.handleClick}>
            <SvgIcon name="download" />
            <Translate term={'BAIXAR BOLETO'} />
          </Button>
        ) : (
          <Button disabled>
            <CircularProgress size={18} style={{ color: '#fff' }} />
          </Button>
        )}
      </div>
    );
  }
}
