import { Observable } from 'rxjs/Observable';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { IScript } from 'interfaces/IScript';

export class ScriptsService {
  private scripts$ = new BehaviorSubject<IScript[]>([]);

  public setScripts(scripts: IScript[]) {
    this.scripts$.next(scripts);
  }

  public getScripts(): Observable<IScript[]> {
    return this.scripts$.asObservable().distinctUntilChanged();
  }
}

// tslint:disable-next-line:variable-name
export const ScriptsServiceFactory = () => new ScriptsService();

export const scriptsService = ScriptsServiceFactory();
