import React, { useCallback, useEffect } from 'react';

import { Options } from './data';
import Method from './Method';
import { darken } from 'polished';
import { makeStyles } from '@material-ui/core/styles';
import { useMappedObservable, useObservable } from 'react-use-observable';
import { combineLatest } from 'rxjs/observable/combineLatest';
import { paymentService } from 'services/PaymentService';
import { cartService } from 'services/CartService';
import { enPaymentMethod } from 'enums/enPaymentMethod';
import { configService } from 'services/ConfigService';
import { parameterService } from 'services/ParameterService';
import { enParameters } from 'enums/enParameters';
import Translate from 'components/Translate';

interface IProps {
  index: number;
}

const useStyles = makeStyles({
  component: {
    display: 'flex',
    overflowX: 'scroll',
    whiteSpace: 'nowrap',
    scrollbarColor: `${darken(0.05, '#fafafa')} rgba(0, 0, 0, 0%) !important`,
    scrollbarWidth: 'thin',
    '&.marginButtom': {
      marginBottom: 10
    },

    '&::-webkit-scrollbar': {
      width: 10,
      height: 18
    },

    '&::-webkit-scrollbar-thumb': {
      height: 6,
      border: '6px solid transparent',
      backgroundClip: 'padding-box',
      backgroundColor: `${darken(0.05, '#fafafa')}`,
      opacity: 0.8,
      borderRadius: 8,
      transition: '0.3s',
      boxShadow: 'inset -1px -1px 0px rgba(250, 250, 250, 0.5), inset 1px 1px 0px rgba(250, 250, 250, 0.5)'
    },

    '&::-webkit-scrollbar-thumb:hover': {
      backgroundColor: `${darken(0.2, '#fafafa')}`
    },

    '&::-webkit-scrollbar-button': {
      width: 0,
      height: 0,
      display: 'none'
    },

    '&::-webkit-scrollbar-corner': {
      backgroundColor: 'transparent'
    }
  }
});

const PaymentMethod: React.FC<IProps> = ({ index }) => {
  const classes = useStyles({});
  const [paymentState] = useMappedObservable(
    () => combineLatest(paymentService.getPayment(index), cartService.getCart(), parameterService.get(enParameters.terminalId)),
    ([{ paymentMethod }, { config }, terminalId]) => {
      if (!paymentMethod) {
        paymentService.setMethodPaymentDefault(config, terminalId);
      }
      return { paymentMethod };
    },
    []
  );

  const [allowPayments] = useObservable(() => paymentService.setAllowPayment(), []);
  const [changeCard] = useObservable(() => parameterService.get(enParameters.changeCard), []);
  const [config] = useObservable(() => configService.getConfig(), []);

  const onSelectMethod = useCallback(
    (paymentMethod: enPaymentMethod) => {
      paymentService.setPaymentData(index, { paymentMethod });
    },
    [index]
  );

  useEffect(() => {
    if (!allowPayments || !paymentState?.paymentMethod) {
      return;
    }

    if (!allowPayments.includes(paymentState?.paymentMethod)) {
      onSelectMethod(enPaymentMethod.CREDIT_CARD);
    }
  }, [onSelectMethod, allowPayments, paymentState?.paymentMethod]);

  if (!paymentState || !allowPayments || !config) {
    return null;
  }

  const { paymentMethod } = paymentState;
  const { id: templateId } = config.template;

  if (changeCard || allowPayments.length === 1) {
    return null;
  }

  return (
    <>
      <span className="chk-label method-label theme-label">
        <Translate term="Selecione um método de pagamento" />
      </span>
      <div className={`${classes.component} ${Number(templateId) !== 18 ? 'marginButtom' : ''}`}>
        {Options.map(({ id, title, method, src }) => {
          if (!allowPayments.includes(method)) {
            return null;
          }

          return (
            <Method
              id={id}
              onClick={() => onSelectMethod(method)}
              key={id}
              title={title}
              active={method === paymentMethod}
              value={method}
              src={src}
            />
          );
        })}
      </div>
    </>
  );
};

export default React.memo(PaymentMethod);
