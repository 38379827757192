import React from 'react';
import Toast from 'toast-eduzz';
import 'toast-eduzz/dist/toast-eduzz.min.css';
import { cartService } from 'services/CartService';
import { BaseComponent, IStateBase } from 'components/BaseComponent';
import { IProductData, enLang } from 'interfaces/IProductData';
import { internationalService } from 'services/InternationalService';

interface IState extends IStateBase {
  cart: IProductData;
}

export default class ToastProducer extends BaseComponent<IState> {
  componentDidMount() {
    internationalService
      .getSelectedCountry()
      .debounceTime(5000)
      .filter((country) => country.lang === enLang.PT_BR)
      .switchMap(() => cartService.getCart().take(1))
      .first()
      .bindComponent(this)
      .subscribe((cart) => this.setState({ cart }));
  }

  render() {
    if (!this.state) {
      return null;
    }

    const { cart } = this.state;

    return (
      <div>
        <div />
        {Toast.init(cart.products[0].id)}
      </div>
    );
  }
}
