import React from 'react';
import AB from 'shared/components/AB';
import { enPaymentMethod } from 'enums/enPaymentMethod';

interface IOptions {
  id: string;
  method: enPaymentMethod;
  title: string | React.ReactNode;
  src: string;
}

export const Options: IOptions[] = [
  {
    id: 'card-payment-method',
    method: enPaymentMethod.CREDIT_CARD,
    title: `Cartão \nde Crédito`,
    src: 'method_card'
  },
  {
    id: 'terminal-payment-method',
    method: enPaymentMethod.TERMINAL,
    title: 'Maquininha \nde pagamento',
    src: 'method_terminal'
  },
  {
    id: 'pix-payment-method',
    method: enPaymentMethod.PIX,
    title: 'Pix',
    src: 'method_pix'
  },
  {
    id: 'bankslip-payment-method',
    method: enPaymentMethod.BANKSLIP,
    title: 'Boleto',
    src: 'method_bankslip'
  },
  {
    id: 'pagdividido-payment-method',
    method: enPaymentMethod.PAGDIVIDIDO,
    title: <AB current={'Boleto Parcelado'} experiment={'Financiamento'} experimentName="pagdividido" />,
    src: 'money'
  },
  {
    id: 'paypal-payment-method',
    method: enPaymentMethod.PAYPAL,
    title: 'PayPal',
    src: 'method_paypal'
  },
  {
    id: 'eduzz-wallet-payment-method',
    method: enPaymentMethod.EDUZZ,
    title: 'Saldo Eduzz',
    src: 'method_wallet'
  }
];
