import React from 'react';
import { SvgIcon } from 'components/SvgIcon';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  container: {
    width: '100%',
    position: 'absolute',
    zIndex: 1,
    backgroundColor: '#FFF',
    color: '#192542',
    maxHeight: 32,
    '& .chk-wrapper': {
      height: 32,
      margin: 'auto',
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'space-between',
      padding: 2,
      maxWidth: 1024
    },
    '& .chk-language__logo': {
      display: 'flex',
      alignItems: 'center',
      '& svg': {
        fill: '#1E406E'
      },
      '& span': {
        fontSize: '.625rem',
        margin: '0 0 0 4px'
      }
    },
    '& .chk-language__selectLanguage': {
      alignItems: 'center',
      display: 'flex',
      fontSize: '.625rem',
      '& span': {
        padding: '0 8px 0 0'
      }
    }
  }
});

const TopBar: React.FC = () => {
  const classes = useStyles({});

  return (
    <div className={`${classes.container} theme-language-bar`}>
      <div className="chk-wrapper">
        <div className="chk-language__logo">
          <SvgIcon name="checkoutLogo" />
          <span>Checkout Sun</span>
        </div>
      </div>
    </div>
  );
};

export default React.memo(TopBar);
