import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { enPaymentMethod } from 'enums/enPaymentMethod';
import { fromPromise } from 'rxjs/observable/fromPromise';
import { CLOUDFRONT_DOMAIN, FINGERPRINT_API_KEY, FPJS_AGENT_DOWNLOAD_PATH, FPJS_BEHAVIOR_PATH, FPJS_GET_RESULT_PATH, IS_DEVELOPMENT } from 'settings';
import { v4 } from 'uuid';
import { of } from 'rxjs/observable/of';
import { sleep } from '../functions';
import * as Sentry from '@sentry/browser';

const FINGERPRINT_SCRIPT_URL = `${CLOUDFRONT_DOMAIN}/${FPJS_BEHAVIOR_PATH}/${FPJS_AGENT_DOWNLOAD_PATH}?apiKey=${FINGERPRINT_API_KEY}`;
const FINGERPRINT_ENDPOINT_URL = `${CLOUDFRONT_DOMAIN}/${FPJS_BEHAVIOR_PATH}/${FPJS_GET_RESULT_PATH}?region=us`;

interface IFingerprintJS {
  get: Function;
}

class FingerprintService {
  private fingerprint$ = new BehaviorSubject<string>('');
  private creating = false;

  public getFingerprint(paymentMethod: enPaymentMethod) {
    const currentFingerprint = this.fingerprint$.value;

    let observable = this.fingerprint$.asObservable();

    const params = new URLSearchParams(window.location.search);
    const enableFingerprintParam = params.get('enableFingerprint');

    const fingerprintPaymentAllowed = [enPaymentMethod.CREDIT_CARD, enPaymentMethod.PIX, enPaymentMethod.BANKSLIP, enPaymentMethod.ONE_CLICK_BUY];

    if (!currentFingerprint && fingerprintPaymentAllowed.includes(paymentMethod) && (!IS_DEVELOPMENT || !!enableFingerprintParam)) {
      this.createFingerprint();
      observable = this.fingerprint$.filter((fingerprint) => !!fingerprint);
    }

    return observable.take(1);
  }

  private async createFingerprint() {
    if (this.creating) {
      return;
    }

    this.creating = true;
    let fp: IFingerprintJS = null;
    const generateFingerprint = () => {
      return new Promise<IFingerprintJS>(async (resolve, reject) => {
        try {
          const fingerprintJS = await import(/* webpackIgnore: true */ FINGERPRINT_SCRIPT_URL);

          const fp = await fingerprintJS.load({
            endpoint: [
              FINGERPRINT_ENDPOINT_URL,
              fingerprintJS.defaultEndpoint // Fallback to default endpoint in case of error
            ]
          });

          resolve(fp);
        } catch (err) {
          reject(new Error(err));
        }
      });
    };

    const generator = async () => {
      try {
        fp = await generateFingerprint();
        const result = await fp.get();
        return result.visitorId;
      } catch (err) {
        await sleep(2000);
        Sentry.captureException(err);
        throw err;
      }
    };

    of(true)
      .switchMap(() => fromPromise(generator()))
      .catch(() => {
        this.creating = false;
        return of('failed-' + v4());
      })
      .subscribe((fp) => this.fingerprint$.next(fp));
  }
}

export const fingerprintService = new FingerprintService();
