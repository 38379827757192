import React, { Fragment } from 'react';
import { BaseComponent, IStateBase } from 'components/BaseComponent';
import { SvgIcon } from 'components/SvgIcon';
import { cartService } from 'services/CartService';
import { IProductData } from 'interfaces/IProductData';
import Translate from 'components/Translate';
import { WithStyles } from 'shared/decorators/withStyles';

interface IState extends IStateBase {
  cart: IProductData;
}

@WithStyles(() => ({
  component: {
    '& .schedule': {
      display: 'flex',
      flexDirection: 'column',
      color: '#807e7e',

      '@media screen and (max-width: 600px)': {
        textAlign: 'center'
      },

      '& h3': {
        display: 'flex',
        alignItems: 'center',
        color: '#000',
        margin: '0 0 14px 0',
        fontWeight: 'bold',

        '@media screen and (max-width: 600px)': {
          fontSize: '0.675rem',
          margin: '0 0 3px 0',
          justifyContent: 'center'
        },

        '& span': {
          fontSize: '0.75rem',
          textTransform: 'uppercase',

          '@media screen and (max-width: 600px)': {
            fontSize: '0.785rem'
          }
        }
      },

      '& .locale': {
        marginTop: '25px',
        '@media screen and (max-width: 600px)': {
          marginTop: '12px'
        }
      },

      '& span': {
        lineHeight: '1.333rem',
        fontSize: '0.875rem',
        '@media screen and (max-width: 600px)': {
          fontSize: '0.785rem',
          lineHeight: '1rem'
        }
      }
    },

    '& svg': {
      height: '20px',
      marginRight: '5px',
      width: '20px'
    }
  }
}))
export class Locale extends BaseComponent<IState, {}> {
  componentDidMount() {
    this.observeState({ cart: null }, cartService.getCart());
  }

  address = () => {
    const { cart } = this.state;
    let address = '';

    if (cart.tickets.street) {
      address += cart.tickets.street;
    }

    if (cart.tickets.number) {
      address += ', ' + cart.tickets.number;
    }

    if (cart.tickets.neighborhood) {
      address += ' - ' + cart.tickets.neighborhood;
    }

    if (cart.tickets.city) {
      address += ' - ' + cart.tickets.city;
    }

    if (cart.tickets.province) {
      address += ', ' + cart.tickets.province;
    }

    if (cart.tickets.cep) {
      address += ', ' + cart.tickets.cep;
    }

    if (cart.tickets.country) {
      address += ', ' + cart.tickets.country;
    }
    return address;
  };

  render() {
    if (!this.state) {
      return null;
    }

    const { cart } = this.state;

    const address = this.address();

    return (
      <div className={this.props.classes.component}>
        <div className="schedule">
          <h3 id="dateTime_label theme-icon">
            <SvgIcon name="calendar" /> <Translate term="Data e Horário" />
          </h3>
          {cart.tickets.eventDate === '31/12/2099 18:00' ? (
            <span id="dateTime_content">Data a definir</span>
          ) : (
            <Fragment>
              <span id="dateTime_content">
                <Translate term="Inicio:" /> {cart.tickets.eventDate}
              </span>
              <span id="dateTime_content">
                <Translate term="Término:" /> {cart.tickets.eventFinishDate}
              </span>
            </Fragment>
          )}
          {address && (
            <Fragment>
              <h3 id="place_Name" className="locale theme-icon">
                <SvgIcon name="pin_map" /> <Translate term="Endereço" />
              </h3>
              <span id="place_address">{address}</span>
            </Fragment>
          )}
        </div>
      </div>
    );
  }
}
