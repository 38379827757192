import React from 'react';
import { cartService } from 'services/CartService';
import { BaseComponent } from 'components/BaseComponent';
import { INext, IPlan } from 'interfaces/INext';
import currencyFormatter from 'currency-formatter';
import { IStateBase } from '../../BaseComponent';
import Grid from '@material-ui/core/Grid/Grid';
import FieldSelect from 'components/Field/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Translate from 'components/Translate';
import { enPlanType } from 'enums/enPlanType';
import { paymentService } from 'services/PaymentService';
import { WithStyles } from 'shared/decorators/withStyles';

interface IState extends IStateBase {
  next: INext;
  selectedPlan?: IPlan;
}

@WithStyles(() => ({
  component: {
    '& .label': {
      fontSize: '0.75rem',
      transform: 'scale(1)'
    },

    '& .margin': {
      margin: '12px 0 16px 0'
    }
  }
}))
export default class Next extends BaseComponent<IState, any> {
  componentDidMount() {
    cartService
      .getCart()
      .filter((c) => !!c)
      .bindComponent(this)
      .subscribe((cart) => {
        this.setState({ next: cart.next });
      });
  }

  createStringPlan = (plan: IPlan): string => {
    if (!plan) {
      return 'Planos Indisponíveis no momento';
    }
    let str = '';

    if (plan.trialDays && plan.trialDays > 0) {
      str += `${plan.trialDays} ${plan.trialDays > 1 ? ' dias ' : ' dia '}grátis -`;
    }

    if (plan.values.accession) {
      str += ` Adesão de ${currencyFormatter.format(plan.values.accession, { code: 'BRL', precision: 2 })} +`;
    }

    str += ` ${currencyFormatter.format(plan.values.price, { code: 'BRL', precision: 2 })}`;

    if (plan.scheduler.type.toLowerCase() === enPlanType.mensal) {
      str += ` a cada ${plan.scheduler.interval}
      ${plan.scheduler.interval > 1 ? 'meses' : 'mês'}`;
    }

    if (plan.scheduler.type.toLowerCase() === enPlanType.anual) {
      str += ` a cada ${plan.scheduler.interval}
      ${plan.scheduler.interval > 1 ? 'anos' : 'ano'}`;
    }

    return str;
  };

  handleChange = (id: number) => {
    if (this.state.selectedPlan) {
      if (this.state.selectedPlan.id == id) {
        return;
      }
    }
    const { next } = this.state;

    const selectedPlan = next.plans.find((plan) => plan.id == id);

    paymentService.setSelectedPlan(selectedPlan);
    this.setState({ selectedPlan });
  };

  render() {
    if (!this.state || !this.state.next || !this.state.next.plans) {
      return null;
    }

    const { next, selectedPlan } = this.state;

    return (
      <div className={this.props.classes.component}>
        <Grid container>
          <Grid item xs={12}>
            <FieldSelect
              id={'select-plan'}
              validation="requiredSelect"
              onChange={this.handleChange}
              label={<Translate term={'Selecione um plano'} />}
              value={selectedPlan && selectedPlan.id}
              InputLabelProps={{
                className: 'theme-label label',
                id: 'label-plans'
              }}
              inputProps={{
                className: 'label planName'
              }}
              margin={'none'}
              className={'margin'}
            >
              {next.plans.map((plan, key) => {
                return (
                  <MenuItem key={key} disableGutters value={plan.id} classes={{ root: 'planItem' }} className={'theme-label'}>
                    <Grid container wrap={'wrap'}>
                      <Grid item xs={12}>
                        <div className={'planName'}>{plan.name}</div>
                      </Grid>
                      <Grid item xs={12}>
                        <div className={'informationsPlan'}>{this.createStringPlan(plan)}</div>
                      </Grid>
                    </Grid>
                  </MenuItem>
                );
              })}
            </FieldSelect>
          </Grid>
        </Grid>
      </div>
    );
  }
}
