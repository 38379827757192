export class HotJarService {
  public log(key: string, value: any = ''): HotJarService {
    window.hj('tagRecording', [key, value]);
    return this;
  }
}

// tslint:disable-next-line:variable-name
export const HotJarServiceFactory = () => new HotJarService();

export const hotJarService = HotJarServiceFactory();
export default hotJarService;
