/* eslint-disable max-lines */
import { BaseComponent, IStateBase } from 'components/BaseComponent';
import React from 'react';
import UserForm from './UserForm';
import Payment from './Payment';
import { SvgIcon } from 'components/SvgIcon';
import { paymentService } from 'services/PaymentService';
import { ICartModel } from 'interfaces/ICartModel';
import Button from '@material-ui/core/Button/Button';
import { cartService } from 'services/CartService';
import { IProductData, IProductConfig } from 'interfaces/IProductData';
import Translate from 'components/Translate';
import { MAX_CREDIT_CARDS } from 'settings';
import Snackbar from '@material-ui/core/Snackbar';
import Fade from '@material-ui/core/Fade';
import UserAddressForm from 'components/UserAddressForm';
import { FieldValidation } from 'components/Field';
import AcceptTerms from 'components/AcceptTerms';
import { enPaymentType } from 'enums/enPaymentType';
import { parameterService } from 'services/ParameterService';
import { validate } from 'validators';
import { enParameters } from 'enums/enParameters';
import QuantityForm from 'components/Cart/Physical/QuantityForm';
import PaymentButton from 'components/Cart/PaymentButton';
import OrderBump from '../OrderBump';
import StuddentForm from './StuddentForm';
import { WithStyles } from 'shared/decorators/withStyles';
import Summary from '../Summary';
import { getPaymentButtonMessage } from 'functions';
import { enPaymentMethod } from 'enums/enPaymentMethod';
import { AcceptProducerTerms } from 'components/AcceptProducerTerms';

interface IState extends IStateBase {
  model: ICartModel;
  cart: IProductData;
  config: IProductConfig;
  payment: ICartModel;
  showMessage: boolean;
  snackMessage: string;
  messageButton: string;
  showPaymentMultipleCardsButton: boolean;
  isValidParamterToSkip: boolean;
}

@WithStyles(() => ({
  component: {
    width: '100%',

    '& .card-message': {
      width: '100%'
    },

    '& .chk-security-message': {
      clear: 'both',
      fontSize: '.85rem',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: '10px',

      '& svg': {
        fill: '#21883b',
        width: '25px',
        height: '25px',
        marginRight: '5px'
      }
    },

    '& .chk-multiple-cards': {
      display: 'flex',
      justifyContent: 'center',

      '@media screen and (min-width: 960px)': {
        margin: '14px 0 0'
      }
    },

    '& .chk-add-credit-card': {
      borderRadius: '5px',
      padding: '6px 15px',
      backgroundColor: '#4caf50',
      color: '#fff',
      textTransform: 'unset',

      '& svg': {
        width: '30px',
        margin: '0 10px 0 0'
      },

      '&:hover': {
        backgroundColor: '#388e3c'
      },

      '&.add': {
        '& svg': {
          width: '14px',
          margin: '0 10px 0 0'
        }
      }
    },

    '& .chk-submit': {
      marginTop: '10px',

      '& .paymentButton': {
        height: '60px',
        fontSize: '1.2rem',
        fontWeight: 500,
        textTransform: 'unset'
      }
    },

    '& .chk-security-badges': {
      padding: '20px 0 0',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',

      '& svg': {
        fill: 'rgb(49, 136, 52)',
        fillRule: 'evenodd',
        height: '40px',
        width: '35px'
      },

      '& div': {
        display: 'flex',
        alignItems: 'center',

        '&:not(:last-child)': {
          marginRight: '10px'
        },

        '& .text': {
          display: 'flex',
          flexFlow: 'column nowrap',
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
          color: '#1AB25A',
          lineHeight: 'normal',
          marginLeft: '5px',
          textTransform: 'uppercase',
          fontSize: '0.666rem',

          '& .buy': {
            fontWeight: 700,
            lineHeight: 'normal'
          },

          '& span a': {
            color: '#1AB25A',
            textDecoration: 'none',

            '&:hover': {
              textDecoration: 'underline'
            }
          }
        }
      }
    },

    '& .tooltip-email-information': {
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',

      '& .modifyEmailLabel': {
        top: '-9px'
      },

      '& .information': {
        fontSize: '12px',
        position: 'absolute',
        fontStyle: 'italic',
        color: '#868686',
        margin: '13px 0 0 0'
      }
    },

    '& .autoSuggestContainer': {
      zIndex: 15,
      position: 'absolute',
      top: '83px',

      '& .autoSuggest': {
        cursor: 'pointer',
        width: '270px',

        '& .item': {
          height: '30px',
          padding: '0 5px',

          '&:hover': {
            backgroundColor: '#f1f1f1'
          },

          '&.actualEmail': {
            backgroundColor: '#f1f1f1'
          }
        }
      }
    },

    '& .cepNotFound': {
      color: '#F59211',
      fontSize: '0.775rem',
      display: 'flex',

      '& svg': {
        width: '15px',
        height: '15px',
        margin: '0 5px 0 0'
      }
    },

    '& .absolute-label': {
      top: '76px',
      position: 'absolute'
    },

    '& .cpf-form': {
      margin: '12px 0 0 0'
    }
  }
}))
export default class MainForm extends BaseComponent<IState> {
  componentDidMount() {
    this.observeState({ cart: null }, cartService.getCart());
    this.observeState({ config: null }, cartService.getConfig());
    this.observeState({ payment: null }, paymentService.getCart());
    this.observeState({ showPaymentMultipleCardsButton: false }, paymentService.showPaymentMultipleCardsButton());
    this.bindModel(paymentService.getCart());

    parameterService
      .getAll()
      .bindComponent(this)
      .filter((params) => !!params.skip)
      .filter((params) => validate(params.name || params.nome, 'required|name|max:100').valid)
      .filter((params) => validate(params.email, 'required|email|max:150').valid)
      .filter((params) => validate(params.phone || params.cel, 'required|min:10|max:11').valid)
      .do((params) => {
        paymentService.setData({
          name: params.name || params.nome,
          email: params.email,
          phone: params.phone || params.cel
        });
      })
      .switchMap(() => parameterService.get(enParameters.skip))
      .filter((skip) => !!skip)
      .bindComponent(this)
      .subscribe(() => {
        this.setState({
          isValidParamterToSkip: true
        });
      });

    paymentService
      .getCart()
      .combineLatest(cartService.getCart(), cartService.getConfig())
      .bindComponent(this)
      .subscribe(([payment, cart, config]) => {
        this.setState({ messageButton: getPaymentButtonMessage(payment, cart, config) });
      });

    this.setState({
      showMessage: false
    });
  }

  addCreditCard = () => {
    paymentService
      .addPayment()
      .bindComponent(this)
      .take(1)
      .subscribe(() => {
        this.setState({
          showMessage: true,
          snackMessage: 'Um novo cartão foi adicionado'
        });
      });
  };

  showAddCardButton = () => {
    const isMultipleCreditCards = this.state.cart.config.multipleCreditCards;
    const canAddCreditCard = this.state.model.payment.length < MAX_CREDIT_CARDS && this.state.model.payment.length > 1;

    return isMultipleCreditCards && canAddCreditCard;
  };

  handleClose = () => {
    this.setState({
      showMessage: false
    });
  };

  removeCreditCard = () => {
    this.setState({
      showMessage: true,
      snackMessage: 'O cartão foi removido'
    });
  };

  finish = async () => {
    if (await this.isFormValid()) {
      this.props.onPay();
    }
  };

  isValid(): boolean {
    const validation = this.state.validation || {};
    return Object.keys(validation).length === 0;
  }

  render() {
    if (!this.state) {
      return <div />;
    }

    const { model, cart, config, messageButton, showPaymentMultipleCardsButton, isValidParamterToSkip } = this.state;
    const isPagDividido = model.payment[0].paymentMethod === enPaymentMethod.PAGDIVIDIDO;
    return (
      <div className={this.props.classes.component}>
        <FieldValidation.Provider value={this.registerFields}>
          {!isValidParamterToSkip && (config.ead ? <StuddentForm /> : <UserForm />)}

          {config.askAddressBeforePayment ? <UserAddressForm /> : ''}

          {cart.pins ? <QuantityForm isPin={!!cart.pins} /> : null}

          {!isValidParamterToSkip ? (
            <div className="chk-security-message" id="security-message-label">
              <SvgIcon name="security" />
              <Translate term="Seus dados serão mantidos em sigilo" />
            </div>
          ) : null}

          {showPaymentMultipleCardsButton && (
            <Fade in={showPaymentMultipleCardsButton}>
              <div className="chk-multiple-cards">
                <Button id="chk-multiplecard-button" className={'chk-add-credit-card theme-button'} onClick={this.addCreditCard}>
                  <SvgIcon name="multipleCards" />
                  <Translate term="Pagar com múltiplos cartões?" />
                </Button>
              </div>
            </Fade>
          )}

          {cart.config.paymentType == enPaymentType.FREE
            ? ''
            : model.payment.map((model, key) => (
                <Payment key={key} index={key} validateForm={this.isFormValid.bind(this)} removeCreditCard={this.removeCreditCard} />
              ))}

          {this.showAddCardButton() ? (
            <div className="chk-multiple-cards">
              <Button id="chk-addcard-button" className={'chk-add-credit-card add theme-button'} onClick={this.addCreditCard}>
                <SvgIcon name="plus" />
                <Translate term="Adicionar cartão de pagamento" />
              </Button>
            </div>
          ) : (
            ''
          )}

          {cart.config.paymentType === enPaymentType.SUBSCRIPTION && !isPagDividido && <AcceptTerms />}

          <AcceptProducerTerms termsUrl={cart.config.termsUrl} />

          {model.payment[0].paymentMethod !== enPaymentMethod.PAGDIVIDIDO && (
            <>
              <OrderBump />
              <Summary />
            </>
          )}
          <div className="chk-submit">
            <PaymentButton
              finish={this.finish}
              disabledButton={!this.isValid && this.state.formSubmitted}
              messageButton={messageButton}
              singleStep={true}
              hidden={model.payment[0].paymentMethod === enPaymentMethod.PAGDIVIDIDO}
            />
          </div>

          <div className="chk-security-badges" id="chk-security-bedges">
            <div>
              <SvgIcon name="security_check" />

              <div className="text">
                <span className="buy">
                  <Translate term={'Compra'} />
                </span>
                <span className="safety">
                  <Translate term={'100% Segura'} />
                </span>
              </div>
            </div>

            <div>
              <SvgIcon name="security_finger" />

              <div className="text">
                <span className="buy">
                  <Translate term="Privacidade" />
                </span>
                <span className="safety">
                  <Translate term="Protegida" />
                </span>
              </div>
            </div>

            <div>
              <SvgIcon name="safeHostIcon" />

              <div className="text">
                <span className="buy">GoDaddy ®</span>
                <span className="safety">
                  <a
                    id="chk-godaddy"
                    href="https://seal.godaddy.com//verifySeal?sealID=ouL8MfhmoQ4juRhF5T4nzZnEC8g5QGxOM2VEfORRFBf4xBhAGt"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Translate term="Verificado e protegido" />
                  </a>
                </span>
              </div>
            </div>

            <Snackbar
              id="chk-card-message"
              className={'card-message'}
              open={this.state.showMessage}
              onClose={this.handleClose}
              autoHideDuration={3000}
              message={<span>{<Translate term={this.state.snackMessage} />}</span>}
            />
          </div>
        </FieldValidation.Provider>
      </div>
    );
  }
}
