/* eslint-disable max-len */
import React, { memo, Fragment } from 'react';
import { IThankYou } from 'services/ThankYouService';
import { SvgIcon } from 'components/SvgIcon';
import Translate from 'components/Translate';
import { SupportContactText } from '../../Text/SupportContactText';

interface IProps {
  thankYou: IThankYou;
}

export const InAnalyse = memo((props: IProps) => {
  const { thankYou } = props;
  const { producerEmail } = props.thankYou.content;

  return (
    <Fragment>
      <div className="message">
        <p>
          <Translate
            hasHTML
            term="Sua compra está em análise, fique de olho em seu <b>e-mail </b> e aguarde para mais informações.Enquanto isso, confirme os seus dados."
          />
        </p>
      </div>
      <div className="data">
        <div className="user">
          <div className="user">
            <p>
              <SvgIcon name="user" />
              <span>{thankYou.studdent ? thankYou.studdent.name : thankYou.user.name}</span>
            </p>
            <p>
              <SvgIcon name="email" />
              <span>{thankYou.studdent ? thankYou.studdent.email : thankYou.user.email}</span>
            </p>
          </div>
        </div>
      </div>
      <div className="alert">
        <SupportContactText contactEmail={producerEmail} />
      </div>
    </Fragment>
  );
});
