import React from 'react';
import { BaseComponent } from 'components/BaseComponent';
import { SvgIcon } from 'components/SvgIcon';
import { WithStyles } from 'shared/decorators/withStyles';

interface IProps {
  infos: string[];
}

@WithStyles(() => ({
  component: {
    '& .chk-userInfos__content': {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'flex-start',
      margin: '0 0 6px 0',
      '& svg': {
        width: '16px',
        fill: '#009358',
        margin: '0 8px 0 0'
      },
      '& span': {
        color: '#596375',
        fontSize: '0.75rem'
      }
    }
  }
}))
export default class UserInfos extends BaseComponent<any, IProps> {
  render() {
    return (
      <div className={`${this.props.classes.component} chk-userInfos`}>
        {this.props.infos.map((info, key) => {
          return (
            <div className="chk-userInfos__content" key={key}>
              <SvgIcon name="checkIcon" />
              <span>{info}</span>
            </div>
          );
        })}
      </div>
    );
  }
}
