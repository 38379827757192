import { enCurrency } from 'enums/enCurrency';

export const ZERO_DECIMALS_CURRENCIES = [
  enCurrency.BIF,
  enCurrency.CLP,
  enCurrency.DJF,
  enCurrency.GNF,
  enCurrency.JPY,
  enCurrency.KMF,
  enCurrency.KRW,
  enCurrency.MGA,
  enCurrency.PYG,
  enCurrency.RWF,
  enCurrency.UGX,
  enCurrency.VND,
  enCurrency.VUV,
  enCurrency.XAF,
  enCurrency.XOF,
  enCurrency.XPF,
  enCurrency.HUF,
  enCurrency.TWD,
  enCurrency.ISK
];
