import React from 'react';
import { BaseComponent } from 'components/BaseComponent';

export default class ShopbackClick extends BaseComponent {
  componentDidMount() {
    const script = document.createElement('script');

    script.innerHTML = `
      window._st_account = 12;
      (function () {
        var ss = document.createElement('script');
        ss.type = 'text/javascript';
        ss.async = true;
        ss.src = 'https://app.shoptarget.com.br/js/tracking.js';
        var sc = document.getElementsByTagName('script')[0];
        sc.parentNode.insertBefore(ss, sc);
      })();
    `;

    document.body.appendChild(script);
  }

  render() {
    return <div />;
  }
}
