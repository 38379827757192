import React, { FC } from 'react';
import { IProductData } from 'interfaces/IProductData';
import Translate from 'components/Translate';
import { CurrencyFormat } from 'components/CurrencyFormat';
import { paymentService } from 'services/PaymentService';
import MultipleInstallments from 'components/Cart/Price/MultipleInstallments';
import WithoutInstallment from 'components/Cart/Price/WithoutInstallment';
import { enPaymentMethod } from 'enums/enPaymentMethod';
import { ICartModel } from 'interfaces/ICartModel';
import { useObservable } from 'react-use-observable';

interface IPriceProps {
  cart: IProductData;
  payment: ICartModel;
}

// tslint:disable-next-line:variable-name
export const SubscriptionWithoutTaxTrial: FC<IPriceProps> = (props) => {
  const { cart, payment } = props;
  const isWithInstallments = [enPaymentMethod.CREDIT_CARD, enPaymentMethod.ONE_CLICK_BUY].includes(payment.payment[0].paymentMethod);
  const [isMultipleCards] = useObservable(() => paymentService.isMultipleCreditCards(), []);

  return (
    <div className="chk-subscription">
      <div className="chk-subscription__text">
        <Translate term="Assinatura" />
      </div>
      <div className="chk-subscription__value theme-label">
        {cart.installments.length > 1 && isWithInstallments && !isMultipleCards ? (
          <MultipleInstallments cart={cart} isTicket={false} />
        ) : (
          <WithoutInstallment cart={cart} />
        )}{' '}
      </div>
      <div className="chk-subscription__frequency">
        <Translate
          term={`a cada {frequency} ${paymentService.getNormalizedFrequencyType(cart.subscription.frequencyType, cart.subscription.frequency)}`}
          properties={{
            tax: <CurrencyFormat value={cart.subscription.tax} />,
            frequency: cart.subscription.frequency
          }}
        />
      </div>
    </div>
  );
};
