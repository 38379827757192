import React from 'react';
import { Translate } from '../Translate';
import { makeStyles } from '@material-ui/core/styles';

interface IProps {
  termsUrl?: string;
}

const useStyles = makeStyles({
  termsLink: {
    color: '#3960A3'
  },
  termsText: {
    fontSize: '0.750rem',
    fontWeight: 400,
    lineHeight: '1.5rem',
    marginTop: '0.5rem',
    color: '#636871'
  }
});

export const AcceptProducerTerms = (props: IProps) => {
  const classes = useStyles({});
  const { termsUrl } = props;

  if (!termsUrl) {
    return null;
  }

  return (
    <>
      <div className={classes.termsText}>
        <Translate term="Concordo que li e compreendi os" />
        &nbsp;
        <a href={termsUrl} target="_blank" rel="noopener noreferrer">
          <Translate className={classes.termsLink} term="termos dessa compra" />
        </a>
      </div>
    </>
  );
};
