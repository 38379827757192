import React from 'react';
import { PixPanelHeaderText } from '../PixPanelHeaderText';
import { PixTimerCode } from '../PixTimerCode';
import { PixPanelProductImage } from '../PixPanelProductImage/index';
import { PixCodeCopyPaste } from '../PixCodeCopyPaste';
import { useObservable } from 'react-use-observable';
import { paymentWaiter } from 'services/PaymentWaiterService';
import { PixCloseModal } from '../PixCloseModal';
import { QRCode } from './QRCode/index';
import { useMappedObservable } from 'shared/hooks/useMappedObservable';
import { configService } from 'services/ConfigService';

const Component: React.FC = () => {
  const [waiter] = useObservable(() => paymentWaiter.getWaiter(), []);
  const [templateId] = useMappedObservable(
    () => configService.getConfig().take(1),
    (config) => Number(config.template.id),
    []
  );

  if (!waiter?.result?.url) {
    return null;
  }

  return (
    <div className="chk-pix-panel">
      <div className={`chk-payment-painel pix chk-pix-modal-container ${[14, 16].includes(templateId) ? 'chk-pix-modal-fit-content' : ''}`}>
        <PixTimerCode />
        <div className="chk-pix-modal-content">
          <div className="chk-pix-product-container">
            <PixPanelProductImage />
            <div>
              <h3 className="chk-pix-modal-title">Falta pouco para concluir seu pedido!</h3>
              <PixPanelHeaderText />
            </div>
          </div>
          <div className="chk-pix-divider"></div>
          <div className="chk-pix-instructions-container chk-pix-instructions-container-qrcode">
            <div>
              <h3 className="chk-pix-instructions-title">Pagar com QR Code</h3>
              <ul className="chk-pix-instructions-list">
                <li>Acesse sua conta bancária no Internet Banking ou através do app;</li>
                <li>
                  Selecione a opção pagar via <b>Pix</b>;
                </li>
                <li>Escaneie o código Pix {[14, 16].includes(templateId) ? 'acima' : 'ao lado'} e finalize o pagamento.</li>
              </ul>
            </div>
            <QRCode />
          </div>
          <div className="chk-pix-divider"></div>
          <h3 className="chk-pix-instructions-title">Pagar com Pix Copia e Cola</h3>
          <div>
            <div className="chk-pix-instructions-text">
              Se preferir, copie o código Pix abaixo, no seu banco selecione a opção Pix Copia e Cola e finalize sua compra!
            </div>
            <PixCodeCopyPaste pixCode={waiter.result.url} buttonVariant="text" />
          </div>
          <PixCloseModal />
        </div>
      </div>
    </div>
  );
};

export const DesktopPixPaymentPanel = React.memo(Component);
