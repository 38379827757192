/* eslint-disable max-lines */
import Button from '@material-ui/core/Button/Button';
import Grid, { GridSize } from '@material-ui/core/Grid/Grid';
import Snackbar from '@material-ui/core/Snackbar';
import AcceptTerms from 'components/AcceptTerms';
import { BaseComponent, IStateBase } from 'components/BaseComponent';
import Coupon from 'components/Cart/Coupon';
import CouponPrice from 'components/Cart/Coupon/CouponPrice';
import FeeInstallments from 'components/Cart/Footer/feeInstallment';
import Payment from 'components/Cart/MainForm/Payment';
import Next from 'components/Cart/Next';
import OpenValue from 'components/Cart/OpenValue';
import PaymentButton from 'components/Cart/PaymentButton';
import QuantityForm from 'components/Cart/Physical/QuantityForm';
import PreviousStep from 'components/Cart/PreviousStep';
import Price, { enLayout } from 'components/Cart/Price';
import FreeShipping from 'components/Cart/Price/FreeShipping';
import { Subscription } from 'components/Cart/Price/Subscription';
import Summary from 'components/Cart/Summary';
import { FieldValidation } from 'components/Field';
import { SvgIcon } from 'components/SvgIcon';
import Translate from 'components/Translate';
import { enPaymentMethod } from 'enums/enPaymentMethod';
import { enPaymentType } from 'enums/enPaymentType';
import { getPaymentButtonMessage } from 'functions';
import { ICartModel } from 'interfaces/ICartModel';
import { IPaymentModel } from 'interfaces/IPaymentModel';
import { IProductData } from 'interfaces/IProductData';
import React from 'react';
import { cartService } from 'services/CartService';
import { currencyService } from 'services/CurrencyService';
import { paymentService } from 'services/PaymentService';
import { MAX_CREDIT_CARDS } from 'settings';
import { IStepProps } from '.';
import OrderBump from '../../../OrderBump';
import HeaderTitle from './HeaderTitle';
import { AcceptProducerTerms } from 'components/AcceptProducerTerms';
import { enCurrency } from 'enums/enCurrency';
import FormHeader from 'components/Cart/FormHeader';
import ProductPrice from 'shared/components/ProductPrice';

interface IState extends IStateBase {
  cart: IProductData;
  model: ICartModel;
  showMessage: boolean;
  snackMessage: string;
  payment: ICartModel;
  paymentMethod: IPaymentModel;
  messageButton: string;
  showPaymentMultipleCardsButton: boolean;
  gridSize: GridSize[];
  currency: enCurrency;
  isMultipleCards: boolean;
}

export interface IProps extends IStepProps {
  onPay: () => void;
  step: number;
}

export default class StepPayment extends BaseComponent<IState, IProps> {
  componentDidMount() {
    this.observeState({ cart: null }, cartService.getCart());
    this.observeState({ payment: null }, paymentService.getCart());
    this.observeState({ showPaymentMultipleCardsButton: false }, paymentService.showPaymentMultipleCardsButton());
    this.observeState({ currency: enCurrency.BRL }, currencyService.getCurrency());
    this.observeState({ isMultipleCards: false }, paymentService.isMultipleCreditCards());
    this.bindModel(paymentService.getCart());

    this.setState({
      gridSize: [7, 5]
    });

    cartService
      .getCart()
      .take(1)
      .subscribe((cart) => {
        if (cart.config.paymentType === enPaymentType.SUBSCRIPTION) {
          this.setState({
            gridSize: [4, 8]
          });
        }
      });

    paymentService
      .getCart()
      .combineLatest(cartService.getCart(), cartService.getConfig())
      .bindComponent(this)
      .subscribe(([payment, cart, config]) => {
        this.setState({ messageButton: getPaymentButtonMessage(payment, cart, config) });
      });
  }

  onValidate(errors: any) {
    this.setState({ validation: errors || {} });
  }

  isValid(): boolean {
    const validation = this.state.validation || {};
    return Object.keys(validation).length === 0;
  }

  finish = async () => {
    if (await this.isFormValid()) {
      this.props.onPay();
    }
  };

  addCreditCard = () => {
    paymentService
      .addPayment()
      .loader()
      .bindComponent(this)
      .take(1)
      .subscribe(() => {
        this.setState({
          showMessage: true,
          snackMessage: 'Um novo cartão foi adicionado'
        });
      });
  };

  showAddCardButton() {
    const isMultipleCreditCards = this.state.cart.config.multipleCreditCards;
    const canAddCreditCard = this.state.model.payment.length < MAX_CREDIT_CARDS && this.state.model.payment.length > 1;

    return isMultipleCreditCards && canAddCreditCard;
  }

  handleClose = () => {
    this.setState({
      showMessage: false
    });
  };

  removeCreditCard = () => {
    this.setState({
      showMessage: true,
      snackMessage: 'O cartão foi removido'
    });
  };

  render() {
    if (!this.state || !this.state.cart) {
      return '';
    }
    const { model, cart, payment, formSubmitted, messageButton, showPaymentMultipleCardsButton, gridSize } = this.state;
    const { paymentType } = cart.config;

    const disabledButton = !this.isValid() && formSubmitted;
    const numberInstallments = payment.payment[0].numberInstallments;
    const isPagDividido = payment.payment[0].paymentMethod === enPaymentMethod.PAGDIVIDIDO;

    return (
      <div>
        <div className="step">
          <FormHeader />
          <FieldValidation.Provider value={this.registerFields}>
            <Grid className="grid" container>
              <HeaderTitle shouldRenderModal={true} />
              <Grid item xs={12}>
                <Price layout={enLayout.column} cart={cart} hideMultipleInstallments={numberInstallments <= 1} />
              </Grid>

              <Grid container direction="row" alignItems="center" justify="space-between" spacing={1} className="chk-stepPayment__priceHeader">
                <Grid item xs={cart.config.paymentType !== enPaymentType.DONATION ? gridSize[0] : 12}>
                  <PreviousStep step={this.props.step} setStep={this.props.setStep} />
                  <OpenValue />
                  <Coupon />
                </Grid>

                <Grid item xs={gridSize[1]} className="chk-stepPayment__productPrice">
                  {paymentType !== enPaymentType.DONATION && <CouponPrice />}

                  {paymentType === enPaymentType.FREE && <Translate term={'Gratuito'} />}

                  {paymentType !== enPaymentType.SUBSCRIPTION && <ProductPrice />}

                  <FreeShipping config={cart.config} />

                  {paymentType === enPaymentType.SUBSCRIPTION && <Subscription payment={payment} cart={cart} />}
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Next />
              </Grid>
              {cart.pins && (
                <div className="chk-stepPayment__pin">
                  <QuantityForm isPin={!!cart.pins} />
                </div>
              )}

              <Grid item xs={12}>
                {showPaymentMultipleCardsButton && (
                  <div className="chk-multiple-cards">
                    <Button id="chk-init-multiplecards-button" className={'chk-add-credit-card theme-button'} onClick={this.addCreditCard}>
                      <SvgIcon name="multipleCards" />
                      <Translate term="Pagar com mais cartões?" />
                    </Button>
                  </div>
                )}
              </Grid>

              {model.payment.map((model, key) => (
                <Payment
                  key={key}
                  index={key}
                  cardFlagLimit={6}
                  validateForm={this.isFormValid.bind(this)}
                  hidePaymentMessage={true}
                  removeCreditCard={this.removeCreditCard}
                />
              ))}

              <Grid item xs={12}>
                {this.showAddCardButton() ? (
                  <div className="chk-multiple-cards">
                    <Button id="chk-add-multiplecard-button" className={'chk-add-credit-card theme-button'} onClick={this.addCreditCard}>
                      <SvgIcon name="multipleCards" />
                      <Translate term="Adicionar cartão de pagamento" />
                    </Button>
                  </div>
                ) : (
                  ''
                )}
              </Grid>

              {cart.config.paymentType == enPaymentType.SUBSCRIPTION && !isPagDividido && (
                <Grid className="accept-terms" item xs={12}>
                  <AcceptTerms />
                </Grid>
              )}

              <Grid className="accept-terms" item xs={12}>
                <AcceptProducerTerms termsUrl={cart.config.termsUrl} />
              </Grid>

              {!isPagDividido && (
                <Grid item xs={12} className="chk-order-bump">
                  <OrderBump />
                </Grid>
              )}

              {!isPagDividido && <Summary />}

              <PaymentButton hidden={isPagDividido} finish={this.finish} disabledButton={disabledButton} messageButton={messageButton} />

              <div className="chk-stepPayment__feeInstallments">{!isPagDividido && <FeeInstallments />}</div>
            </Grid>

            <Snackbar
              id="chk-cardmessage"
              className={'card-message'}
              open={this.state.showMessage}
              onClose={this.handleClose}
              autoHideDuration={1000}
              message={<span>{this.state.snackMessage}</span>}
            />
          </FieldValidation.Provider>
        </div>
      </div>
    );
  }
}
