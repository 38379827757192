import { BaseComponent, IStateBase } from 'components/BaseComponent';
import { ICartModel } from 'interfaces/ICartModel';
import { IProductData, IProductConfig, enTheme } from 'interfaces/IProductData';
import Button from '@material-ui/core/Button/Button';
import Grid from '@material-ui/core/Grid/Grid';
import React from 'react';
import { cartService } from 'services/CartService';
import { paymentService } from 'services/PaymentService';
import { IStepProps } from './index';
import Payment from 'components/Cart/MainForm/Payment';
import { MAX_CREDIT_CARDS } from 'settings';
import Snackbar from '@material-ui/core/Snackbar';
import { FieldValidation } from 'components/Field';
import { SvgIcon } from 'components/SvgIcon';
import HeaderStep from './HeaderStep/index';
import Translate from 'components/Translate';
import AcceptTerms from 'components/AcceptTerms';
import { enPaymentType } from 'enums/enPaymentType';
import PaymentButton from 'components/Cart/PaymentButton';
import QuantityForm from 'components/Cart/Physical/QuantityForm';
import OrderBump from '../../../OrderBump';
import { Divider, Hidden } from '@material-ui/core';
import OpenValue from 'components/Cart/OpenValue';
import Next from 'components/Cart/Next';
import Summary from 'components/Cart/Summary';
import Logo from '../../Widget/Header/Logo/index';
import { Helmet } from 'react-helmet';
import { getPaymentButtonMessage } from 'functions';
import { enPaymentMethod } from 'enums/enPaymentMethod';
import { AcceptProducerTerms } from 'components/AcceptProducerTerms';
import CpfForm from 'components/Cart/MainForm/Payment/PaymentForm/CpfForm';
import FormHeader from 'components/Cart/FormHeader';

interface IState extends IStateBase {
  cart: IProductData;
  config: IProductConfig;
  model: ICartModel;
  showMessage: boolean;
  snackMessage: string;
  messageButton: string;
  showPaymentMultipleCardsButton: boolean;
  isUserDataEditLocked: boolean;
}

export interface IProps extends IStepProps {
  onPay: () => void;
  step: number;
}

export default class StepPayment extends BaseComponent<IState, IProps> {
  componentDidMount() {
    this.observeState({ cart: null }, cartService.getCart());
    this.observeState({ showPaymentMultipleCardsButton: false }, paymentService.showPaymentMultipleCardsButton());
    this.bindModel(paymentService.getCart());
    this.observeState({ config: null }, cartService.getConfig());

    paymentService
      .getCart()
      .combineLatest(cartService.getCart(), cartService.getConfig())
      .bindComponent(this)
      .subscribe(([payment, cart, config]) => {
        this.setState({ cart });
        this.setState({ messageButton: getPaymentButtonMessage(payment, cart, config) });
      });

    cartService.isContractSaleRecovery().subscribe((isContractSaleRecovery) => this.setState({ isUserDataEditLocked: isContractSaleRecovery }));
  }

  onValidate(errors: any) {
    this.setState({ validation: errors || {} });
  }

  isValid(): boolean {
    const validation = this.state.validation || {};
    return Object.keys(validation).length === 0;
  }

  finish = async () => {
    if (await this.isFormValid()) {
      this.props.onPay();
    }
  };

  addCreditCard = () => {
    paymentService
      .addPayment()
      .loader()
      .take(1)
      .bindComponent(this)
      .subscribe(() => {
        this.setState({
          showMessage: true,
          snackMessage: 'Um novo cartão foi adicionado'
        });
      });
  };

  showAddCardButton() {
    const isMultipleCreditCards = this.state.cart.config.multipleCreditCards;
    const canAddCreditCard = this.state.model.payment.length < MAX_CREDIT_CARDS && this.state.model.payment.length > 1;

    return isMultipleCreditCards && canAddCreditCard;
  }

  handleClose = () => {
    this.setState({
      showMessage: false
    });
  };

  removeCreditCard = () => {
    this.setState({
      showMessage: true,
      snackMessage: 'O cartão foi removido'
    });
  };

  render() {
    if (!this.state || !this.state.cart) {
      return '';
    }

    const { messageButton, model, formSubmitted, cart, showPaymentMultipleCardsButton, config, isUserDataEditLocked } = this.state;
    const disabledButton = !this.isValid() && formSubmitted;
    const isPagDividido = model.payment[0].paymentMethod === enPaymentMethod.PAGDIVIDIDO;
    const showDocumentForm =
      cart.config.paymentType != enPaymentType.FREE && !cart.config.ead && config.template.id == enTheme.HOTSITE && !isUserDataEditLocked;

    return (
      <div>
        <Helmet>
          <body className="step-payment" />
        </Helmet>
        <Hidden mdUp>
          <div className="chk-multiStep__productName theme-header-bg theme-header">
            <Logo image={config.logo} optionalClasses="chk-logo__content-step-payment" />
            <h2 className="product-name">{cart.content.title}</h2>
          </div>
        </Hidden>
        <FormHeader />
        <HeaderStep step={this.props.step} setStep={this.props.setStep} />
        <FieldValidation.Provider value={this.registerFields}>
          <OpenValue />
          <Divider />
          <div className="step">
            <Grid className="grid step-payment" container>
              {cart.pins ? (
                <div className="chk-stepPayment__pin">
                  <QuantityForm isPin={!!cart.pins} />
                </div>
              ) : null}
              <Grid item xs={12}>
                <Next />
              </Grid>

              <div style={{ width: '100%' }}>
                {showPaymentMultipleCardsButton && (
                  <Grid item xs={12}>
                    <div className="chk-multiple-cards">
                      <Button id="chk-init-multiplecards-button" className={'chk-add-credit-card theme-button'} onClick={this.addCreditCard}>
                        <SvgIcon name="multipleCards" />
                        <Translate term="Pagar com mais cartões?" />
                      </Button>
                    </div>
                  </Grid>
                )}

                {model.payment.map((model, key) => (
                  <Payment
                    key={key}
                    index={key}
                    cardFlagLimit={6}
                    validateForm={this.isFormValid.bind(this)}
                    hidePaymentMessage={true}
                    removeCreditCard={this.removeCreditCard}
                  />
                ))}

                {showDocumentForm && (
                  <Grid item xs={12}>
                    <CpfForm />
                  </Grid>
                )}

                <div className="add-card">
                  {this.showAddCardButton() ? (
                    <Button id="chk-add-multiplecard-button" className={'chk-add-credit-card add theme-button'} onClick={this.addCreditCard}>
                      <SvgIcon name="plus" />
                      <Translate term="Adicionar cartão de pagamento" />
                    </Button>
                  ) : (
                    ''
                  )}
                </div>
              </div>

              <Grid container className="security_container">
                <Grid item xs={6}>
                  <div className="security">
                    <SvgIcon name="security_check" />
                    <div className="text">
                      <p>
                        <Translate term="Compra" />
                      </p>
                      <p>
                        <Translate term="100% Segura" />
                      </p>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div className="security">
                    <SvgIcon name="security_finger" />
                    <div className="text">
                      <p>
                        <Translate term="Privacidade" />
                      </p>
                      <p>
                        <Translate term="Protegida" />
                      </p>
                    </div>
                  </div>
                </Grid>
              </Grid>
              {cart.config.paymentType === enPaymentType.SUBSCRIPTION && !isPagDividido && (
                <Grid item xs={12}>
                  <AcceptTerms />
                </Grid>
              )}

              <AcceptProducerTerms termsUrl={cart.config.termsUrl} />

              {!isPagDividido && <OrderBump />}

              {!isPagDividido && <Summary />}
            </Grid>

            <PaymentButton hidden={isPagDividido} finish={this.finish} disabledButton={disabledButton} messageButton={messageButton} />

            <Snackbar
              id="chk-cardmessage"
              className={'card-message'}
              open={this.state.showMessage}
              onClose={this.handleClose}
              autoHideDuration={1000}
              message={<Translate term={this.state.snackMessage} />}
            />
          </div>
        </FieldValidation.Provider>
      </div>
    );
  }
}
