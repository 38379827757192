/* eslint-disable max-lines */
import { BaseComponent, IStateBase } from 'components/BaseComponent';
import { ICartModel } from 'interfaces/ICartModel';
import { IProductConfig } from 'interfaces/IProductData';
import React from 'react';
import { cartService } from 'services/CartService';
import { paymentService } from 'services/PaymentService';
import AddressAfter from './AddressAfter';
import { IPayProps, PayContext } from 'components/Cart';
import { Observable } from 'rxjs/Observable';
import { HistoryContext } from 'shared/HistoryContext';
import { History } from 'history';
import StepUser from './StepUser';
import StepAddress from './StepAddress';
import StepPayment from './StepPayment';
import { userService } from 'services/UserService';
import ExitPopUp from 'components/ExitPopUp/index';
import { WithStyles } from 'shared/decorators/withStyles';
import { currencyService } from 'services/CurrencyService';
import { enPaymentMethod } from 'enums/enPaymentMethod';
import { enProductType } from 'enums/enProductType';

export enum enStep {
  user,
  address,
  payment,
  addressAfter
}

export interface IStepProps {
  setStep: (step: enStep) => void;
}

interface IState extends IStateBase {
  config: IProductConfig;
  model: ICartModel;
  cart: any;
  step: enStep;
}

@WithStyles(() => ({
  '@global': {
    '.step-payment .links .chk-installment-fee': {
      display: 'inline !important'
    }
  },
  component: {
    display: 'flex',
    transition: 'all .5s ease-in-out',

    '& .field': {
      fontSize: '0.75rem',
      transform: 'scale(1)'
    },

    '& input': {
      fontSize: '1rem',
      backgroundColor: '#FFF'
    },

    '& .field.normal, & .chk-cityState__state, & .chk-cityState__city, & .chk-userAddressInternational__country': {
      margin: '4px 0 4px 0',
      fontSize: '1.125rem',
      padding: 0,

      '& input': {
        appearance: 'none',
        padding: '8px',
        border: '1px solid #d9d9d9'
      },

      '& label': {
        fontSize: '0.75rem',
        transform: 'translate(0, -4px)'
      }
    },

    '& .steps': {
      flex: 1,
      width: '100%',

      '& .chk-multiStep__productName': {
        display: 'flex',
        justifyContent: 'center',
        padding: '45px 20px 10px 20px'
      },

      '& .product-name': {
        display: 'block',
        fontSize: '16px',
        fontWeight: 'normal',
        margin: '15px 0 5px 0',
        textAlign: 'center',
        wordBreak: 'break-word',
        width: '100%'
      },

      '& .chk-logo__content': {
        position: 'absolute',
        top: '-60px',
        backgroundPosition: 'center',
        backgroundSize: '100%',
        backgroundColor: '#FFF',
        backgroundRepeat: 'no-repeat'
      },

      '& .chk-logo__content-step-payment': {
        top: '-50px'
      },

      '& .chk-logo__content-step-address': {
        top: '-50px'
      },

      '& .header': {
        padding: '8px 20px',

        '& .chk-full': {
          textAlign: 'right',

          '& .chk-number-price': {
            fontSize: '1.25rem',
            color: '#636871 !important',
            fontWeight: 700
          },

          '& .chk-full-price': {
            fontSize: '1.5rem',
            fontWeight: 600
          },

          '& .tax': {
            fontSize: '0.75rem'
          }
        },

        '& .chk-button-coupon': {
          margin: '10px 0 0 0'
        },

        '& .free': {
          textAlign: 'right',
          fontSize: '1.5rem',
          fontWeight: 700
        }
      },

      '& .security_container': {
        marginTop: 16
      },

      '& .security': {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'center',
        color: '#318834',
        fontSize: '.8rem',
        fontWeight: 600,

        '& svg': {
          width: '24px',
          fill: '#318834',
          marginRight: '10px'
        },

        '& .text': {
          '& p': {
            margin: 0,
            fontSize: '0.75rem'
          },

          '& p:last-child': {
            fontWeight: 'normal'
          }
        }
      },

      '& .grid': {
        width: '100%',
        minHeight: '150px',
        margin: '0 auto',

        '& .cpf-form': {
          marginBottom: '2%'
        },

        '& .chk-add-credit-card': {
          background: '#00b356',
          borderRadius: '5px',
          margin: '0px 0 16px 0px',
          padding: '4px 16px',
          color: '#fff',
          fontSize: '0.75rem',
          fontWeight: 500,
          textAlign: 'left',
          textTransform: 'unset',

          '& svg': {
            width: '25px',
            margin: '0 10px 0 0'
          },

          '&.add': {
            margin: '0 0 8px 0',

            '& svg': {
              width: '12px'
            }
          }
        },

        '& .add-card': {
          display: 'flex',
          justifyContent: 'center'
        },

        '& .chk-form-component': {
          margin: 0,
          padding: '20px 20px 8px 20px'
        },

        '& .chk-multiStep__productName': {
          alignItems: 'center',
          backgroundColor: '#333',
          borderRadius: '4px 4px 0 0',
          color: 'white',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-end',
          padding: '45px 20px 24px 20px',
          textAlign: 'center',

          '& h2': {
            fontWeight: 'normal',
            fontSize: '16px',
            margin: 0
          },

          '& .chk-logo__content': {
            position: 'absolute',
            top: '-60px',
            backgroundPosition: 'center',
            backgroundSize: '100%',
            backgroundColor: '#FFF',
            backgroundRepeat: 'no-repeat'
          }
        },

        '& .chk-stepUser__securityMessage': {
          alignItems: 'center',
          color: '#333',
          display: 'flex',
          fontSize: '0.75rem',
          justifyContent: 'center',
          width: '100%',
          padding: '0 0 4px 0',

          '& svg': {
            fill: '#679e2b',
            margin: '0 5px 0 0',
            width: '22px'
          }
        }
      },
      '& .step': {
        '& .chk-btnContinue, & .chk-stepUser__btnFinish, & .chk-stepUser__btnGetForFree': {
          borderRadius: '0px 0px 4px 4px',
          boxShadow: 'none',
          padding: '15px',
          fontWeight: 500,
          fontSize: '1.2rem',
          textTransform: 'unset'
        }
      },

      '& .chk-coupon': {
        '& .field.normal': {
          margin: 0
        },

        '& Button': {
          height: '39px'
        },

        '& label': {
          fontSize: '12px !important'
        }
      },

      '& .chk-stepUser__btnFinish': {
        '& svg': {
          width: '25px',
          margin: '0 4px 0 0',
          fill: '#FFF'
        }
      },

      '& .step-payment': {
        padding: '16px 20px'
      },

      '& .donation-input': {
        padding: '0 20px',
        margin: '5px 0'
      },

      '& .chk-stepPayment__pin': {
        width: '100%',
        margin: '0 0 10px 0'
      },

      '& .product-info': {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        backgroundColor: '#f8f8f8',
        padding: '20px',
        fontSize: '.8rem',

        '& .title': {
          fontSize: '1rem',
          fontWeight: 600,
          marginRight: '20px'
        },

        '& .price': {
          whiteSpace: 'nowrap',
          textAlign: 'right',

          '& span': {
            fontSize: '1.5rem',
            fontWeight: 600
          }
        }
      },

      '& .chk-payment-flags': {
        height: '27px',

        '& .chk-flag-option': {
          margin: '5px 1px',
          width: '40px',
          height: '27px',

          '&:hover': {
            opacity: 1,
            width: '40px',
            height: '35px'
          }
        }
      },

      '& .chk-payment': {
        padding: 0,

        '& .chk-paymentForm__boletoMessage, & .chk-paymentForm__paypalMessage': {
          color: '#615f61',
          fontSize: '0.8rem',
          display: 'inline-block',
          marginBottom: '24px',
          textAlign: 'center',
          maxWidth: 500,
          '& p': {
            margin: 0
          }
        },

        '& .chk-multiple-cards-header .chk-remove-card': {
          backgroundColor: '#585858',
          borderRadius: '4px'
        }
      }
    },

    '& .sidebar': {
      marginRight: '24px',
      width: '273px',

      '@media screen and (max-width: 600px)': {
        display: 'none'
      }
    },

    '& .state-container': {
      margin: '8px 0 4px 0 !important'
    },

    '& .chk-cityState__state, & .chk-cityState__city': {
      margin: '4px 0 8px 6px',

      '& label': {
        fontSize: '0.75rem',
        transform: 'translate(0, 0)'
      }
    },

    '& .chk-multiple-cards-header': {
      padding: '8px 0 0 0'
    },

    '& .step-payment': {
      '& .chk-payment:not(:first-child)': {
        '& .chk-multiple-cards-header': {
          borderTop: '1px solid #cecece'
        }
      }
    },

    '& .chk-btn-download': {
      borderRadius: '0px 0px 4px 4px',
      boxShadow: 'none',
      padding: '15px',
      fontWeight: 500,
      fontSize: '1.2rem',
      textTransform: 'capitalize'
    },

    '& .chk-subscription': {
      textAlign: 'right',

      '& .chk-subscription__text': {
        color: '#333',
        fontSize: '0.8125rem',
        fontWeight: 700,
        textTransform: 'uppercase'
      },

      '& .chk-subscription__value': {
        fontSize: '1.5rem',
        fontWeight: 700
      },

      '& .chk-subscription__frequency': {
        color: '#333'
      },

      '& .chk-subscription__frequency, & .chk-subscription__trial': {
        fontSize: '0.875rem'
      },

      '& .chk-subscription__trial': {
        padding: '4px 0',

        'span:first-child': {
          backgroundColor: '#000',
          color: '#FFF',
          cursor: 'auto',
          padding: '2px 4px'
        }
      }
    },

    '& .tooltip-email-information': {
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',

      '& .modifyEmailLabel': {
        top: '-3px'
      },

      '& .information': {
        fontSize: '9px',
        position: 'absolute',
        fontStyle: 'italic',
        color: '#868686',
        margin: '9px 0 0 0'
      }
    },

    '& .autoSuggestContainer': {
      zIndex: 10,
      position: 'absolute',
      top: '61px',

      '& .autoSuggest': {
        cursor: 'pointer',
        width: '270px',

        '& .item': {
          height: '30px',
          padding: '0 5px',

          '&:hover': {
            backgroundColor: '#f1f1f1'
          },

          '&.actualEmail': {
            backgroundColor: '#f1f1f1'
          }
        }
      }
    },

    '& .summary-cart': {
      margin: '12px 0 0 0',
      '& .title, & .content, & .value': {
        fontSize: '0.75rem'
      },
      '& .item:nth-child(even)': {
        backgroundColor: '#fff'
      }
    },

    '& .freeShipping': {
      display: 'flex',
      alignItems: 'center',
      fontSize: 12,
      color: '#009358',
      fontWeight: 'bold',
      margin: '8px 0',
      justifyContent: 'flex-end',

      '& svg': {
        width: 22,
        margin: '0 4px 0 0'
      }
    },

    '& .chk-searchCEP__button': {
      position: 'relative',
      fontWeight: 500,
      textDecoration: 'underline',
      color: '#F59211',
      fontSize: '0.75rem',
      display: 'block',
      textAlign: 'right',
      marginBottom: '-15px',
      cursor: 'pointer',
      zIndex: 1000,
      outline: 'none'
    }
  }
}))
export default class MultiStep extends BaseComponent<IState, IPayProps> {
  private history: History;

  componentDidMount() {
    this.observeState({ config: null }, cartService.getConfig());
    this.observeState({ cart: null }, cartService.getCart());
    this.bindModel(paymentService.getCart());
    this.setState({ step: enStep.user });

    currencyService
      .getCurrency()
      .bindComponent(this)
      .subscribe(() => this.setState({ step: enStep.user }));

    cartService
      .getCart()
      .filter((cart) => !cart.isCached && cart.upsell == enPaymentMethod.BANKSLIP && cart.config.productType != enProductType.PHYSICAL)
      .take(1)
      .subscribe(() => {
        this.setState({ step: enStep.payment });
        this.onPay();
      });
  }

  setStep = (step: enStep) => {
    const { config } = this.state;
    if (step == enStep.address && !config.askAddressBeforePayment) {
      step = enStep.payment;
    }

    userService.setBasicUser().subscribe();

    this.setState({ step });
  };

  onPay = () => {
    if (paymentService.hasPaymentInProgress()) {
      return;
    }

    this.props
      .onPay()
      .switchMap(() => cartService.getCart())
      .combineLatest(paymentService.shouldBlockAddressAfterPayment())
      .take(1)
      .switchMap(([cart, shouldBlockAddressAfterPayment]) => {
        if (cart.config.askAddressAfterPayment && !shouldBlockAddressAfterPayment) {
          this.setStep(enStep.addressAfter);
          return Observable.of('#');
        }

        return paymentService.getPaymentResult().map((result) => {
          if (result.redirectTo) {
            Observable.never().subscribe();

            window.location.href = result.redirectTo;
            return null;
          }

          return `/thankyou/${cart.contentId}/${result.key}`;
        });
      })
      .filter((url) => url !== null)
      .bindComponent(this)
      .subscribe((url) => {
        this.history.push(url);
      });
  };

  setHistory(history: History): any {
    this.history = history;
    return null;
  }

  render() {
    if (!this.state) {
      return '';
    }

    const { step } = this.state;
    const { cart } = this.state;

    return (
      <div className={this.props.classes.component}>
        <PayContext.Provider value={this.onPay.bind(this)}>
          {cart.config.exitModal && !paymentService.hasPayment() ? <ExitPopUp /> : ''}
          <HistoryContext.Consumer>{(h) => this.setHistory(h)}</HistoryContext.Consumer>
          <div className="steps">
            {enStep.user == step ? <StepUser setStep={this.setStep} product={cart.content} onPay={this.onPay} /> : null}
            {enStep.address == step ? <StepAddress step={this.state.step} setStep={this.setStep} onPay={this.onPay} /> : null}
            {enStep.payment == step ? <StepPayment step={this.state.step} setStep={this.setStep} onPay={this.onPay} /> : null}
            {enStep.addressAfter == step ? <AddressAfter setStep={this.setStep} history={this.history} /> : null}
          </div>
        </PayContext.Provider>
      </div>
    );
  }
}
