import { ChangeEvent, ReactInstance, ReactNode, Component } from 'react';

import { Observable } from 'rxjs/Observable';
import { RouteComponentProps } from 'react-router';
import { Subscription } from 'rxjs/Subscription';
import { IFieldValidationContext } from 'components/Field';
import FieldBase from 'components/Field/Base';

export interface IStateBase<T = any> {
  model?: Partial<T>;
  formSubmitted?: boolean;
  validation?: {
    [key: string]: string;
  };
}

export abstract class BaseComponent<S extends IStateBase<M> = any, P = any, M = any, R = any> extends Component<P, S> {
  public subscriptions: Subscription[];

  public refs: R & { [key: string]: ReactInstance };
  public props: Readonly<{ children?: ReactNode }> &
    Readonly<P> & {
      onChange?: (model: any) => void;
      onValidate?: (errors: any) => void;
      classes?: { [key: string]: any };
    } & Partial<RouteComponentProps<any>>;

  public registerFields: IFieldValidationContext = {
    bind: (field) => {
      this.fields.push(field);
    },
    unbind: (field) => {
      const index = this.fields.findIndex((f) => f === field);
      this.fields.splice(index, 1);
    },
    isValid: () => {
      return !!this.isFormValid();
    },
    isSubmitted: () => {
      return !!this.state.formSubmitted;
    },
    getValidationMessages: () => {
      return this.state.validation || {};
    },
    update: () => {}
  };

  protected fields: FieldBase<any, any>[];

  constructor(props: any) {
    super(props);
    this.subscriptions = [];
    this.fields = [];
  }

  public componentWillUnmount(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  public previousPage() {
    this.props.history.goBack();
  }

  public setState<K extends keyof S>(f: (prevState: S, props: P) => Pick<S, K>, callback?: () => any): Promise<void>;

  public setState<K extends keyof S>(state: Pick<S, K>): Promise<void>;

  public setState(state: any): Promise<void> {
    return new Promise((resolve) => {
      super.setState(state as any, () => resolve());
    });
  }

  public observeState<K extends keyof S, T>(startValue: Pick<S, K>, observable: Observable<T>): void {
    const keys = Object.keys(startValue);

    if (keys.length != 1) {
      throw new Error('You must watch only one state property at a time');
    }

    const [key] = keys;

    observable.bindComponent(this).subscribe((result) => {
      const state = {};
      state[key] = result;
      this.setState(state);
    });
  }

  public async isFormValid(submitted: boolean = true): Promise<boolean> {
    this.fields.forEach((f) => f.setFormSubmitted(submitted));

    if (!this.fields.length) {
      console.warn('There is no field registred, did you use FieldValidation.Provider?');
    }

    const isValid = this.fields.every((f) => {
      if (!f.isValid()) {
        document.getElementById(f.props.id).focus();
      }

      return f.isValid();
    });

    return isValid;
  }

  public getErrorMessage(key: string): string {
    return (this.state.validation || {})[key];
  }

  protected bindModel(observable: Observable<M>) {
    observable.bindComponent(this).subscribe((model) => this.setModel(model));
  }

  protected setModel(model: Partial<M>) {
    this.setState({ model });
  }

  protected mergeModel(model: any): void {
    this.updateModel(() => Object.assign(this.state.model, model));
  }

  protected updateModel(handler: (value: any) => void): any {
    return (event: ChangeEvent<any>) => {
      let { model } = this.state as any;

      handler(event.target ? (event.target.type === 'checkbox' ? event.target.checked : event.target.value) : event);

      this.setState({ model });

      if (this.props.onChange) {
        this.props.onChange(model);
      }
    };
  }
}
