import { IS_DEVELOPMENT } from 'settings';
import * as Sentry from '@sentry/browser';
import { translateService } from 'services/TranslateService';

export function breadcrumb(text: string, type: string = 'manual', extraData: any = {}): void {
  if (IS_DEVELOPMENT) console.log('breadcrumb: ' + type + ' - ' + text, extraData);

  extraData = extraData || {};
  delete extraData.type;

  //TODO: Breadcrumb
}

export function handleError(err: any, force: boolean = false): void {
  if (!err) return;

  if (typeof err === 'string') {
    err = new Error(err);
  }

  if (err.ignoreLog && !force) {
    return;
  }

  const code = err.data && err.data.code;

  translateService.getErrorData(code).subscribe((errorData) => {
    const shouldReport = !!errorData.shouldReport;
    const ignoredCodes = ['CAP_A'];

    if (!shouldReport || ignoredCodes.includes(code)) {
      console.log('ignoring message log, its ok');
      return;
    }

    if (IS_DEVELOPMENT) {
      console.error(err);
      err.metadata && console.log(err.metadata);
      return;
    }

    Sentry.addBreadcrumb({
      level: Sentry.Severity.Error,
      data: { extra: err.metadata }
    });
    Sentry.captureException(err);
  });
}
