import { Observable } from 'rxjs/Observable';

export class AutoSuggestService {
  keyBoard = (): Observable<string> => {
    return Observable.fromEvent(document, 'keydown')
      .filter((event: any) => event.code == 'ArrowUp' || event.code == 'ArrowDown' || event.code == 'Enter' || event.code == 'Tab')
      .map((event: any) => event.code);
  };
}
// tslint:disable-next-line:variable-name
export const AutoSuggestServiceFactory = () => new AutoSuggestService();

export const autoSuggestService = AutoSuggestServiceFactory();
