import React, { SFC } from 'react';
import Button from '@material-ui/core/Button';
import Translate from 'components/Translate';
import { SvgIcon } from 'components/SvgIcon';

interface IProps {
  message: string;
  code: string;
  idleState: () => void;
}

// tslint:disable-next-line:variable-name
export const WalletPaymentError: SFC<IProps> = (props: IProps) => {
  const { message, code, idleState } = props;

  return (
    <div className="paymentErrorMessage">
      <div className="paymentErrorIcon">
        <SvgIcon name="paypalError" />
      </div>
      <div className="defaultMessage">
        <Translate term={'Algo não está certo...'} />
      </div>
      <div className="errorMessage">
        <span>{message}</span>
      </div>
      <div className="helper">
        <div className="title">
          <Translate term={'O que você pode fazer?'} />
        </div>
        <div className="description">
          <ul>
            <li>
              <Translate term={'Consultar o saldo disponível em sua conta eduzz'} />
            </li>
            <li>
              <Translate term={'Verificar suas credenciais de acesso'} />
            </li>
          </ul>
        </div>
      </div>
      <Button
        id="error-modal-button"
        size="large"
        onClick={idleState}
        classes={{
          label: 'paymentButtonLabel'
        }}
        color="primary"
        className="paymentErrorButton theme-button"
      >
        <Translate term={'Ok, Entendi'} />
      </Button>
      <div className="code">
        <Translate term={code} />
      </div>
    </div>
  );
};
