import validator from 'validatorjs';

const validate = (value: any) => {
  if (!value || value === 'none') {
    return false;
  }
  return true;
};

validator.register('requiredSelect', validate, 'required');
