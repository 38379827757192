import React, { Fragment, useMemo } from 'react';
import { translateService } from 'services/TranslateService';
import { useObservable } from 'react-use-observable';

interface IProps {
  term: string;
  properties?: { [key: string]: any };
  className?: string;
  hasHTML?: boolean;
}

export const Translate: React.FC<IProps> = React.memo(({ term, properties, className, hasHTML = false }) => {
  const [value] = useObservable(() => translateService.getTextStream(term), [term]);

  const result = useMemo(() => {
    const property = properties || {};

    const parts: any[] = (value || '').split(/(\{[^}]+})/gi);

    const propertyMap = Object.keys(property).reduce((acc, key) => {
      acc[`{${key}}`] = property[key];
      return acc;
    }, {});

    if (hasHTML) {
      parts.forEach((word, index) => {
        propertyMap[word]
          ? (parts[index] = <span className={className} key={index} dangerouslySetInnerHTML={{ __html: propertyMap[word] }} />)
          : (parts[index] = <span className={className} key={index} dangerouslySetInnerHTML={{ __html: word }} />);
      });
    }

    if (!hasHTML) {
      parts.forEach((word, index) => {
        propertyMap[word]
          ? (parts[index] = (
              <span className={className} key={index}>
                {propertyMap[word]}
              </span>
            ))
          : (parts[index] = (
              <span className={className} key={index}>
                {word}
              </span>
            ));
      });
    }

    return parts;
  }, [value, className, hasHTML, properties]);

  return <Fragment>{result}</Fragment>;
});

export default Translate;
