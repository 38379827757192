import { BaseComponent, IStateBase } from 'components/BaseComponent';
import { ICartModel } from 'interfaces/ICartModel';
import { IProductData, IProductConfig } from 'interfaces/IProductData';
import Button from '@material-ui/core/Button/Button';
import Grid from '@material-ui/core/Grid/Grid';
import React from 'react';
import { cartService } from 'services/CartService';
import { paymentService } from 'services/PaymentService';
import { enStep, IStepProps } from './index';
import UserAddressForm from 'components/UserAddressForm';
import { FieldValidation } from 'components/Field';
import HeaderStep from './HeaderStep';
import { enPaymentType } from 'enums/enPaymentType';
import PaymentButton from 'components/Cart/PaymentButton';
import Logo from '../../Widget/Header/Logo/index';
import { Hidden } from '@material-ui/core';
import FormHeader from 'components/Cart/FormHeader';

interface IState extends IStateBase {
  cart: IProductData;
  model: ICartModel;
  config: IProductConfig;
}

export interface IProps extends IStepProps {
  onPay: () => void;
  step: enStep;
}

export default class StepAddress extends BaseComponent<IState, IProps> {
  componentDidMount() {
    this.observeState({ cart: null }, cartService.getCart());
    this.bindModel(paymentService.getCart());
    this.observeState({ config: null }, cartService.getConfig());
  }

  onValidate(errors: any) {
    this.setState({ validation: errors || {} });
  }

  isValid(): boolean {
    const validation = this.state.validation || {};
    return Object.keys(validation).length === 0;
  }

  goToNextStep = async () => {
    if (await this.isFormValid()) {
      this.props.setStep(enStep.payment);
    }
  };

  finish = async () => {
    if (await this.isFormValid()) {
      this.props.onPay();
    }
  };

  render() {
    if (!this.state) {
      return '';
    }

    const { formSubmitted, cart, config } = this.state;

    const disabledButton = !this.isValid() && formSubmitted;

    return (
      <div className="step">
        <Hidden mdUp>
          <div className="chk-multiStep__productName theme-header-bg theme-header">
            <Logo image={config.logo} optionalClasses="chk-logo__content-step-address" />
            <h2 className="product-name">{cart.content.title}</h2>
          </div>
        </Hidden>
        <FormHeader />
        <HeaderStep step={this.props.step} setStep={this.props.setStep} />
        <FieldValidation.Provider value={this.registerFields}>
          <Grid className="grid" container>
            <Grid item xs={12} className="chk-form-component">
              <UserAddressForm />
            </Grid>
            {cart.config.paymentType === enPaymentType.FREE ? (
              <PaymentButton finish={this.finish} disabledButton={disabledButton} messageButton="Obter gratuitamente" />
            ) : (
              <Button
                id="chk-next-button"
                onClick={this.goToNextStep}
                fullWidth
                variant="contained"
                size="large"
                color="primary"
                className="chk-btnContinue theme-button"
                disabled={disabledButton}
              >
                Próximo
              </Button>
            )}
          </Grid>
        </FieldValidation.Provider>
      </div>
    );
  }
}
