import Button from '@material-ui/core/Button/Button';
import Grid from '@material-ui/core/Grid/Grid';
import { BaseComponent } from 'components/BaseComponent';
import { IStepProps } from 'components/Cart/Templates/Mobile/MultiStep';
import { FieldValidation } from 'components/Field';
import { SvgIcon } from 'components/SvgIcon';
import Translate from 'components/Translate';
import UserAddressForm from 'components/UserAddressForm';
import React from 'react';
import { Observable } from 'rxjs/Observable';
import { cartService } from 'services/CartService';
import { paymentService } from 'services/PaymentService';
import { userService } from 'services/UserService';
import { WithStyles } from 'shared/decorators/withStyles';
import { Hidden } from '@material-ui/core';
import Logo from '../../Widget/Header/Logo/index';
import FormHeader from 'components/Cart/FormHeader';
import { thankYouService } from 'services/ThankYouService';
export interface IProps extends IStepProps {}

@WithStyles(() => ({
  component: {
    '& .chk-grid__addressAfter': {
      alignItems: 'center',
      display: 'flex',

      '& .icon': {
        marginRight: '20px',
        '& svg': {
          width: '60px',
          height: '60px',
          '& path': {
            fill: '#009358'
          }
        }
      },

      '& .chk-paragraph': {
        '& .title': {
          color: '#000',
          fontSize: '1em',
          textAlign: 'left',
          marginBottom: '5px'
        },

        '& .label': {
          fontSize: '0.8em',
          color: '#000',
          marginTop: 0
        }
      }
    }
  }
}))
export default class AddressAfter extends BaseComponent<any, IProps> {
  componentDidMount() {
    this.observeState({ cart: null }, cartService.getCart());
    this.observeState({ config: null }, cartService.getConfig());
  }

  finish = async () => {
    if (await this.isFormValid()) {
      userService.setBasicUser().bindComponent(this).subscribe();

      Observable.combineLatest(paymentService.getPaymentResult())
        .loader()
        .bindComponent(this)
        .subscribe(([paymentResult]) => {
          const urlThankYou = thankYouService.redirectThankYou(paymentResult);

          if (urlThankYou) {
            this.props.history.push(urlThankYou);
          }
        });
    }
  };

  render() {
    if (!this.state) {
      return null;
    }

    const { config, cart } = this.state;
    return (
      <div className="step">
        <FormHeader />
        {!!cart && (
          <Hidden mdUp>
            <div className="chk-multiStep__productName theme-header-bg theme-header">
              <Logo image={config.logo} optionalClasses="chk-logo__content-step-payment" />
              <h2 className="product-name">{cart.content.title}</h2>
            </div>
          </Hidden>
        )}
        <FieldValidation.Provider value={this.registerFields}>
          <Grid container spacing={3} className={`${this.props.classes.component} grid`}>
            <Grid item className="chk-grid__addressAfter" xs={12}>
              <div className="icon">
                <SvgIcon name="check" />
              </div>
              <div className="chk-paragraph">
                <h1 className="title theme-label" id="thankyou-title">
                  <Translate term="Obrigado pela Compra!" />
                </h1>
                <p className="label theme-label">
                  <Translate term="O seu pagamento está em processamento, complete o breve formulário abaixo para continuar" />
                </p>
              </div>
            </Grid>
            <UserAddressForm />
            <Button id="chk-addressafter-button" className="chk-btn-download theme-button" onClick={this.finish}>
              <Translate term="Concluir" />
            </Button>
          </Grid>
        </FieldValidation.Provider>
      </div>
    );
  }
}
