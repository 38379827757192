import { BaseComponent, IStateBase } from 'components/BaseComponent';
import React, { Fragment } from 'react';
import { SvgIcon } from 'components/SvgIcon';
import { enStep } from 'components/Cart/Templates/Mobile/MultiStep';
import { IStepProps } from 'components/Cart/Templates/Mobile/MultiStep';
import { cartService } from 'services/CartService';
import Translate from 'components/Translate';
import { parameterService } from 'services/ParameterService';
import { enParameters } from 'enums/enParameters';
import { validate } from 'validators';
import { IProductData } from 'interfaces/IProductData';
import { enProductType } from 'enums/enProductType';
import { WithStyles } from 'shared/decorators/withStyles';

interface IState extends IStateBase {
  askAddressBeforePayment: boolean;
  skip: number;
  cart: IProductData;
}

interface IProps extends IStepProps {
  step: enStep;
}

@WithStyles(() => ({
  component: {
    alignItems: 'center',
    cursor: 'pointer',
    display: 'flex',
    fontSize: '1rem',
    margin: '0 0 3px 0',
    '& svg': {
      margin: '0 8px 0 0',
      width: '24px'
    }
  }
}))
export default class PreviousStep extends BaseComponent<IState, IProps> {
  componentDidMount() {
    this.observeState({ cart: null }, cartService.getCart());

    parameterService
      .getAll()
      .bindComponent(this)
      .filter((params) => !!params.skip)
      .filter((params) => validate(params.name || params.nome, 'required|name|max:100').valid)
      .filter((params) => validate(params.email, 'required|email|max:150').valid)
      .filter((params) => validate(params.phone || params.cel, 'required|min:10|max:11').valid)
      .switchMap(() => parameterService.get(enParameters.skip))
      .filter((skip) => !!skip)
      .subscribe(() => {
        this.setState({
          skip: 1
        });
      });

    cartService
      .getConfig()
      .take(1)
      .bindComponent(this)
      .subscribe((config) => {
        this.setState({
          askAddressBeforePayment: config.askAddressBeforePayment
        });
      });
  }

  backStep = () => {
    this.props.setStep(this.getPreviousStep(this.props.step));
  };

  getPreviousStep = (step: enStep) => {
    switch (step) {
      case enStep.ticket:
        return enStep.user;
      case enStep.address:
        return this.state.cart.config.productType == enProductType.TICKET ? enStep.ticket : enStep.user;
      case enStep.payment:
        if (this.state.askAddressBeforePayment) {
          return enStep.address;
        } else {
          if (this.state.cart.config.productType == enProductType.TICKET) {
            return enStep.ticket;
          } else {
            return enStep.user;
          }
        }
      default:
        return step;
    }
  };

  render() {
    if (!this.state) {
      return null;
    }

    return (
      <Fragment>
        {!this.state.skip ? (
          <div id="chk-back-button" onClick={this.backStep} className={`chk-previousStep ${this.props.classes.component} theme-backStep-button`}>
            <SvgIcon name="backIcon" />
            <Translate term="Voltar" />
          </div>
        ) : null}
      </Fragment>
    );
  }
}
