import Grid from '@material-ui/core/Grid/Grid';
import { BaseComponent } from 'components/BaseComponent';
import React from 'react';
import { enShippingType } from 'enums/enShippingType';
import { SvgIcon } from 'components/SvgIcon';
import { WithStyles } from 'shared/decorators/withStyles';
import { IProductConfig } from 'interfaces/IProductData';
import { enProductType } from 'enums/enProductType';
import { Translate } from 'components/Translate';

export interface IProps {
  config: IProductConfig;
}

@WithStyles(() => ({
  component: {
    '& .freeShipping': {
      display: 'flex',
      alignItems: 'center',
      fontSize: 12,
      color: '#009358',
      fontWeight: 'bold',
      margin: '8px 0',
      justifyContent: 'flex-end',

      '& svg': {
        width: 22,
        margin: '0 4px 0 0'
      }
    }
  }
}))
export default class FreeShipping extends BaseComponent<{}, IProps> {
  render() {
    const { config } = this.props;

    if (config.shippingType !== enShippingType.FREE || config.productType !== enProductType.PHYSICAL) {
      return null;
    }

    return (
      <Grid item xs={12} className={this.props.classes.component}>
        <div className="freeShipping">
          <SvgIcon name="freeShipping" /> <Translate term="Frete Grátis" />
        </div>
      </Grid>
    );
  }
}
