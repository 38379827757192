/* eslint-disable max-lines */
import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles({
  '@global': {
    '@keyframes terminalComplete': {
      '0%': {
        transform: 'scale(0.1) rotate(-45deg)'
      },
      '50%': {
        transform: 'scale(1.3) rotate(0deg)'
      },
      '70%': {
        transform: 'scale(0.8) rotate(0deg)'
      },
      '100%': {
        transform: 'scale(1) rotate(0deg)'
      }
    }
  },
  component: {
    width: '100%',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    margin: '50px auto',
    opacity: 1,
    zIndex: 12,
    position: 'fixed',
    maxWidth: 650,
    boxShadow: '1px 1px 3px 1px rgba(156, 156, 156, 0.3)',
    maxHeight: 500,
    paddingBottom: 20,
    backgroundColor: '#fff'
  },

  modalContent: {
    backgroundColor: 'white',
    display: 'flex',
    flexFlow: 'column nowrap',
    width: '100%',
    height: '100%'
  },
  completeContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%'
  },
  iconComplete: {
    width: '150px',
    height: '150px',
    fill: '#21f369',
    animationName: 'terminalComplete',
    animationDuration: '800ms',
    animationTimingFunction: 'ease-out',
    animationDirection: 'forwards'
  },
  timerHead: {
    width: '100%',
    padding: '12px 0',
    textAlign: 'center',
    fontWeight: 700,
    backgroundColor: '#ECEFF1',

    '& span': {
      color: '#0D47A1'
    }
  },
  progressMessage: {
    fontWeight: 300,
    fontSize: '16px',
    textAlign: 'center',
    animation: 'fadeIn 0.3s ease-in-out 0.8s forwards'
  },
  cancelButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    '& .MuiButton-textSecondary': {
      color: '#f44336'
    }
  }
});
