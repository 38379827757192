/* eslint-disable max-len */
import cardValidator from 'credit-card-type';

interface brandAlias {
  [key: string]: string;
}

export class BrandService {
  private alias: brandAlias = {
    'american-express': 'amex',
    'diners-club': 'diners',
    discover: 'other',
    elo: 'elo',
    hiper: 'hipercard',
    hipercard: 'hipercard',
    jcb: 'other',
    maestro: 'mastercard',
    mastercard: 'mastercard',
    mir: 'other',
    unionpay: 'other',
    visa: 'visa',
    other: 'other'
  };

  public getCardBrandByNumber(number: string) {
    number = number.replace(/\D+/gi, '');

    const valids = cardValidator(number);

    if (valids.length > 0) {
      return this.alias[valids[0].type];
    }

    return 'other';
  }
}

//  tslint:disable-next-line:variable-name
export const BrandServiceFactory = () => new BrandService();

export const brandService = BrandServiceFactory();
