export class ServiceError<T = any> extends Error {
  public readonly ignoreLog: boolean;
  public readonly metadata: T;
  public readonly code: string;
  public readonly error: string;
  public isServiceError = true;

  constructor(message: string, metadata?: T, ignoreLog: boolean = true) {
    super(message);
    this.message = message;
    this.error = message;
    this.metadata = metadata || null;

    const md: any = this.metadata;
    this.code = md && md.response && md.response.data && md.response.data.code;
    this.ignoreLog = ignoreLog;
  }
}
