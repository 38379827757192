import { Observable } from 'rxjs/Observable';
import { BehaviorSubject } from 'rxjs';
import { enCurrency } from 'enums/enCurrency';

export class CurrencyService {
  private currency$ = new BehaviorSubject<enCurrency>(enCurrency.BRL);
  private availableCurrencies$ = new BehaviorSubject<enCurrency[]>([enCurrency.BRL]);

  public getIsInternacional() {
    return this.availableCurrencies$.value.length > 1;
  }

  public setAvailableCurrencies(currencies: enCurrency[]) {
    this.availableCurrencies$.next(currencies);
  }

  public setCurrency(currency: enCurrency) {
    const availableCurrencies = this.availableCurrencies$.value;

    if (availableCurrencies.includes(currency)) {
      this.currency$.next(currency);
      return;
    }
  }

  public getCurrency(): Observable<enCurrency> {
    return this.currency$.asObservable().distinctUntilChanged();
  }
}

// tslint:disable-next-line:variable-name
export const CurrencyServiceFactory = () => new CurrencyService();

export const currencyService = CurrencyServiceFactory();
