import { BaseComponent, IStateBase } from 'components/BaseComponent';
import UserForm from 'components/Cart/MainForm/UserForm';
import { SvgIcon } from 'components/SvgIcon';
import { ICartModel } from 'interfaces/ICartModel';
import { IProductConfig, IProductData } from 'interfaces/IProductData';
import Button from '@material-ui/core/Button/Button';
import Grid from '@material-ui/core/Grid/Grid';
import React from 'react';
import { cartService } from 'services/CartService';
import { paymentService } from 'services/PaymentService';
import { enStep, IStepProps } from './index';
import { FieldValidation } from 'components/Field';
import Footer from '../../../Footer';
import Translate from 'components/Translate';
import { enPaymentType } from 'enums/enPaymentType';
import { parameterService } from 'services/ParameterService';
import { validate } from 'validators';
import { enProductType } from 'enums/enProductType';
import StuddentForm from 'components/Cart/MainForm/StuddentForm';
import HeaderTitle from './HeaderTitle';
import HeaderStep from './HeaderStep';
import Alert from 'shared/components/Alert';
import { enParameters } from 'enums/enParameters';
import FormHeader from 'components/Cart/FormHeader';

interface IState extends IStateBase {
  cart: IProductData;
  config: IProductConfig;
  model: ICartModel;
  changeCard: boolean;
}

export interface IProps extends IStepProps {
  onPay: () => void;
}

export default class StepUser extends BaseComponent<IState, IProps> {
  constructor(props: any) {
    super(props);

    cartService
      .getCart()
      .map((cart) => cart.config)
      .filter((config) => config.paymentType !== enPaymentType.FREE || config.askAddressBeforePayment)
      .switchMap(() => parameterService.getAll())
      .bindComponent(this)
      .filter((params) => !!params.skip)
      .filter((params) => validate(params.name || params.nome, 'required|name|max:100').valid)
      .filter((params) => validate(params.email, 'required|email|max:150').valid)
      .filter((params) => validate(params.phone || params.cel, 'required|min:10|max:11').valid)
      .subscribe(() => {
        this.goToNextStep();
      });
  }

  componentDidMount() {
    this.observeState({ cart: null }, cartService.getCart());
    this.observeState({ config: null }, cartService.getConfig());
    this.bindModel(paymentService.getCart());
    parameterService.get(enParameters.changeCard).subscribe((changeCard) => this.setState({ changeCard: Boolean(changeCard) }));
  }

  onValidate(errors: any) {
    this.setState({ validation: errors || {} });
  }

  isValid(): boolean {
    const validation = this.state.validation || {};
    return Object.keys(validation).length === 0;
  }

  goToNextStep = async () => {
    const { setStep } = this.props;
    const { cart } = this.state;

    if (await this.isFormValid()) {
      cart.config.productType == enProductType.TICKET ? setStep(enStep.ticket) : setStep(enStep.address);
    }
  };

  getForFree() {
    const { cart } = this.state;

    return cart.config.paymentType === enPaymentType.FREE && !cart.config.askAddressBeforePayment;
  }

  finish = async () => {
    if (await this.isFormValid()) {
      this.props.onPay();
    }
  };

  render() {
    if (!this.state) {
      return null;
    }

    const { formSubmitted, config, changeCard } = this.state;
    const disabledButton = !this.isValid() && formSubmitted;

    return (
      <div className="step">
        <FormHeader />
        <FieldValidation.Provider value={this.registerFields}>
          <Grid className="grid" container spacing={1}>
            {changeCard && <Alert message="Atenção! Este checkout irá alterar a sua forma de pagamento das próximas assinaturas." />}
            <HeaderTitle shouldRenderModal={true} />
            <HeaderStep setStep={this.props.setStep} />
            <Grid item xs={12} className="chk-stepUser__userForm">
              {config.ead ? <StuddentForm /> : <UserForm />}
            </Grid>
            <Grid item xs={12} className="chk-stepUser__btnContinueGrid">
              {this.getForFree() ? (
                <Button
                  id="chk-free-button"
                  onClick={this.finish}
                  fullWidth
                  variant="contained"
                  size="large"
                  color="primary"
                  className="chk-stepUser__btnContinue theme-button"
                >
                  <Translate term={'Obter gratuitamente'} />
                </Button>
              ) : (
                <Button
                  id="chk-next-button"
                  onClick={this.goToNextStep}
                  fullWidth
                  variant="contained"
                  size="large"
                  color="primary"
                  className="chk-stepUser__btnContinue theme-button"
                  disabled={disabledButton}
                >
                  <Translate term="Continuar" />
                </Button>
              )}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <div className="footer">
              <div className="security">
                <SvgIcon name="security" />
                <div className="text">
                  <p>
                    <Translate term="Compra" />
                    <Translate term=" 100% Segura" />
                  </p>
                  <p>{<Translate term="Verificado e protegido" />}</p>
                </div>
              </div>
              <div className="links">
                <Footer hiddenFeeInstallments />
              </div>
            </div>
          </Grid>
        </FieldValidation.Provider>
      </div>
    );
  }
}
