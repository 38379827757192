import { makeStyles } from '@material-ui/core/styles';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useObservable } from 'react-use-observable';
import { cartService } from 'services/CartService';
import { pagDivididoService } from 'services/PagDivididoService';
import ValidationHOC from 'shared/components/ValidationHOC';
import { useMappedObservable } from 'shared/hooks/useMappedObservable';

import Address from './Steps/Address';
import Intro from './Steps/Intro';
import PersonalInfo from './Steps/PersonalInfo';
import Simulation from './Steps/Simulation';
import Summary from './Steps/Summary';

export enum enPagdivididoSteps {
  INTRO = 'intro',
  PERSONAL_INFO = 'personal_info',
  SIMULATION = 'simulation',
  ADDRESS = 'address',
  SUMMARY = 'summary'
}

interface IPagDivididoContext {
  skipAddressStep: boolean;
  onSetStep: (step: enPagdivididoSteps) => void;
  onBack: () => void;
  getStepNumber: (step: enPagdivididoSteps) => number;
  validateParent: (submit: boolean) => Promise<boolean>;
}

export const PagDivididoContext = React.createContext<IPagDivididoContext>({
  skipAddressStep: false,
  onSetStep: () => {},
  onBack: () => {},
  getStepNumber: () => 0,
  validateParent: () => Promise.resolve(false)
});

interface IProps {
  validateForm: (submit: boolean) => Promise<boolean>;
}

const useStyles = makeStyles({
  component: {
    color: '#37474F',
    '& .next-step-button': {
      fontWeight: 'normal',
      textTransform: 'none',
      padding: '10px 24px',
      marginBottom: 8,

      '&.back': {
        marginRight: '16px'
      },

      '&:disabled, &[disabled]': {
        backgroundColor: 'rgba(0, 0, 0, 0.26) !important',
        color: 'red'
      }
    },
    '& .step-title': {
      fontSize: '16px',
      '& span': {
        marginRight: '16px',
        textAlign: 'center',
        backgroundColor: '#CDEBFA',
        display: 'inline-block',
        lineHeight: '30px',
        height: '30px',
        width: '30px',
        borderRadius: '50%'
      }
    }
  }
});

const PagDividido: React.FC<IProps> = ({ validateForm }) => {
  const classes = useStyles({});

  const [step, setStep] = useState<enPagdivididoSteps>(enPagdivididoSteps.INTRO);
  const [stepList, setStepList] = useState([
    enPagdivididoSteps.INTRO,
    enPagdivididoSteps.PERSONAL_INFO,
    enPagdivididoSteps.SIMULATION,
    enPagdivididoSteps.ADDRESS,
    enPagdivididoSteps.SUMMARY
  ]);

  const [pagDivididoData] = useObservable(() => pagDivididoService.getEstimate(), []);

  const [skipAddressStep] = useMappedObservable(
    () => cartService.getCart(),
    (cart) => {
      return !!cart.config.askAddressBeforePayment;
    },
    []
  );

  const onSetStep = useCallback(
    (newStep: enPagdivididoSteps) => {
      setStep(newStep);
    },
    [setStep]
  );

  const onBack = useCallback(() => {
    const currentStepIndex = stepList.indexOf(step);
    const nextStep = stepList[currentStepIndex - 1];
    if (!nextStep) {
      return;
    }
    setStep(nextStep);
  }, [step, setStep, stepList]);

  useEffect(() => {
    if (skipAddressStep && step === enPagdivididoSteps.ADDRESS) {
      setStep(enPagdivididoSteps.SUMMARY);
      setStepList([enPagdivididoSteps.INTRO, enPagdivididoSteps.PERSONAL_INFO, enPagdivididoSteps.SIMULATION, enPagdivididoSteps.SUMMARY]);
      return;
    }
  }, [skipAddressStep, step]);

  const getStepNumber = useCallback(
    (step: enPagdivididoSteps) => {
      return stepList.indexOf(step);
    },
    [stepList]
  );

  const simulationId = pagDivididoData?.simulateId ?? '';
  useObservable(() => pagDivididoService.watch(), []);

  useEffect(() => {
    const currentStepId = getStepNumber(step);
    const simulateStepId = getStepNumber(enPagdivididoSteps.SIMULATION);

    if (currentStepId > simulateStepId) {
      setStep(enPagdivididoSteps.SIMULATION);
    }
  }, [simulationId, getStepNumber, step]);

  const contextValue = useMemo<IPagDivididoContext>(
    () => ({
      skipAddressStep,
      onSetStep,
      onBack,
      getStepNumber,
      validateParent: validateForm
    }),
    [onSetStep, onBack, skipAddressStep, validateForm, getStepNumber]
  );

  return (
    <div className={classes.component}>
      <ValidationHOC>
        <PagDivididoContext.Provider value={contextValue}>
          {step === enPagdivididoSteps.INTRO && <Intro />}
          {step === enPagdivididoSteps.PERSONAL_INFO && <PersonalInfo />}
          {step === enPagdivididoSteps.SIMULATION && <Simulation />}
          {step === enPagdivididoSteps.ADDRESS && <Address />}
          {step === enPagdivididoSteps.SUMMARY && <Summary />}
        </PagDivididoContext.Provider>
      </ValidationHOC>
    </div>
  );
};

export default React.memo(PagDividido);
