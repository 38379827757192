import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Observable } from 'rxjs/Observable';
import { IPaymentStatus } from 'interfaces/IPaymentStatus';
import { enPaymentStatus } from 'enums/enPaymentStatus';

export class PaymentProgressService {
  private paymentProgressStatus$ = new BehaviorSubject<IPaymentStatus>({ status: enPaymentStatus.IDLE });

  public setPaymentProgressStatus(paymentStatus: IPaymentStatus): void {
    this.paymentProgressStatus$.next(paymentStatus);
  }

  public getPaymentProgressStatus(): Observable<IPaymentStatus> {
    return this.paymentProgressStatus$.asObservable();
  }
}

export const paymentProgressServiceFactory = () => new PaymentProgressService();

export const paymentProgressService = paymentProgressServiceFactory();
