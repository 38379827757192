import React from 'react';
import Paper from '@material-ui/core/Paper/Paper';
import Typography from '@material-ui/core/Typography/Typography';
import { BaseComponent, IStateBase } from 'components/BaseComponent';
import { autoSuggestService } from 'services/AutoSuggestService';
import { paymentService } from 'services/PaymentService';

interface IState extends IStateBase {
  suggestions: Array<string>;
  actualEmail: number;
}

interface IProps {
  autoComplete: any;
  email: string;
}
export default class SuggestEmail extends BaseComponent<IState, IProps> {
  constructor(props: any) {
    super(props);
    this.state = {
      suggestions: ['@gmail.com', '@hotmail.com', '@yahoo.com', '@msn.com', '@outlook.com', '@bol.com.br'],
      actualEmail: 0
    };
  }

  componentDidMount() {
    autoSuggestService
      .keyBoard()
      .bindComponent(this)
      .subscribe((keyCode) => {
        this.moveUpDown(keyCode);
      });

    paymentService
      .getCart()
      .bindComponent(this)
      .subscribe(() => this.setState({ actualEmail: 0 }));
  }

  moveUpDown(keyCode: string) {
    const langth = this.state.suggestions.filter((suggestion) => suggestion.includes(this.props.email)).length - 1;
    const actualEmail = this.state.actualEmail;
    if (keyCode == 'ArrowUp') {
      if (actualEmail > 0) {
        this.setState({ actualEmail: actualEmail - 1 });
      }
    }
    if (keyCode == 'ArrowDown') {
      if (actualEmail < langth) {
        this.setState({ actualEmail: actualEmail + 1 });
      }
    }

    if (keyCode == 'Enter' || keyCode == 'Tab') {
      const suggestion = this.state.suggestions.filter((suggestion) => suggestion.includes(this.props.email))[actualEmail];
      if (suggestion) {
        this.props.autoComplete(suggestion);
      }
    }
  }
  render() {
    const { autoComplete, email } = this.props;
    const { suggestions, actualEmail } = this.state;

    return (
      <div className="autoSuggestContainer">
        <Paper className={'autoSuggest'}>
          {suggestions
            .filter((suggestion) => suggestion.includes(email))
            .map((suggestion, key) => {
              return (
                <Typography key={key} className={`item ${actualEmail == key ? 'actualEmail' : null}`} onClick={autoComplete.bind(this, suggestion)}>
                  {suggestion}
                </Typography>
              );
            })}
        </Paper>
      </div>
    );
  }
}
