import React, { SFC } from 'react';
import { getCdnImage } from 'functions';

interface IProps {
  logoUrl: string;
  title: string;
}

// tslint:disable-next-line:variable-name
export const HeaderProductLogo: SFC<IProps> = (props) => {
  const image = getCdnImage(props.logoUrl);

  return (
    <section className="chk-sessionProductLogo">
      <section className="chk-headerProductLogo">
        {image && <img alt="" src={image} />}
        <div className="title">{props.title}</div>
      </section>
    </section>
  );
};
