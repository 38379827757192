import React from 'react';
import { useObservable } from 'react-use-observable';
import { paymentWaiter } from 'services/PaymentWaiterService';
import { PixTimerCode } from '../PixTimerCode';
import { PixCodeCopyPaste } from '../PixCodeCopyPaste';
import { PixCloseModal } from '../PixCloseModal';
import { PixPanelProductImage } from '../PixPanelProductImage/index';
import { PixPanelHeaderText } from '../PixPanelHeaderText/index';

const Component: React.FC = () => {
  const [waiter] = useObservable(() => paymentWaiter.getWaiter(), []);

  if (!waiter?.result?.url) {
    return null;
  }

  return (
    <div className="chk-pix-panel">
      <div className="chk-payment-painel pix chk-pix-modal-container">
        <PixTimerCode />
        <div className="chk-pix-modal-content">
          <h3 className="chk-pix-modal-title">Falta pouco para concluir seu pedido!</h3>
          <div className="chk-pix-product-container">
            <PixPanelProductImage />
            <PixPanelHeaderText />
          </div>
          <div className="chk-pix-instructions-container">
            Para realizar o pagamento da sua compra via Pix:
            <ul className="chk-pix-instructions-list">
              <li>
                Copie o código Pix abaixo:
                <PixCodeCopyPaste pixCode={waiter.result.url} />
              </li>
              <li>Acesse sua conta bancária no Internet Banking ou através do app;</li>
              <li>
                Escolha pagar via <b>Pix Copia e Cola</b>;
              </li>
              <li>Cole o código Pix que você copiou e finalize o pagamento.</li>
            </ul>
          </div>
          <PixCloseModal />
        </div>
      </div>
    </div>
  );
};

export const MobilePixPaymentPanel = React.memo(Component);
