import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';

import { BaseComponent, IStateBase } from 'components/BaseComponent';
import { shippingService } from 'services/ShippingService';
import { IShippingData, IShipping } from 'interfaces/IProductData';
import { CurrencyFormat } from 'components/CurrencyFormat';
import Translate from 'components/Translate';
import { cartService } from 'services/CartService';
import { WithStyles } from 'shared/decorators/withStyles';

interface IState extends IStateBase {
  selected: string;
  model: IShippingData;
}

@WithStyles(() => ({
  component: {
    '& .chk-form-shipping': {
      padding: '10px 0',
      '& .chk-shipping-form-control-root': {
        height: '28px'
      },
      '& .chk-shipping-form-control': {
        width: '100%',
        whiteSpace: 'normal'
      },
      '& .right': {
        textAlign: 'right'
      }
    }
  }
}))
export default class ShippingForm extends BaseComponent<IState> {
  componentDidMount() {
    shippingService
      .getShipping()
      .distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b))
      .bindComponent(this)
      .subscribe((model) => {
        if (model.shippingItems.length === 0) {
          this.setModel(model);
          this.setState({ selected: null });
          return;
        }

        this.setModel(model);
        this.setState({ selected: model.shippingItems[0].code });
      });
  }

  handleChange = (event: any) => {
    const selectedShipping = this.state.model.shippingItems.find((data: IShipping) => data.code === event.target.value);
    this.setState({ selected: selectedShipping.code });

    cartService.setShipping(this.state.model.hash, selectedShipping);
  };

  render() {
    if (!this.state) {
      return <div />;
    }

    const { shippingItems } = this.state.model;

    if (shippingItems.length === 0) {
      return null;
    }

    return (
      <div className={this.props.classes.component}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <div className="chk-form-shipping">
              <RadioGroup id="radioGroup" aria-label="shipping" name="shipping" value={this.state.selected} onChange={this.handleChange}>
                {shippingItems.map((method) => (
                  <FormControlLabel
                    key={method.code}
                    value={method.code}
                    control={<Radio checked={this.state.selected == method.code} id={`shipping-radio-${method.code}`} />}
                    classes={{
                      root: 'chk-shipping-form-control-root',
                      label: 'chk-shipping-form-control'
                    }}
                    label={
                      <Grid container spacing={1}>
                        <Grid item xs={4}>
                          {method.title}
                        </Grid>
                        <Grid item xs={4}>
                          {method.value !== 0 ? <CurrencyFormat value={method.value} /> : ' '}
                        </Grid>
                        {method.deadline && (
                          <Grid item xs={4} className="right">
                            {method.deadline}&nbsp;
                            {method.deadline === 1 ? <Translate term="dia" /> : <Translate term="dias" />}
                          </Grid>
                        )}
                      </Grid>
                    }
                  />
                ))}
              </RadioGroup>
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }
}
