import React from 'react';
import { BaseComponent, IStateBase } from 'components/BaseComponent';
import { legacyPixelService } from 'services/LegacyPixelService';

interface IState extends IStateBase {
  pixel: string;
  addAffiliatePixel: string;
}

export default class LegacyPixel extends BaseComponent<IState> {
  constructor(props: any) {
    super(props);

    this.state = {
      pixel: '',
      addAffiliatePixel: ''
    };
  }

  componentDidMount() {
    legacyPixelService
      .getPixel()
      .bindComponent(this)
      .subscribe((pixel) => {
        this.setState({ pixel });
      });

    legacyPixelService
      .getParameterPixel()
      .take(1)
      .subscribe((addAffiliatePixel) => {
        this.setState({ addAffiliatePixel });
      });
  }

  render() {
    if (!this.state.pixel) {
      return null;
    }

    const { pixel, addAffiliatePixel } = this.state;

    return (
      <div style={{ display: 'none' }} id="pixel-container">
        {pixel && <iframe id="pixel-load-trackers" src={pixel} width="1" height="1" frameBorder="0" />}
        {addAffiliatePixel && <iframe id="pixel-mark-affiliate" src={addAffiliatePixel} width="1" height="1" frameBorder="0" />}
      </div>
    );
  }
}
