import * as React from 'react';

import { BaseComponent, IStateBase } from 'components/BaseComponent';
import { IPaymentModel } from 'interfaces/IPaymentModel';
import { enPaymentMethod } from 'enums/enPaymentMethod';
import CreditCardForm from './CreditCardForm';
import { paymentService } from 'services/PaymentService';
import Translate from 'components/Translate';
import EduzzWallet from './EduzzWallet/index';
import PagDividido from './PagDividido';
import PaymentTerminal from './PaymentTerminal';

interface IState extends IStateBase<IPaymentModel> {
  model: IPaymentModel;
}

interface IProps {
  index: number;
  validateForm: (submit: boolean) => Promise<boolean>;
}

export default class PaymentForm extends BaseComponent<IState, IProps> {
  componentDidMount() {
    this.bindModel(paymentService.getPayment(this.props.index));
  }

  render() {
    if (!this.state) {
      return <div />;
    }

    const { model } = this.state;

    if ([enPaymentMethod.TERMINAL].indexOf(model.paymentMethod) >= 0) {
      return <PaymentTerminal />;
    }

    if ([enPaymentMethod.PAYPAL].indexOf(model.paymentMethod) >= 0) {
      return (
        <span className="chk-paymentForm__paypalMessage">
          <p>
            <Translate term="Você será redirecionado para o Paypal para concluir o pagamento." />
          </p>
          <p>
            <Translate term="Pagamentos via Paypal somente à vista." />
          </p>
        </span>
      );
    }

    if ([enPaymentMethod.BANKSLIP, enPaymentMethod.PIX].indexOf(model.paymentMethod) >= 0) {
      const msg1 = 'Depois do pagamento, fique atento ao seu e-mail para receber os dados de acesso ao produto (verifique também a caixa de SPAM).';
      return (
        <div className="chk-paymentForm__boletoMessage">
          <p style={{ fontSize: '1.0em' }}>
            <Translate term={msg1} />
          </p>
        </div>
      );
    }

    if ([enPaymentMethod.EDUZZ].indexOf(model.paymentMethod) >= 0) {
      return <EduzzWallet />;
    }

    if ([enPaymentMethod.PAGDIVIDIDO].indexOf(model.paymentMethod) >= 0) {
      return <PagDividido validateForm={this.props.validateForm} />;
    }

    return <CreditCardForm index={this.props.index} />;
  }
}
