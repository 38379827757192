/* eslint-disable max-lines */
import * as React from 'react';

import { BaseComponent, IStateBase } from 'components/BaseComponent';
import { IPaymentModel } from 'interfaces/IPaymentModel';
import Grid from '@material-ui/core/Grid';
import Installments from './Installments';
import { paymentService } from 'services/PaymentService';
import FieldText from 'components/Field/Text';
import Translate from 'components/Translate';
import { SvgIcon } from 'components/SvgIcon';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';
import Fade from '@material-ui/core/Fade/Fade';
import Checkbox from '@material-ui/core/Checkbox/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel/FormControlLabel';
import { cartService } from 'services/CartService';
import { getCdnImage } from 'functions';
import { currencyService } from 'services/CurrencyService';
import { Hidden } from '@material-ui/core';
import { parameterService } from 'services/ParameterService';
import { enParameters } from 'enums/enParameters';
import cardValidator from 'credit-card-type';
import { enCurrency } from 'enums/enCurrency';
import { enPersonType } from 'enums/enPersonType';

interface IState extends IStateBase {
  model: IPaymentModel;
  showValueField: boolean;
  currency: enCurrency;
  showCardDocument: boolean;
  personType: string;
  changeCard: boolean;
  cvvLimit: { max: number; min: number };
}

interface IProps {
  index: number;
}

export default class CreditCardForm extends BaseComponent<IState, IProps> {
  componentDidMount() {
    this.observeState({ currency: undefined }, currencyService.getCurrency());
    this.setState({ cvvLimit: { max: 4, min: 3 } });

    parameterService.get(enParameters.changeCard).subscribe((changeCard) => this.setState({ changeCard: Boolean(changeCard) }));

    this.bindModel(paymentService.getPayment(this.props.index));

    this.showValueField();

    paymentService.handleChangeSaveCard(this.props.index, true);

    paymentService
      .getCart()
      .bindComponent(this)
      .subscribe((cart) => this.setState({ personType: cart.personType }));

    cartService
      .getCart()
      .bindComponent(this)
      .subscribe((cart) => this.setState({ showCardDocument: cart.config.showCardDocument }));
  }

  showValueField = () => {
    paymentService
      .getCart()
      .bindComponent(this)
      .subscribe((cart) => {
        this.setState({ showValueField: cart.payment.length > 1 });
      });
  };

  changeValue = (value: any) => {
    value = Number(value.replace(/\D+/gi, '')) / 100;
    paymentService.setPaymentData(this.props.index, { value });
  };

  setCreditCardNumber = (creditCard: any) => {
    paymentService.setPaymentData(this.props.index, { creditCard });
    this.validateCvv();
  };

  setExpires = (expires: any) => {
    paymentService.setPaymentData(this.props.index, { expires });
  };

  setCvv = (cvv: any) => {
    paymentService.setPaymentData(this.props.index, { cvv });
  };

  setName = (name: any) => {
    paymentService.setPaymentData(this.props.index, { name });
  };

  setSaveCard = (saveCard: any) => {
    const { changeCard } = this.state;
    if (changeCard) {
      paymentService.handleChangeSaveCard(this.props.index, changeCard);
      return;
    }
    paymentService.handleChangeSaveCard(this.props.index, saveCard.target.checked);
  };

  setDocument = (document: string) => {
    paymentService.setData({ document });
    paymentService.setPaymentData(this.props.index, { document });
  };

  validateCvv = () => {
    const { model } = this.state;
    const cardInfo = cardValidator(model.creditCard);

    if (cardInfo.length === 0) {
      return;
    }
    this.setState({ cvvLimit: { max: cardInfo[0].code.size, min: cardInfo[0].code.size } });
  };

  render() {
    if (!this.state) {
      return null;
    }

    const { model, personType, showValueField, showCardDocument, changeCard, cvvLimit } = this.state;
    const { index } = this.props;

    let method = model.brand;

    if (!method || method === 'other') {
      method = 'creditCard';
    }

    const documentRule = (model.document || '').length > 11 ? 'cnpj' : 'cpf';
    const showCheckboxOneClickBuy = !changeCard && personType !== enPersonType.FOREIGNER;

    return (
      <div>
        <Grid alignItems="stretch" container spacing={1}>
          <Grid item xs={12}>
            <div className="brand-icon">
              <SvgIcon name={`pm_${method}`} />
            </div>
            <FieldText
              id={`credit-card-number-${index}`}
              label={<Translate term="Número do Cartão" />}
              validation="required|unique_card|card_number|numeric"
              value={model.creditCard}
              mask="creditCard"
              inputProps={{
                pattern: '[0-9]*',
                inputMode: 'numeric',
                className: 'theme-input credit-card-number'
              }}
              InputLabelProps={{
                className: 'theme-label',
                id: 'label-credit-card-number'
              }}
              onChange={this.setCreditCardNumber}
            />
          </Grid>

          <Grid item xs={6} sm={7}>
            <FieldText
              id={`credit-card-expires-${index}`}
              label={<Translate term="Validade (MM/AA)" />}
              validation="required|card_validity"
              value={model.expires}
              mask="monthYear"
              inputProps={{
                pattern: '[0-9]*',
                inputMode: 'numeric',
                className: 'theme-input'
              }}
              InputLabelProps={{
                className: 'theme-label',
                id: 'label-card-validity'
              }}
              onChange={this.setExpires}
            />
          </Grid>
          <Grid item xs={6} sm={5}>
            <div className="tooltip-card">
              <Hidden xsDown>
                <Tooltip
                  id={`tooltip-cvv-card-${index}`}
                  placement="top"
                  classes={{
                    popper: 'card-popper',
                    tooltip: 'card-tooltip'
                  }}
                  disableTouchListener={true}
                  TransitionComponent={Fade}
                  TransitionProps={{ timeout: 600 }}
                  title={
                    <div
                      style={{
                        width: '120px',
                        height: '70px',
                        backgroundImage: `url(${getCdnImage('/sun/assets/img/creditcard-cvv.png')})`
                      }}
                    />
                  }
                >
                  <div className="question-mark theme-button">?</div>
                </Tooltip>
              </Hidden>
              <FieldText
                id={`credit-card-cvv-${index}`}
                label={<Translate term="Cod Segurança" />}
                validation={`required|min:${cvvLimit.min}|max:${cvvLimit.max}`}
                value={model.cvv}
                mask="cvv"
                inputProps={{
                  minLength: 3,
                  maxLength: 4,
                  className: 'theme-input',
                  pattern: '[0-9]*',
                  inputMode: 'numeric'
                }}
                InputLabelProps={{
                  className: 'theme-label',
                  id: 'label-security-code'
                }}
                onChange={this.setCvv}
              />
            </div>
          </Grid>

          <Grid item xs={12}>
            <FieldText
              id={`credit-card-name-${index}`}
              label={<Translate term="Nome impresso no cartão" />}
              validation="required"
              value={model.name}
              mask="name"
              inputProps={{
                className: 'theme-input'
              }}
              InputLabelProps={{
                className: 'theme-label',
                id: 'label-credit-card-name'
              }}
              onChange={this.setName}
            />
          </Grid>

          {!!showValueField && (
            <Grid item xs={12}>
              <FieldText
                id={`credit-card-value-${index}`}
                label={<Translate term="Valor utilizado neste cartão" />}
                validation="required"
                value={model.value}
                mask="money"
                inputProps={{
                  pattern: '[0-9]*',
                  inputMode: 'numeric',
                  className: 'theme-input'
                }}
                InputLabelProps={{
                  className: 'theme-label'
                }}
                onChange={this.changeValue}
              />
            </Grid>
          )}
          {showCardDocument ? (
            <Grid item xs={12}>
              <FieldText
                id={`credit-card-document-${index}`}
                label={<Translate term="CPF ou CNPJ do Titular" />}
                validation={personType !== 'E' ? 'required|' + documentRule : 'required|min:3|max:32'}
                value={model.document}
                mask={personType != 'E' ? 'cpfCnpj' : null}
                inputProps={{
                  className: 'theme-input',
                  pattern: '[0-9]*',
                  inputMode: 'numeric',
                  maxLength: 100
                }}
                InputLabelProps={{
                  className: 'theme-label',
                  id: 'label-credit-card-document'
                }}
                onChange={this.setDocument}
              />
            </Grid>
          ) : null}

          <Installments index={this.props.index} />

          {showCheckboxOneClickBuy && (
            <Grid className="chk-creditCardForm__saveCard checkbox_singleStep" item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    id={`chk-savecard-button-${index}`}
                    className="checkbox"
                    value="1"
                    checked={showCheckboxOneClickBuy ? model.saveCard : false}
                    onChange={(checked) => this.setSaveCard(checked)}
                  />
                }
                label={<Translate term="Usar esses dados nas próximas compras" />}
              />
            </Grid>
          )}
        </Grid>
      </div>
    );
  }
}
