import io from 'socket.io-client';
import * as Sentry from '@sentry/browser';
import { WEBSOCKET_SERVER, WEBSOCKET_PATH, STANDALONE_MODE, PREVIEW_MODE } from '../settings';
import { contentDeliveryService, IMessage } from './ContentDeliveryService';

export class SocketService {
  private socket: SocketIOClient.Socket;

  private eventCache: any[] = [];

  constructor(io: SocketIOClientStatic) {
    if (!WEBSOCKET_SERVER || STANDALONE_MODE || PREVIEW_MODE) {
      return;
    }

    if (window.location.href.includes('noSocket')) {
      return;
    }

    setTimeout(() => {
      this.socket = io.connect(WEBSOCKET_SERVER, {
        path: WEBSOCKET_PATH
      });

      this.eventCache.forEach((item) => {
        this.socket.emit(item[0], item[1]);
      });

      this.socket.on('welcome', () => {
        Sentry.addBreadcrumb({
          message: 'Socket connected',
          category: 'init',
          level: Sentry.Severity.Info,
          data: null
        });
      });

      this.socket.on('delivered', (data: IMessage) => {
        contentDeliveryService.set(data);
      });
    }, 4000);
  }

  init() {}

  public emit(event: string, args: any) {
    if (!this.socket) {
      this.eventCache.push([event, args]);
      return;
    }

    this.socket.emit(event, args);
  }
}

// tslint:disable-next-line:variable-name
export const SocketServiceFactory = () => new SocketService(io);

export const socketService = SocketServiceFactory();
export default socketService;
