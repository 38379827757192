import React from 'react';
import { BaseComponent, IStateBase } from 'components/BaseComponent';
import { WithStyles } from 'shared/decorators/withStyles';
import { SvgIcon } from 'components/SvgIcon';
import { configService } from 'services/ConfigService';
import { timerService } from 'services/TimerService';
import { cartService } from 'services/CartService';
import { PREVIEW_MODE } from 'settings';
import IShortageTimer from 'interfaces/IShortageTimer';
import { checkLocalStorage } from 'functions';

interface IState extends IStateBase {
  initialTimer: number;
  timer: number;
  minutes: any;
  seconds: any;
  isTimeOver: boolean;
  contentId: string;
  shortageTimer: IShortageTimer;
  bodyClassApplied: boolean;
}

@WithStyles(() => ({
  component: {
    position: 'fixed',
    width: '100%',
    zIndex: 11,
    color: '#FFF',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 65,

    '&.scarcity-timer': {
      backgroundColor: 'rgba(255, 152, 0, .8)'
    },

    '& .time': {
      fontWeight: 'bold',
      fontSize: '1.8rem',
      margin: '0 18px',
      '@media screen and (max-width: 600px)': {
        fontSize: '1.2rem'
      }
    },

    '& .timerDescription': {
      color: '#FFF',
      display: 'flex',
      alignItems: 'center',
      '@media screen and (max-width: 600px)': {
        maxWidth: '50%'
      },

      '& .text': {
        maxWidth: 280,
        '@media screen and (max-width: 600px)': {
          fontSize: '0.6rem'
        }
      },

      '& svg': {
        fill: '#FFF',
        width: 45,
        margin: '8px 10px 0 0',
        '@media screen and (max-width: 600px)': {
          width: '35px'
        }
      }
    }
  }
}))
export class Timer extends BaseComponent<IState, {}> {
  constructor(props: any) {
    super(props);
    this.state = {
      contentId: null,
      timer: 0,
      minutes: '',
      seconds: '',
      isTimeOver: false,
      initialTimer: 0,
      shortageTimer: null,
      bodyClassApplied: false
    };
  }

  componentDidMount() {
    cartService
      .getCart()
      .take(1)
      .filter((cart) => !!cart.contentId)
      .map((cart) => cart.contentId)
      .combineLatest(
        configService
          .getConfig()
          .filter((config) => !!config.template.styles.shortageTimer)
          .map((config) => config.template.styles.shortageTimer)
      )
      .bindComponent(this)
      .subscribe(([contentId, shortageTimer]) => {
        let localStorageKey: string = `${contentId}-sun-scarcity-time`;
        let resetTime = false;

        if (PREVIEW_MODE) {
          localStorageKey = `${localStorageKey}-customizer`;
        }

        if (PREVIEW_MODE && shortageTimer.countTime !== this.state.initialTimer) {
          timerService.resetIsOver();
          resetTime = true;

          if (checkLocalStorage()) {
            window.localStorage.removeItem(localStorageKey);
          }
        }

        this.setState({
          initialTimer: shortageTimer.countTime,
          timer: shortageTimer.countTime,
          shortageTimer
        });

        if (!shortageTimer.countTime) {
          return;
        }

        const currentTime = checkLocalStorage() ? Number(window.localStorage.getItem(localStorageKey)) : 0;

        if (!resetTime && currentTime) {
          timerService.setTimeLeft(currentTime);
        }

        if (resetTime || !currentTime) {
          timerService.setTimeLeft(shortageTimer.countTime * 60);
        }

        timerService.setReserveTime(shortageTimer.countTime);
      });

    timerService
      .getIsOver()
      .bindComponent(this)
      .subscribe((isTimeOver) => {
        this.setState({ isTimeOver });
      });

    configService
      .getConfig()
      .filter((config) => !!config.template.styles.shortageTimer && !!config.template.styles.shortageTimer.isActive)
      .switchMap(() => timerService.calculateTime())
      .distinctUntilChanged()
      .bindComponent(this)
      .subscribe((timer) => {
        const minutes = ('0' + Math.floor(timer / 60)).slice(-2);
        const seconds = ('0' + ((timer / 60 - Math.floor(timer / 60)) * 60).toFixed(0)).slice(-2);
        this.setState({ timer, minutes, seconds });
      });
  }

  setBodyClass = () => {
    document.body.classList.add('scarcity-timer-appear');
    this.setState({ bodyClassApplied: true });
  };

  render() {
    if (!this.state) {
      return null;
    }

    const { timer, minutes, seconds, isTimeOver, shortageTimer, bodyClassApplied } = this.state;

    if (!timer || !shortageTimer || !shortageTimer.isActive) {
      return null;
    }

    if (!bodyClassApplied) {
      this.setBodyClass();
    }

    return (
      <div className={`${this.props.classes.component} scarcity-timer`}>
        <div className="time">
          00 : {isTimeOver ? '00' : minutes} : {isTimeOver ? '00' : seconds}
        </div>
        <div className="timerDescription">
          <SvgIcon name="timer" />
          <span className="text">{isTimeOver ? shortageTimer.message : 'Oferta é por tempo limitado! Não perca essa oportunidade'}</span>
        </div>
      </div>
    );
  }
}
