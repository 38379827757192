import React, { memo } from 'react';
import { SvgIcon } from 'components/SvgIcon';
import { enPaymentMethod } from 'enums/enPaymentMethod';
import { IThankYou } from '../../../../services/ThankYouService';
import Translate from 'components/Translate';

interface IProps {
  thankYou: IThankYou;
}

export const Header = memo((props: IProps) => {
  const thankYou = props.thankYou;
  const inAnalyse = thankYou.status === 8;

  let message;

  if (inAnalyse) {
    message = { title: 'Estamos analisando sua compra', subTitle: 'Sua compra não foi efetuada ainda, aguarde para mais informações' };
  } else {
    switch (thankYou.paymentResult.paymentMethod) {
      case enPaymentMethod.BANKSLIP:
        message = { title: 'Seu boleto está pronto!', subTitle: 'Agora é só pagar seu boleto para receber o produto' };
        break;
      case enPaymentMethod.FREE:
        message = { title: 'Nós amamos produtos grátis!', subTitle: 'Estamos preparando tudo para você' };
        break;
      default:
        message = { title: 'Excelente Compra!', subTitle: 'Confirme os dados da sua compra' };
    }
  }

  return (
    <div className="header" style={inAnalyse ? { backgroundColor: '#F59211' } : null}>
      <div className="icon">{inAnalyse ? <SvgIcon name="alertIcon" /> : <SvgIcon name="check" />}</div>
      <div>
        <h2 id="title-thankyou">
          <Translate term={message.title} />
        </h2>
        <h3 id="subtitle-thankyou">
          <Translate term={message.subTitle} />
        </h3>
      </div>
    </div>
  );
});
