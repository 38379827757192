import React from 'react';
import { BaseComponent, IStateBase } from 'components/BaseComponent';
import { WithRouter } from 'shared/decorators/withRouter';
import { WithStyles } from 'shared/decorators/withStyles';

interface IState extends IStateBase {}

interface IProps {
  message?: string;
}

@WithStyles(() => ({
  '@keyframes anima_eduzinho': {
    '0%': {
      top: '-100px'
    },
    '10%': {
      top: '-120px'
    },
    '20%': {
      top: '-100px',
      transform: 'rotate(0deg)',
      backgroundSize: '100%'
    },
    '30%': {
      top: '-120px',
      transform: 'rotate(360deg)',
      backgroundSize: '80%'
    },
    '40%': {
      top: '-100px',
      backgroundSize: '100%'
    },
    '60%': {
      top: '-160px'
    },
    '80%': {
      top: '-100px'
    },
    '100%': {
      top: '-100px'
    }
  },
  component: {
    '& .container': {
      width: '100%',
      height: '100%',
      position: 'absolute',
      backgroundImage: 'url(https://my.eduzz.com/assets/images/errorpage_bg.svg)',
      backgroundAttachment: 'fixed',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      fontWeight: 400,
      '& *, & *::before, & *::after': {
        boxSizing: 'border-box'
      },
      '& .content': {
        width: '480px',
        position: 'absolute',
        left: '50%',
        textAlign: 'center',
        transform: 'translate(-50%, 0%)'
      },
      '& .text': {
        position: 'relative',
        top: '-50px',
        transition: 'all 0.3s ease-in-out',
        animation: 'fadeInUp 0.6s linear'
      },
      '& .text h1, & .text h2, & .text h3': {
        margin: '0px',
        padding: '0px',
        textAlign: 'center'
      },
      '& .text h1': {
        fontWeight: 800,
        fontSize: '84px',
        letterSpacing: '-0.0625em',
        textShadow: '0px 4px 8px rgba(0, 0, 0, 0.15)',
        color: '#ffcc32'
      },
      '& .text h2': {
        fontWeight: 300,
        fontSize: '33px',
        color: '#fff'
      },
      '& .text h3': {
        fontWeight: 300,
        fontSize: '18px',
        color: '#fff'
      },
      '& .text h3 span': {
        color: '#ffcc32',
        fontSize: '28px'
      },
      '& .eduzz': {
        width: '100%',
        height: '160px',
        top: 0,
        opacity: 0.6,
        float: 'right',
        position: 'relative',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'top right',
        backgroundSize: '160px',
        zIndex: 0,
        transition: 'all 0.3s ease-in-out',
        animation: 'fadeIn 0.6s linear'
      },
      '& .eduzinho': {
        width: '250px',
        height: '287px',
        float: 'left',
        position: 'relative',
        top: '-50px',
        backgroundImage: 'url(https://my.eduzz.com/assets/images/errorpage_mascote.png)',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: '100%',
        zIndex: 1,
        transition: 'all 0.3s ease-in-out',
        animation: 'anima_eduzinho 16s infinite'
      },

      '& .icon-credits': {
        width: '100%',
        position: 'absolute',
        bottom: '4px',
        fontSize: '12px',
        color: 'rgba(255, 255, 255, 0.08)',
        textAlign: 'center',
        zIndex: -1
      },
      '& .icon-credits a': {
        textDecoration: 'none',
        color: 'rgba(255, 255, 255, 0.16)'
      },
      '& .acao a': {
        margin: '0 10px',
        background: 'rgba(255, 204, 51, 0.8)',
        fontWeight: 700,
        padding: '10px 15px',
        color: '#011b39',
        borderRadius: '50px',
        textDecoration: 'none'
      },
      '& .acao a:hover': {
        background: 'rgba(255, 204, 51, 0.9)'
      },
      '& .acao': {
        marginTop: '40px'
      }
    }
  }
}))
@WithRouter()
export default class Error extends BaseComponent<IState, IProps> {
  render() {
    const message = this.props.message || 'Não encontramos a página que você está procurando.';

    return (
      <div className={this.props.classes.component}>
        <div className="container">
          <section className={'content'}>
            <div className="eduzz" />
            <div className="eduzinho" />
            <div className="text">
              <h1>Oopzz!</h1>
              <h2>{message}</h2>
              <div className="acao">
                <a href="http://my.eduzz.com">Página inicial</a>
                <a href="https://eduzz.zendesk.com/hc/pt-br">Central de Ajuda</a>
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  }
}
