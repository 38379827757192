/* eslint-disable max-lines */
import Grid, { GridSize } from '@material-ui/core/Grid/Grid';
import { BaseComponent, IStateBase } from 'components/BaseComponent';
import Coupon from 'components/Cart/Coupon';
import CouponPrice from 'components/Cart/Coupon/CouponPrice';
import PreviousStep from 'components/Cart/PreviousStep';
import { Subscription } from 'components/Cart/Price/Subscription';
import Translate from 'components/Translate';
import { enPaymentType } from 'enums/enPaymentType';
import { IProductData } from 'interfaces/IProductData';
import React from 'react';
import { cartService } from 'services/CartService';
import { paymentService } from 'services/PaymentService';
import { IStepProps } from '.';
import { ICartModel } from 'interfaces/ICartModel';
import OpenValue from 'components/Cart/OpenValue';
import FreeShipping from 'components/Cart/Price/FreeShipping';
import { currencyService } from 'services/CurrencyService';
import { enCurrency } from 'enums/enCurrency';
import ProductPrice from 'shared/components/ProductPrice';

interface IState extends IStateBase {
  cart: IProductData;
  payment: ICartModel;
  gridSize: GridSize[];
  currency: enCurrency;
  isMultipleCards: boolean;
}

export interface IProps extends IStepProps {
  step?: number;
}

export default class HeaderStep extends BaseComponent<IState, IProps> {
  constructor(props: IProps) {
    super({ ...props, noPrevious: true });
    this.state = { cart: null, payment: null, gridSize: [], currency: null, isMultipleCards: false };
  }

  componentDidMount() {
    this.observeState({ cart: null }, cartService.getCart());
    this.observeState({ payment: null }, paymentService.getCart());
    this.observeState({ currency: enCurrency.BRL }, currencyService.getCurrency());
    this.observeState({ isMultipleCards: false }, paymentService.isMultipleCreditCards());
    this.bindModel(paymentService.getCart());

    this.setState({
      gridSize: [7, 5]
    });

    cartService
      .getCart()
      .take(1)
      .subscribe((cart) => {
        if (cart.config.paymentType === enPaymentType.SUBSCRIPTION) {
          this.setState({
            gridSize: [4, 8]
          });
        }
      });
  }

  render() {
    if (!this.state || !this.state.cart) {
      return '';
    }

    const { cart, payment, gridSize } = this.state;
    const { step, setStep } = this.props;
    const { paymentType } = cart.config;

    return (
      <Grid
        container
        direction="row"
        alignItems="center"
        justify="space-between"
        spacing={1}
        className={`${paymentType !== enPaymentType.FREE ? 'chk-stepPayment__priceHeader' : 'chk-stepPayment__free'}`}
      >
        <Grid item xs={paymentType !== enPaymentType.DONATION && paymentType !== enPaymentType.FREE ? gridSize[0] : 12}>
          {step && <PreviousStep step={step} setStep={setStep} />}
          <OpenValue />
          <Coupon />
        </Grid>

        <Grid item xs={gridSize[1]} className="chk-stepPayment__productPrice">
          {paymentType !== enPaymentType.DONATION && <CouponPrice />}

          {paymentType === enPaymentType.FREE && <Translate term={'Gratuito'} />}

          {paymentType !== enPaymentType.SUBSCRIPTION && paymentType !== enPaymentType.DONATION && paymentType !== enPaymentType.FREE && (
            <ProductPrice />
          )}

          <FreeShipping config={cart.config} />

          {paymentType === enPaymentType.SUBSCRIPTION && <Subscription payment={payment} cart={cart} />}
        </Grid>
      </Grid>
    );
  }
}
