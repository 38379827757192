import Translate from 'components/Translate';
import React from 'react';

interface IProps {
  contactEmail: string;
}

export const SupportContactText: React.FC<IProps> = ({ contactEmail }) => {
  return (
    <>
      <Translate
        hasHTML
        term={'Em caso de dúvidas, ou se você precisar de suporte, entre em contato com o vendedor pelo <b>e-mail</b>: {contactEmail}'}
        properties={{
          contactEmail
        }}
      />
    </>
  );
};
