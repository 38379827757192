import React, { Fragment, FC } from 'react';
import { ISubscription } from 'interfaces/IProductData';
import Translate from 'components/Translate';
import { CurrencyFormat } from 'components/CurrencyFormat';
import { paymentService } from 'services/PaymentService';
import { makeStyles } from '@material-ui/styles';
import { useObservable } from 'react-use-observable';
import { cartService } from 'services/CartService';
import MultipleInstallments from '../../MultipleInstallments';
import { enPaymentType } from 'enums/enPaymentType';
import WithoutInstallment from '../../WithoutInstallment';

const useStyles = makeStyles({
  greenText: {
    color: '#2EAD43',
    margin: 0,
    fontWeight: 'bold'
  }
});

interface IPriceProps {
  subscription: ISubscription;
  classes?: any;
}
// tslint:disable-next-line:variable-name
export const SubscriptionWithTrial: FC<IPriceProps> = (props) => {
  const { subscription } = props;
  const classes = useStyles({});
  const [cart] = useObservable(() => cartService.getCart(), []);
  const [payment] = useObservable(() => paymentService.getCart(), []);
  const [isMultipleCards] = useObservable(() => paymentService.isMultipleCreditCards(), []);
  return (
    <div className="chk-subscription">
      {cart && subscription.frequencyType === 'Y' && !!subscription.hasTrial ? (
        cart.config.paymentType !== enPaymentType.DONATION && payment.payment[0].numberInstallments > 1 && !isMultipleCards ? (
          <MultipleInstallments cart={cart} isTicket={false} />
        ) : (
          <WithoutInstallment cart={cart} />
        )
      ) : (
        <Fragment>
          <div className="chk-subscription__text">
            <Translate term="Assinatura" />
          </div>
          <div className="chk-subscription__value theme-label">
            <CurrencyFormat value={subscription.price} />
          </div>
          <div className="chk-subscription__frequency">
            <Translate
              term={`a cada {frequency} ${paymentService.getNormalizedFrequencyType(subscription.frequencyType, subscription.frequency)}`}
              properties={{
                tax: <CurrencyFormat value={subscription.tax} />,
                frequency: subscription.frequency
              }}
            />
          </div>
        </Fragment>
      )}
      <div className="chk-subscription__trial">
        {subscription.hasTrialTax ? (
          <span className="theme-button">
            <Translate
              term={`{trial} ${subscription.trial > 1 ? 'dias' : 'dia'} na adesão por`}
              properties={{
                trial: subscription.trial
              }}
            />{' '}
            <CurrencyFormat value={subscription.trialTax} />!
          </span>
        ) : subscription.frequencyType === 'Y' && !!subscription.hasTrial ? (
          <span className="chk-subscription__trial__free">
            <Translate
              term={`Você terá {info} para experimentar o produto, {bold}`}
              properties={{
                info: (
                  <span className={classes.greenText}>
                    {subscription.trial}&nbsp;
                    {subscription.trial > 1 ? 'dias' : 'dia'}
                  </span>
                ),
                bold: <b>você só será cobrado após esse período.</b>
              }}
            />
          </span>
        ) : (
          <span className="theme-button">
            {subscription.dtEndTrial ? (
              <Translate
                term={`Trial até dia {dtEndTrial}`}
                properties={{
                  dtEndTrial: subscription.dtEndTrial.toString().substring(0, 10)
                }}
              />
            ) : (
              <Translate
                term={`{trial} ${subscription.trial > 1 ? 'dias' : 'dia'} grátis na adesão!`}
                properties={{
                  trial: subscription.trial
                }}
              />
            )}
          </span>
        )}
      </div>
    </div>
  );
};
