/* eslint-disable @typescript-eslint/no-unused-vars */
import { cacheService, logService } from 'shared/services';

import { ICache } from 'shared/interfaces/ICache';
import { Observable } from 'rxjs/Observable';
import { Subscriber } from 'rxjs/Subscriber';
import { Subscription } from 'rxjs/Subscription';

interface IOptions {
  refresh: boolean;
  persist: boolean;
  ttl: number;
}

function cache<T>(this: Observable<T>, key: string, options: Partial<IOptions> = {}): Observable<T> {
  const defaultOptions: IOptions = {
    refresh: false,
    persist: false,
    ttl: 300
  };

  return this.lift(new CacheOperator(key, { ...defaultOptions, ...options }));
}

Observable.prototype.cache = cache;

declare module 'rxjs/Observable' {
  interface Observable<T> {
    cache: typeof cache;
  }
}

class CacheOperator {
  constructor(private key: string, private options: IOptions) {}

  public call(subscriber: Subscriber<any>, source: Observable<any>): Subscription {
    if (this.options.refresh) {
      return source.do((data) => cacheService.saveData(this.key, data, this.options)).subscribe(subscriber);
    }

    let currentCache: ICache;
    return cacheService
      .getData(this.key)
      .switchMap((cache) => {
        currentCache = cache;
        if (cache && !cacheService.isExpirated(cache)) {
          return Observable.of(cache.data);
        }

        return !cache ? source : source.startWith(cache.data);
      })
      .switchMap((data) => {
        if (currentCache && currentCache.data === data) {
          logService.breadcrumb('Cache', 'manual', data);
          return Observable.of(data);
        }

        logService.breadcrumb('Cache Set', 'manual', data);
        return cacheService.saveData(this.key, data, this.options).map(() => data);
      })
      .subscribe(subscriber);
  }
}
