/* eslint-disable max-lines */
import { enCurrency } from 'enums/enCurrency';
import { enFrequencyType } from 'enums/enFrequencyType';
import { enPaymentMethod } from 'enums/enPaymentMethod';
import { enPaymentType } from 'enums/enPaymentType';
import { enProductType } from 'enums/enProductType';
import { enShippingType } from 'enums/enShippingType';
import { INext } from './INext';
import { IPins } from './IPins';
import { IScript } from './IScript';
import IShortageTimer from './IShortageTimer';
import { IReservationData, ITickets } from './ITickets';

export interface IProductTemplateMediaConfig {
  readonly background?: IBackgroundTemplateMediaConfig;
  readonly bannerCenter: string;
  readonly bannerTop: string;
  readonly bannerTopUrl?: string;
  readonly bannerRight: string;
  readonly bannerRightUrl?: string;
  readonly bannerYoutube: string;
}
export interface IBackgroundTemplateMediaConfig {
  readonly image: string;
  readonly color: number;
}

export enum enTheme {
  HOTSITE = 14,
  WIDGET = 16,
  PADRAO = 18
}

export interface IProductTemplateConfig {
  readonly id: number;
  readonly customized: boolean;
  readonly media: IProductTemplateMediaConfig;
  readonly styles: any;
  readonly inputStyle: 'normal' | 'material';
  readonly shortageTimer?: IShortageTimer;
}

export enum enLang {
  EN_US = 'enus',
  PT_BR = 'ptbr',
  PT_PT = 'ptpt'
}

export enum enCountry {
  BR = 'BR',
  US = 'US'
}

export interface IPixel {
  readonly userId?: string;
  readonly campaignKey?: string;
  readonly contentId?: string;
  readonly campaign?: string;
}

export interface IExitModelButton {
  readonly enabled?: boolean;
  readonly theme?: IExitModelButtonTheme;
}

export interface IExitModelButtonTheme {
  readonly text?: string;
  readonly textColor?: string;
  readonly textSize?: number;
  readonly backgroundColor?: string;
  readonly backgroundColorHover?: string;
}

export interface IExitModelTimerTheme {
  readonly title?: string;
  readonly titleColor?: string;
  readonly titleSize?: number;
  readonly backgroundColor?: string;
}

export interface IExitModelMedia {
  readonly video?: string;
  readonly audio?: string;
  readonly image?: string;
}

export interface IExitModelTimer {
  readonly time?: number;
  readonly enabled?: boolean;
  readonly theme?: IExitModelTimerTheme;
}

export interface IExitModel {
  readonly coupon?: string;
  readonly media: IExitModelMedia;
  readonly timer?: IExitModelTimer;
  readonly button?: IExitModelButton;
}

export interface IProductConfig {
  readonly affiliateId: number;
  readonly askAddressAfterPayment: boolean;
  readonly askAddressBeforePayment: boolean;
  readonly askCVV: boolean;
  readonly askEmailConfirmation?: boolean;
  readonly allowForeignShipping: boolean;
  readonly availableCurrencies: enCurrency[];
  readonly currency: enCurrency;
  readonly ead: boolean;
  readonly enableBankslip: boolean;
  readonly enableCreditcard: boolean;
  readonly enablePix: boolean;
  readonly enablePaypal: boolean;
  readonly enableEduzzWallet: boolean;
  readonly enableSetLanguageByNavigator: boolean;
  readonly enablePagDividido: boolean;
  readonly enablePaymentWithoutLimit: boolean;
  readonly exitModal?: IExitModel;
  readonly foreignEnabled: boolean;
  readonly hasCoupon: boolean;
  readonly hideQuantity: boolean;
  readonly isCrossborder: boolean;
  readonly isLaunch: boolean;
  readonly isOneClick: boolean;
  readonly logo: string;
  readonly maxInstallments: number;
  readonly multipleCreditCards: boolean;
  readonly mustEstimate: boolean;
  readonly package: boolean;
  readonly paymentType: enPaymentType;
  readonly pixel: IPixel;
  readonly producerId: number;
  readonly productType: enProductType;
  readonly purchaseLimit?: number;
  readonly purchaseMinimumLimit?: number;
  readonly scripts: IScript[];
  readonly template: IProductTemplateConfig;
  readonly shippingType?: enShippingType;
  readonly backRedirect?: IConfigBackRedirect;
  readonly showCardDocument?: boolean;
  readonly askDocument: boolean;
  readonly hasGift?: boolean;
  readonly termsUrl?: string;
}

export interface IProduct {
  readonly id: number;
  readonly description: string;
  readonly hideQuantity: boolean;
  readonly itemId: number;
  readonly purchaseLimit?: number;
  readonly quantity: number;
  readonly realPrice: number;
  readonly tax: number;
  readonly taxedPrice: number;
  readonly title: string;
  readonly totalPrice: number;
  readonly type: enProductType;
  readonly unitPrice: number;
  readonly offer?: {
    id: number;
    key: string;
  };
}

export interface IInstallment {
  readonly installment: number;
  readonly value: number;
}

export interface ICoupon {
  readonly code: string;
  readonly value: number;
}

export interface ICouponData {
  readonly coupon: ICoupon;
  readonly price: number;
  readonly realPrice: number;
  readonly taxedPrice: number;
  readonly cartPrice: number;
  readonly tax: number;
  readonly installments: IInstallment[];
  readonly products: IProduct[];
}

export interface IShippingData {
  readonly hash: string;
  readonly products: IProduct[];
  readonly price: number;
  readonly realPrice: number;
  readonly tax: number;
  readonly realTax: number;
  readonly taxedPrice: number;
  readonly cartPrice: number;
  readonly installments: IInstallment[];
  readonly shippingType: number;
  readonly shippingItems: IShipping[];
  readonly reservation: IReservationData;
}

export interface IShipping {
  readonly title: string;
  readonly value: number;
  readonly deadline: number;
  readonly code: string;
  readonly totalValue: number;
  readonly installments: IInstallment[];
  readonly price: number;
  readonly realPrice: number;
  readonly tax: number;
  readonly taxedPrice: number;
  readonly cartPrice: number;
  readonly config: IProductConfig;
}

export interface IUser {
  readonly id?: number;
  readonly street?: string;
  readonly cityId?: number;
  readonly city?: string;
  readonly complement?: string;
  readonly countryId?: number;
  readonly countryName?: string;
  readonly district?: string;
  readonly number?: string;
  readonly province?: string;
  readonly phone?: string;
  readonly document?: string;
  readonly email?: string;
  readonly name?: string;
  readonly personType?: string;
  readonly postalCode?: string;
  readonly creditCard?: ICreditCard;
  readonly cityName?: string;
  readonly lock?: boolean;
}

export interface ICreditCard {
  readonly id: number;
  readonly brand: string;
  readonly identifier: string;
  readonly secret: string;
  readonly month: number;
  readonly year: number;
  readonly creationDate: string;
  readonly updatedDate: string;
}

export interface ISubscription {
  readonly frequency?: number;
  readonly frequencyType?: enFrequencyType;
  readonly hasTax?: boolean;
  readonly tax?: number;
  readonly taxTax?: number;
  readonly hasTrial?: boolean;
  readonly trial?: number;
  readonly hasTrialTax?: boolean;
  readonly trialTax?: number;
  readonly price?: number;
  readonly priceTax?: number;
  readonly dtEndTrial?: Date | null;
}

export interface IOrderBump {
  readonly contentId: string;
  readonly description: string;
  readonly title: string;
  readonly logo: string;
  readonly price: number;
  readonly realPrice: number;
  readonly totalPrice: number;
  readonly type: enProductType;
  readonly hideQuantity: boolean;
  readonly shipping?: IOrderBumpShipping;
  readonly purchaseLimit?: number;
  readonly paymentType?: string;
  readonly offerTitle?: string;
  readonly offer?: {
    readonly key: string;
  };
}

export interface IOrderBumpShipping {
  type: enShippingType;
  term: number;
  price: number;
}

export interface IContent {
  readonly id: number;
  readonly title: string;
  readonly description: string;
  readonly producerName: string;
  readonly producerEmail: string;
}

export interface IProductData {
  readonly checkoutId: string;
  readonly isEcommerce: boolean;
  readonly contentId: string;
  readonly pixelContentId: string;
  originalCheckoutId: string;
  readonly content: IContent;
  readonly config: IProductConfig;
  readonly coupon: ICoupon;
  readonly feeInstallment: number;
  readonly goofyId: string;
  readonly hasValidCoupons: boolean;
  readonly installmentSelected?: number;
  readonly installments: IInstallment[];
  readonly price: number;
  readonly products: IProduct[];
  readonly realPrice: number;
  readonly realTax: number;
  readonly subscription?: ISubscription;
  readonly tax: number;
  readonly tracker: ITracker;
  readonly taxedPrice: number;
  readonly cartPrice: number;
  readonly transactionKey: string;
  readonly upsell?: enPaymentMethod;
  readonly user?: IUser;
  readonly pins: IPins;
  readonly tickets: ITickets;
  readonly next?: INext;
  readonly orderBump?: IOrderBump[];
  readonly contractId?: number;
  readonly termsUrl?: string;
  readonly offer?: {
    key: string;
    name: string;
  };

  isCached: boolean;
  hash?: string;
  numberInstallments: number;

  readonly student?: IUser;
}

export interface ITracker {
  campaignKey?: string;
  campaignId?: string;
  utmSource?: string;
  utmCampaign?: string;
  utmContent?: string;
  utmMedium?: string;
}

export interface IConfigBackRedirect {
  readonly active: boolean;
  readonly url: string;
}
