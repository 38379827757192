/* eslint-disable max-lines */
import { BaseComponent, IStateBase } from 'components/BaseComponent';
import React from 'react';
import Button from '@material-ui/core/Button/Button';
import { paymentProgressService } from 'services/PaymentProgressService';
import { IPaymentStatus } from 'interfaces/IPaymentStatus';
import { enPaymentStatus } from 'enums/enPaymentStatus';
import PaymentButtonMessage from './PaymentButtonMessage';
import { paymentService } from 'services/PaymentService';
import { ICartModel } from 'interfaces/ICartModel';
import { WithStyles } from 'shared/decorators/withStyles';
import PaymentPanel from 'components/PaymentPanel';
import { cartService } from 'services/CartService';
import { enPaymentType } from 'enums/enPaymentType';

interface IState extends IStateBase {
  paymentStatus: IPaymentStatus;
  payment: ICartModel;
  blockPayment: boolean;
  acceptedSubscriptionTerm: boolean;
  paymentType: enPaymentType;
}

export interface IProps {
  finish: () => void;
  disabledButton: boolean;
  messageButton: string;
  singleStep?: boolean;
  hidden?: boolean;
}

@WithStyles(() => ({
  '@keyframes up': {
    '100%': {
      height: '100%'
    }
  },

  '@keyframes scaleUp': {
    '0%': {
      transform: 'scale(0)'
    },

    '80%': {
      transform: 'scale(1.2)'
    },

    '100%': {
      transform: 'scale(1)'
    }
  },

  '@keyframes cardScaleUp': {
    '0%': {
      transform: 'scale(0)'
    },

    '100%': {
      transform: 'scale(1)'
    }
  },

  '@keyframes show': {
    '50%': {
      transform: 'scale(1)',
      opacity: 0.1
    },

    '100%': {
      opacity: 1,
      transform: 'scale(1)'
    }
  },

  '@keyframes loading': {
    '0%': {
      width: '0%'
    },

    '5%': {
      width: '40%'
    },

    '50%': {
      width: '50%'
    },

    '100%': {
      width: '80%'
    }
  },

  '@keyframes complete': {
    '0%': {
      width: '80%'
    },

    '100%': {
      width: '100%'
    }
  },

  '@keyframes pulseIcon': {
    to: {
      boxShadow: '0 0 0 12px transparent, 0 0 0 24px transparent'
    }
  },
  component: {
    width: '100%',

    '& .chk-payment-painel': {
      position: 'absolute',
      bottom: 0,
      left: 0,

      '@media screen and (max-width: 600px)': {
        position: 'fixed',
        backgroundColor: '#FFF'
      },

      '& .chk-data': {
        backgroundColor: 'white',
        display: 'none'
      }
    },

    '& .button__singleStep': {
      backgroundColor: '#c6c6c6 !important',

      '&:hover': {
        backgroundColor: '#d6d6d6 !important'
      }
    },

    '&:before': {
      position: 'absolute',
      content: '""',
      zIndex: 10,
      bottom: 0,
      left: 0
    },

    '& .paymentButton': {
      position: 'relative',

      '&:before': {
        position: 'absolute',
        content: '""',
        bottom: 0,
        left: 0
      },

      '& .paymentButtonLabel': {
        position: 'relative',
        zIndex: 10,
        fontSize: '0.777rem',

        '& svg': {
          position: 'relative',
          zIndex: 10,
          width: '25px',
          height: '25px',
          margin: 0
        }
      }
    },

    '&.animationInProgress': {
      '& .progressMessage': {
        fontWeight: 300,
        fontSize: '16px',
        textAlign: 'center',
        animation: 'fadeIn 0.3s ease-in-out 0.8s forwards'
      },

      '&.singleStep': {
        padding: '20px',

        '&:before': {
          position: 'fixed',
          opacity: 0.8,
          zIndex: 10
        },

        '& .chk-payment-painel': {
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'fixed',
          top: 0,
          margin: '50px auto',
          paddingBottom: '50px',
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: 12,
          backgroundColor: '#fff',
          boxShadow: '1px 1px 3px 1px rgba(156, 156, 156, 0.3)',
          maxWidth: '650px',
          maxHeight: '500px',
          opacity: 1
        },

        '& .chk-data': {
          backgroundColor: 'white',
          maxWidth: '450px',
          width: '100%',
          position: 'relative',
          top: '30px'
        },

        '& .paymentButton': {
          position: 'relative',
          zIndex: 0,
          borderRadius: 0,

          '&:before': {
            borderBottomLeftRadius: '0px',
            borderBottomRightRadius: '0px'
          }
        }
      },

      '& .chk-payment-painel': {
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 15,

        '& .chk-data': {
          backgroundColor: 'white',
          display: 'block',
          animation: 'show 0.5s ease-in 0.1s forwards'
        }
      },

      '&:before': {
        width: '100%',
        height: '0%',
        opacity: 0.95,
        borderRadius: '3px',
        backgroundColor: '#fff',
        animation: 'up 0.3s ease-in 0.1s forwards'
      },

      '& .paymentButton': {
        position: 'relative',
        zIndex: 20,

        '&.button__sing1leStep': {
          backgroundColor: '#d6d6d6 !important',

          '&:hover, &:active': {
            backgroundColor: '#d6d6d6 !important'
          }
        },

        '&:before': {
          borderBottomLeftRadius: '5px',
          borderBottomRightRadius: '5px',
          width: '0%',
          height: '100%',
          backgroundColor: '#fff',
          opacity: 0.4,
          zIndex: 15,
          animation: 'loading 15s ease-in-out 0.5s forwards'
        }
      }
    },

    '&.animationError': {
      '& .code': {
        fontWeight: 400,
        fontSize: '0.6em',
        marginTop: '10px',
        color: '#a5a5a5',
        opacity: 0,
        animation: 'fade-in 0.6s ease-in-out 1.2s forwards'
      },

      '&.singleStep': {
        padding: '20px',

        '&:before': {
          position: 'fixed',
          opacity: 0.5
        },

        '& .chk-payment-painel': {
          transition: 'background-color 2.5s ease-out',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'fixed',
          top: 0,
          left: 0,
          margin: '50px auto',
          paddingBottom: '50px',
          right: 0,
          zIndex: 12,
          backgroundColor: '#fff',
          boxShadow: '1px 1px 3px 1px rgba(156, 156, 156, 0.3)',
          maxWidth: '650px',
          maxHeight: '500px',
          opacity: 1
        },

        '& .paymentErrorMessage': {
          maxWidth: '450px',
          position: 'relative',
          top: '60px'
        }
      },

      '& .paymentErrorMessage': {
        padding: '20px',
        textAlign: 'center',

        '& .defaultMessage': {
          fontSize: '0.8em',
          fontWeight: 300,
          marginBottom: '10px',
          opacity: 0,
          animation: 'fade-in 0.3s ease-in-out 0.5s forwards'
        },

        '& .errorMessage': {
          fontWeight: 800,
          fontSize: '1.1em',
          opacity: 0,
          animation: 'fade-in 0.3s ease-in-out 0.8s forwards'
        },

        '& .helper': {
          margin: '30px 0 30px',
          fontSize: '0.9em',
          opacity: 0,
          animation: 'fade-in 0.3s ease-in-out 0.5s forwards',

          '& .title': {
            fontWeight: 500
          },

          '& .description': {
            color: '#333',
            fontWeight: 300,
            fontSize: '0.9em',

            '& ul': {
              padding: 0,
              listStyle: 'none',
              margin: 0,

              '& li': {
                padding: '5px 16px 0 16px',
                position: 'relative'
              }
            }
          }
        },

        '& .paymentErrorIcon': {
          width: '100%',
          position: 'absolute',
          display: 'flex',
          zIndex: 10,
          alignItems: 'center',
          justifyContent: 'center',
          margin: 0,
          left: 0,
          top: '-80px',

          '& svg': {
            width: '80px',
            boxShadow: '0 0 0 0 #ffbc00, 0 0 0 0 transparentize(#ffbc00, 0.3)',
            borderRadius: '50%',
            transform: 'scale(0)',
            animation: `scaleUp 1s forwards 0.5s cubic-bezier(0.66, 0.33, 0, 1),
                        pulseIcon 0.8s forwards 0.8s cubic-bezier(0.66, 0.33, 0, 1)`,
            transformOrigin: '50% 50%',

            '& .icon-content': {
              transform: 'scale(0)',
              animation: 'cardScaleUp 0.5s forwards 1s cubic-bezier(0.66, 0.33, 0, 1)',
              transformOrigin: '50% 50%'
            }
          }
        },

        '& .paymentErrorButton': {
          backgroundColor: '#00b356',
          borderRadius: '4px',
          color: '#FFFFFF',
          textTransform: 'none',
          opacity: 0,
          animation: 'fade-in 0.3s ease-in-out 1s forwards'
        }
      },

      '& .chk-payment-painel': {
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 15,

        '& .chk-data': {
          backgroundColor: 'white',
          display: 'block',
          animation: 'show 0.5s ease-in 0.1s forwards'
        }
      },

      '&:before': {
        width: '100%',
        height: '0%',
        opacity: 0.95,
        borderRadius: '3px',
        backgroundColor: '#fff',
        animation: 'up 0.3s ease-in 0.1s forwards'
      },

      '& .paymentButton': {
        zIndex: 10,

        '& svg': {
          transform: 'scale(0)',
          animation: 'cardScaleUp 0.5s forwards 0.1s cubic-bezier(0.66, 0.33, 0, 1)',
          transformOrigin: '50% 50%'
        },

        '&:before': {
          width: '0%',
          height: '100%',
          backgroundColor: '#fff',
          opacity: 0.8,
          zIndex: 12,
          animation: 'complete 0.1s ease-in 0s forwards'
        }
      }
    },

    '&.animationSuccess': {
      '& .paymentButton': {
        zIndex: 10,
        backgroundColor: '#8EC63F !important',

        '& svg': {
          transform: 'scale(0)',
          animation: 'cardScaleUp 0.5s forwards 0.1s cubic-bezier(0.66, 0.33, 0, 1)',
          transformOrigin: '50% 50%'
        },

        '&:before': {
          width: '0%',
          height: '100%',
          backgroundColor: '#fff',
          opacity: 0.1,
          zIndex: 12,
          transition: 'opacity 0.5 linear',
          animation: 'complete 0.1s ease-in 0s forwards'
        }
      }
    },

    '& .button__disabled': {
      backgroundColor: '#ababab !important'
    }
  },
  '@global': {
    '.theme_14 .chk-payment-painel, .theme_16 .chk-payment-painel': {
      backgroundColor: '#FFF'
    }
  }
}))
export default class PaymentButton extends BaseComponent<IState, IProps> {
  componentDidMount() {
    paymentProgressService
      .getPaymentProgressStatus()
      .bindComponent(this)
      .subscribe((paymentStatus: IPaymentStatus) => {
        this.setState({ paymentStatus });
      });

    paymentService
      .getCart()
      .bindComponent(this)
      .subscribe((payment) => {
        this.setState({ payment });
      });

    cartService
      .getCart()
      .bindComponent(this)
      .subscribe((cart) => {
        this.setState({ paymentType: cart?.config.paymentType });
      });

    this.observeState({ blockPayment: false }, paymentService.shouldBlockPayment());
    this.observeState({ acceptedSubscriptionTerm: false }, cartService.getAcceptedSubscriptionTerm());
  }

  idleState = (): void => {
    paymentProgressService.setPaymentProgressStatus({
      status: enPaymentStatus.IDLE,
      message: ''
    });
  };

  statusClassName = (): string => {
    switch (this.state.paymentStatus.status) {
      case enPaymentStatus.PROGRESS:
        return 'animationInProgress';
      case enPaymentStatus.SUCCESS:
        return 'animationSuccess';
      case enPaymentStatus.ERROR:
        return 'animationError';
      default:
        return null;
    }
  };

  render() {
    if (!this.state) {
      return null;
    }

    const { disabledButton, messageButton, finish, singleStep } = this.props;
    const { paymentStatus, blockPayment, acceptedSubscriptionTerm, paymentType } = this.state;
    const statusClassName = this.statusClassName();
    const isIdle = paymentStatus.status === enPaymentStatus.IDLE;

    const hasNotAcceptedSubscriptionTerms = paymentType === enPaymentType.SUBSCRIPTION && !acceptedSubscriptionTerm;

    const shouldDisableButton = disabledButton || !isIdle || blockPayment || hasNotAcceptedSubscriptionTerms;

    return (
      <div className={`${this.props.classes.component} ${!!statusClassName ? statusClassName : ''} ${!!singleStep ? 'singleStep' : ''}`}>
        {paymentStatus.status !== enPaymentStatus.IDLE && <PaymentPanel />}
        {!this.props.hidden && (
          <Button
            id="chk-finish-payment-button"
            onClick={finish}
            fullWidth
            variant="contained"
            size="large"
            classes={{
              label: 'paymentButtonLabel'
            }}
            color="primary"
            className={`paymentButton chk-stepUser__btnFinish theme-button ${!!singleStep && !isIdle ? ' button__singleStep' : ''} ${
              shouldDisableButton ? 'button__disabled' : ''
            }`}
            disabled={shouldDisableButton}
            disableFocusRipple={true}
          >
            <PaymentButtonMessage status={paymentStatus.status} message={messageButton} />
          </Button>
        )}
      </div>
    );
  }
}
