import React from 'react';

import { BaseComponent, IStateBase } from 'components/BaseComponent';
import { captchaService } from 'services/CaptchaService';
import { Observable } from 'rxjs/Observable';
import { CAPTCHA_KEY } from 'settings';
import { WithStyles } from 'shared/decorators/withStyles';

interface IState extends IStateBase {
  show: boolean;
}

let scriptLoaded = false;

@WithStyles(() => ({
  component: {
    zIndex: 100,
    position: 'fixed',
    backgroundColor: 'rgba(0, 0, 0, 0.9)',
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& .captcha-container': {
      backgroundColor: 'white',
      minWidth: '300px',
      padding: '8px 6px 6px 8px',
      overflow: 'hidden',
      borderRadius: '6px',
      boxShadow: '0 0 30px rgba(255, 255, 255, .3)',
      minHeight: '78px'
    }
  }
}))
export default class Captcha extends BaseComponent<IState> {
  constructor(props: any) {
    super(props);

    this.state = {
      show: false
    };
  }

  componentDidMount() {
    captchaService
      .getCaptchaStatus()
      .do((show) => {
        this.setState({ show });
      })
      .filter((show) => show)
      .switchMap(() => this.loadScript())
      .switchMap(() => {
        return Observable.of(true)
          .switchMap(() => {
            if (!window.grecaptcha.render) {
              return Observable.throw(new Error('captcha not loaded'));
            }
            return Observable.of(true);
          })
          .retryWhen(() => {
            console.log('waiting captcha load');
            return Observable.interval(100);
          });
      })
      .bindComponent(this)
      .subscribe(() => {
        setTimeout(() => {
          window.grecaptcha.render('recaptcha', {
            sitekey: CAPTCHA_KEY,
            callback: (token: string) => captchaService.setResult(token)
          });
        }, 100);
      });
  }

  loadScript(): Observable<boolean> {
    if (scriptLoaded) {
      return Observable.of(true);
    }

    return Observable.fromPromise(
      new Promise((resolve) => {
        const script = document.createElement('script');

        script.onload = () => {
          console.log('script loaded');
          resolve(true);
        };

        script.setAttribute('src', '//www.google.com/recaptcha/api.js?render=explicit&onload=');
        document.body.appendChild(script);
        scriptLoaded = true;
      })
    );
  }

  render() {
    const { show } = this.state;

    if (!show) {
      return null;
    }

    return (
      <div className={this.props.classes.component}>
        <div className="captcha-container">
          <div id="recaptcha" />
        </div>
      </div>
    );
  }
}
