import React from 'react';
import { getCdnImage } from 'functions';

interface IProps {
  topBanner: string;
  topBannerUrl?: string;
}

// tslint:disable-next-line:variable-name
export const Header: React.FC<IProps> = (props) => {
  const bannerUrl = getCdnImage(props.topBanner);
  const image = bannerUrl && bannerUrl.includes('data:') ? props.topBanner : bannerUrl;
  const topBannerUrl = props.topBannerUrl;

  return (
    <section className="banner-header">
      <div className="header">
        {image &&
          (topBannerUrl ? (
            <a href={topBannerUrl} target="_blank" rel="noreferrer">
              <img alt="" src={image} />
            </a>
          ) : (
            <img alt="" src={image} />
          ))}
      </div>
    </section>
  );
};
