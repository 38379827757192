/* eslint-disable max-lines */
import { Observable } from 'rxjs/Observable';
import * as api from 'shared/services/api';
import { ICity, IProvince, ICountry } from 'interfaces/ICity';
import { ISearchCep } from 'interfaces/ISearchCep';
import { ReplaySubject } from 'rxjs/ReplaySubject';

const citiesCache: { [key: string]: ReplaySubject<ICity[]> } = {};

export interface ICEP {
  idCity?: number;
  cep: string;
  state: string;
  city: string;
  street: string;
  neighborhood: string;
}

const cepCache = {};

export const getAddress = (c: string): Observable<ICEP> => {
  if (cepCache[c]) {
    return Observable.of(cepCache[c]);
  }

  return api
    .get<any>(`https://viacep.com.br/ws/${c}/json/`, null, null, {
      maxAttempts: 1
    })
    .map((data) => {
      return {
        cep: data.cep,
        street: data.logradouro,
        city: data.localidade,
        state: data.uf,
        neighborhood: data.bairro
      };
    })
    .catch(() => {
      return api.get<any>(`/cep/${c}`, null, null, { local: true });
    })
    .logError()
    .do((data) => {
      cepCache[c] = data;
    });
};

export const getCitiesByState = (uf: string): Observable<ICity[]> => {
  if (!uf || uf === 'none' || uf === 'undefined') {
    return Observable.of([]);
  }

  if (!citiesCache[uf]) {
    citiesCache[uf] = new ReplaySubject<ICity[]>(1);

    api
      .get<ICity[]>(`/country/BR/state/${uf}/cities`)
      .loader()
      .map((cities) => cities.filter((c) => c.name !== 'sorcaba'))
      .subscribe((cities) => citiesCache[uf].next(cities));
  }

  return citiesCache[uf].asObservable();
};

export const getCeps = (data: ISearchCep): Observable<any> => {
  const street = data.street.trim().replace(/^(praca|praça|r|rua|av|avenida|alameda)(\.?)(\s+)/i, '');
  return api.get(`https://viacep.com.br/ws/${data.province}/${data.city}/${street}/json/`).logError();
};

export const getCountries = (): Observable<ICountry[]> => {
  return Observable.of(true)
    .map((): [number, string][] => {
      return [
        [1, 'Australia'],
        [2, 'Montserrat'],
        [3, 'Korea, Republic of'],
        [4, 'Albania'],
        [5, 'Uzbekistan'],
        [6, 'Antarctica'],
        [7, 'Ghana'],
        [8, `LaoLao People's Democratic Republic`],
        [9, 'Tonga'],
        [10, 'Cayman Islands'],
        [11, 'Cuba'],
        [12, 'Burkina Faso'],
        [13, 'Czech Republic'],
        [14, 'Indonesia'],
        [15, 'South Africa'],
        [16, 'El Salvador'],
        [17, 'Kazakhstan'],
        [18, '-'],
        [19, 'Argentina'],
        [20, 'Armenia'],
        [21, 'Rwanda'],
        [22, 'Fiji'],
        [23, 'Israel'],
        [24, 'Gibraltar'],
        [25, "Cote D'ivoire"],
        [26, 'Croatia'],
        [27, 'Kenya'],
        [28, 'Jamaica'],
        [29, 'Moldova, Republic of'],
        [30, 'Sudan'],
        [31, 'Macao'],
        [32, 'Luxembourg'],
        [33, 'Slovenia'],
        [34, 'Azerbaijan'],
        [35, 'Netherlands'],
        [36, 'Singapore'],
        [37, 'Turkmenistan'],
        [38, 'Anguilla'],
        [39, 'Malawi'],
        [40, 'Solomon Islands'],
        [41, 'Bosnia and Herzegovina'],
        [42, 'Iraq'],
        [43, 'Nigeria'],
        [44, 'Palau'],
        [45, 'Spain'],
        [46, 'Samoa'],
        [47, 'Chile'],
        [48, 'South Sudan'],
        [49, 'Slovakia'],
        [50, 'Venezuela, Bolivarian Republic of'],
        [51, 'Canada'],
        [52, 'Liberia'],
        [53, 'Finland'],
        [54, 'Tokelau'],
        [55, 'Taiwan, Province of China'],
        [56, 'Hungary'],
        [57, 'Dominica'],
        [58, 'Cabo Verde'],
        [59, 'Niger'],
        [60, 'Maldives'],
        [61, 'Swaziland'],
        [62, 'Lebanon'],
        [63, 'Romania'],
        [64, 'Oman'],
        [65, 'Uruguay'],
        [66, 'Northern Mariana Islands'],
        [67, 'New Caledonia'],
        [68, 'Sweden'],
        [69, 'Mauritius'],
        [70, 'China'],
        [71, 'Aruba'],
        [72, 'Mexico'],
        [73, 'Palestine, State of'],
        [74, 'Reunion'],
        [75, 'Kyrgyzstan'],
        [76, 'Denmark'],
        [77, 'Bulgaria'],
        [78, 'Malaysia'],
        [79, 'United Arab Emirates'],
        [80, 'Saint Lucia'],
        [81, 'Saint Kitts and Nevis'],
        [82, 'Nepal'],
        [83, 'Latvia'],
        [84, 'Jersey'],
        [85, 'San Marino'],
        [86, 'Paraguay'],
        [87, 'Liechtenstein'],
        [88, 'Micronesia, Federated States of'],
        [89, 'Mayotte'],
        [90, 'Guatemala'],
        [91, 'French Polynesia'],
        [92, 'United States Minor Outlying Islands'],
        [93, 'French Guiana'],
        [94, 'Kuwait'],
        [95, 'Central African Republic'],
        [96, 'Zambia'],
        [97, 'New Zealand'],
        [98, 'Algeria'],
        [99, 'Vanuatu'],
        [100, 'Dominican Republic'],
        [101, 'Russian Federation'],
        [102, 'Qatar'],
        [103, 'Seychelles'],
        [104, 'Pakistan'],
        [105, 'Gambia'],
        [106, 'Lithuania'],
        [107, 'Thailand'],
        [108, 'Nauru'],
        [109, 'Cyprus'],
        [110, 'Burundi'],
        [111, 'Nicaragua'],
        [112, 'Brazil'],
        [113, 'Ukraine'],
        [114, 'Tunisia'],
        [115, 'South Georgia and The South Sandwich Islands'],
        [116, 'Bolivia, Plurinational State of'],
        [117, 'Egypt'],
        [118, 'Switzerland'],
        [119, 'Angola'],
        [120, 'Cook Islands'],
        [121, 'Djibouti'],
        [122, 'Guinea'],
        [123, 'Guadeloupe'],
        [124, 'Macedonia, The Former Yugoslav Republic of'],
        [125, 'Tajikistan'],
        [126, 'Poland'],
        [127, 'Congo'],
        [128, 'Estonia'],
        [129, 'Libya'],
        [130, 'Colombia'],
        [131, 'Austria'],
        [132, 'Comoros'],
        [133, 'Botswana'],
        [134, 'Bhutan'],
        [135, 'Japan'],
        [136, 'Sierra Leone'],
        [137, 'Eritrea'],
        [138, 'Philippines'],
        [139, 'Sint Maarten (Dutch Part)'],
        [140, 'Kiribati'],
        [141, 'Afghanistan'],
        [142, 'Bahrain'],
        [143, 'Honduras'],
        [144, 'United Kingdom'],
        [145, 'Norfolk Island'],
        [146, 'Cambodia'],
        [147, 'Uganda'],
        [148, 'France'],
        [149, 'Aland Islands'],
        [150, 'Senegal'],
        [151, 'Germany'],
        [152, 'Greece'],
        [153, 'Saudi Arabia'],
        [154, 'Myanmar'],
        [155, 'Italy'],
        [156, 'Hong Kong'],
        [157, 'American Samoa'],
        [158, 'Andorra'],
        [159, 'Mali'],
        [160, 'Monaco'],
        [161, 'Morocco'],
        [162, 'Costa Rica'],
        [163, 'Bangladesh'],
        [164, 'Martinique'],
        [165, 'Turkey'],
        [166, 'Brunei Darussalam'],
        [167, 'Equatorial Guinea'],
        [168, 'Viet Nam'],
        [169, 'Norway'],
        [170, 'Mozambique'],
        [171, 'Malta'],
        [172, 'Mauritania'],
        [173, 'Jordan'],
        [174, 'Falkland Islands (Malvinas)'],
        [175, 'Namibia'],
        [176, 'Portugal'],
        [178, 'Timor-Leste'],
        [179, 'Tuvalu'],
        [180, 'Georgia'],
        [181, 'Curacao'],
        [182, 'Yemen'],
        [183, 'Iran, Islamic Republic of'],
        [184, "Korea, Democratic People's Republic of"],
        [185, 'Saint Vincent and The Grenadines'],
        [186, 'Lesotho'],
        [187, 'Tanzania, United Republic of'],
        [188, 'Guernsey'],
        [189, 'Madagascar'],
        [190, 'Belgium'],
        [191, 'Saint Barthelemy'],
        [192, 'Haiti'],
        [193, 'Cameroon'],
        [194, 'Puerto Rico'],
        [195, 'Holy See'],
        [196, 'Montenegro'],
        [197, 'Belize'],
        [198, 'Trinidad and Tobago'],
        [199, 'Mongolia'],
        [200, 'Bahamas'],
        [201, 'Virgin Islands, British'],
        [202, 'Cocos (Keeling) Islands'],
        [203, 'Barbados'],
        [204, 'Marshall Islands'],
        [205, 'Saint Pierre and Miquelon'],
        [206, 'Peru'],
        [207, 'Guinea-Bissau'],
        [208, 'Congo, The Democratic Republic of The'],
        [209, 'Isle of Man'],
        [210, 'Sao Tome and Principe'],
        [211, 'Wallis and Futuna'],
        [212, 'Guyana'],
        [213, 'Antigua and Barbuda'],
        [214, 'Serbia'],
        [215, 'Guam'],
        [216, 'British Indian Ocean Territory'],
        [217, 'Ireland'],
        [218, 'India'],
        [219, 'Bonaire, Sint Eustatius and Saba'],
        [220, 'Benin'],
        [221, 'United States'],
        [222, 'Sri Lanka'],
        [223, 'Bermuda'],
        [224, 'Papua New Guinea'],
        [225, 'Virgin Islands, U.S.'],
        [226, 'Zimbabwe'],
        [227, 'Ethiopia'],
        [228, 'Syrian Arab Republic'],
        [229, 'Panama'],
        [230, 'Togo'],
        [231, 'Faroe Islands'],
        [232, 'Suriname'],
        [233, 'Iceland'],
        [234, 'Chad'],
        [235, 'Niue'],
        [236, 'Belarus'],
        [237, 'Saint Martin (French Part)'],
        [238, 'Grenada'],
        [239, 'Somalia'],
        [240, 'Ecuador'],
        [241, 'Gabon'],
        [242, 'Turks and Caicos Islands'],
        [243, 'Greenland'],
        [244, 'Saint Helena']
      ];
    })
    .map((i) => i.map(([id, country]) => ({ id, country })));
};

export const getProvinces = (): Observable<IProvince[]> => {
  return Observable.of(true).map(() => {
    return [
      { uf: 'AC', name: 'Acre' },
      { uf: 'AL', name: 'Alagoas' },
      { uf: 'AP', name: 'Amapá' },
      { uf: 'AM', name: 'Amazonas' },
      { uf: 'BA', name: 'Bahia' },
      { uf: 'CE', name: 'Ceará' },
      { uf: 'DF', name: 'Distrito Federal' },
      { uf: 'ES', name: 'Espírito Santo' },
      { uf: 'GO', name: 'Goías' },
      { uf: 'MA', name: 'Maranhão' },
      { uf: 'MT', name: 'Mato Grosso' },
      { uf: 'MS', name: 'Mato Grosso do Sul' },
      { uf: 'MG', name: 'Minas Gerais' },
      { uf: 'PA', name: 'Pará' },
      { uf: 'PB', name: 'Paraíba' },
      { uf: 'PR', name: 'Paraná' },
      { uf: 'PE', name: 'Pernambuco' },
      { uf: 'PI', name: 'Piauí' },
      { uf: 'RJ', name: 'Rio de Janeiro' },
      { uf: 'RN', name: 'Rio Grande do Norte' },
      { uf: 'RS', name: 'Rio Grande do Sul' },
      { uf: 'RO', name: 'Rondônia' },
      { uf: 'RR', name: 'Roraíma' },
      { uf: 'SC', name: 'Santa Catarina' },
      { uf: 'SP', name: 'São Paulo' },
      { uf: 'SE', name: 'Sergipe' },
      { uf: 'TO', name: 'Tocantins' }
    ];
  });
};
