import { ReplaySubject } from 'rxjs/ReplaySubject';
import { Subject } from 'rxjs/Subject';
import * as Sentry from '@sentry/browser';

export class AlertService {
  private alert$ = new ReplaySubject<{
    message: string;
    title?: string;
    confirmation?: boolean;
    code?: string;
    response: Subject<boolean>;
  }>(1);

  public show(message: string, title: string, confirmation: boolean = false, code: string = undefined) {
    const response$ = new Subject<boolean>();

    this.alert$.next({
      message,
      title,
      confirmation,
      code,
      response: response$
    });

    if (code) {
      Sentry.setTag('error_code', code);
    }

    return response$;
  }

  public getAlert() {
    return this.alert$.asObservable().do((data) => {
      Sentry.addBreadcrumb({
        message: 'Alert Log',
        category: 'init',
        level: Sentry.Severity.Info,
        data: {
          GOOFY_ID: window.GOOFY_ID,
          SSID: window.SSID,
          message: data.message,
          title: data.title,
          confirmation: data.confirmation,
          code: data.code
        }
      });
    });
  }
}

// tslint:disable-next-line:variable-name
export const AlertServiceFactory = () => new AlertService();

export const alertService = AlertServiceFactory();
