import React, { memo } from 'react';
import { IScript } from '../../interfaces/IScript';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import { makeStyles } from '@material-ui/styles';
import { useObservable } from 'react-use-observable';
import { cartService } from 'services/CartService';

const useStyles = makeStyles({
  whatsapp: {
    position: 'fixed',
    width: 60,
    height: 60,
    bottom: 20,
    right: 20,
    backgroundColor: '#25d366',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#FFF',
    borderRadius: '50px 50px 6px 50px',
    boxShadow: '0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)',
    transition: `background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
       box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
       border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms
    `,
    fontSize: 30,
    zIndex: 100,

    '&:hover': {
      backgroundColor: '#149043'
    }
  },
  whatsappIcon: {
    fontSize: 38
  }
});
interface IProps {
  script: IScript;
}

export const Whatsapp: React.FC<IProps> = ({ script }) => {
  const classes = useStyles({});
  const [cart] = useObservable(() => cartService.getCart(), []);

  if (!cart) {
    return null;
  }

  // eslint-disable-next-line max-len
  let url = `https://api.whatsapp.com/send?phone=${script.value}&text=Olá !%0D%0AGostaria de tirar algumas dúvidas sobre o produto ${cart.content.title} - ${cart.contentId}`;
  if (script.type == 'whatsapp-link') {
    url = script.value;
  }

  return (
    <a
      // eslint-disable-next-line max-len
      href={url}
      className={classes.whatsapp}
      target="_blank"
      rel="noopener noreferrer"
    >
      <WhatsAppIcon color="inherit" className={classes.whatsappIcon} />
    </a>
  );
};

export default memo(Whatsapp);
