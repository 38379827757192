import TextField from '@material-ui/core/TextField';
import React from 'react';

import FieldBase from './Base';
import Translate from 'components/Translate';

export default class FieldSelect extends FieldBase {
  onChange(event: any) {
    const value = event.target ? event.target.value : event;
    super.onChange(value);
  }

  render() {
    const { value } = this.props;

    const { config, selectListToTop } = this.state;

    const inputStyle = config ? config.template.inputStyle : 'normal';

    return (
      <div ref={this.inputRef} className={`list-on-${selectListToTop ? 'top' : 'bottom'}`}>
        {super.render()}

        <TextField
          {...{
            fullWidth: true,
            margin: 'normal',
            ...this.props,
            value: value === undefined ? '' : value,
            select: true,
            error: !!this.errorMessage,
            helperText: <Translate term={this.errorMessage} />,
            onChange: this.onChange.bind(this),
            onBlur: this.onBlur.bind(this),
            submitted: null,
            InputProps: {
              className: `field-select ${inputStyle}`,
              disableUnderline: inputStyle != 'material',
              onChange: this.onChange.bind(this)
            },
            FormHelperTextProps: {
              classes: {
                root: 'field-error',
                error: 'error',
                disabled: 'disabled'
              }
            },
            touched: null
          }}
        >
          {this.props.children}
        </TextField>
      </div>
    );
  }
}
