/* eslint-disable max-lines */
import React from 'react';
import { BaseComponent, IStateBase } from 'components/BaseComponent';
import { WithRouter } from 'shared/decorators/withRouter';
import Dialog from './Dialog';
import { thankYouService, IThankYou } from 'services/ThankYouService';
import { paymentService } from 'services/PaymentService';
import { postBackService } from 'services/PostBackService';
import { Overlay } from './Overlay/index';
import { enPaymentMethod } from 'enums/enPaymentMethod';
import { legacyPixelService, enLegacyPixelPath } from 'services/LegacyPixelService';
import { pixelService, enPixelPath } from 'services/PixelService';
import ContentDelivery from './ContentDelivery/index';
import { WithStyles } from 'shared/decorators/withStyles';

interface IState extends IStateBase {
  page: string;
  showPage: boolean;
  model?: IThankYou;
}

export const ThankYouContext = React.createContext<IThankYou>(null);

@WithStyles(() => ({
  '@keyframes countdown': {
    '0%': {
      strokeDashoffset: '0px'
    },

    '90%': {
      strokeDashoffset: '56.5px',
      transformOrigin: 'center',
      transform: 'scale(1)'
    },

    '100%': {
      strokeDashoffset: '0px',
      transformOrigin: 'center',
      transform: 'scale(0.4)'
    }
  },
  component: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100vh',
    position: 'relative',

    '& h2, & h3, & h4, & p': {
      margin: 0
    },

    '& .overlay': {
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',

      '& .shape': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: 500,
        backgroundColor: 'rgba(0, 0, 0, 0.2)'
      },

      '& .frame': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: 100
      }
    },

    '& .dialog__thank-you': {
      color: '#596375',
      backgroundColor: '#fff',
      width: '92%',
      maxWidth: '550px',
      margin: '50px auto',

      position: 'relative',
      top: 0,

      zIndex: 1000,

      borderRadius: '3px',
      boxShadow: '0px 2px 30px 0px rgba(105, 105, 105, 0.42)',

      '&.is-default': {
        boxShadow: 'none'
      },

      '& .close': {
        cursor: 'pointer',
        position: 'absolute',
        top: '-10px',
        right: '-10px',
        padding: '10px',
        backgroundColor: '#fff',
        borderRadius: '100%',
        height: '40px',
        transition: 'transform .5s ease-in-out',

        '&:hover': {
          transform: 'scale(0.8)'
        },

        '& svg': {
          width: '20px',

          '& path': {
            fill: '#596375 !important'
          }
        }
      },

      '& .bankslip': {
        width: '100%',
        padding: '20px',
        textAlign: 'center',
        borderBottom: '1px solid #cbcfd6',

        '& .code': {
          fontSize: '0.77rem'
        },

        '& .bankslip-download': {
          marginTop: '10px',

          '& svg': {
            width: '18px',
            fill: '#fff',
            marginRight: '10px'
          },

          '& button': {
            cursor: 'pointer',

            padding: '10px 50px',
            fontWeight: 600,
            fontSize: '0.77rem'
          }
        },

        '& button': {
          color: '#fff',
          backgroundColor: '#ff7f30',
          textTransform: 'uppercase',
          outline: 'none',
          border: 0,

          '&.has-countdown': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',

            '& .countdown': {
              backgroundColor: '#fff',
              color: '#ff7f30',
              padding: '10px',
              borderRadius: '100%'
            }
          }
        },

        '& .copy': {
          position: 'relative',
          marginTop: '10px',

          '& .button': {
            cursor: 'pointer',
            position: 'absolute',
            top: 0,
            right: 0,
            height: '46px',
            backgroundColor: '#fff',
            color: '#ff7e30',
            border: '1px solid #ff7e30',
            textTransform: 'uppercase',
            outline: 'none',
            padding: '0 20px',
            fontWeight: 600,
            fontSize: '0.77rem'
          },

          '& .input-code': {
            width: '100%',

            '& .input': {
              backgroundColor: '#fff',
              padding: '5px 10px',
              borderRadius: '3px',
              transition: 'border .5s ease-in-out',
              border: '1px solid #dbdbdb',

              '&:hover': {
                border: '1px solid #ff7e30'
              }
            }
          }
        }
      },

      '& .header': {
        color: '#fff',
        backgroundColor: '#019358',
        padding: '20px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        borderTopRightRadius: '3px',
        borderTopLeftRadius: '3px',

        '& .icon': {
          marginRight: '15px'
        },

        '& svg': {
          width: '42px'
        },

        '& h2': {
          fontWeight: 900,
          fontSize: '1.25rem'
        },

        '& h3': {
          fontWeight: 300,
          fontSize: '1rem'
        }
      },

      '& .product': {
        display: 'flex',
        alignItems: 'center',
        padding: '20px 20px 0 20px',

        '& .image': {
          width: '50px',
          height: '50px',
          backgroundSize: 'cover',
          backgroundColor: '#efefef',
          marginRight: '10px',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center'
        },

        '& .price': {
          color: '#019358'
        },

        '& .chk-subscription': {
          fontSize: '0.88rem',

          '& .chk-subscription__text': {
            display: 'inline-block'
          },

          '& .chk-subscription__value': {
            color: '#019358',
            marginLeft: '3px',
            display: 'inline-block'
          },

          '& .chk-subscription__frequency': {
            color: '#019358'
          }
        },

        '& .item': {
          '& .productTitle': {
            wordBreak: 'break-word'
          }
        }
      },
      '& .buttonOut': {
        margin: 'auto',
        marginBottom: '8px',
        color: '#fff',
        backgroundColor: '#019358',
        padding: '20px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        borderTopRightRadius: '3px',
        borderTopLeftRadius: '3px'
      },

      '& .message': {
        fontSize: '0.88rem',
        padding: '20px 20px 0 20px'
      },

      '& .alert': {
        fontSize: '0.88rem',
        padding: '0px 20px 20px 20px'
      },

      '& .data': {
        padding: '20px',

        '& .user': {
          padding: '2px 10px',
          backgroundColor: '#efefef',
          borderRadius: '3px',

          '& p': {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            margin: '10px 0',
            fontSize: '0.88rem'
          },

          '& svg': {
            width: '20px',
            marginRight: '10px',
            fill: '#596375'
          }
        }
      },

      '& .action': {
        padding: '0 20px 10px 20px',

        '& .button': {
          cursor: 'pointer',
          textDecoration: 'none',
          width: '100%',
          padding: '20px',
          backgroundColor: '#ff7f30',
          color: '#fff',
          textTransform: 'uppercase',
          fontWeight: 900,
          fontSize: '0.88rem',
          borderRadius: '3px',
          textAlign: 'center',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',

          '& .text': {
            marginLeft: '5px'
          }
        }
      },

      '& .faq': {
        padding: '0px 20px 20px 20px',
        textAlign: 'center',

        '& .chk-modal-doubts': {
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',

          '& svg': {
            width: '18px',
            marginRight: '5px',
            fill: '#AEB0BF'
          }
        },

        '& a': {
          cursor: 'pointer',
          color: '#9b9db1',
          fontSize: '0.77rem',

          '&:hover': {
            textDecoration: 'underline'
          }
        }
      },

      '& .countdown': {
        height: '20px',
        width: '20px',
        textAlign: 'center',

        '& svg': {
          width: '20px',
          height: '20px',
          transform: 'rotateY(-180deg) rotateZ(-90deg)',

          '& circle': {
            strokeDasharray: '56.5px',
            strokeDashoffset: '0px',
            strokeLinecap: 'round',
            strokeWidth: '2px',
            stroke: 'white',
            fill: 'none',
            animation: 'countdown 10s linear forwards'
          }
        }
      }
    },

    '@media screen and (maxWidth: 768px)': {
      display: 'block',
      position: 'relative',
      minHeight: '100vh',

      '& .overlay': {
        display: 'none'
      },

      '& .dialog__thank-you': {
        maxWidth: '92%',
        height: '100%',
        borderRadius: 0,
        boxShadow: 'none',
        margin: 0,

        '& .close': {
          top: '10px',
          right: '10px',
          padding: '5px',
          borderRadius: 0,
          height: '30px'
        },

        '& .header': {
          borderRadius: 0
        }
      }
    }
  }
}))
@WithRouter()
export default class ThankYou extends BaseComponent<IState> {
  componentDidMount() {
    window.history.pushState(null, null, window.location.href);

    window.onpopstate = function () {
      window.history.go(1);
    };

    thankYouService
      .getThankYou()
      .bindComponent(this)
      .subscribe((model) => {
        this.setState({ model });

        let pixelPath = enPixelPath.CREDITCARD;

        if (model.paymentResult.paymentMethod === enPaymentMethod.BANKSLIP) {
          pixelPath = enPixelPath.BANKSLIP;
        }

        if (model.paymentResult.paymentMethod === enPaymentMethod.PIX) {
          pixelPath = enPixelPath.PIX;
        }

        if (model.paymentResult.paymentMethod === enPaymentMethod.PAGDIVIDIDO) {
          pixelPath = enPixelPath.PAGDIVIDIDO;
        }

        const isBankslip = model.paymentResult.paymentMethod === enPaymentMethod.BANKSLIP;
        legacyPixelService.track(isBankslip ? enLegacyPixelPath.THANKYOU_BANKSLIP : enLegacyPixelPath.THANKYOU);

        pixelService.setPath(pixelPath);
      });

    thankYouService.getPostBack().subscribe(([paymentResult, params]) => {
      const page = postBackService.getParams(params, paymentResult.thankyouUrl, paymentResult.key);

      if (!!page) {
        this.setState({ page, showPage: true });
      }

      postBackService.postParams(params, paymentResult.thankyouUrl, paymentResult.key);
    });

    if (!paymentService.hasPayment()) {
      const transactionKey = this.props.location.pathname
        .replace(/^\/+|\/+$/gi, '')
        .split('/')
        .pop();

      thankYouService.getThankyouByHash(transactionKey).subscribe((model) => {
        this.setState({ model });
      });
    }
  }

  render() {
    if (!this.state) {
      return null;
    }

    return (
      <React.Fragment>
        <ThankYouContext.Provider value={this.state.model}>
          <ContentDelivery isDefault={!this.state.model.paymentResult.thankyouUrl} />
          <section className={`${this.props.classes.component}`}>
            <Dialog
              thankyouUrl={this.state.showPage ? this.state.page : this.state.model.paymentResult.thankyouUrl}
              hasCountdown={this.state.model.paymentResult.paymentMethod !== enPaymentMethod.BANKSLIP && !!this.state.model.paymentResult.thankyouUrl}
            />
            {!!this.state.model.paymentResult.thankyouUrl ? <Overlay bankslipUrl={this.state.model.paymentResult.thankyouUrl} /> : null}
          </section>
        </ThankYouContext.Provider>
        <div id="post-get" />
        <iframe style={{ border: 0, width: 0, height: 0, opacity: 0 }} className="hidden" id="post-params" name="post-params" />
      </React.Fragment>
    );
  }
}
