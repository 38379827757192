import React from 'react';
import { IProductData } from 'interfaces/IProductData';
import { CurrencyFormat } from 'components/CurrencyFormat';
import { BaseComponent } from 'components/BaseComponent';
import { Translate } from 'components/Translate';
import { enProductType } from 'enums/enProductType';

interface IPriceProps {
  cart: IProductData;
}

export default class Br extends BaseComponent<null, IPriceProps> {
  render() {
    const { cart } = this.props;
    const { price } = cart;
    const product = cart.products[0];

    if (cart.next) {
      return null;
    }

    if (product.totalPrice === 0 && product.type === enProductType.PHYSICAL) {
      return (
        <div className="chk-full">
          <span className="chk-full-price theme-label">
            <Translate term="Grátis" />
          </span>
          <div className="chk-small-price">
            <span>
              {' '}
              <Translate term="Frete de" /> <CurrencyFormat value={price} />
            </span>
          </div>
        </div>
      );
    }

    if (price === 0) {
      return (
        <div className="chk-full">
          <span className="chk-full-price theme-label">
            <Translate term="Grátis" />
          </span>
        </div>
      );
    }

    return (
      <div className="chk-full">
        <span className="chk-number-price theme-label">
          <CurrencyFormat value={price} />
        </span>
        {!cart.subscription && (
          <div className="chk-small-price">
            <Translate term="à vista" />
          </div>
        )}
      </div>
    );
  }
}
