import { BaseComponent } from 'components/BaseComponent';
import React from 'react';
import PriceQuantity from 'components/Cart/PriceQuantity';
import Button from '@material-ui/core/Button/Button';
import Grid from '@material-ui/core/Grid';
import { enStep } from '.';
import Translate from 'components/Translate';
import HeaderStep from './HeaderStep';

interface IProps {
  step: number;
  setStep: any;
}

export default class StepTicket extends BaseComponent<null, IProps> {
  goToNextStep = async () => {
    if (await this.isFormValid()) {
      this.props.setStep(enStep.address);
    }
  };

  render() {
    return (
      <div className={'step'}>
        <Grid container spacing={1}>
          <HeaderStep step={this.props.step} setStep={this.props.setStep} />
          <Grid item xs={12}>
            <PriceQuantity step={this.props.step} />
          </Grid>
          <Grid item xs={12} className="chk-stepUser__btnContinueGrid">
            <Button
              id="chk-next-button"
              onClick={this.goToNextStep}
              fullWidth
              variant="contained"
              size="large"
              color="primary"
              className="chk-stepTicket__btnContinue theme-button"
            >
              <Translate term="Continuar" />
            </Button>
          </Grid>
        </Grid>
      </div>
    );
  }
}
