import * as uuid from 'uuid';
import * as sessionStorage from 'shared/services/SessionStorage';
import { Observable } from 'rxjs/Observable';
import { paymentService } from './PaymentService';
import { cartService } from 'services/CartService';
import { enPaymentType } from 'enums/enPaymentType';
import { apiService } from 'shared/services';
import { currencyService } from './CurrencyService';
import { parameterService } from 'services/ParameterService';
import { enParameters } from 'enums/enParameters';
import { showError } from 'shared/services/dialog';
import { PREVIEW_MODE } from 'settings';
import { of } from 'rxjs/observable/of';

interface IClientData {
  name: string;
  phone: string;
  ddi: string;
  email: string;
  personType: string;
  document?: string;
  address?: {
    postalCode: string;
    street: string;
    number: string;
    province: string;
    countryId?: string;
    countryName?: string;
    cityName?: string;
    complement: string;
    district: string;
    cityId?: string;
  };
}

export class UserService {
  private lastData = '';

  getSSID(): Observable<string> {
    return sessionStorage.getValue('ssid', () => uuid.v4().replace(/\d/gi, ''));
  }

  setBasicUser(): Observable<any> {
    return paymentService
      .getCart()
      .combineLatest(
        cartService.getCart(),
        currencyService.getCurrency(),
        parameterService.get(enParameters.sdoc).map((sdoc) => !!sdoc),
        paymentService.getCart()
      )
      .filter(([data, cart]) => data.name && !!cart.transactionKey)
      .take(1)
      .switchMap(([data, cart, currency, sdoc, payment]) => {
        if (PREVIEW_MODE) {
          return of(true);
        }

        if (data.name && data.name.split(' ').length < 2) {
          return of(true);
        }

        const askDocument = cart.config.askDocument || sdoc;

        if (payment.personType != 'E' && askDocument && data.document) {
          data.personType = data.document.length === 14 ? 'J' : 'F';
        }

        const body: IClientData = {
          name: data.name,
          phone: data.phone,
          email: data.email,
          ddi: data.ddi,
          personType: data.personType || null
        };

        if (data.address && data.address.street) {
          if (data.personType === 'E') {
            body.address = {
              street: data.address.street || null,
              complement: data.address.complement || null,
              district: data.address.district || null,
              postalCode: data.address.postalCode || null,
              number: data.address.number || null,
              province: data.address.province || null,
              countryId: data.address.country || null,
              cityName: data.address.cityName || null,
              countryName: data.address.countryName || null,
              cityId: data.address.cityId || '1'
            };
          }

          if (data.personType !== 'E') {
            body.address = {
              cityId: data.address.cityId || '1',
              street: data.address.street || null,
              complement: data.address.complement || null,
              district: data.address.district || null,
              postalCode: data.address.postalCode || null,
              number: data.address.number || null,
              province: data.address.province || null,
              countryId: data.address.country || null
            };
          }
        }

        if (!data.name || !data.phone || !data.email) {
          return Observable.empty();
        }

        if (cart.config.paymentType !== enPaymentType.FREE || payment.personType != 'E') {
          body.document = data.document;
        }

        const tmpKey = JSON.stringify([data, cart.config.paymentType, currency, payment.personType]);

        if (tmpKey === this.lastData) {
          return Observable.empty();
        }

        this.lastData = tmpKey;

        return apiService.post(`/client/create/${cart.transactionKey}`, body);
      })
      .logError()
      .catch((err) => {
        showError(err).subscribe();
        return Observable.empty();
      });
  }
}

// tslint:disable-next-line:variable-name
export const UserServiceFactory = () => new UserService();

export const userService = UserServiceFactory();
