import React from 'react';
import { CurrencyFormat } from 'components/CurrencyFormat';
import { ISubscription } from 'interfaces/IProductData';
import { SubscriptionWithTrial } from 'components/Cart/Price/Subscription/SubscriptionWithTrial';
import Translate from 'components/Translate';
import { paymentService } from 'services/PaymentService';
import { enPaymentMethod } from 'enums/enPaymentMethod';

interface IProps {
  paymentMethod: enPaymentMethod;
  installments: number;
  price: number;
  subscription?: ISubscription;
  pagDivididoInstallment?: string;
}

const Price: React.FC<IProps> = ({ installments, price, subscription, pagDivididoInstallment, paymentMethod }) => {
  const withInstallments = [enPaymentMethod.CREDIT_CARD, enPaymentMethod.ONE_CLICK_BUY].includes(paymentMethod);
  if (subscription) {
    return (
      <div>
        {subscription.hasTax ? (
          <div className="chk-subscription">
            <div className="chk-subscription__text">
              <Translate term="Adesão de" />
            </div>
            <div className="chk-subscription__value theme-label">
              {installments > 1 ? (
                <div className="price">
                  {installments}x <CurrencyFormat value={price / installments} />
                </div>
              ) : (
                <div className="price">
                  <CurrencyFormat value={price} />
                </div>
              )}
            </div>
            <div className="chk-subscription__frequency">
              + <CurrencyFormat value={subscription.price} />
              &nbsp;
              <Translate
                // tslint:disable-next-line:max-line-length
                term={`a cada {frequency} ${paymentService.getNormalizedFrequencyType(subscription.frequencyType, subscription.frequency)}`}
                properties={{
                  tax: <CurrencyFormat value={subscription.tax} />,
                  frequency: subscription.frequency
                }}
              />
            </div>
          </div>
        ) : null}

        {subscription.hasTrial ? <SubscriptionWithTrial subscription={subscription} /> : null}

        {!subscription.hasTax && !subscription.hasTrial ? (
          <div className="chk-subscription">
            <div className="chk-subscription__text">
              <Translate term="Assinatura" />
            </div>
            <div className="chk-subscription__value theme-label">
              {installments > 1 ? (
                <div className="price">
                  {installments}x <CurrencyFormat value={price / installments} />
                </div>
              ) : (
                <div className="price">
                  <CurrencyFormat value={price} />
                </div>
              )}
            </div>
            <div className="chk-subscription__frequency">
              <Translate
                // tslint:disable-next-line:max-line-length
                term={`a cada {frequency} ${paymentService.getNormalizedFrequencyType(subscription.frequencyType, subscription.frequency)}`}
                properties={{
                  tax: <CurrencyFormat value={subscription.tax} />,
                  frequency: subscription.frequency
                }}
              />
            </div>
          </div>
        ) : null}
      </div>
    );
  }

  if (!!pagDivididoInstallment) {
    return <div className="price">{pagDivididoInstallment}</div>;
  }

  if (installments > 1 && withInstallments) {
    return (
      <div className="price">
        {installments}x <CurrencyFormat value={price / installments} />
      </div>
    );
  }

  return (
    <div className="price">
      <CurrencyFormat value={price} />
    </div>
  );
};

export default React.memo(Price);
