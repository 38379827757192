import React from 'react';
import ReactDOM from 'react-dom';
import { IScript } from 'interfaces/IScript';
import { BaseComponent } from 'components/BaseComponent';

interface IProps {
  script: IScript;
}

export default class Zopim extends BaseComponent<{}, IProps> {
  componentDidMount() {
    const script = document.createElement('script');

    script.innerHTML = `window.$zopim || (function (d, s) {
        var z = $zopim = function (c) { z._.push(c) }, $ = z.s =
          d.createElement(s), e = d.getElementsByTagName(s)[0]; z.set = function (o) {
            z.set.
            _.push(o)
          }; z._ = []; z.set._ = []; $.async = !0; $.setAttribute('charset', 'utf-8');
        $.src = '//v2.zopim.com/?${this.props.script.value}'; z.t = +new Date; $.
          type = 'text/javascript'; e.parentNode.insertBefore($, e)
      })(document, 'script');`;

    // eslint-disable-next-line react/no-find-dom-node
    ReactDOM.findDOMNode(this).appendChild(script);
  }

  render() {
    return <div />;
  }
}
