import { IProductData } from 'interfaces/IProductData';
import React from 'react';
import MultipleInstallments from './../MultipleInstallments';
import WithoutInstallment from './../WithoutInstallment';
import Coupon from './../../Coupon/index';
import Translate from 'components/Translate';
import CouponPrice from '../../Coupon/CouponPrice/index';
import Grid from '@material-ui/core/Grid/Grid';
import { paymentService } from 'services/PaymentService';
import { Subscription } from './../Subscription';
import { enPaymentType } from 'enums/enPaymentType';
import { BaseComponent, IStateBase } from 'components/BaseComponent';
import { unescapeHtml } from '../../../../functions';
import { cartService } from 'services/CartService';
import { ICartModel } from 'interfaces/ICartModel';
import OpenValue from '../../OpenValue';
import Next from '../../Next';
import { WithStyles } from 'shared/decorators/withStyles';
import FreeShipping from './../FreeShipping';
import { enPaymentMethod } from 'enums/enPaymentMethod';
import { SvgIcon } from 'components/SvgIcon';
import { enTerminalMethod } from 'enums/enTerminalMethod';

export enum enLayout {
  column = 'column',
  row = 'row'
}

interface IPriceProps {
  cart: IProductData;
  circle?: boolean;
  showConfigSelectors?: boolean;
  showBorder?: boolean;
  layout?: enLayout;
  hideMultipleInstallments?: boolean;
  isTicket?: boolean;
  step?: number;
}

interface IState extends IStateBase {
  justTitle: boolean;
  payment: ICartModel;
  changeCard: boolean;
  isMultipleCards: boolean;
}

@WithStyles(() => ({
  component: {
    '& .chk-header-title': {
      fontWeight: 900,
      margin: 0,

      '& svg': {
        width: '10px'
      }
    },

    '& .align-left': {
      '& .chk-couponPrice': {
        textAlign: 'left'
      }
    },

    '& button, & a': {
      cursor: 'pointer'
    },

    '&.chk-payment-option': {
      position: 'relative',

      '& .row': {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
      },

      '&.show-border': {
        padding: '2%',
        cursor: 'pointer',
        borderRadius: '4px',
        boxShadow: '0 0 5px 0 rgba(0, 0, 0, .2)'
      },

      '& .chk-header-title': {
        textAlign: 'left',
        fontSize: '1.4rem',
        lineHeight: '1.8rem',
        margin: '0 0 8px 0',
        padding: 0
      },

      '& .chk-parts': {
        fontSize: '1.5rem',
        color: 'rgb(51, 51, 51)',
        '&.multiple-installments-free-physical': {
          margin: '0 0 12px 0'
        },
        '& .chk-free-physical': {
          display: 'flex',
          flexDirection: 'column'
        },
        '&.end': {
          textAlign: 'end',
          width: '50%',
          float: 'right'
        },
        '& .chk-number-parts': {
          fontSize: '.85rem',
          color: '#333',
          paddingRight: '5px'
        },

        '& .chk-small-price': {
          fontSize: '.75rem'
        }
      },

      '& .chk-full': {
        '& .chk-number-price': {
          fontSize: '1.5rem'
        },

        '& .chk-full-price': {
          fontSize: '1.5rem',
          color: 'rgb(51, 51, 51)'
        },

        '& .tax': {
          color: '#333',
          fontSize: '.75rem',

          '& .small-text': {
            fontSize: '.875rem',
            color: '#333'
          }
        }
      },

      '& .chk-currency-language': {
        height: '2rem',
        width: '20rem',
        float: 'right'
      }
    },

    '& .chk-product-infos': {
      display: 'flex',
      alignItems: 'center',

      '& img': {
        maxHeight: '9rem',
        height: 'auto',
        maxWidth: '9rem',
        width: 'auto',
        margin: '0 3% 0 0',
        boxShadow: 'none',
        border: '1px solid #e2e2e2'
      }
    },

    '& .freeShipping': {
      justifyContent: 'flex-start'
    }
  },
  messageChangeCard: {
    textTransform: 'uppercase'
  }
}))
export default class PriceCoupon extends BaseComponent<IState, IPriceProps> {
  constructor(props: IPriceProps) {
    super(props);

    this.state = {
      justTitle: false,
      payment: undefined,
      changeCard: false,
      isMultipleCards: false
    };
  }

  componentDidMount() {
    paymentService
      .getPaymentResult()
      .take(1)
      .subscribe(() => {
        this.setState({ justTitle: true });
      });

    this.observeState({ payment: undefined }, paymentService.getCart());
    this.observeState({ isMultipleCards: false }, paymentService.isMultipleCreditCards());
  }

  setValueOnCart = (value: any) => {
    const newValue = { value: Number(value.replace(/\D+/gi, '')) / 100 };
    const price = { price: Number(value.replace(/\D+/gi, '')) / 100 };
    cartService.setCart(this.props.cart, price);
    paymentService.setPaymentData(0, newValue);
  };

  render() {
    const { classes, cart, isTicket, step, hideMultipleInstallments } = this.props;
    const { installments, config } = cart;

    const { payment, isMultipleCards } = this.state;

    if (!this.state || !installments || !payment) {
      return null;
    }
    const [firstPayment] = payment.payment;

    const { paymentMethod, method } = firstPayment;

    const isFree = config.paymentType === enPaymentType.FREE;
    const isSubscription = config.paymentType === enPaymentType.SUBSCRIPTION;
    const isTerminal = paymentMethod === enPaymentMethod.TERMINAL && method === enTerminalMethod.CREDIT;

    const isWithInstallments = isTerminal || [enPaymentMethod.CREDIT_CARD, enPaymentMethod.ONE_CLICK_BUY].includes(paymentMethod);

    return (
      <Grid item xs={12} className={classes.component}>
        <h4 className="chk-header-title theme-label" id="chk-header-product-title">
          {isTicket && <SvgIcon name="ticket" />} {unescapeHtml(cart.content.title)}
        </h4>
        <div className={step == 4 ? 'align-left' : ''}>
          <CouponPrice />
        </div>
        {[enPaymentType.NORMAL, enPaymentType.ECOMMERCE].includes(config.paymentType) &&
          config.paymentType !== enPaymentType.DONATION &&
          (config.enableCreditcard &&
          isWithInstallments &&
          payment.payment[0].numberInstallments > 1 &&
          !hideMultipleInstallments &&
          !isMultipleCards ? (
            <MultipleInstallments cart={cart} isTicket={isTicket} />
          ) : (
            <WithoutInstallment cart={cart} />
          ))}
        <FreeShipping config={config} />
        <OpenValue />
        <Next />
        {isFree && <Translate term={'Gratuito'} />}
        {isSubscription && <Subscription payment={payment} cart={cart} />}
        {(!step || step !== 4) && (
          <Grid item xs={6}>
            <Coupon />
          </Grid>
        )}
      </Grid>
    );
  }
}
