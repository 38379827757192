import Grid from '@material-ui/core/Grid';
import FieldText from 'components/Field/Text';
import React from 'react';
import { enPagdivididoSteps } from '../..';
import NextStep from '../../NextStep';
import { useMappedObservable } from 'shared/hooks/useMappedObservable';
import { paymentService } from 'services/PaymentService';
import { IPagDivididoModel } from 'interfaces/ICartModel';
import { useCallback } from 'react';
import CityState from 'components/UserAddressForm/ContextAddress/Br/CityState';
import StepTitle from '../../StepTitle';
import MenuItem from '@material-ui/core/MenuItem/MenuItem';
import FieldSelect from 'components/Field/Select';
import { configService } from 'services/ConfigService';
import { useObservable } from 'react-use-observable';
import { Hidden } from '@material-ui/core';

const PersonalInfo: React.FC = () => {
  const [pagData] = useMappedObservable(
    () => paymentService.getCart(),
    (data) => ({
      ...(data.pagDividido || ({} as IPagDivididoModel)),
      document: data.document,
      personType: data.personType
    }),
    []
  );

  const [config] = useObservable(() => configService.getConfig(), []);

  const setDocument = useCallback((document: string) => {
    paymentService.setData({ document });
  }, []);

  const setFieldValue = useCallback(
    (field: string, value: string) => {
      const newPagdata = { ...(pagData || {}) };
      newPagdata[field] = value;
      paymentService.setData({ pagDividido: newPagdata });
    },
    [pagData]
  );

  const setCity = useCallback(
    (data: any) => {
      const newPagdata = { ...(pagData || {}), ...data };
      paymentService.setData({ pagDividido: newPagdata });
    },
    [pagData]
  );

  const genders = [
    { value: 'M', name: 'Masculino' },
    { value: 'F', name: 'Feminino' },
    { value: 'O', name: 'Prefiro não identificar' }
  ];

  if (!pagData || !config) {
    return null;
  }

  const rule = (pagData.document || '').length > 11 ? 'cnpj' : 'cpf';
  const templateId = config?.template?.id;

  return (
    <Grid container spacing={2}>
      <StepTitle title="Preencha as informações referentes ao seu nascimento" step={enPagdivididoSteps.PERSONAL_INFO} />
      <Grid item xs={12}>
        <CityState
          prefixId="pagdividido"
          labels={{ province: 'Estado de nascimento', city: 'Cidade de Nascimento' }}
          disableAutocompleteOnProvince
          template={templateId}
          value={{ province: pagData.province, city: pagData.city }}
          onChange={setCity}
        />
      </Grid>
      <Grid item xs={6}>
        <FieldText
          id="birthDate"
          label="Data de Nascimento"
          value={pagData.birthDate}
          validation="required|birthdate"
          mask="date"
          inputProps={{
            className: `theme-input`,
            pattern: '[0-9]*',
            inputMode: 'numeric',
            type: 'tel'
          }}
          InputLabelProps={{
            className: 'theme-label'
          }}
          onChange={(value) => setFieldValue('birthDate', value)}
        />
      </Grid>
      <Grid item xs={6}>
        <FieldSelect
          id="gender"
          className="state-container field normal"
          value={pagData.gender}
          validation="required"
          InputLabelProps={{
            className: 'theme-label'
          }}
          inputProps={{
            className: 'theme-input-select br field normal',
            id: 'select-gender'
          }}
          label="Gênero"
          onChange={(value) => setFieldValue('gender', value)}
        >
          {genders.map((gender) => (
            <MenuItem key={gender.value} value={gender.value}>
              {gender.name}
            </MenuItem>
          ))}
        </FieldSelect>
      </Grid>
      <Hidden mdUp={templateId === 18}>
        <Grid item xs={12}>
          <FieldText
            id="document"
            label="CPF/CNPJ"
            value={pagData.document}
            validation={pagData.personType != 'E' ? 'required|' + rule : 'required|min:3|max:95'}
            mask="cpfCnpj"
            inputProps={{
              className: `themeinput`,
              pattern: '[09]*',
              inputMode: 'numeric',
              type: 'tel'
            }}
            InputLabelProps={{
              className: 'themelabel'
            }}
            onChange={(value) => setDocument(value)}
          />
        </Grid>
      </Hidden>

      <NextStep allowBack={true} buttonText="Continuar" toStep={enPagdivididoSteps.SIMULATION} />
    </Grid>
  );
};

export default React.memo(() => <PersonalInfo />);
