import React from 'react';
import { IProductConfig } from 'interfaces/IProductData';
import { BaseComponent } from 'components/BaseComponent';
import { cartService } from 'services/CartService';
import { IStateBase } from 'components/BaseComponent';
import { enProductType } from 'enums/enProductType';
import QuantityForm from 'components/Cart/Physical/QuantityForm';
import ShippingForm from 'components/Cart/Physical/ShippingForm';
import ContextAddress from './ContextAddress';
import { IUserAddressModel } from 'interfaces/IUserAddressModel';
import { paymentService } from 'services/PaymentService';
import SaleRecoveryAddressData from './SaleRecoveryAddressData';

interface IState extends IStateBase {
  config: IProductConfig;
  model: IUserAddressModel;
  isAddressDataEditLocked: boolean;
  personType: 'E' | 'F' | 'J';
}

export default class UserAddressForm extends BaseComponent<IState> {
  componentDidMount() {
    this.observeState({ config: null }, cartService.getConfig());
    this.observeState({ model: null }, paymentService.getAddress('address'));
    this.observeState(
      { personType: null },
      paymentService.getCart().map((c) => c.personType)
    );

    cartService.isContractSaleRecovery().subscribe((isContractSaleRecovery) => this.setState({ isAddressDataEditLocked: isContractSaleRecovery }));
  }

  onChange = (data: Partial<IUserAddressModel>) => {
    paymentService.setAddressData('address', data || {});
  };

  render() {
    if (!this.state) {
      return null;
    }

    if (this.state.isAddressDataEditLocked) {
      return <SaleRecoveryAddressData />;
    }

    const { config, personType } = this.state;

    return (
      <div>
        {config.productType === enProductType.PHYSICAL && !config.askAddressAfterPayment && <QuantityForm />}
        <ContextAddress data={this.state.model} onChange={this.onChange} personType={personType} />
        {config.productType === enProductType.PHYSICAL && config.askAddressBeforePayment && <ShippingForm />}
      </div>
    );
  }
}
