import React from 'react';
import { BaseComponent, IStateBase } from 'components/BaseComponent';
import { cartService } from 'services/CartService';
import { ICoupon } from 'interfaces/IProductData';
import { CurrencyFormat } from 'components/CurrencyFormat';
import Translate from 'components/Translate';
import { WithStyles } from 'shared/decorators/withStyles';

interface IState extends IStateBase {
  cart: { realPrice: number; realTax: number; coupon: ICoupon };
}

@WithStyles(() => ({
  component: {
    fontSize: '0.8rem',
    textDecoration: 'line-through'
  }
}))
export default class CouponPrice extends BaseComponent<IState> {
  componentDidMount() {
    this.observeState(
      { cart: null },
      cartService.getMappedCart((cart) => ({
        realPrice: cart.realPrice,
        realTax: cart.realTax,
        coupon: cart.coupon
      }))
    );
  }

  render() {
    if (!this.state) {
      return null;
    }

    const { cart } = this.state;

    if (!cart.coupon) {
      return null;
    }

    return (
      <div className={`${this.props.classes.component} chk-couponPrice`}>
        <Translate
          term="De {realPrice} por"
          properties={{
            realPrice: <CurrencyFormat id="real-price" value={cart.realPrice + cart.realTax} />
          }}
        />
      </div>
    );
  }
}
