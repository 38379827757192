import React from 'react';
import { BaseComponent } from 'components/BaseComponent';
import { getCdnImage } from 'functions';
import { WithStyles } from 'shared/decorators/withStyles';

interface IProps {
  image: string;
}

@WithStyles(() => ({
  component: {
    backgroundSize: 'cover',
    border: '5px solid white',
    borderRadius: '100%',
    width: '100px',
    height: '100px'
  }
}))
export default class Logo extends BaseComponent<any, IProps> {
  render() {
    const customStyle = {
      logo: {
        backgroundImage: `url(${getCdnImage(this.props.image)})`
      }
    };

    return <div className={`chk-logo__content ${this.props.classes.component}`} style={customStyle.logo} id="logo-content" />;
  }
}
