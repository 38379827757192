import { BaseComponent, IStateBase } from 'components/BaseComponent';
import { ICartModel } from 'interfaces/ICartModel';
import { IProductData } from 'interfaces/IProductData';
import Button from '@material-ui/core/Button/Button';
import Grid from '@material-ui/core/Grid/Grid';
import React from 'react';
import { cartService } from 'services/CartService';
import { paymentService } from 'services/PaymentService';
import { IStepProps } from './index';
import Payment from 'components/Cart/MainForm/Payment';
import { MAX_CREDIT_CARDS } from 'settings';
import Snackbar from '@material-ui/core/Snackbar';
import { FieldValidation } from 'components/Field';
import { SvgIcon } from 'components/SvgIcon';
import HeaderStep from './HeaderStep/index';
import Translate from 'components/Translate';
import AcceptTerms from 'components/AcceptTerms';
import Helmet from 'react-helmet';
import { enPaymentType } from 'enums/enPaymentType';
import QuantityForm from 'components/Cart/Physical/QuantityForm';
import OrderBump from '../../../OrderBump';
import Divider from '@material-ui/core/Divider/Divider';
import OpenValue from 'components/Cart/OpenValue';
import Next from 'components/Cart/Next';
import Summary from 'components/Cart/Summary';
import PaymentButton from 'components/Cart/PaymentButton';
import { getPaymentButtonMessage } from 'functions';
import { enPaymentMethod } from 'enums/enPaymentMethod';
import { AcceptProducerTerms } from 'components/AcceptProducerTerms';
import FormHeader from 'components/Cart/FormHeader';

interface IState extends IStateBase {
  cart: IProductData;
  model: ICartModel;
  showMessage: boolean;
  snackMessage: string;
  messageButton: string;
  showPaymentMultipleCardsButton: boolean;
}

export interface IProps extends IStepProps {
  onPay: () => void;
  step: number;
}

export default class StepPayment extends BaseComponent<IState, IProps> {
  componentDidMount() {
    this.observeState({ cart: null }, cartService.getCart());
    this.observeState({ showPaymentMultipleCardsButton: false }, paymentService.showPaymentMultipleCardsButton());
    this.bindModel(paymentService.getCart());

    paymentService
      .getCart()
      .combineLatest(cartService.getCart(), cartService.getConfig())
      .bindComponent(this)
      .subscribe(([payment, cart, config]) => {
        this.setState({ messageButton: getPaymentButtonMessage(payment, cart, config) });
      });
  }

  onValidate(errors: any) {
    this.setState({ validation: errors || {} });
  }

  isValid(): boolean {
    const validation = this.state.validation || {};
    return Object.keys(validation).length === 0;
  }

  finish = async () => {
    if (await this.isFormValid()) {
      this.props.onPay();
    }
  };

  addCreditCard = () => {
    paymentService
      .addPayment()
      .loader()
      .bindComponent(this)
      .take(1)
      .subscribe(() => {
        this.setState({
          showMessage: true,
          snackMessage: 'Um novo cartão foi adicionado'
        });
      });
  };

  showAddCardButton = () => {
    const isMultipleCreditCards = this.state.cart.config.multipleCreditCards;
    const canAddCreditCard = this.state.model.payment.length < MAX_CREDIT_CARDS && this.state.model.payment.length > 1;

    return isMultipleCreditCards && canAddCreditCard;
  };

  handleClose = () => {
    this.setState({
      showMessage: false
    });
  };

  removeCreditCard = () => {
    this.setState({
      showMessage: true,
      snackMessage: 'O cartão foi removido'
    });
  };

  render() {
    if (!this.state || !this.state.cart) {
      return '';
    }
    const { messageButton, cart, showPaymentMultipleCardsButton, model, formSubmitted } = this.state;
    const disabledButton = !this.isValid() && formSubmitted;
    const isPagDividido = model.payment[0].paymentMethod === enPaymentMethod.PAGDIVIDIDO;

    return (
      <div>
        <Helmet>
          <body className="step-payment" />
        </Helmet>
        <FormHeader />
        <HeaderStep step={this.props.step} setStep={this.props.setStep} />
        <FieldValidation.Provider value={this.registerFields}>
          <OpenValue />
          <Divider />
          <div className="step">
            <Grid className="grid step-payment" container>
              {cart.pins && (
                <div className="chk-stepPayment__pin">
                  <QuantityForm isPin={!!cart.pins} />
                </div>
              )}

              <Grid item xs={12}>
                <Next />
              </Grid>

              <div style={{ width: '100%' }}>
                {showPaymentMultipleCardsButton && (
                  <Grid item xs={12}>
                    <div className="chk-multiple-cards">
                      <Button id="chk-init-multiplecards-button" className={'chk-add-credit-card theme-button'} onClick={this.addCreditCard}>
                        <SvgIcon name="multipleCards" />
                        <Translate term="Pagar com mais cartões?" />
                      </Button>
                    </div>
                  </Grid>
                )}

                {model.payment.map((model, key) => (
                  <Payment
                    key={key}
                    index={key}
                    cardFlagLimit={6}
                    validateForm={this.isFormValid.bind(this)}
                    hidePaymentMessage={true}
                    removeCreditCard={this.removeCreditCard}
                  />
                ))}

                <div className="add-card">
                  {this.showAddCardButton() ? (
                    <Button id="chk-add-multiplecard-button" className={'chk-add-credit-card add theme-button'} onClick={this.addCreditCard}>
                      <SvgIcon name="plus" />
                      <Translate term="Adicionar cartão de pagamento" />
                    </Button>
                  ) : (
                    ''
                  )}
                </div>
              </div>

              <Grid container>
                <Grid item xs={6}>
                  <div className="security">
                    <SvgIcon name="security_check" />
                    <div className="text">
                      <p>
                        <Translate term="Compra" />
                      </p>
                      <p>
                        <Translate term="100% Segura" />
                      </p>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div className="security">
                    <SvgIcon name="security_finger" />
                    <div className="text">
                      <p>
                        <Translate term="Privacidade" />
                      </p>
                      <p>
                        <Translate term="Protegida" />
                      </p>
                    </div>
                  </div>
                </Grid>
              </Grid>
              {cart.config.paymentType === enPaymentType.SUBSCRIPTION && !isPagDividido && (
                <Grid item xs={12}>
                  <AcceptTerms />
                </Grid>
              )}

              <AcceptProducerTerms termsUrl={cart.config.termsUrl} />

              {!isPagDividido && <OrderBump />}

              {!isPagDividido && <Summary />}
            </Grid>

            <PaymentButton finish={this.finish} hidden={isPagDividido} disabledButton={disabledButton} messageButton={messageButton} />

            <Snackbar
              id="chk-cardmessage"
              className={'card-message'}
              open={this.state.showMessage}
              onClose={this.handleClose}
              autoHideDuration={1000}
              message={<span>{this.state.snackMessage}</span>}
            />
          </div>
        </FieldValidation.Provider>
      </div>
    );
  }
}
