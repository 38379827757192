import React from 'react';
import Code from './Code';
import Download from './Download';
import { ThankYouContext } from 'components/ThankYou';

interface IProps {
  thankyouUrl?: string;
  affiliateId: number;
}

export const Bankslip = (props: IProps) => (
  <ThankYouContext.Consumer>
    {(model) => (
      <div className="bankslip">
        <Code code={model.paymentResult.bankslipCode} thankyouUrl={props.thankyouUrl || null} />
        <Download affiliateId={props.affiliateId} bankslipUrl={model.paymentResult.bankslipUrl} thankyouUrl={props.thankyouUrl || null} />
      </div>
    )}
  </ThankYouContext.Consumer>
);
