import { BaseComponent, IStateBase } from 'components/BaseComponent';
import React, { Fragment } from 'react';
import { paymentService } from 'services/PaymentService';
import { CreditCardPaymentError } from './CreditCardPaymentError/index';
import { enPaymentMethod } from 'enums/enPaymentMethod';
import { PaypalPaymentError } from './PaypalPaymentError/index';
import { BankslipPaymentError } from './BankslipPaymentError/index';
import { WalletPaymentError } from './WalletPaymentError/index';

interface IState extends IStateBase {
  paymentMethod: enPaymentMethod;
}

interface IProps {
  message: string;
  code: string;
  idleState: () => void;
}

export default class PaymentError extends BaseComponent<IState, IProps> {
  componentDidMount() {
    paymentService
      .getCart()
      .bindComponent(this)
      .subscribe((payment) => {
        this.setState({ paymentMethod: payment.payment[0].paymentMethod });
      });
  }

  paymentMethodComponent = () => {
    const { message, idleState, code } = this.props;
    const { paymentMethod } = this.state;

    if (paymentMethod === enPaymentMethod.BANKSLIP) {
      return <BankslipPaymentError message={message} code={code} idleState={idleState} />;
    }
    if (paymentMethod === enPaymentMethod.PAYPAL) {
      return <PaypalPaymentError message={message} code={code} idleState={idleState} />;
    }
    if (paymentMethod === enPaymentMethod.EDUZZ) {
      return <WalletPaymentError message={message} code={code} idleState={idleState} />;
    }

    return <CreditCardPaymentError message={message} code={code} idleState={idleState} />;
  };

  render() {
    if (!this.state) {
      return null;
    }

    return (
      <Fragment>
        <this.paymentMethodComponent />
      </Fragment>
    );
  }
}
