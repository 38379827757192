import { Styles } from '@material-ui/styles/withStyles';
import { DefaultTheme } from '@material-ui/styles';

export const styles: Styles<DefaultTheme, {}, 'component'> = {
  component: {
    '& span': {
      '&.chk-icon': {
        borderTop: '5px solid #000',
        borderLeft: '5px solid transparent',
        borderRight: '5px solid transparent',
        borderBottom: '5px solid transparent'
      }
    },

    '& .chk-header': {
      position: 'relative',
      height: '7rem',
      backgroundColor: '#596375'
    },

    '& .chk-title': {
      alignItems: 'center',
      display: 'flex',
      padding: '0 14px 0 24px',
      margin: '40px 0 40px 0',
      width: '100%',

      '@media screen and (max-width: 640px)': {
        display: 'flex'
      },

      '& .chk-close': {
        display: 'flex',
        backgroundColor: '#cecece',
        height: '25px',
        margin: '0 0 0 5px',
        width: '25px',
        borderRadius: '50px',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',

        '&:hover': {
          backgroundColor: '#b3b3b3'
        },

        '& svg': {
          width: '9px'
        }
      }
    },

    '& .chk-span-icon': {
      alignItems: 'center',
      display: 'flex',
      width: '100%',

      '& span': {
        color: '#596375',
        fontSize: '1.3rem',
        fontWeight: 700,
        margin: '3%'
      },

      '& svg': {
        fill: '#F59211',
        width: '1.5rem'
      }
    },

    '& .chk-expansion-panel': {
      margin: 0,
      border: 0,
      boxShadow: 'none',
      width: '100%',
      display: 'block',

      '&::before': {
        position: 'relative'
      },

      '& .chk-expansion-panel-detail': {
        display: 'flex',
        flexDirection: 'column',
        boxShadow: 'inset 0 0 100px 1px rgba(0, 0, 0, .1)',

        '& .sub-title': {
          margin: '0 0 0 25px',
          fontSize: '0.975rem'
        },

        '& p': {
          color: 'rgba(89, 99, 117, 1)',
          textAlign: 'justify'
        },

        '& ul': {
          color: 'rgba(89, 99, 117, 1)',
          textAlign: 'justify',
          padding: '0 0 0 24px',

          '& li': {
            fontSize: '0.875rem'
          }
        }
      },

      '& .chk-expansion-title': {
        color: '#596375',
        fontSize: '1rem'
      }
    },

    '& .chk-central-help': {
      '& .producer-contact': {
        marginBottom: '30px'
      },

      fontSize: '1rem',
      textAlign: 'center',
      lineHeight: 0.5,
      marginTop: '20%',

      '@media screen and (max-width: 640px)': {
        marginTop: '5%'
      },

      '& p': {
        color: '#596375'
      }
    }
  }
};
