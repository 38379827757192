import * as React from 'react';
import Grid from '@material-ui/core/Grid';

import { paymentService } from 'services/PaymentService';
import { BaseComponent, IStateBase } from 'components/BaseComponent';
import FieldText from 'components/Field/Text';
import Fab from '@material-ui/core/Fab';
import RemoveIcon from '@material-ui/icons/Remove';
import AddIcon from '@material-ui/icons/Add';
import Translate from 'components/Translate';
import { WithStyles } from 'shared/decorators/withStyles';
import { Observable } from 'rxjs/Observable';
import { IProductModel } from 'interfaces/ICartModel';

interface IState extends IStateBase {
  limit: number;
  productModel: IProductModel;
  hideQuantity: boolean;
  index: number;
}

interface IProps {
  limit: number;
  productId: string;
  isPin?: boolean;
  isTicket?: boolean;
  hideLabel?: boolean;
  hideQuantity?: boolean;
}

@WithStyles(() => ({
  component: {
    display: 'hidden',

    '& .quantityTicket': {
      height: '12px',
      textAlign: 'end',
      width: '85px',

      color: 'blue'
    },

    '& .quantity-content': {
      display: 'flex',
      alignItems: 'center',

      '& .quantity-form': {
        width: '120px',
        margin: '12px 8px',
        border: 'none !important',
        borderRadius: '2px'
      }
    },

    '& .quantity-form__ticket': {
      backgroundColor: '#f5f7f8',
      '& .field.normal': {
        margin: 0,
        padding: 0,
        width: '50px'
      },

      '& .field.normal input': {
        color: '#596375',
        padding: 0,
        margin: '0 8px',
        width: '50px',
        boxShadow: 'none',
        border: 'none',
        textAlign: 'center'
      },

      '& .quantityTicket': {
        height: '36px'
      },

      '& .fab__ticket': {
        backgroundColor: 'transparent',
        boxShadow: 'none',
        width: '25px',
        height: '25px',
        border: 'none',
        color: '#596375',

        '&:disabled': {
          borderColor: '#ccc',
          color: '#ccc',

          '& svg': {
            borderColor: '#ccc'
          }
        },

        '& svg': {
          fontSize: '1rem',
          width: '20px',
          height: '20px',
          border: '1px solid #596375',
          borderRadius: '100%'
        }
      }
    }
  }
}))
export default class OrderBumpQuantityForm extends BaseComponent<IState, IProps> {
  componentDidMount() {
    paymentService
      .getCart()
      .filter((paymentCart) => !!paymentCart.products)
      .map((paymentCart) => paymentCart.products.findIndex((p) => p.contentId === this.props.productId))
      .filter((index) => index !== -1)
      .switchMap((index) => Observable.combineLatest(Observable.of(index), paymentService.getProduct(index)))
      .filter(([, product]) => !!product)
      .bindComponent(this)
      .subscribe(([index, product]) => {
        this.setState({ index });

        const productModel: IProductModel = {
          contentId: product.contentId,
          quantity: product.quantity
        };

        this.setState({ productModel });
      });
  }

  handleChange = (quantity: number) => {
    if (quantity == this.state.productModel.quantity) {
      return;
    }

    if (quantity > this.state.limit) {
      quantity = this.state.limit;
    }

    if (quantity <= 0) {
      quantity = 1;
    }

    paymentService.setProductData(this.state.index, { quantity: Number(quantity) });
  };

  decrement = () => {
    if (this.state.productModel.quantity <= 1) {
      paymentService.setProductData(this.state.index, { quantity: 1 });
      return;
    }

    paymentService.setProductData(this.state.index, { quantity: this.state.productModel.quantity - 1 });
  };

  increment = () => {
    if (this.state.productModel.quantity >= this.props.limit) {
      paymentService.setProductData(this.state.index, { quantity: this.props.limit });
      return;
    }

    paymentService.setProductData(this.state.index, { quantity: this.state.productModel.quantity + 1 });
  };

  render() {
    if (!this.state || !this.state.productModel) {
      return null;
    }

    const { quantity } = this.state.productModel;
    const { hideQuantity, productId: product, limit } = this.props;

    if (hideQuantity || !product) {
      return null;
    }

    return (
      <React.Fragment>
        <div className={this.props.classes.component}>
          <Grid id="ticket_price_unique" className="item right" item xs={12}>
            <div className="quantity-content">
              <div className="quantity-label theme-label">
                <Translate term="Quantidade" />
              </div>
              <div className="quantity-form theme-input">
                <Grid className="quantity-form__ticket" direction={'row'} alignItems={'center'} justify={'flex-start'} wrap={'nowrap'} container>
                  <Fab className="fab__ticket" disabled={quantity === 1} onClick={this.decrement} size="small">
                    <RemoveIcon />
                  </Fab>
                  <FieldText
                    id="quantity"
                    label={''}
                    validation={`required|numeric|min:1${limit > 0 ? `|max:${limit}` : ''}`}
                    disabled={true}
                    value={quantity}
                    inputProps={{
                      className: 'quantityTicket quantity'
                    }}
                    InputLabelProps={{
                      className: 'theme-label',
                      hidden: hideQuantity
                    }}
                    onChange={this.handleChange}
                  />
                  <Fab className="fab__ticket" disabled={quantity >= limit} onClick={this.increment} size="small">
                    <AddIcon />
                  </Fab>
                </Grid>
              </div>
            </div>
          </Grid>
        </div>
      </React.Fragment>
    );
  }
}
