import { Observable } from 'rxjs/Observable';
import { translateService } from 'services/TranslateService';
import { alertService } from '../../services/AlertService';

export function showAlert(message: string, title: string = 'Alerta', confirmation: boolean = false, code: string = undefined): Observable<boolean> {
  return Observable.of(true).switchMap(() => alertService.show(message, title, confirmation, code));
}

export function showError(err: any): Observable<boolean> {
  const isRedirect = (err && err.data && err.data.redirectTo) || false;
  if (isRedirect) {
    return Observable.of(true).switchMap(() => new Promise(() => {}));
  }

  const code: string = (err && err.data && err.data.code) || '';

  return translateService.getErrorData(code).switchMap((errorData) => showAlert(errorData.message, errorData.title || 'Ops!', false, code));
}

export function showConfirm(message: string, title: string = 'Confirmação'): Observable<boolean> {
  return Observable.of(true).switchMap(() => showAlert(message, title, true));
}
