import { AxiosResponse } from 'axios';

import { ServiceError } from './ServiceError';

interface IApiErrorMeta {
  request: {
    baseURL: string;
    url: string;
    method: string;
    params: any;
    data: any;
    headers: any;
  };
  response?: {
    status: number;
    data?: any;
  };
  err: any;
}

export class ApiError extends ServiceError<IApiErrorMeta> {
  public readonly status: number;
  public readonly data: any;

  constructor(error: any, axiosResponse: AxiosResponse) {
    const request = error.config
      ? {
          baseURL: error.config.baseURL,
          url: error.config.url,
          method: error.config.method,
          params: error.config.params,
          data: error.config.data,
          headers: error.config.headers
        }
      : null;
    const response = !axiosResponse ? { status: -1, data: '' } : { status: axiosResponse.status, data: axiosResponse.data };

    const code = response && response.data && response.data.code;
    const redirectTo = response && response.data && response.data.redirectTo;

    if (redirectTo) {
      window.location.href = redirectTo;
    }

    const message =
      response.status === -1
        ? 'Erro ao acessar servidores, verifique sua conexão com a internet e tente novamente'
        : code
        ? response.data.message
        : 'Não foi possível se comunicar com nossos servidores. Tente novamente mais tarde';

    super(
      message,
      {
        request,
        response,
        err: error
      },
      false
    );

    this.status = response.status;
    this.data = response.data;
  }
}
