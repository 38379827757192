import { makeStyles } from '@material-ui/core';
import { SvgIcon } from 'components/SvgIcon';
import Translate from 'components/Translate';
import React from 'react';

interface IProps {
  title: string;
  closeMenu: () => void;
}

const useStyles = makeStyles({
  container: {
    marginBottom: 18,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',

    '& svg': {
      width: 10
    }
  },
  button: {
    background: 'none',
    border: 'none',
    cursor: 'pointer'
  }
});

const MenuHeader: React.FC<IProps> = React.memo(({ title, closeMenu }) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Translate term={title} />
      <button className={classes.button} onClick={closeMenu}>
        <SvgIcon name="closeIcon" />
      </button>
    </div>
  );
});

export { MenuHeader };
