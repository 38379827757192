import React from 'react';
import { CurrencyFormat } from 'components/CurrencyFormat';
import { useObservable } from 'react-use-observable';
import { paymentService } from 'services/PaymentService';

const Component: React.FC = () => {
  const [value] = useObservable(() => {
    return paymentService.getCart().map((data) => data.payment[0].value);
  }, []);

  return (
    <div className="chk-pix-product-text">
      Pague{' '}
      <span>
        <CurrencyFormat value={value} />
      </span>{' '}
      via Pix para receber seu produto
    </div>
  );
};

export const PixPanelHeaderText = React.memo(Component);
