/* eslint-disable max-len */
import React from 'react';
import { memo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { enPaymentType } from 'enums/enPaymentType';
import { enPaymentMethod } from 'enums/enPaymentMethod';
import { IThankYou } from 'services/ThankYouService';
interface IProps {
  paymentMethod: enPaymentMethod;
  thankYou: IThankYou;
}

const useStyles = makeStyles((theme) => ({
  title: {
    color: '#596375',
    fontWeight: 'bold',
    margin: 0
  },
  text: {
    color: '#939393',
    margin: 0
  },
  message: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      display: 'flex',
      flexFlow: 'row wrap'
    }
  }
}));

const MensageThankYou = memo((props: IProps) => {
  const style = useStyles(props);
  const { thankYou, paymentMethod } = props;
  const message = {
    [enPaymentMethod.BANKSLIP]: 'Agora é so pagar o boleto.',
    [enPaymentMethod.PAGDIVIDIDO]: 'Fique atento, você receberá um SMS para finalizar o processo. Verifique seu e-mail'
  };

  return (
    <div className={style.message}>
      <p className={style.title}>{thankYou.paymentType === enPaymentType.FREE ? 'Obrigado!' : 'Obrigado pela compra!'}&nbsp;</p>
      <p className={style.text}>{message[paymentMethod] ?? 'Verifique seu e-mail'}</p>
    </div>
  );
});

export default MensageThankYou;
