import { Observable } from 'rxjs/Observable';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { cartService } from 'services/CartService';
import { isLoading } from 'shared/operators/loader';
import { IActiveModal } from 'interfaces/IExitPopUp';
import { PREVIEW_MODE } from 'settings';
import { checkLocalStorage } from 'functions';

export class ExitPopUpService {
  private activeModal$ = new BehaviorSubject<IActiveModal>({ id: '', time: '' });
  private postMessage$ = Observable.fromEvent(window, 'message')
    .filter((event: MessageEvent) => {
      // TODO - ALTERAR NO CUSTOMIZADOR DE CHECKOUT PARA ENVIAR OBJETO!
      return typeof event.data === 'string' && event.data.substring(0, 9) === 'template:';
    })
    .map((event: MessageEvent) => JSON.parse(event.data.substring(9)));

  public mouseEvent = (): Observable<any> => {
    return Observable.fromEvent(document.querySelector('body'), 'mousemove')
      .map((e: any) => e.y)
      .filter(() => !PREVIEW_MODE)
      .filter((y: any) => y > 25)
      .first()
      .switchMap(() => Observable.fromEvent<any>(document.querySelector('body'), 'mouseout'))
      .filter(() => !isLoading())
      .combineLatest(cartService.getCart(), this.activeModal())
      .filter(([, cart]) => !cart.coupon)
      .do(([, cart]) => {
        if (checkLocalStorage() && window.localStorage.getItem(cart.products[0].id.toString())) {
          this.activeModal$.next(JSON.parse(window.localStorage.getItem(cart.products[0].id.toString())));
        }
      })
      .filter(([e, cart, activeModal]) => e.clientY <= 1 && activeModal.id != cart.products[0].id.toString())
      .switchMap(([, cart]) => {
        let d = new Date().getTime();
        let infos = JSON.stringify({ id: cart.products[0].id, time: d });

        if (checkLocalStorage()) {
          window.localStorage.setItem(cart.products[0].id.toString(), infos);
        }

        return Observable.of(new Date(d).getTime() + cart.config.exitModal.timer.time * 60000);
      });
  };

  public activeModal = (): Observable<any> => {
    return this.activeModal$.asObservable();
  };

  public verifyTimer = (): Observable<any> => {
    return cartService
      .getCart()
      .filter((cart) => !cart.coupon)
      .take(1)
      .switchMap((cart) => {
        let actualValue: any = new Date().getTime();
        let aux = checkLocalStorage() ? JSON.parse(window.localStorage.getItem(cart.products[0].id.toString())) : '';
        let registredValue: any = aux ? new Date(aux.time).getTime() + cart.config.exitModal.timer.time * 60000 : null;
        return Observable.of([registredValue, registredValue - actualValue]);
      })
      .filter(([, keepOpen]) => {
        return keepOpen >= 0;
      })
      .switchMap(([registredValue]) => Observable.of(registredValue));
  };

  listenPostMessage = (): Observable<any> => {
    return this.postMessage$;
  };
}

// tslint:disable-next-line:variable-name
export const ExitPopUpServiceFactory = () => new ExitPopUpService();

export const exitPopUpService = ExitPopUpServiceFactory();
