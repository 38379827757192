import React from 'react';
import { BaseComponent, IStateBase } from 'components/BaseComponent';
import { IProductData } from 'interfaces/IProductData';
import { cartService } from 'services/CartService';
import Translate from 'components/Translate';

interface IState extends IStateBase {
  cart: IProductData;
}

export default class FeeInstallments extends BaseComponent<IState> {
  componentDidMount() {
    this.observeState({ cart: null }, cartService.getCart());
  }

  render() {
    if (!this.state || !this.state.cart.installments) {
      return null;
    }

    const { cart } = this.state;
    return (
      <span>
        {cart.installments.length > 1 ? (
          <span className="chk-installment-fee theme-footer">
            {cart.feeInstallment > 0 ? (
              <Translate
                term="* Pagamento parcelado com acréscimo de {interest}% a.m."
                properties={{
                  interest: cart.feeInstallment.toFixed(2)
                }}
              />
            ) : (
              <Translate term="Parcelamento sem juros" />
            )}
          </span>
        ) : null}
      </span>
    );
  }
}
