import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem/MenuItem';
import FieldAutocomplete from 'components/Field/Autocomplete';
import FieldSelect from 'components/Field/Select';
import Translate from 'components/Translate';
import React, { useCallback, useState, useEffect, useContext } from 'react';
import { getCitiesByState, getProvinces } from 'services/LocationService';
import { useObservable } from 'react-use-observable';
import { FieldValidation } from 'components/Field';

export interface ICityState {
  province: string;
  city: string;
  cityId?: string;
  country?: string;
}

interface IProps {
  value: ICityState;
  onChange: (result: ICityState) => void;
  disableAutocompleteOnProvince?: boolean;
  prefixId: string;
  template: number;
  labels?: {
    city?: string;
    province?: string;
  };
}

const CityState: React.FC<IProps> = ({ labels, value, template, onChange, disableAutocompleteOnProvince, prefixId }) => {
  const [city, setCity] = useState('');
  const [province, setProvince] = useState('');

  labels = (labels || {}) as any;

  if (!labels.city) labels.city = 'Cidade';
  if (!labels.province) labels.province = labels.province || 'Estado';

  const validationContext = useContext(FieldValidation);
  useEffect(() => {
    setProvince(value.province);
    setCity(value.city);
  }, [value, value.city, value.province]);

  useEffect(() => {
    validationContext.update();
  }, [city, province, validationContext]);

  const [provinces] = useObservable(() => getProvinces(), []);
  const [cities] = useObservable(() => getCitiesByState(province).retry(5).loader(), [province]);

  const selectProvince = useCallback(
    (province: string) => {
      setProvince(province);
      onChange({ city, province });
    },
    [onChange, city]
  );

  const selectCity = useCallback(
    (city: string) => {
      setCity(city);
      onChange({ city, province });
    },
    [province, onChange]
  );

  if (!provinces) {
    return null;
  }

  return (
    <Grid container spacing={template === 18 ? 2 : 1}>
      <Grid item sm={province !== undefined && template === 18 ? 6 : 12} xs={12}>
        {disableAutocompleteOnProvince ? (
          <FieldSelect
            id={`${prefixId}-find-cep-select-state`}
            className="state-container field normal"
            label={<Translate term={labels.province || 'Estado'} />}
            value={value.province}
            validation="required|requiredSelect"
            autoComplete="off"
            InputLabelProps={{
              className: 'theme-label',
              id: `${prefixId}-label-province`
            }}
            inputProps={{
              className: 'theme-input-select chk-cityState__state br',
              id: `${prefixId}-find-cep-select-state`
            }}
            onChange={selectProvince}
          >
            {provinces.map((p) => (
              <MenuItem key={p.uf} value={p.uf}>
                {p.uf}
              </MenuItem>
            ))}
          </FieldSelect>
        ) : (
          <FieldAutocomplete
            hideUnselectIcon
            hideSearchIcon
            id={`${prefixId}-find-cep-select-state`}
            label={<Translate term={labels.province || 'Estado'} />}
            value={province}
            validation="required|requiredSelect"
            autoComplete="off"
            className="chk-cityState__state"
            inputProps={{
              className: 'theme-input',
              id: `${prefixId}-province-autocomplete`,
              onChange: () => {
                selectCity(null);
                selectProvince(null);
              }
            }}
            InputLabelProps={{
              className: 'theme-label',
              id: `${prefixId}-label-province`
            }}
            onChange={selectProvince}
            options={provinces.map((p) => ({ label: p.uf, value: p.uf }))}
          />
        )}
      </Grid>

      {province && cities && cities.length > 0 && (
        <Grid item sm={template !== 18 ? 12 : 6} xs={12}>
          <FieldAutocomplete
            hideUnselectIcon
            hideSearchIcon
            id={`${prefixId}-find-cep-select-city`}
            label={<Translate term={labels.city || 'Cidade'} />}
            value={value.city}
            validation="required|requiredSelect"
            autoComplete="off"
            className="chk-cityState__container field normal"
            inputProps={{
              className: 'theme-input',
              id: `${prefixId}-find-cep-select-city`,
              onChange: () => {
                selectCity(null);
              }
            }}
            InputLabelProps={{
              className: 'theme-label',
              id: `${prefixId}-label-city`
            }}
            onChange={selectCity}
            options={cities.map((c) => ({ label: c.name, value: c.name }))}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default CityState;
