import React from 'react';
import { BaseComponent } from 'components/BaseComponent';
import { IStateBase } from './../BaseComponent';
import { IScript } from 'interfaces/IScript';
import Analytics from './Analytics';
import TagManager from './TagManager';
import Funnelytics from './Funnelytics';
import ClickDesk from './ClickDesk';
import Jivo from './Jivo';
import Live from './Live';
import Tawk from './Tawk';
import Zendesk from './Zendesk';
import Zopim from './Zopim';
import { scriptsService } from 'services/ScriptsService';
import { pixelService } from 'services/PixelService';
import { Whatsapp } from './Whatsapp';

interface IState extends IStateBase {
  scripts: IScript[];
  hasNewPixelTagmanager: boolean;
}

export default class Scripts extends BaseComponent<IState> {
  componentDidMount() {
    this.observeState({ hasNewPixelTagmanager: true }, pixelService.hasTagmanager());
    this.observeState({ scripts: [] }, scriptsService.getScripts());
  }

  getScriptComponent = (script: IScript) => {
    switch (script.type) {
      case 'analytics':
        return <Analytics script={script} />;

      case 'tagmanager':
        return !this.state.hasNewPixelTagmanager && <TagManager script={script} />;

      case 'funnelytics':
        return <Funnelytics script={script} />;

      case 'jivochat':
        return <Jivo script={script} />;

      case 'zopimchat':
        return <Zopim script={script} />;

      case 'tawktochat':
        return <Tawk script={script} />;

      case 'zendeskchat':
        return <Zendesk script={script} />;

      case 'livechat':
        return <Live script={script} />;

      case 'whatsapp':
        return <Whatsapp script={script} />;

      case 'whatsapp-link':
        return <Whatsapp script={script} />;

      case 'clickdesk':
        return <ClickDesk script={script} />;

      default:
        return <div />;
    }
  };

  render() {
    if (!this.state) {
      return <div />;
    }

    const { scripts } = this.state;

    return (
      <div>
        {scripts.map((script, key) => (
          <div key={key}>{this.getScriptComponent(script)}</div>
        ))}
      </div>
    );
  }
}
