import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/styles';
import { SvgIcon } from 'components/SvgIcon';
import Translate from 'components/Translate';
import { enPaymentStatus } from 'enums/enPaymentStatus';
import React, { Fragment } from 'react';
import { styles } from './styles';

export interface IProps {
  message: string;
  status: enPaymentStatus;
}

const useStyles = makeStyles(styles);

const PaymentButtonMessage: React.FC<IProps> = React.memo((props) => {
  const classes = useStyles({});

  const IdleMessage = () => (
    <Fragment>
      <SvgIcon name="security" />
      <Translate term={props.message} />
    </Fragment>
  );

  const SuccessMessage = () => (
    <Fragment>
      <SvgIcon name="checkIcon" />
    </Fragment>
  );

  const ErrorMessage = () => (
    <Fragment>
      <Translate term="Não foi possível concluir o pagamento" className="errorMessage" />
    </Fragment>
  );

  const ProgressMessage = () => (
    <Fragment>
      <CircularProgress size={25} style={{ color: '#fff' }} className="theme-button-color" />
      <Translate term="Já é quase seu!" className="progressMessage" />
    </Fragment>
  );

  const { status } = props;

  return (
    <span className={classes.component}>
      {status === enPaymentStatus.IDLE && <IdleMessage />}
      {status === enPaymentStatus.PROGRESS && <ProgressMessage />}
      {status === enPaymentStatus.SUCCESS && <SuccessMessage />}
      {status === enPaymentStatus.ERROR && <ErrorMessage />}
    </span>
  );
});

export default PaymentButtonMessage;
