import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Subject } from 'rxjs/Subject';
import { paymentService } from './PaymentService';
import goofyService from './GoofyService';

export class CaptchaService {
  private askCaptcha$ = new BehaviorSubject(false);
  private captchaResult$ = new Subject<string>();

  showCaptcha() {
    // Goofy de falha de pagamento
    goofyService.track({
      flow: 'sale',
      trackerId: window.GOOFY_ID,
      step: 'captcha_shown',
      data: {
        hasCaptcha: true
      }
    });
    this.askCaptcha$.next(true);
    return this.captchaResult$.asObservable();
  }

  getCaptchaStatus() {
    return this.askCaptcha$.asObservable();
  }

  setResult(captchaToken: string) {
    paymentService.setData({
      captchaToken
    });
    // Goofy de falha de pagamento
    goofyService.track({
      flow: 'sale',
      trackerId: window.GOOFY_ID,
      step: 'captcha_resolved',
      data: {}
    });
    this.captchaResult$.next(captchaToken);
    this.askCaptcha$.next(false);
  }
}

// tslint:disable-next-line:variable-name
export const CaptchaServiceFactory = () => new CaptchaService();

export const captchaService = CaptchaServiceFactory();
