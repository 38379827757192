import validator from 'validatorjs';

const validate = (date: string): boolean => {
  if (!date) {
    return true;
  }

  if (!date.match(/^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/)) {
    return false;
  }

  const formattedDate = date.replace(/^(\d{2})\/(\d{2})\/(\d{4})$/, '$3-$2-$1');

  try {
    const dateObject = new Date(formattedDate);

    return dateObject.toISOString().substr(0, 10) === formattedDate;
  } catch (err) {
    return false;
  }
};

validator.register('birthdate', validate, 'Data inválida');
