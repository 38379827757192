import Grid from '@material-ui/core/Grid';
import FieldSelect from 'components/Field/Select';
import { Translate } from 'components/Translate';
import React, { useCallback } from 'react';
import { pagDivididoService } from 'services/PagDivididoService';
import { enPagdivididoSteps } from '../..';
import NextStep from '../../NextStep';
import StepTitle from '../../StepTitle';
import { useObservable } from 'react-use-observable';
import MenuItem from '@material-ui/core/MenuItem';
import { paymentService } from 'services/PaymentService';
import { useMappedObservable } from 'shared/hooks/useMappedObservable';
import { useEffect } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import EmptyState from './EmptyState';

const useStyles = makeStyles(() => ({
  loading: {
    height: 200,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexFlow: 'column wrap'
  },
  textLoading: {
    textAlign: 'center'
  },
  selectBreak: {
    whiteSpace: 'break-spaces'
  }
}));

const Simulation: React.FC = () => {
  const classes = useStyles({});
  const [estimate] = useObservable(() => pagDivididoService.getEstimate(), []);
  const [isLoading] = useObservable(() => pagDivididoService.isLoading(), []);

  useEffect(() => pagDivididoService.trigger(), []);

  const selectFinancingOption = useCallback(
    (offerId: string) => {
      if (!estimate) {
        return;
      }

      const offer = estimate.offers.find((o) => o.id === offerId);

      paymentService.setPaymentData(0, {
        pagDivididoOffer: { ...offer },
        pagDivididoProvider: estimate.institution.name
      });
    },
    [estimate]
  );

  const [offerId] = useMappedObservable(
    () => paymentService.getCart(),
    (p) => p.payment[0]?.pagDivididoOffer?.id,
    []
  );

  if (isLoading) {
    return (
      <Grid className={classes.loading} container item justify="center" alignItems="center">
        <CircularProgress thickness={4} size={60} />
        <p className={classes.textLoading}>Aguarde, estamos procurando as ofertas disponíveis.</p>
      </Grid>
    );
  }

  if (!estimate || estimate.offers.length === 0) {
    return <EmptyState />;
  }

  return (
    <Grid container>
      <StepTitle title="Escolha o parcelamento" step={enPagdivididoSteps.SIMULATION} />

      {estimate.offers.length > 0 && (
        <>
          <p>
            Os valores das parcelas representam uma simulação. Os valores exatos dependem do retorno do banco após realizar a análise de crédito
            individual
          </p>

          <Grid item xs={12}>
            <FieldSelect
              id="simulation-selector"
              className="state-container field normal"
              label={<Translate term="Escolher parcelamento" />}
              value={offerId}
              validation="required|requiredSelect"
              autoComplete="off"
              InputLabelProps={{
                className: 'theme-label',
                id: 'label-simulation-selector'
              }}
              inputProps={{
                className: 'theme-input-select chk-cityState__state br',
                id: 'find-cep-select-state'
              }}
              onChange={selectFinancingOption}
            >
              {estimate.offers.map((e) => (
                <MenuItem key={e.id} value={e.id} className={classes.selectBreak}>
                  {e.description}
                </MenuItem>
              ))}
            </FieldSelect>
          </Grid>

          <p>Oferecido por: {estimate?.institution?.name}</p>
        </>
      )}
      <NextStep allowBack={true} buttonText="Continuar" toStep={enPagdivididoSteps.ADDRESS} />
    </Grid>
  );
};

export default React.memo(Simulation);
