import React from 'react';
import ReactDOM from 'react-dom';
import { IScript } from 'interfaces/IScript';
import { BaseComponent } from 'components/BaseComponent';

interface IProps {
  script: IScript;
}

export default class Zendesk extends BaseComponent<{}, IProps> {
  componentDidMount() {
    const script = document.createElement('script');

    if (this.props.script.value.toString().includes('zendesk.com')) {
      let scriptsRegex = this.props.script.value;
      if (scriptsRegex.includes('http')) {
        scriptsRegex = scriptsRegex.replace(/^https:\/\/|http:\/\//, '');
      }

      // eslint-disable-next-line max-len
      script.innerHTML = `/*<![CDATA[*/window.zEmbed||function(e,t){var n,o,d,i,s,a=[],r=document.createElement("iframe");window.zEmbed=function(){a.push(arguments)},window.zE=window.zE||window.zEmbed,r.src="javascript:false",r.title="",r.role="presentation",(r.frameElement||r).style.cssText="display:none",d=document.getElementsByTagName("script"),d=d[d.length-1],d.parentNode.insertBefore(r,d),i=r.contentWindow,s=i.document;try{o=s}catch(e){n=document.domain,r.src='javascript:var d=document.open();d.domain="'+n+'";void(0);',o=s}o.open()._l=function(){var e=this.createElement("script");n&&(this.domain=n),e.id="js-iframe-async",e.src="https://assets.zendesk.com/embeddable_framework/main.js",this.t=+new Date,this.zendeskHost='${scriptsRegex}',this.zEQueue=a,this.body.appendChild(e)},o.write('<body onload="document._l();">'),o.close()}();/*]]>*/`;
    } else {
      script.id = 'ze-snippet';
      script.src = `https://static.zdassets.com/ekr/snippet.js?key=${this.props.script.value}`;
    }
    // eslint-disable-next-line react/no-find-dom-node
    ReactDOM.findDOMNode(this).appendChild(script);
  }

  render() {
    return <div />;
  }
}
