/* eslint-disable max-len */
import Button from '@material-ui/core/Button';
import PaymentButtonMessage from 'components/Cart/PaymentButton/PaymentButtonMessage';
import PaymentError from 'components/Cart/PaymentButton/PaymenteMessage/PaymentError';
import { PaymentInProgress } from 'components/Cart/PaymentButton/PaymenteMessage/PaymentInProgress';
import { enPaymentStatus } from 'enums/enPaymentStatus';
import { getPaymentButtonMessage } from 'functions';
import React, { useCallback, useEffect } from 'react';
import { useObservable } from 'react-use-observable';
import { cartService } from 'services/CartService';
import { paymentProgressService } from 'services/PaymentProgressService';
import { paymentService } from 'services/PaymentService';
import { paymentWaiter } from 'services/PaymentWaiterService';

import { enPaymentMethod } from 'enums/enPaymentMethod';
import PixModal from './PixModal';
import { useStyles } from './PixModal/styles';
import TerminalModal from './TerminalModal';

const PaymentPanel: React.FC = () => {
  const styles = useStyles({});

  const [paymentStatus] = useObservable(() => paymentProgressService.getPaymentProgressStatus(), []);

  const singleStep = false;

  const setIdleState = useCallback(() => {
    paymentProgressService.setPaymentProgressStatus({
      status: enPaymentStatus.IDLE,
      message: ''
    });
  }, []);

  const [messageButton] = useObservable(() => {
    return paymentService
      .getCart()
      .combineLatest(cartService.getCart(), cartService.getConfig())
      .map(([payment, cart, config]) => getPaymentButtonMessage(payment, cart, config));
  }, []);

  const [waiter] = useObservable(() => paymentWaiter.getWaiter(), []);

  useEffect(() => {
    if (!waiter || !waiter?.waiting) {
      return;
    }
  }, [waiter]);

  if (!paymentStatus || paymentStatus.status === enPaymentStatus.IDLE) {
    return null;
  }

  if (waiter?.type === enPaymentMethod.PIX && waiter?.result.url) {
    return <PixModal waiter={waiter} />;
  }

  if (waiter?.type === enPaymentMethod.TERMINAL) {
    return <TerminalModal waiter={waiter} />;
  }

  return (
    <div className={styles.component}>
      <div className="chk-payment-painel">
        <div className="chk-data">
          {paymentStatus.status === enPaymentStatus.PROGRESS && <PaymentInProgress paymentMethod={waiter?.type} />}

          {paymentStatus.status === enPaymentStatus.ERROR && (
            <PaymentError message={paymentStatus.message} code={paymentStatus.code} idleState={setIdleState} />
          )}
          {singleStep && paymentStatus.status === enPaymentStatus.PROGRESS && (
            <Button
              id="chk-finish-payment-button"
              fullWidth
              variant="contained"
              size="large"
              classes={{
                label: 'paymentButtonLabel'
              }}
              color="primary"
              className="paymentButton chk-stepUser__btnFinish theme-button"
              disabled={true}
              disableFocusRipple={true}
            >
              <PaymentButtonMessage status={paymentStatus.status} message={messageButton} />
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default React.memo(PaymentPanel);
