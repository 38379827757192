import * as storageService from './storage';

import { ICache } from 'shared/interfaces/ICache';
import { Observable } from 'rxjs/Observable';

const memory: { [key: string]: ICache } = {};

export function getData(key: string): Observable<ICache> {
  if (memory[key]) return Observable.of(memory[key]);
  return storageService.get('react-cache' + key);
}

export function saveData<T>(key: string, data: T, options: { persist: boolean; ttl: number }): Observable<ICache<T>> {
  const cache: ICache<T> = {
    createdAt: new Date(),
    expirationDate: Date.now() + options.ttl,
    data
  };

  if (options.persist) {
    return storageService.set('react-cache' + key, cache);
  }

  return Observable.of(true).map(() => {
    memory[key] = cache;
    return cache;
  });
}

export function isExpirated(cache: ICache): boolean {
  if (cache.expirationDate) {
    return Date.now() > cache.expirationDate;
  }

  const difference = Date.now() - new Date(cache.createdAt).getTime();
  return difference / 1000 / 60 > 300;
}

export function clear(): Observable<void> {
  return storageService.clear(/^react-cache/gi);
}
