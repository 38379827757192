import { enPaymentMethod } from 'enums/enPaymentMethod';
import { enPaymentType } from 'enums/enPaymentType';
import { ICartModel } from 'interfaces/ICartModel';
import { IProductConfig, IProductData } from 'interfaces/IProductData';
import get from 'lodash/get';
import { CDN_URL } from 'settings';

export function sleep(time: number) {
  return new Promise((resolve) => setTimeout(() => resolve(true), time));
}

export function getCdnImage(image: string) {
  if (!image) {
    return 'https://invalid.eduzz.com';
  }

  if (image.startsWith('https://')) {
    return image;
  }

  const numberImage = Number(image);

  if (isNaN(numberImage)) {
    return CDN_URL + image;
  }

  return `https://my.eduzz.com/file_upload/redirect/${image}/false`;
}

export function findGetParameter(parameterName: string) {
  const items = window.location.search.substr(1).split('&');

  for (let index = 0; index < items.length; index++) {
    const tmp = items[index].split('=');
    if (tmp[0] === parameterName) {
      return decodeURIComponent(tmp[1]);
    }
  }

  return '';
}

export function formatScripts(scripts: any) {
  if (!scripts) {
    return [];
  }

  return Object.keys(scripts)
    .filter((key) => key.startsWith('has'))
    .filter((key) => scripts[key] === '1')
    .map((key) => {
      const realKey = key.replace(/^has/gi, '');
      return {
        type: realKey,
        value: scripts[realKey]
      };
    })
    .filter((data) => !!data.value);
}

export function unescapeHtml(unsafe: string) {
  return unsafe
    .replace(/&amp;/g, '&')
    .replace(/&lt;/g, '<')
    .replace(/&gt;/g, '>')
    .replace(/&quot;/g, '"')
    .replace(/&#039;/g, "'");
}

export function checkLocalStorage(): Boolean {
  try {
    return !!window.localStorage;
  } catch (error) {
    return false;
  }
}

export function getPaymentButtonMessage({ payment }: ICartModel, cart: IProductData, config: IProductConfig) {
  const isSubscription = cart.config.paymentType === enPaymentType.SUBSCRIPTION;
  const isOneClickWithoutSubscription = config.isOneClick && !isSubscription;

  if (cart.config.paymentType == enPaymentType.FREE) {
    return 'Obter Gratuitamente';
  }

  const defaultMessage = isOneClickWithoutSubscription ? 'Compra Segura' : 'Pagar e Receber Agora';

  const messageButton = get(config, 'template.styles.buttons.text', defaultMessage) || defaultMessage;

  if (isSubscription) {
    const mappingMessage: Record<string, string> = {
      'Pagar e Receber Agora': 'Pagar e Assinar Agora',
      'Finalizar Pagamento': 'Finalizar Assinatura',
      'Concluir Compra': 'Concluir Assinatura',
      'Pagar Agora': 'Assinar Agora'
    };

    return mappingMessage[messageButton] || defaultMessage;
  }

  if (cart.config.paymentType == enPaymentType.DONATION) {
    return 'Finalizar';
  }

  if (payment.length !== 1) {
    return messageButton;
  }

  const paymentMethodMessages: Record<string, string> = {
    [enPaymentMethod.TERMINAL]: 'Ir para maquininha',
    [enPaymentMethod.BANKSLIP]: 'Gerar Boleto',
    [enPaymentMethod.PAYPAL]: 'Ir para o PayPal',
    [enPaymentMethod.PIX]: 'Gerar Pix'
  };

  const paymentMethod = payment[0].paymentMethod;

  return paymentMethodMessages[paymentMethod] || messageButton;
}
