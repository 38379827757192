/* eslint-disable max-lines */
import React from 'react';
import { IStateBase, BaseComponent } from 'components/BaseComponent';
import { IProductData, IProductConfig } from 'interfaces/IProductData';
import { cartService } from 'services/CartService';
import { IPayProps } from 'components/Cart';
import { paymentService } from 'services/PaymentService';
import { Observable } from 'rxjs/Observable';
import { HistoryContext } from 'shared/HistoryContext';
import { History } from 'history';
import { enStep } from 'components/Cart/Templates/Hotsite/MultiStep';
import Footer from 'components/Cart/Footer';
import Paper from '@material-ui/core/Paper/Paper';
import Typography from '@material-ui/core/Typography/Typography';
import YoutubeEmbed from 'components/YoutubeEmbed/index';
import { ContentOneClickBuy } from './ContentOneClickBuy';
import AskAddress from 'components/Cart/AskAddress';
import Translate from 'components/Translate';
import Divider from '@material-ui/core/Divider/Divider';
import { userService } from 'services/UserService';
import { WithStyles } from 'shared/decorators/withStyles';
import { nl2br } from 'shared/functions';
import { enPaymentMethod } from 'enums/enPaymentMethod';
import { PayContext } from '../../../index';
import FormHeader from 'components/Cart/FormHeader';
import { thankYouService } from 'services/ThankYouService';

interface IState extends IStateBase {
  cart: IProductData;
  step: enStep;
  config: IProductConfig;
  showAskAddress: boolean;
}

export interface IStepProps {
  setStep: (step: enStep) => void;
}

interface IProps extends IPayProps {
  hiddenFooter?: boolean;
}

@WithStyles(() => ({
  '@global': {
    body: {
      minHeight: '100vh'
    }
  },

  component: {
    '& .chk-oneClickBuy__desktop': {
      maxWidth: '1024px',
      margin: '12px auto 0',

      '@media screen and (min-width: 768px)': {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'row-reverse',

        '& .chk-sidebar': {
          float: 'left',
          width: '14rem',
          height: '100%',
          flexDirection: 'column'
        }
      },

      '& .content': {
        width: '415px',
        padding: '20px',

        '@media screen and (max-width: 768px)': {
          width: '100%'
        }
      },

      '& .description-banner': {
        display: 'none',
        padding: '33px 20px 0 0',
        width: 'calc(100% - 370px)',

        '@media screen and (max-width: 768px)': {
          width: '100%'
        },

        '& .description-card': {
          border: '1px solid #e8e7e7'
        },

        '& .description': {
          textAlign: 'left',
          padding: '18px',
          fontSize: '0.875rem',
          color: '#807e7e',

          '& h3': {
            marginBottom: '8px',
            fontSize: '0.75rem',
            fontWeight: 700,
            textTransform: 'uppercase'
          },

          '& hr': {
            margin: '12px 0'
          },

          '& .infos-producer .infos-details': {
            fontSize: '0.75rem'
          }
        },

        '@media screen and (min-width: 768px)': {
          display: 'block'
        }
      }
    },

    '& .without-cvv': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between'
    },

    '& .chk-oneClickBuy__product': {
      height: 'auto',
      lineHeight: '20px',
      textAlign: 'right',

      '& .chk-subscription': {
        '& .chk-subscription__text': {
          fontSize: '0.8125rem',
          textTransform: 'uppercase',

          '@media screen and (max-width: 768px)': {
            fontSize: '0.75rem'
          }
        },

        '& .chk-subscription__value': {
          fontSize: '1.5rem'
        },

        '& .chk-subscription__frequency, & .chk-subscription__trial': {
          fontSize: '0.875rem',

          '@media screen and (max-width: 768px)': {
            fontSize: '0.75rem',
            letterSpacing: '-0.9px'
          }
        },

        '& .chk-subscription__trial': {
          padding: '4px 0',

          '& span:first-child': {
            backgroundColor: '#000',
            color: '#FFF',
            cursor: 'auto',
            padding: '2px 4px'
          }
        }
      }
    },

    '& .chk-oneClickBuy__coupon': {
      padding: '0 16px',
      '& .theme-label': {
        zIndex: 2,
        fontSize: '0.75rem',
        transform: 'translate(0, 6px)',
        '& + div': {
          marginTop: 24
        }
      },
      '& .theme-button:not(.chk-close)': {
        height: 50
      }
    },

    '& .chk-oneClickBuy__content': {
      '& .chk-oneClickBuy__card': {
        maxWidth: '350px',
        margin: 'auto'
      },

      '& .chk-acceptTerms__span': {
        margin: '0 0 8px 0'
      },

      '& .chk-oneClickBuy__securityMessage': {
        color: '#505050',
        fontSize: '0.75rem',
        fontWeight: 600
      },

      '& .chk-creditCard__security': {
        position: 'absolute',
        width: '295px',

        '& svg': {
          float: 'right',
          margin: '8px 0 0 0',
          position: 'relative',
          width: '95px'
        }
      },

      '& .chk-userInfos': {
        margin: '4px 0 0 0'
      },

      '& .chk-stepCreditCard__finishButton': {
        backgroundColor: '#238533',
        borderRadius: '4px',
        color: '#FFFFFF',
        fontSize: '0.75rem',
        fontWeight: 700,
        height: '45px',
        margin: '16px 0',
        textTransform: 'uppercase',

        '& svg': {
          fill: '#FFF',
          margin: '0 8px 0 0',
          width: '20px'
        }
      }
    },

    '& .chk-installments__value': {
      fontSize: '0.75rem',
      margin: '8px 0 0 0',
      color: '#585858',

      '& span': {
        fontWeight: 700,
        marginRight: '8px'
      },

      '& a': {
        textDecoration: 'underline',
        color: '#333',
        cursor: 'pointer'
      }
    },

    '& .links': {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row',
      justifyContent: 'center',
      margin: '16px 0',

      '& a': {
        display: 'inline-block',
        textDecoration: 'none',
        color: '#3C3C3C',

        '&:after': {
          content: '"|"',
          display: 'inline-block',
          margin: '0 5px'
        },

        '&:last-child:after': {
          content: '""'
        }
      },

      '& .chk-media-footer': {
        fontSize: '.625rem',
        textAlign: 'center',
        color: 'black',
        textShadow: '1px 1px 2px #fff'
      }
    },

    '& .ticketTimer': {
      padding: '16px',
      marginBottom: '5px'
    },

    '& .quantityContent': {
      display: 'flex',
      alignItems: 'center',
      marginTop: '5px',
      height: '100%',

      '& label': {
        margin: '3px',
        fontSize: '12px',
        color: '#596375'
      },

      '& input': {
        width: '85px'
      }
    }
  },

  '& .theme_18': {
    overflowX: 'hidden'
  }
}))
export default class OneClickBuy extends BaseComponent<IState, IProps> {
  private history: History;

  componentDidMount() {
    this.observeState({ cart: null }, cartService.getCart());
    this.observeState({ config: null }, cartService.getConfig());
    this.setState({ showAskAddress: false });

    cartService
      .getCart()
      .filter((cart) => !cart.isCached && cart.upsell && cart.upsell != enPaymentMethod.BANKSLIP && !cart.config.askCVV)
      .take(1)
      .subscribe(() => {
        this.onPay();
      });
  }

  public setStep(step: enStep) {
    const { config } = this.state;
    if (step == enStep.address && !config.askAddressBeforePayment) {
      step = enStep.payment;
    }

    userService.setBasicUser().subscribe();

    this.setState({ step });
  }

  onPay = async () => {
    if (await this.isFormValid()) {
      this.props
        .onPay()
        .switchMap(() => cartService.getCart())
        .combineLatest(paymentService.shouldBlockAddressAfterPayment())
        .take(1)
        .switchMap(([cart, shouldBlockAddressAfterPayment]) => {
          if (cart.config.askAddressAfterPayment && !shouldBlockAddressAfterPayment) {
            this.setState({ showAskAddress: true });
            return Observable.of('#');
          }

          return paymentService.getPaymentResult().map((result) => {
            return thankYouService.redirectThankYou(result);
          });
        })
        .filter((url) => url !== null)
        .bindComponent(this)
        .subscribe((url) => {
          this.history.push(url);
        });
    }
  };

  setHistory(history: History): any {
    this.history = history;
    return null;
  }

  render() {
    if (!this.state) {
      return null;
    }

    const { cart, showAskAddress } = this.state;

    return (
      <div className={this.props.classes.component}>
        <PayContext.Provider value={this.onPay.bind(this)}>
          <HistoryContext.Consumer>{(h) => this.setHistory(h)}</HistoryContext.Consumer>
          <div className={'chk-oneClickBuy__desktop'}>
            <div className={'chk-oneClickBuy__container'}>
              <FormHeader />
              {showAskAddress ? (
                <div className="chk-askAdress__oneClickBuy">
                  <AskAddress />
                </div>
              ) : (
                <ContentOneClickBuy cart={cart} onPay={this.onPay} />
              )}
            </div>
            <div className="description-banner">
              <Paper className={'description-card'} elevation={0}>
                {cart.config.template.media.bannerYoutube && (
                  <div className="banner">
                    <YoutubeEmbed url={cart.config.template.media.bannerYoutube} />
                  </div>
                )}
                <div className="description">
                  <Typography variant="h5">
                    <b>
                      <Translate term="Detalhes" />
                    </b>
                  </Typography>

                  <div style={{ wordBreak: 'break-word' }} dangerouslySetInnerHTML={{ __html: nl2br(cart.content.description) }} />

                  {cart.content.producerName && (
                    <React.Fragment>
                      <Divider />
                      <div className="infos-producer">
                        <Typography variant="body1" className="infos-details">
                          <Translate term="Oferecido por" /> <b>{cart.content.producerName}</b>
                        </Typography>
                        <Typography variant="body1" className="infos-details">
                          {cart.content.producerEmail}
                        </Typography>
                      </div>
                    </React.Fragment>
                  )}
                </div>
              </Paper>
            </div>
          </div>
          {!this.props.hiddenFooter ? (
            <div className="links">
              <Footer />
            </div>
          ) : null}
        </PayContext.Provider>
      </div>
    );
  }
}
