import React from 'react';

interface IProps {
  bankslipUrl: string;
}

export const Overlay = (props: IProps) => {
  return (
    <div className="overlay">
      <div className="shape" />
      <iframe className="frame" src={props.bankslipUrl} sandbox="sandbox" />
    </div>
  );
};
