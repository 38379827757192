import { BaseComponent, IStateBase } from 'components/BaseComponent';
import UserForm from 'components/Cart/MainForm/UserForm';
import { ICartModel } from 'interfaces/ICartModel';
import { IProductConfig, IProductData } from 'interfaces/IProductData';
import Button from '@material-ui/core/Button/Button';
import Grid from '@material-ui/core/Grid/Grid';
import React from 'react';
import { cartService } from 'services/CartService';
import { paymentService } from 'services/PaymentService';
import { enStep, IStepProps } from './index';
import { FieldValidation } from 'components/Field';
import { SvgIcon } from 'components/SvgIcon';
import Translate from 'components/Translate';
import { enPaymentType } from 'enums/enPaymentType';
import { parameterService } from 'services/ParameterService';
import { validate } from 'validators';
import { enParameters } from 'enums/enParameters';
import Alert from 'shared/components/Alert';
import FormHeader from 'components/Cart/FormHeader';

interface IState extends IStateBase {
  cart: IProductData;
  config: IProductConfig;
  model: ICartModel;
  changeCard: boolean;
}

export interface IProps extends IStepProps {
  onPay: () => void;
}

export default class StepUser extends BaseComponent<IState, IProps> {
  componentDidMount() {
    cartService
      .getCart()
      .map((cart) => cart.config)
      .filter((config) => config.paymentType !== enPaymentType.FREE || config.askAddressBeforePayment)
      .switchMap(() => parameterService.getAll())
      .bindComponent(this)
      .filter((params) => !!params.skip)
      .filter((params) => validate(params.name || params.nome, 'required|name|max:100').valid)
      .filter((params) => validate(params.email, 'required|email|max:150').valid)
      .filter((params) => validate(params.phone || params.cel, 'required|min:10|max:11').valid)
      .subscribe(() => {
        this.goToNextStep();
      });

    this.observeState({ cart: null }, cartService.getCart());
    parameterService.get(enParameters.changeCard).subscribe((changeCard) => this.setState({ changeCard: Boolean(changeCard) }));
    this.bindModel(paymentService.getCart());
  }

  goToNextStep = async () => {
    if (await this.isFormValid()) {
      this.props.setStep(enStep.address);
    }
  };

  finish = async () => {
    if (await this.isFormValid()) {
      this.props.onPay();
    }
  };

  render() {
    if (!this.state) {
      return '';
    }

    const { formSubmitted, cart, changeCard, validation = {} } = this.state;

    const isValid = Object.keys(validation).length === 0;

    const disabledButton = !isValid && formSubmitted;

    const getForFree = cart.config.paymentType === enPaymentType.FREE && !cart.config.askAddressBeforePayment;

    return (
      <div className="step">
        <FormHeader />
        <FieldValidation.Provider value={this.registerFields}>
          <Grid className="grid" container>
            <Grid item xs={12} className="chk-form-component">
              {changeCard && <Alert message="Atenção! Este checkout irá alterar a sua forma de pagamento das próximas assinaturas." />}
              <UserForm />
            </Grid>
            <small className="chk-stepUser__securityMessage">
              <SvgIcon name="security" />
              <Translate term="Seus dados serão mantidos em sigilo" />
            </small>
            {getForFree ? (
              <Button
                id="chk-free-button"
                onClick={this.finish}
                fullWidth
                variant="contained"
                size="large"
                color="primary"
                className="chk-btnContinue theme-button"
                disabled={disabledButton}
              >
                {<Translate term="Obter gratuitamente" />}
              </Button>
            ) : (
              <Button
                id="chk-next-button"
                onClick={this.goToNextStep}
                fullWidth
                variant="contained"
                size="large"
                color="primary"
                className="chk-btnContinue theme-button"
                disabled={disabledButton}
              >
                <Translate term="Próximo" />
              </Button>
            )}
          </Grid>
        </FieldValidation.Provider>
      </div>
    );
  }
}
