/* eslint-disable max-lines */

import { BaseComponent } from 'components/BaseComponent';
import { ITemplateState } from 'components/Cart';
import { cartService } from 'services/CartService';
import React from 'react';
import { BannerCenter } from './BannerCenter/index';
import MultiStep from './MultiStep/index';
import Footer from './../../Footer/index';
import OneClickBuy from './OneClickBuy';
import Hidden from '@material-ui/core/Hidden';
import TopBar from 'components/TopBar';
import { enProductType } from 'enums/enProductType';
import { WithStyles } from 'shared/decorators/withStyles';
import { configService } from 'services/ConfigService';

interface IState extends ITemplateState {}

@WithStyles(() => ({
  '@global': {
    body: {
      overflowX: 'hidden'
    }
  },
  component: {
    '& .chk-main-container': {
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover'
    },

    '& .chk-youtube': {
      height: '100%'
    },

    '& .chk-has-number': {
      fontSize: '0.75rem',
      color: '#636871'
    },

    '& .chk-content-container': {
      maxWidth: '1080px',
      minHeight: '100vh',
      padding: '70px 0 0 0',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'relative',
      margin: 'auto',

      '@media screen and (max-width: 960px)': {
        padding: '34px 0 0 0'
      },

      '& > div': {
        zIndex: 2
      },

      '@media screen and (max-width:768px)': {
        maxWidth: '100%'
      },

      '&.chk-with-language-selector': {
        minHeight: '100vh'
      }
    },

    '& .field-select': {
      backgroundColor: '#FFF',
      borderRadius: '1px',
      fontSize: '1rem',
      padding: '2px 2px 1px 3px',
      border: '1px solid #d9d9d9'
    },

    '& .chk-form-container': {
      position: 'relative',
      display: 'block',
      width: '320px',
      margin: '-35px 0 8px -16px',

      '& .chk-inner-container': {
        width: '100%',
        position: 'relative',
        display: 'block'
      },

      '& .steps, & .chk-oneClickBuy__container': {
        background: '#F5F5F5',
        borderRadius: '8px',
        boxShadow: '0 8px 20px 10px rgba(0, 0, 0, .15)'
      },

      '@media screen and (max-width: 960px)': {
        margin: '60px 0 8px 0'
      },

      '& iframe': {
        height: '100%',
        width: '100%'
      }
    },

    '& .chk-parts': {
      textAlign: 'right',
      color: '#333',

      '& .chk-number-parts': {
        fontSize: '0.875rem',
        fontWeight: 600
      },

      '& .chk-no-fee': {
        fontWeight: 'bold',
        fontSize: '14px'
      }
    },

    '& .chk-small-price': {
      fontSize: '0.625rem'
    },

    '& .chk-couponPrice': {
      fontSize: '0.75rem',
      color: '#333',
      textAlign: 'right'
    },

    '& .chk-media-container': {
      width: '750px',
      height: '500px',
      display: 'block',
      position: 'relative',

      '@media screen and (max-width: 960px)': {
        display: 'none'
      },

      '& .chk-media-content': {
        display: 'block',
        width: '100%',
        height: 'calc(100% - 20px)',
        minHeight: '500px',
        borderRadius: '4px',
        background: '#232323'
      }
    },

    '& .links': {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row',
      justifyContent: 'start',

      '& a': {
        display: 'inline-block',
        textDecoration: 'none',
        color: '#3C3C3C',

        '&:after': {
          content: '"|"',
          display: 'inline-block',
          margin: '0 5px'
        },

        '&:last-child:after': {
          content: '""'
        }
      }
    },

    '& .chk-creditCardForm__saveCard, & .checkbox_wallet': {
      marginBottom: '12px',

      '& label': {
        margin: 0
      },

      '& span': {
        fontSize: '0.665rem'
      },

      '& .checkbox': {
        margin: '0 8px 0 0',
        width: '20px',
        height: '20px',
        padding: 0,

        '& svg': {
          color: '#000',
          width: '25px',
          height: 'auto'
        }
      }
    },

    '& .chk-previousStep': {
      margin: '0 0 12px 0'
    },

    '& .chk-coupon .chk-coupon-set': {
      height: '30px',

      '& button': {
        height: '37px'
      }
    },

    '& .chk-oneClickBuy__productPrice .chk-full': {
      textAlign: 'right',
      '& .chk-number-price': {
        fontSize: '1.25rem',
        fontWeight: 700
      },
      '& .chk-small-price': {
        color: '#585858',
        fontSize: '0.625rem',
        fontWeight: 600,
        letterSpacing: '0.42px'
      }
    },

    '& .chk-oneClickBuy__productPrice': {
      '& .chk-parts': {
        textAlign: 'right',

        '& .chk-number-parts': {
          fontSize: '0.75rem',
          fontWeight: 600,
          color: '#585858',
          marginRight: '5px'
        },

        '& .chk-number-price': {
          fontSize: '1.25rem',
          color: '#636871 !important',
          fontWeight: 700
        },

        '& .chk-small-price': {
          fontSize: '0.625rem',
          letterSpacing: '0.42px',
          color: '#585858',
          fontWeight: 600
        },

        '& .chk-no-fee': {
          fontWeight: 'bold',
          fontSize: '14px'
        }
      }
    },

    '& .chk-oneClickBuy__product': {
      padding: '16px',

      '& .chk-oneClickBuy__title': {
        overflow: 'hidden',
        textOverflow: 'ellipsis',

        '& h1': {
          display: 'inline-block',
          fontSize: '0.875rem',
          lineHeight: '19px',
          textAlign: 'left',
          width: '100%',
          wordBreak: 'break-word'
        }
      }
    },

    '& .chk-oneClickBuy__container': {
      '& .summary-cart': {
        margin: '12px 0 0',
        '& .title, & .content, & .value': {
          fontSize: '0.75rem'
        },
        '& .item:nth-child(even)': {
          backgroundColor: '#fff'
        }
      },

      '& .addressAfter': {
        padding: '0 20px 0 20px',
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',

        '& .icon': {
          marginRight: '20px'
        },

        '& .chk-paragraph': {
          '& .title': {
            color: '#000',
            fontSize: '1em',
            textAlign: 'left',
            marginBottom: '5px'
          },

          '& .label': {
            fontSize: '0.8em',
            color: '#000',
            marginTop: 0
          }
        }
      },

      '& .chk-oneClickBuy__content': {
        '& .chk-oneClickBuy__form': {
          padding: '16px',

          '& .paymentButton': {
            margin: '10px 0'
          }
        },

        '& .chk-installments-wrapper': {
          margin: '4px 0 0 0'
        },

        '& .chk-stepCreditCard__finishButton': {
          borderRadius: '4px',
          boxShadow: 'none',
          fontWeight: 500,
          fontSize: '1rem',
          height: '55px',
          margin: '16px 0',
          textTransform: 'capitalize',
          padding: '10px'
        },

        '& .chk-askAdress__content': {
          '& .form': {
            padding: '20px 20px 4px 20px',
            width: '100%'
          },

          '& .chk-btn-download': {
            maxHeight: '60px',
            padding: '14px',
            marginTop: '8px',
            width: '100%,'
          }
        }
      }
    },

    '& .chk-creditCard__content': {
      '& .chk-creditCard__cvvBrand': {
        margin: '4px 0 0 0',

        '& .chk-creditCard__cvv': {
          '& .field': {
            '& label[data-shrink="false"]': {
              zIndex: 1
            },

            '& label[data-shrink="true"]': {
              color: '#FFF'
            }
          }
        }
      }
    },

    '& .chk-installments__installmentFieldSelect': {
      margin: 0,

      '& .field-select': {
        fontSize: '1rem'
      }
    },

    '& .chk-installments__value': {
      fontSize: '0.75rem',
      margin: 0,
      color: '#585858',

      '& span': {
        fontWeight: 700,
        marginRight: '8px'
      },

      '& a': {
        textDecoration: 'underline',
        color: '#333',
        cursor: 'pointer'
      }
    },

    '& .brand-icon': {
      marginTop: '29px'
    },

    '& .chk-multiple-cards': {
      textAlign: 'center'
    },

    '& .tooltip-cvv-card .cvv-question-mark': {
      margin: '-4px 10px 0 0',
      fontSize: '0.666rem',
      width: '18px',
      height: '18px'
    },

    '& .chk-installment-fee': {
      display: 'none'
    },

    '& .cepNotFound': {
      color: '#F59211',
      fontSize: '0.775rem',
      display: 'flex',

      '& svg': {
        width: '30px',
        height: '15px',
        margin: '0 5px 0 5px'
      }
    }
  }
}))
export default class HotsiteTheme extends BaseComponent<IState> {
  componentDidMount() {
    this.observeState({ cart: null }, cartService.getCart());
    this.observeState({ config: null }, configService.getConfig());
  }

  render() {
    if (!this.state) {
      return null;
    }

    const { cart, config } = this.state;
    const bannerCenter = config.template.media.bannerCenter;
    const bannerYoutube = config.template.media.bannerYoutube;
    const productTitle = cart.content.title;

    return (
      <div className={`${this.props.classes.component} theme-hotsite`}>
        <TopBar />
        <div className="chk-main-container theme-background-color theme-background-image">
          <div className={`chk-content-container ${cart.config.productType !== enProductType.PHYSICAL ? 'chk-with-language-selector' : ''}`}>
            <div className="chk-media-container">
              <Hidden smDown>
                <BannerCenter media={bannerYoutube || `${bannerCenter}`} isVideo={bannerYoutube ? true : false} title={productTitle} />
              </Hidden>
              <div className="links">
                <Footer />
              </div>
            </div>
            <div className="chk-form-container">
              <div className="chk-inner-container">
                {cart.config.isOneClick ? <OneClickBuy onPay={this.props.onPay} hiddenFooter /> : <MultiStep onPay={this.props.onPay} />}
              </div>
              <Hidden mdUp>
                <div className="links">
                  <Footer />
                </div>
              </Hidden>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
