import * as React from 'react';
import { PureComponent } from 'react';
import { IIconInterface } from 'shared/icons';

export interface ISvgIconProps {
  name: string;
  viewBox?: string;
}

interface IState {
  icons: { [key: string]: IIconInterface };
}

export class SvgIcon extends PureComponent<ISvgIconProps, IState> {
  constructor(props: any) {
    super(props);
    this.state = { icons: null };
  }

  async componentDidMount() {
    const { icons } = await import('shared/icons');

    this.setState({ icons });
  }

  render() {
    const { icons } = this.state;
    const { name, viewBox } = this.props;

    if (!icons) {
      return null;
    }

    const icon = icons[name] || icons['unknown'];

    const svgParams = { ...icon };

    delete svgParams.paths;

    return icon.svg ? (
      icon.svg
    ) : (
      <svg xmlns="http://www.w3.org/2000/svg" className="icon" viewBox={icon.viewBox || viewBox || '0 0 16 16'} {...svgParams}>
        {icon.paths && icon.paths.map((path, key) => <path key={key} fillRule="evenodd" {...path} />)};
      </svg>
    );
  }
}
