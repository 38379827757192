import React from 'react';
import { BaseComponent, IStateBase } from 'components/BaseComponent';
import ResultItem from './ResultItem';
import { WithStyles } from 'shared/decorators/withStyles';

interface IState extends IStateBase {
  model: any;
  open: boolean;
}

@WithStyles(() => ({
  component: {
    '& .chk-tableCEP__title': {
      fontSize: '0.9rem',
      fontWeight: 'normal',
      margin: '16px 0 8px 0'
    },
    '& .chk-tableCEP__addressList': {
      marginTop: '8px',
      maxHeight: '400px',
      overflow: 'auto',
      '& .chk-tableCEP__address': {
        fontSize: '0.9rem',
        backgroundColor: '#FFFFFF',
        border: '1px solid #B9B9B9',
        borderRadius: '2px',
        color: '#596375',
        padding: '8px',
        '&:not(:last-child)': {
          margin: '0 0 4px 0'
        },
        '& .chk-tableCEP__label': {
          fontWeight: 600
        }
      }
    }
  }
}))
export default class TableCep extends BaseComponent<IState> {
  constructor(props: any) {
    super(props);
    this.state = { model: null, open: false };
  }

  choosePostalCode = (data: any) => {
    this.props.handleClose(data.cep || '');
  };

  render() {
    return (
      <div className={this.props.classes.component}>
        <h6 className="chk-tableCEP__title theme-label">Selecione uma opção</h6>
        <div className="chk-tableCEP__addressList">
          {this.props.data.map((data: any, key: any) => (
            <ResultItem key={key} data={data} onChoose={this.choosePostalCode} />
          ))}
        </div>
      </div>
    );
  }
}
