import { FieldValidation, IFieldValidationContext } from 'components/Field';
import FieldBase from 'components/Field/Base';
import React, { useCallback, useMemo, useRef, useState } from 'react';

const ValidationHOC: React.FC<{ children: any }> = ({ children }) => {
  const fields = useRef<FieldBase<any, any>[]>([]);
  const [submitted, setSubmitted] = useState(false);
  const [update, setUpdateCount] = useState(0);
  const [isValid, setIsValid] = useState(true);

  const timeout = useRef<any>();

  const validate = useCallback(
    (submitted?: boolean) => {
      if (submitted) {
        fields.current.forEach((f) => f.setFormSubmitted(submitted));
        setSubmitted(true);
      }

      if (fields.current.length === 0) {
        setIsValid(true);
        return true;
      }

      const firstInvalid = fields.current.find((f) => {
        return !f.isValid();
      });

      if (firstInvalid && submitted) {
        document.getElementById(firstInvalid.props.id)?.focus();
      }

      setIsValid(!firstInvalid);
      return !firstInvalid;
    },
    [setSubmitted]
  );

  const updateTicks = useCallback(() => {
    clearTimeout(timeout.current);
    timeout.current = setTimeout(() => {
      validate();
      setUpdateCount(update + 1);
    }, 100);
  }, [update, setUpdateCount, validate]);

  const registerFields: IFieldValidationContext = useMemo(
    () => ({
      bind: (f) => {
        fields.current.push(f);
      },
      unbind: (f) => {
        const index = fields.current.indexOf(f);
        fields.current.splice(index, 1);
      },
      isValid: validate,
      isFormValid: isValid,
      isSubmitted: () => submitted,
      getValidationMessages: () => {
        return fields.current.reduce((acc, item) => {
          if (!item.isValid()) {
            acc[item.getFieldId()] = item.getErrorMessage();
          }

          return acc;
        }, {}) as { [key: string]: string };
      },
      update: () => {
        updateTicks();
      }
    }),
    [updateTicks, submitted, isValid, validate]
  );

  if (!children) {
    return null;
  }

  return <FieldValidation.Provider value={registerFields}>{children}</FieldValidation.Provider>;
};

export default React.memo(ValidationHOC);
