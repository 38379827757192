import React, { useState, useEffect } from 'react';
import { useObservable } from 'react-use-observable';
import { paymentWaiter } from 'services/PaymentWaiterService';

const Component: React.FC = () => {
  const [timeLeft, setTimeLeft] = useState('00:00');
  const [waiter] = useObservable(() => paymentWaiter.getWaiter(), []);

  useEffect(() => {
    if (!waiter || !waiter?.waiting) {
      return () => false;
    }

    const interval = setInterval(() => {
      const now = new Date().getTime();

      const diff = Math.floor((waiter.expiresAt - now) / 1000);

      if (diff < 0) {
        setTimeLeft('00:00');
        return;
      }

      const minutes = Math.floor(diff / 60);
      const seconds = diff % 60;

      setTimeLeft(`${minutes >= 10 ? minutes : `0${minutes}`}:${seconds >= 10 ? seconds : `0${seconds}`}`);
    }, 1000);

    return () => clearInterval(interval);
  }, [waiter, waiter?.waiting]);

  return (
    <div className="chk-pix-timer-container">
      Código Pix válido por <span>{timeLeft}</span>
    </div>
  );
};

export const PixTimerCode = React.memo(Component);
