import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import AcceptTerms from 'components/AcceptTerms';
import CartSummary from 'components/Cart/Summary';
import { enPaymentType } from 'enums/enPaymentType';
import { IPagDivididoModel } from 'interfaces/ICartModel';
import React from 'react';
import { useObservable } from 'react-use-observable';
import { configService } from 'services/ConfigService';
import { pagDivididoService } from 'services/PagDivididoService';
import { paymentService } from 'services/PaymentService';
import { useMappedObservable } from 'shared/hooks/useMappedObservable';
import { enPagdivididoSteps } from '../..';
import NextStep from '../../NextStep';
import StepTitle from '../../StepTitle';
import CardData from './CardData';
import { AcceptProducerTerms } from 'components/AcceptProducerTerms';

const useStyles = makeStyles((theme) => ({
  gridStepOne: {
    padding: '0 16px 16px 0',
    [theme.breakpoints.down('sm')]: {
      padding: '0 0 16px 0'
    },
    '&.small-gridStepOne': {
      padding: '0 0 16px 0'
    }
  },
  gridStepTwo: {
    paddingBottom: 16
  }
}));

const Summary: React.FC = () => {
  const classes = useStyles({});
  const [pagData] = useMappedObservable(
    () => paymentService.getCart(),
    (data) => ({
      ...(data.pagDividido ?? ({} as IPagDivididoModel)),
      document: data.document,
      personType: data.personType,
      offer: data.payment[0]?.pagDivididoOffer,
      address: data.address ?? {}
    }),
    []
  );

  const [estimate] = useObservable(() => pagDivididoService.getEstimate(), []);

  const [config] = useObservable(() => configService.getConfig(), []);

  if (!pagData || !config) {
    return null;
  }

  const isTemplateDefault = config.template.id === 18;

  return (
    <Grid container>
      <StepTitle title="Verifique as informações para finalizar sua compra" step={enPagdivididoSteps.SUMMARY} />

      <Grid style={{ marginTop: 16 }} item container xs={12}>
        <Grid
          className={isTemplateDefault ? classes.gridStepOne : `${classes.gridStepOne} small-gridStepOne`}
          item
          md={isTemplateDefault ? 6 : 12}
          sm={12}
        >
          <CardData
            step={1}
            title="Dados Pessoais"
            listData={[
              {
                key: 'Localização',
                value: `${pagData.city}, ${pagData.province}`
              },
              {
                key: 'Data de Nascimento',
                value: pagData.birthDate
              }
            ]}
          />
        </Grid>
        <Grid className={classes.gridStepTwo} item container md={isTemplateDefault ? 6 : 12} sm={12}>
          <CardData
            step={2}
            title="Parcelamento"
            listData={[
              {
                key: 'Valor',
                value: pagData.offer.description
              }
            ]}
          />
        </Grid>
        <Grid item xs={12}>
          <CardData
            step={3}
            title="Endereço de Cobrança"
            listData={[
              {
                key: 'CEP',
                value: pagData.address.postalCode
              },
              {
                key: 'Endereço',
                value: `${pagData.address.street}, ${pagData.address.number}, ${pagData.address.district} ${
                  pagData.address.complement ? `, ${pagData.address.complement}` : ''
                } - ${pagData.address.city} - ${pagData.address.province}`
              }
            ]}
          />
        </Grid>
      </Grid>

      <p>Oferecido por: {estimate?.institution?.name}</p>

      <CartSummary />

      {config.paymentType === enPaymentType.SUBSCRIPTION && <AcceptTerms />}

      <AcceptProducerTerms termsUrl={config.termsUrl} />

      <NextStep allowBack={true} finalStep buttonText="Finalizar Compra" />
    </Grid>
  );
};

export default React.memo(Summary);
