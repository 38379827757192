import { Grid, RadioGroup } from '@material-ui/core';
import Radio from '@material-ui/core/Radio';
import { makeStyles } from '@material-ui/core/styles';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import { enTerminalMethod } from 'enums/enTerminalMethod';

import React from 'react';
import { useObservable } from 'react-use-observable';
import { cartService } from 'services/CartService';
import { paymentService } from 'services/PaymentService';
import Installments from '../CreditCardForm/Installments';

const useStyles = makeStyles({
  component: {
    display: 'flex',
    flexDirection: 'column'
  },
  componentMethods: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginBottom: '12px 0 0 0'
  },
  labelComponentMethod: {
    marginBottom: '8px',
    color: '#636871',
    fontSize: '16px'
  },
  card: {
    display: 'flex',
    textAlign: 'center',
    justifyContent: 'center',
    marginTop: '8px',
    border: '1px solid #ccc',
    cursor: 'pointer',
    height: '65px',
    width: '290px',
    boxSizing: 'border-box',
    transition: 'all 0.3s ease 0s',
    alignItems: 'center',
    borderRadius: '4px',
    flexDirection: 'row',

    '&.active': {
      filter: 'unset',
      border: '1px solid #3960A3',
      color: '#3960A3'
    },

    '@media screen and (max-width: 960px)': {
      width: '100%'
    }
  },
  cardGroup: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '12px'
  },
  installmentComponent: {
    width: '320px',
    '& .SingleStep-component-210 .chk-installments__installmentFieldSelect .field-select': {
      fontSize: '20px'
    }
  }
});

const methods = [
  {
    label: 'Crédito',
    id: 'terminal-credit',
    value: enTerminalMethod.CREDIT
  },
  {
    label: 'Débito',
    id: 'terminal-débit',
    value: enTerminalMethod.DEBIT
  }
];

const PaymentTerminal: React.FC<{}> = () => {
  const classes = useStyles({});
  const [method] = useObservable(() => paymentService.getPayment(0).map((payment) => payment.method), []);

  const [installments] = useObservable(() => paymentService.getPaymentInstallments(0).map((payment) => payment.installments), []);

  const [feeInstallment] = useObservable(() => cartService.getCart().map((c) => c.feeInstallment), []);

  const handleChange = (value: enTerminalMethod) => {
    paymentService.setPaymentData(0, { method: value });
    paymentService.setInstallmentsDefault();
  };

  if (!feeInstallment && !installments) {
    return null;
  }

  return (
    <div className={classes.component}>
      <div className={`${classes.componentMethods} `}>
        <label className={classes.labelComponentMethod}>Selecione crédito ou débito como forma de pagamento</label>
        <RadioGroup
          value={method}
          id="radio-group-terminal-method"
          aria-label="terminal-method"
          name="terminal-method"
          row
          className={classes.cardGroup}
        >
          {methods.map((methodRow) => (
            <div
              onClick={() => handleChange(methodRow.value)}
              id={methodRow.id}
              key={methodRow.id}
              className={`${classes.card} ${method === methodRow.value ? 'active' : ''}`}
            >
              <FormControlLabel
                key={methodRow.id}
                value={methodRow.value}
                label={methodRow.label}
                control={<Radio checked={method === methodRow.value} />}
              />
            </div>
          ))}
        </RadioGroup>
      </div>

      {method === enTerminalMethod.CREDIT && (
        <Grid item xs={12} direction="row">
          <div className={`${classes.installmentComponent}`}>
            <Installments index={0} classes={{ width: '375px' }} />
          </div>
        </Grid>
      )}
    </div>
  );
};

export default React.memo(PaymentTerminal);
