import React from 'react';
import Faq from 'components/ThankYou/Faq';
import { ThankYouContext } from 'components/ThankYou';
import { Content } from './Content';
import { Header } from './Header';
import { enPaymentMethod } from 'enums/enPaymentMethod';
import { Bankslip } from './Bankslip';
import { BaseComponent, IStateBase } from 'components/BaseComponent';
import { SvgIcon } from 'components/SvgIcon';
import Price from './Price';
import contentDeliveryService from 'services/ContentDeliveryService';
import { Button } from '@material-ui/core';
import { Cookies } from 'react-cookie';
import { CHECKOUT_URL_PARAMS } from 'settings';

interface IState extends IStateBase {
  countdown: number;
  clicked: boolean;
}

interface IProps {
  hasCountdown: boolean;
  thankyouUrl?: string;
  hasPostback?: boolean;
}

const cookie = new Cookies();

export default class Dialog extends BaseComponent<IState, IProps> {
  state = {
    countdown: 4,
    clicked: false
  };

  componentDidMount() {
    if (!!this.props.hasCountdown) {
      this.handleRedirect(7);
    }

    contentDeliveryService.listen().subscribe((data) => {
      if (data) {
        this.handleClick();
      }
    });
  }

  handleClick = () => {
    this.setState({ clicked: true });
  };

  handleClose = () => {
    window.location.href = this.props.thankyouUrl;
  };

  handleRedirect = (countdown: number) => {
    this.setState({ countdown });

    const interval = setInterval(() => {
      this.setState({ countdown: this.state.countdown - 1 });
      if (this.state.clicked && this.props.hasCountdown) {
        clearInterval(interval);
      }

      if (this.state.countdown === 0) {
        clearInterval(interval);

        this.handleClose();
      }
    }, 1000);
  };

  removeCookies = () => {
    cookie.remove('last_purchase');
    cookie.remove('email');
    cookie.remove('he');
    cookie.remove('lastPaymentMethod');
    cookie.remove('lastKey');
  };

  redirectToCheckout = () => {
    const urlOrigin = localStorage.getItem(CHECKOUT_URL_PARAMS);
    window.location.assign(urlOrigin);
    this.removeCookies();
  };

  render() {
    return (
      <ThankYouContext.Consumer>
        {(model) => (
          <section id="dialog-thankyou" className={`dialog__thank-you ${!this.props.thankyouUrl ? 'is-default' : ''}`} onClick={this.handleClick}>
            {!!model.paymentResult.thankyouUrl && (
              <div className="close" onClick={this.handleClose}>
                <SvgIcon name="closeIcon" />
              </div>
            )}
            <Header thankYou={model} />
            {model.paymentResult.paymentMethod === enPaymentMethod.BANKSLIP && (
              <Bankslip affiliateId={model.affiliateId || null} thankyouUrl={this.props.thankyouUrl || null} />
            )}
            <div className="product">
              {!!model.logo && model.logo !== 'https://invalid.eduzz.com' && (
                <div className="image" style={{ backgroundImage: `url(${model.logo})` }} />
              )}
              <div className="item">
                <h4 className="productTitle">{model.content.title}</h4>
                {model.paymentResult.paymentMethod !== enPaymentMethod.FREE ? (
                  <Price
                    paymentMethod={model.paymentResult.paymentMethod}
                    price={model.paid}
                    installments={model.installments}
                    subscription={model.subscription ?? null}
                    pagDivididoInstallment={model.pagDivididoInstallmentInfo ?? null}
                  />
                ) : (
                  <div className="price">Grátis</div>
                )}
              </div>
            </div>
            <Content model={model} />
            {model.paymentResult.paymentMethod !== enPaymentMethod.BANKSLIP && !!model.paymentResult.thankyouUrl ? (
              <div className="action">
                <a id="btn-confirm-thankyou" href={this.props.thankyouUrl} className="button">
                  {this.props.hasCountdown && !this.state.clicked && (
                    <span className="countdown">
                      <svg>
                        <circle r="9" cx="10" cy="10" />
                      </svg>
                    </span>
                  )}
                  <span className="text">Confirmar</span>
                </a>
              </div>
            ) : null}
            {model.paymentResult.paymentMethod === enPaymentMethod.TERMINAL && (
              <Button className="buttonOut" onClick={() => `${this.redirectToCheckout()}`}>
                Volte ao checkout
              </Button>
            )}
            <div className="faq">
              <Faq name="Dúvidas Frequentes" showIcon />
            </div>
          </section>
        )}
      </ThankYouContext.Consumer>
    );
  }
}
