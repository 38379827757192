/* eslint-disable max-lines */
/* eslint-disable max-len */
import React from 'react';

const UnfinishedPurchase: React.FC = () => {
  return (
    <svg width="260" height="137" viewBox="0 0 260 137" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M243.107 125.129C241.582 122.217 235.383 119.877 232.611 119.345C217.806 116.519 202.302 116.914 187.306 116.96C170.978 117.01 155.4 119.628 139.201 120.843C130.777 121.471 121.879 120.34 113.438 120.046C99.5373 119.56 85.6403 118.995 71.7471 118.35C59.7661 117.785 47.3333 119.046 36.7412 125.66C35.5386 126.412 34.3247 127.322 33.7827 128.673C30.3498 137.154 73.1304 136.079 78.7031 136.226C106.821 136.956 134.955 137.357 163.073 136.571C183.964 136.006 205.769 136.04 226.507 133.089C231.092 132.433 237.331 132.201 241.294 129.499C243.508 127.995 243.824 126.503 243.107 125.129Z"
        fill="#CDEBFA"
      />
      <path d="M224.904 9.48238C225.271 9.48238 225.649 9.48238 226.033 9.48238" stroke="#D2E8F9" strokeMiterlimit="10" />
      <path
        d="M228.297 9.66329C235.586 10.4039 244.49 13.485 243.451 19.7999C241.791 29.8686 197.108 26.1543 186.425 26.2617C164.97 26.4709 143.272 26.5501 122.229 31.2481C117.114 32.3787 109.808 34.9115 110.344 41.4525C110.536 43.7138 111.88 45.8 113.591 47.349C119.734 52.9007 128.299 53.4377 136.248 54.4101C148.269 55.8857 205.458 53.9183 224.147 60.0635C227.828 61.2734 232.43 62.8281 232.582 68.097C232.972 81.4843 199.761 80.8229 189.802 81.0999C163.214 81.784 136.598 81.5126 110.011 80.9868C88.5557 80.5628 66.9706 80.1841 45.521 78.799C37.5543 78.2506 24.4554 78.0979 18.3576 72.0884C14.9699 68.6963 14.5013 62.6132 19.8933 59.4925C23.6931 57.2934 34.8386 58.2544 39.1296 57.887C52.1157 56.7563 48.1634 48.8867 37.6673 47.8804"
        stroke="#D2E8F9"
        strokeMiterlimit="10"
        strokeDasharray="4.01 4.01"
      />
      <path d="M36.5321 47.8013C36.1651 47.8013 35.7868 47.8013 35.4028 47.8013" stroke="#D2E8F9" strokeMiterlimit="10" />
      <path
        d="M225.666 19.2741L222.244 1.1549C222.196 0.910743 222.085 0.683311 221.923 0.495063C221.761 0.306814 221.552 0.16426 221.317 0.0814815C221.083 -0.00129733 220.831 -0.0214379 220.587 0.0230489C220.342 0.0675358 220.114 0.175111 219.923 0.33515L205.944 12.36C205.753 12.5239 205.609 12.7352 205.526 12.9727C205.443 13.2101 205.424 13.4653 205.471 13.7124C205.518 13.9596 205.629 14.1899 205.793 14.3803C205.958 14.5706 206.169 14.7142 206.407 14.7966L223.791 20.891C224.028 20.9738 224.282 20.9927 224.528 20.9459C224.775 20.8991 225.004 20.7882 225.194 20.6244C225.384 20.4605 225.528 20.2494 225.611 20.0124C225.693 19.7754 225.712 19.5208 225.666 19.2741Z"
        fill="#F0FAFF"
      />
      <path
        d="M223.029 18.9971C222.929 18.9967 222.83 18.9795 222.736 18.9462L208.44 13.9373C208.292 13.8865 208.16 13.7975 208.057 13.6791C207.955 13.5608 207.885 13.4174 207.856 13.2635C207.827 13.1096 207.839 12.9507 207.891 12.8029C207.943 12.6552 208.033 12.5238 208.152 12.4222L219.636 2.52867C219.754 2.42828 219.897 2.36063 220.05 2.33242C220.203 2.3042 220.36 2.31639 220.507 2.36778C220.653 2.41918 220.784 2.508 220.886 2.62545C220.987 2.74291 221.057 2.88494 221.087 3.03748L223.91 17.9399C223.938 18.0937 223.926 18.2522 223.874 18.3998C223.823 18.5475 223.734 18.6792 223.616 18.7823C223.453 18.9224 223.244 18.9987 223.029 18.9971ZM209.196 13.0101L222.747 17.742L220.054 3.65936L209.196 13.0101Z"
        fill="#B5DDF7"
      />
      <path
        d="M216.819 12.914C216.693 12.8624 216.59 12.767 216.529 12.6455C216.468 12.524 216.452 12.3845 216.485 12.2526L217.903 7.2719C217.934 7.16172 217.987 7.05911 218.06 6.97033C218.132 6.88155 218.222 6.80846 218.323 6.75555C218.425 6.70263 218.536 6.671 218.65 6.66257C218.764 6.65415 218.879 6.66911 218.987 6.70655C219.097 6.7439 219.198 6.80362 219.283 6.88197C219.369 6.96031 219.438 7.05559 219.485 7.16179C219.532 7.268 219.557 7.38284 219.558 7.49908C219.558 7.61532 219.535 7.73046 219.489 7.83724L217.496 12.5974C217.441 12.7238 217.342 12.8258 217.217 12.8841C217.092 12.9424 216.951 12.953 216.819 12.914Z"
        fill="#B5DDF7"
      />
      <path
        d="M216.926 14.2934C216.892 14.4018 216.826 14.4974 216.737 14.5678C216.648 14.6382 216.541 14.6803 216.427 14.6884C216.314 14.6966 216.202 14.6705 216.103 14.6135C216.005 14.5566 215.927 14.4714 215.878 14.3691C215.829 14.2667 215.812 14.152 215.829 14.0398C215.846 13.9276 215.896 13.8231 215.973 13.7399C216.05 13.6568 216.151 13.5987 216.261 13.5734C216.371 13.5481 216.487 13.5567 216.593 13.5981C216.726 13.6502 216.834 13.751 216.896 13.8801C216.958 14.0091 216.968 14.1568 216.926 14.2934Z"
        fill="#B5DDF7"
      />
      <path
        d="M34.3193 41.6786L23.0666 36.0704C22.9133 35.9937 22.7425 35.9588 22.5715 35.9691C22.4004 35.9794 22.2351 36.0346 22.092 36.1291C21.949 36.2236 21.8334 36.3541 21.7566 36.5076C21.6799 36.661 21.6449 36.8319 21.655 37.0032L22.4285 49.5708C22.4398 49.7417 22.4956 49.9066 22.5904 50.0491C22.6853 50.1916 22.8158 50.3067 22.969 50.3828C23.1222 50.4589 23.2927 50.4934 23.4634 50.4829C23.6341 50.4723 23.799 50.4171 23.9417 50.3227L34.4209 43.369C34.5638 43.2743 34.6794 43.1437 34.7559 42.9902C34.8325 42.8367 34.8674 42.6657 34.8571 42.4944C34.8468 42.3232 34.7917 42.1576 34.6973 42.0144C34.6029 41.8712 34.4725 41.7554 34.3193 41.6786Z"
        fill="#F0FAFF"
      />
      <path
        d="M33.0829 43.0015C33.0424 43.0551 32.9927 43.1011 32.9361 43.1372L24.3145 48.8585C24.226 48.9171 24.1237 48.9517 24.0177 48.9586C23.9117 48.9656 23.8058 48.9447 23.7104 48.898C23.6149 48.8496 23.5336 48.7773 23.4742 48.6882C23.4149 48.5991 23.3795 48.4961 23.3716 48.3892L22.7336 38.0378C22.7309 37.9331 22.7552 37.8295 22.804 37.7369C22.8529 37.6443 22.9247 37.5658 23.0126 37.509C23.1005 37.4523 23.2015 37.4191 23.3059 37.4127C23.4103 37.4063 23.5146 37.4269 23.6087 37.4725L32.8684 42.0913C32.9631 42.1392 33.0439 42.2106 33.1032 42.2987C33.1625 42.3868 33.1983 42.4886 33.2071 42.5945C33.213 42.7403 33.1692 42.8838 33.0829 43.0015ZM24.1282 48.0896L32.2699 42.6793L23.5241 38.2922L24.1282 48.0896Z"
        fill="#B5DDF7"
      />
      <path
        d="M27.2166 43.8721C27.1379 43.9236 27.0426 43.9429 26.9501 43.9261C26.8577 43.9092 26.7752 43.8576 26.7197 43.7817L24.5968 40.955C24.5494 40.8927 24.5155 40.8212 24.4972 40.7451C24.4788 40.669 24.4765 40.5899 24.4903 40.5128C24.5041 40.4357 24.5337 40.3624 24.5773 40.2974C24.6209 40.2324 24.6775 40.1772 24.7436 40.1352C24.8079 40.0914 24.8806 40.0614 24.9571 40.0471C25.0335 40.0327 25.1122 40.0344 25.188 40.0519C25.2638 40.0694 25.3352 40.1024 25.3976 40.1489C25.4601 40.1953 25.5123 40.2542 25.551 40.3218L27.3295 43.3803C27.3769 43.4612 27.3917 43.5572 27.3707 43.6487C27.3497 43.7401 27.2945 43.8201 27.2166 43.8721Z"
        fill="#B5DDF7"
      />
      <path
        d="M27.9956 44.3753C28.0363 44.437 28.0578 44.5094 28.0573 44.5833C28.0568 44.6572 28.0344 44.7293 27.9929 44.7905C27.9515 44.8517 27.8928 44.8991 27.8243 44.9268C27.7559 44.9545 27.6808 44.9612 27.6085 44.9461C27.5362 44.931 27.4701 44.8948 27.4184 44.8419C27.3668 44.7891 27.3319 44.7221 27.3184 44.6495C27.3048 44.5768 27.3131 44.5017 27.3422 44.4338C27.3713 44.3659 27.4199 44.3081 27.4818 44.2679C27.5643 44.2143 27.6646 44.1955 27.7608 44.2156C27.8571 44.2358 27.9415 44.2931 27.9956 44.3753Z"
        fill="#B5DDF7"
      />
      <path
        d="M42.1164 67.0172C42.6341 67.0172 43.0537 66.5971 43.0537 66.0788C43.0537 65.5605 42.6341 65.1403 42.1164 65.1403C41.5988 65.1403 41.1792 65.5605 41.1792 66.0788C41.1792 66.5971 41.5988 67.0172 42.1164 67.0172Z"
        stroke="#D2E8F9"
        strokeMiterlimit="10"
      />
      <path
        d="M181.253 49.6499C181.771 49.6499 182.19 49.2298 182.19 48.7115C182.19 48.1932 181.771 47.773 181.253 47.773C180.736 47.773 180.316 48.1932 180.316 48.7115C180.316 49.2298 180.736 49.6499 181.253 49.6499Z"
        stroke="#D2E8F9"
        strokeMiterlimit="10"
      />
      <path
        d="M107.43 23.3502C107.948 23.3502 108.368 22.9301 108.368 22.4118C108.368 21.8935 107.948 21.4733 107.43 21.4733C106.913 21.4733 106.493 21.8935 106.493 22.4118C106.493 22.9301 106.913 23.3502 107.43 23.3502Z"
        stroke="#D2E8F9"
        strokeMiterlimit="10"
      />
      <path
        d="M106.307 94.7246C106.825 94.7246 107.244 94.3045 107.244 93.7862C107.244 93.2679 106.825 92.8477 106.307 92.8477C105.789 92.8477 105.37 93.2679 105.37 93.7862C105.37 94.3045 105.789 94.7246 106.307 94.7246Z"
        stroke="#D2E8F9"
        strokeMiterlimit="10"
      />
      <path d="M128.152 38.524V40.644" stroke="#D2E8F9" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M129.213 39.5812H127.09" stroke="#D2E8F9" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M166.212 11.3932V13.5189" stroke="#D2E8F9" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M167.273 12.4561H165.15" stroke="#D2E8F9" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M225.096 68.668V70.7937" stroke="#D2E8F9" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M226.157 69.7309H224.034" stroke="#D2E8F9" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M147.185 129.369C147.185 129.369 143.232 131.065 143.232 131.902C143.232 132.382 148.658 131.692 148.986 131.364C149.511 130.839 148.771 129.442 148.771 129.442L147.185 129.369Z"
        fill="#26387A"
      />
      <path
        d="M146.931 127.011L147.185 129.442C147.243 129.574 147.339 129.685 147.461 129.763C147.582 129.84 147.724 129.88 147.868 129.878C148.044 129.881 148.218 129.844 148.377 129.768C148.536 129.693 148.674 129.581 148.783 129.442L148.591 126.401"
        fill="#FFB288"
      />
      <path
        d="M153.825 71.2856C155.264 77.0747 154.728 80.0201 152.628 84.3902C151.352 99.0891 150.46 113.714 148.749 128.04L146.931 128.142C142.815 114.195 139.026 93.3452 139.506 74.2875"
        fill="#072D83"
      />
      <path
        d="M152.932 73.2756C153.419 74.651 153.626 76.1098 153.542 77.5665C153.474 79.7657 152.582 81.7501 152 83.8362C151.233 86.6177 151.17 89.3878 150.962 92.2372C150.634 96.692 150.299 101.149 149.957 105.607C149.403 112.878 148.839 120.148 148.263 127.418L148.212 128.069"
        stroke="#0061A6"
        strokeWidth="0.5"
        strokeMiterlimit="10"
      />
      <path
        d="M162.926 129.934C162.926 129.934 158.974 131.63 158.974 132.467C158.974 132.947 164.405 132.258 164.727 131.93C165.252 131.404 164.518 130.008 164.518 130.008L162.926 129.934Z"
        fill="#26387A"
      />
      <path
        d="M162.672 127.577L162.926 130.008C162.985 130.139 163.081 130.251 163.202 130.328C163.324 130.406 163.465 130.445 163.609 130.443C163.785 130.447 163.959 130.409 164.118 130.334C164.277 130.258 164.416 130.146 164.524 130.008L164.326 126.966"
        fill="#FFB288"
      />
      <path
        d="M166.952 70.7937C168.77 76.4754 168.431 79.4491 166.619 83.9492C166.319 98.6934 165.309 114.088 164.541 128.493H162.678C157.647 114.851 152.255 93.7862 151.481 74.7398"
        fill="#072D83"
      />
      <path
        d="M166.054 73.1456C166.054 73.1456 168.155 78.081 164.598 85.0177C164.191 91.5531 163.977 128.504 163.977 128.504"
        stroke="#0061A6"
        strokeWidth="0.5"
        strokeMiterlimit="10"
      />
      <path
        d="M160.848 74.7115C161.226 75.2625 161.764 75.6831 162.39 75.9157C163.01 76.1443 163.657 76.2925 164.315 76.3567C165.091 76.4638 165.875 76.5036 166.658 76.4754"
        stroke="#0061A6"
        strokeWidth="0.5"
        strokeMiterlimit="10"
      />
      <path
        d="M161.103 75.6331C161.793 76.2284 162.622 76.6388 163.513 76.8259C164.401 77.0065 165.306 77.0918 166.212 77.0803"
        stroke="#0061A6"
        strokeWidth="0.5"
        strokeMiterlimit="10"
      />
      <path
        d="M145.395 43.3181C139.117 54.2575 132.364 64.1623 128.355 76.1079L127.164 75.4691C127.469 64.3658 131.06 48.2196 135.78 38.1565"
        fill="#FDD0B7"
      />
      <path
        d="M142.34 31.8982C140.84 32.3849 139.417 33.0815 138.111 33.9674C135.984 35.4562 134.298 37.4937 133.233 39.8639C132.177 42.2249 131.469 44.7273 131.133 47.2925C132.919 48.1382 134.823 48.71 136.779 48.9885C138.844 49.2029 140.925 49.2029 142.99 48.9885"
        fill="#CDEBFA"
      />
      <path
        d="M137.66 38.3657C136.993 50.1927 137.501 62.3588 137.801 73.8692C137.801 73.8692 141.922 74.966 151.916 74.966C161.91 74.966 168.42 71.9188 168.42 71.9188C166.489 60.0975 164.947 45.5851 160.221 35.8499C160.221 35.8499 154.88 30.0835 149.612 29.846C148.531 29.8796 147.473 30.1683 146.524 30.6884C145.779 31.0332 142.363 31.8699 142.363 31.8699L137.66 38.3657Z"
        fill="#CDEBFA"
      />
      <path d="M137.603 39.4172C137.344 41.6786 137.344 48.7115 137.344 48.7115" stroke="#94C1F2" strokeWidth="0.5" strokeMiterlimit="10" />
      <path
        d="M147.557 77.6117C164.281 75.7574 171.271 96.9747 175.099 105.212C184.099 124.671 218.06 122.5 233.53 122.5C253.8 122.5 262.693 129.335 253.235 132.925"
        stroke="#039BE5"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M153.3 78.5502C153.3 87.1604 146.79 92.3615 141.223 92.3615H132.121V64.7389H141.245C146.812 64.7389 153.3 69.9344 153.3 78.5502Z"
        fill="#FFF4D4"
      />
      <path
        d="M148.619 78.5502C148.619 86.9625 143.639 93.8145 137.501 93.8145H127.429V63.3086H137.49C143.639 63.3086 148.619 70.1323 148.619 78.5502Z"
        fill="#FFCA28"
      />
      <g style={{ mixBlendMode: 'multiply' }} opacity="0.5">
        <path
          d="M150.849 70.2227C148.67 73.1964 146.549 76.2116 144.486 79.2682C144.779 79.2071 145.049 79.064 145.265 78.8555C145.265 79.0873 144.904 79.483 145.225 79.63C145.547 79.777 145.886 79.3982 146.225 79.5C146.541 79.4265 146.835 79.0308 147.066 78.8046C146.988 78.9718 146.94 79.1519 146.925 79.336C149.144 78.4711 151.131 76.3963 152.859 74.7285C152.463 73.1185 151.782 71.5926 150.849 70.2227Z"
          fill="#FDAE2B"
        />
      </g>
      <path
        d="M128.152 93.7862C134.292 93.7862 139.269 86.9648 139.269 78.5502C139.269 70.1356 134.292 63.3143 128.152 63.3143C122.012 63.3143 117.035 70.1356 117.035 78.5502C117.035 86.9648 122.012 93.7862 128.152 93.7862Z"
        fill="#FFF4D4"
      />
      <path
        d="M129.078 83.9492C129.078 85.1986 128.225 86.2106 127.175 86.2106C126.125 86.2106 125.267 85.1986 125.267 83.9492C125.267 82.6998 126.119 81.6879 127.175 81.6879C128.231 81.6879 129.078 82.6998 129.078 83.9492Z"
        fill="#FFCA28"
      />
      <path
        d="M129.078 73.1455C129.078 74.4006 128.225 75.4069 127.175 75.4069C126.125 75.4069 125.267 74.395 125.267 73.1455C125.267 71.8961 126.119 70.8842 127.175 70.8842C128.231 70.8842 129.078 71.8961 129.078 73.1455Z"
        fill="#FFCA28"
      />
      <path
        d="M163.27 47.0833C163.27 47.0833 163.976 54.2801 160.764 60.2671C158.014 65.3551 152.554 72.0092 148.144 75.6613L147.083 75.4013C148.619 69.5047 153.463 59.0063 153.768 55.0998C154.073 51.1933 153.994 46.6197 154.067 44.4318"
        fill="#FDD0B7"
      />
      <path
        d="M149.624 29.846C149.624 29.846 155.541 30.2531 158.974 33.1589C163.875 37.3029 164.62 45.4155 164.62 47.2925C163.141 48.8924 159.906 48.9885 157.546 48.9885C156.109 48.9796 154.7 48.5894 153.463 47.8578L152.978 38.9028"
        fill="#CDEBFA"
      />
      <path
        d="M147.512 74.5363C146.819 74.6273 146.164 74.9091 145.621 75.3504C145.101 75.7574 144.492 76.4358 144.531 76.7468C144.571 77.0577 145.451 76.4471 145.553 76.5715C145.655 76.6959 144.102 79.0534 144.424 79.2286C144.746 79.4039 146.039 77.2725 146.039 77.2725C146.039 77.2725 144.751 79.5339 145.254 79.6017C145.666 79.6413 146.812 77.4874 146.812 77.4874C146.812 77.4874 145.847 79.4152 146.174 79.4887C146.502 79.5622 147.399 77.64 147.399 77.64C147.399 77.64 146.671 79.2795 146.953 79.3078C147.095 79.3078 147.812 78.505 148.111 77.5156C148.41 76.5263 148.754 75.7009 148.489 74.9377"
        fill="#FDD0B7"
      />
      <path d="M153.689 39.8469V47.9991" stroke="#94C1F2" strokeWidth="0.5" strokeMiterlimit="10" />
      <path
        d="M137.361 22.327C137.332 22.6775 137.315 23.0336 137.321 23.3898C137.281 24.1634 137.478 24.9308 137.886 25.589C138.192 26.0259 138.642 26.3419 139.156 26.4822C139.512 26.584 139.986 26.5388 140.139 26.1996C140.188 26.0757 140.209 25.9425 140.201 25.8095C140.193 24.4923 139.938 23.1883 139.45 21.9651"
        fill="#072D83"
      />
      <path
        d="M146.293 23.0676C145.784 21.0832 144.187 18.313 141.589 18.8671C140.777 19.013 140.017 19.3681 139.383 19.8974C138.75 20.4267 138.265 21.1121 137.976 21.886C137.411 23.746 138.332 25.9112 139.444 27.432C139.839 28.0176 140.365 28.5034 140.98 28.851C142.482 29.6312 144.508 28.9923 145.53 27.7203C146.005 27.055 146.32 26.2895 146.452 25.4828C146.585 24.6761 146.53 23.8498 146.293 23.0676Z"
        fill="#FDD0B7"
      />
      <path
        d="M142.645 28.2461L143.498 31.3498L142.369 31.9151C142.69 32.2827 143.1 32.5607 143.56 32.7222C144.02 32.8837 144.514 32.9231 144.994 32.8367C148.54 32.3278 149.652 29.863 149.652 29.863L147.653 29.6368L145.395 25.8603"
        fill="#FDD0B7"
      />
      <path
        d="M148.02 20.9023C147.864 20.2291 147.57 19.5957 147.157 19.042C146.744 18.4883 146.221 18.0264 145.621 17.6855C143.52 16.4078 140.539 16.3061 138.67 18.0643C137.603 19.0649 135.689 22.587 137.981 23.3163C138.693 23.5368 139.427 23.1015 140.048 22.6888L141.945 21.4168C142.159 21.2441 142.417 21.1345 142.69 21.1002C142.912 21.1199 143.124 21.2051 143.298 21.345C143.471 21.4848 143.6 21.6731 143.667 21.886C143.785 22.3173 143.878 22.7553 143.944 23.1976C143.994 23.5142 144.164 23.7994 144.418 23.9947C144.482 24.0411 144.554 24.0737 144.631 24.0904C144.708 24.107 144.788 24.1074 144.865 24.0914C144.942 24.0754 145.015 24.0435 145.079 23.9976C145.143 23.9518 145.197 23.893 145.237 23.8251C145.288 23.6114 145.288 23.3887 145.237 23.175C145.237 22.9545 145.361 22.6888 145.575 22.6944C145.655 22.7036 145.73 22.7371 145.79 22.7905C146.355 23.2202 146.163 24.0795 146.355 24.7579C146.355 24.8484 146.197 26.2165 146.259 26.2844C148.032 25.8603 148.218 21.8803 148.02 20.9023Z"
        fill="#072D83"
      />
      <path
        d="M145.395 22.8527C145.39 22.726 145.434 22.6022 145.519 22.5074C145.603 22.4126 145.72 22.354 145.847 22.3439C145.973 22.3399 146.1 22.3613 146.218 22.4069C146.336 22.4526 146.444 22.5215 146.535 22.6096C146.706 22.746 146.844 22.9177 146.942 23.1129C147.039 23.3081 147.093 23.5222 147.1 23.7403C147.094 24.0809 146.986 24.4119 146.789 24.6901C146.677 24.8936 146.497 25.0515 146.281 25.1367C146.138 25.1704 145.988 25.1646 145.848 25.1198C145.708 25.0749 145.582 24.9929 145.485 24.8823"
        fill="#FDD0B7"
      />
      <path
        d="M82.5424 129.284C82.5424 129.284 86.3479 129.917 86.4495 130.76C86.5116 131.234 81.1139 131.811 80.7469 131.528C80.1823 131.076 80.8824 129.38 80.8824 129.38L82.5424 129.284Z"
        fill="#26387A"
      />
      <path
        d="M82.2377 126.943L82.5482 129.357C82.5064 129.495 82.4254 129.618 82.3151 129.71C82.2048 129.803 82.07 129.861 81.9272 129.878C81.7527 129.904 81.5745 129.889 81.4069 129.834C81.2392 129.779 81.0867 129.686 80.9617 129.561L80.5156 126.553"
        fill="#FFB288"
      />
      <path
        d="M68.0036 72.0262C67.326 77.9566 67.2865 80.8059 69.9345 84.8707C71.188 90.609 73.1133 96.4772 74.57 102.351C73.9433 109.367 78.3303 120.538 80.696 128.515L82.3898 128.227C84.6878 113.861 85.5347 91.8414 83.4739 72.5632"
        fill="#072D83"
      />
      <path
        d="M69.1162 80.0597C69.1162 80.0597 75.6657 100.887 75.6657 102.018C75.6657 103.148 75.8464 107.032 76.1343 109.554C76.6651 114.172 80.8432 127.78 80.8432 127.78"
        stroke="#0061A6"
        strokeWidth="0.5"
        strokeMiterlimit="10"
      />
      <path d="M80.6452 128.837L80.3799 127.797L82.5819 127.712L82.6101 128.673L80.6452 128.837Z" fill="#0061A6" />
      <path
        d="M52.9283 129.403C52.9283 129.403 55.9094 131.557 55.6441 132.314C55.4916 132.75 50.6473 130.952 50.4553 130.556C50.1448 129.923 51.4716 128.781 51.4716 128.781L52.9283 129.403Z"
        fill="#26387A"
      />
      <path
        d="M53.657 127.283L52.9061 129.465C52.8126 129.566 52.6918 129.637 52.5584 129.67C52.425 129.704 52.2848 129.697 52.1551 129.651C51.9973 129.596 51.8537 129.507 51.7346 129.39C51.6155 129.272 51.5238 129.13 51.4663 128.973L52.3584 126.231"
        fill="#FFB288"
      />
      <path
        d="M55.2096 70.7937C53.3915 76.4754 53.7303 79.4491 55.537 83.9492C55.65 89.3313 55.7064 94.7812 55.7629 100.231C51.9348 109.842 51.325 118.452 51.8106 127.599L53.6569 128.283C53.6569 128.283 64.6612 109.062 66.6881 101.492C68.9779 92.7446 70.3163 83.7751 70.6799 74.7398"
        fill="#072D83"
      />
      <path
        d="M55.6615 72.0262C55.6615 72.0262 53.6571 77.8944 56.2826 83.5478C56.2826 85.1251 57.186 98.2467 56.8472 100.271C56.6778 101.243 54.8767 105.885 53.6741 110.396C52.3473 115.394 52.0649 127.17 52.0649 127.17"
        stroke="#0061A6"
        strokeWidth="0.5"
        strokeMiterlimit="10"
      />
      <path d="M51.4717 127.594L51.7653 126.361L54.131 127.464L53.6624 128.521L51.4717 127.594Z" fill="#0061A6" />
      <path
        d="M85.9812 26.8893L84.5019 25.6003V22.9997C84.5019 22.5927 83.9373 20.173 83.4518 20.173C82.9662 20.173 82.842 19.5624 82.5936 19.8112C82.3451 20.0599 82.7968 22.8188 82.5936 23.0223C82.3903 23.2259 82.1249 21.7107 81.9386 21.8916C81.7523 22.0726 81.7354 23.1919 81.7354 23.7629C81.8191 24.527 82.1343 25.247 82.6387 25.8264C83.0904 26.2787 85.0779 28.4327 85.0779 28.4327L85.9812 26.8893Z"
        fill="#B28B67"
      />
      <path
        d="M85.2413 26.0526L83.9992 27.4603C83.9992 27.4603 88.8943 33.9504 90.447 36.1213C88.8248 35.447 87.277 34.6058 85.8285 33.6112C83.5249 32.1017 81.2156 31.2989 79.6178 31.2989C76.2301 31.2989 77.9974 42.4927 81.9327 44.1265C89.3912 45.2572 97.7418 44.5392 99.0348 41.916C100.943 38.0378 90.0066 28.8566 85.2413 26.0526Z"
        fill="#CDEBFA"
      />
      <path d="M90.4473 36.1213C90.4473 36.1213 91.3789 38.7332 93.0388 39.5868" stroke="#94C1F2" strokeWidth="0.5" strokeMiterlimit="10" />
      <path
        d="M62.4877 34.2613C53.5951 44.2566 53.7306 61.4599 53.7306 73.1512C53.7306 73.1512 60.2575 77.6117 70.2285 77.6117C80.1996 77.6117 84.3438 75.1525 84.3438 75.1525C84.6431 63.2803 82.5314 58.1922 84.485 38.6145C84.485 38.6145 81.3006 31.2989 79.6067 31.2989C77.9129 31.2989 71.2731 30.6431 71.2731 30.6431L62.4877 34.2613Z"
        fill="#CDEBFA"
      />
      <path d="M84.0103 44.3922C84.0103 44.3922 84.2079 41.0002 85.0379 40.1409" stroke="#94C1F2" strokeWidth="0.5" strokeMiterlimit="10" />
      <path
        d="M74.2539 23.1467C74.5871 21.1284 76.0212 18.2452 78.6579 18.5731C80.3122 18.7766 81.5995 19.9186 82.5255 21.2585C83.3216 22.3891 83.0167 23.5198 81.8875 26.42C81.4341 27.2004 80.8452 27.8935 80.1485 28.4666C78.7144 29.3711 76.6366 28.9132 75.5074 27.7147C74.9686 27.0968 74.5761 26.3651 74.359 25.5742C74.142 24.7833 74.1061 23.9535 74.2539 23.1467Z"
        fill="#B28B67"
      />
      <path
        d="M74.5816 22.898C74.9599 22.8414 74.5816 22.5983 74.751 22.9432C74.8583 23.1637 74.8865 23.2259 75.1067 23.2993C75.3269 23.3728 75.3212 23.5651 75.4963 23.6951C75.7673 23.8986 76.0044 23.6216 76.1174 23.3841C76.3024 22.9295 76.3571 22.4322 76.2754 21.9482C76.2359 21.5751 76.1117 20.9871 76.4787 20.727C76.8457 20.467 77.4329 20.8231 77.7378 20.9136C78.2121 21.0493 78.6807 21.2019 79.1493 21.3433C80.2761 21.7614 81.4802 21.9292 82.6781 21.8351C83.2159 21.7884 83.7347 21.6137 84.1914 21.3256C84.6481 21.0375 85.0294 20.6442 85.3036 20.1787C85.9021 19.048 84.7051 17.4989 83.4855 17.4989C83.1675 17.5319 82.8536 17.5963 82.5483 17.6912C82.4336 17.7221 82.3129 17.7221 82.1982 17.6912C81.9328 17.612 81.8143 17.3124 81.6618 17.0806C81.4418 16.7606 81.1336 16.5116 80.7746 16.364C80.4157 16.2164 80.0216 16.1767 79.6405 16.2495C78.7737 16.4344 77.9238 16.6915 77.0998 17.0184C76.6669 17.0749 76.2284 17.0749 75.7955 17.0184C75.5519 17.0326 75.3163 17.1106 75.1122 17.2445C74.9081 17.3784 74.7427 17.5636 74.6324 17.7816C74.2654 17.3915 73.4411 18.3469 73.2378 18.59C72.3345 19.6868 72.0465 21.2698 72.0239 22.6605C72.0239 23.876 72.2046 25.6399 73.5314 26.1261C74.096 26.3352 74.4518 26.4483 74.4743 25.9282C74.2674 25.2663 74.1478 24.5802 74.1186 23.8873C74.0752 23.6705 74.0996 23.4456 74.1885 23.2433C74.2774 23.0409 74.4265 22.871 74.6155 22.7566"
        fill="#072D83"
      />
      <path
        d="M78.9063 27.9691L78.6184 31.0163L79.6234 31.2989C79.4164 31.7383 79.0999 32.1169 78.7043 32.3981C78.3087 32.6793 77.8474 32.8538 77.3649 32.9045C73.8135 33.3737 71.3066 30.6431 71.3066 30.6431L72.4867 30.417L74.2821 24.7636"
        fill="#B28B67"
      />
      <path
        d="M75.174 22.8753C75.1667 22.7489 75.111 22.6301 75.0185 22.5437C74.9259 22.4574 74.8036 22.4101 74.6771 22.4118C74.5509 22.4175 74.4271 22.449 74.3135 22.5045C74.1999 22.5599 74.0989 22.638 74.0165 22.734C73.0228 23.7234 74.2706 26.1261 75.2474 24.888"
        fill="#B28B67"
      />
      <path
        d="M81.865 26.3691C81.7069 26.7423 81.0746 26.8949 80.7358 26.9345C79.042 27.1211 77.6812 26.0412 76.0834 25.8434C75.9372 25.778 75.7754 25.7554 75.6169 25.7784C75.4584 25.8013 75.3097 25.8688 75.1879 25.9729C75.0661 26.0771 74.9764 26.2137 74.929 26.3669C74.8816 26.5201 74.8786 26.6835 74.9203 26.8384C74.8973 27.2149 74.8973 27.5925 74.9203 27.9691C74.9345 28.294 75.0234 28.6113 75.18 28.8962C75.9027 30.1004 78.308 30.3039 79.5332 30.2304C80.0268 30.2281 80.5091 30.0828 80.9221 29.8121C81.4867 29.3937 81.594 28.6814 81.7973 28.0313C81.9525 27.4907 81.9757 26.9206 81.865 26.3691Z"
        fill="#072D83"
      />
      <path
        d="M80.6791 27.6864C80.6297 27.6452 80.5682 27.6214 80.5041 27.6186C79.8924 27.5743 79.2836 27.497 78.6804 27.3868C78.5778 27.3671 78.4724 27.3671 78.3698 27.3868C78.2672 27.4309 78.1779 27.5011 78.1106 27.5903C78.0433 27.6795 78.0003 27.7847 77.9859 27.8956C77.9654 27.9957 77.9808 28.0999 78.0293 28.1898C78.0778 28.2797 78.1564 28.3497 78.2513 28.3874C78.6186 28.5537 79.0169 28.6404 79.42 28.6418C79.7969 28.7013 80.1829 28.6316 80.5153 28.444C80.6791 28.3196 80.9219 27.8617 80.6791 27.6864Z"
        fill="#B28B67"
      />
      <path
        d="M73.3336 71.8283C53.9279 70.8276 42.5962 80.8512 34.5618 99.14C24.1334 122.884 9.56078 124.773 3 124.637"
        stroke="#039BE5"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M65.8808 73.4113C66.5979 81.9988 73.5143 86.6346 79.0588 86.171L88.1547 85.4135L85.8624 57.887L76.7665 58.6445C71.222 59.1081 65.1637 64.8294 65.8808 73.4113Z"
        fill="#FFF4D4"
      />
      <path
        d="M70.567 73.0212C71.2672 81.4109 76.7778 87.7936 82.9094 87.2848L92.9482 86.4424L90.4188 56.0722L80.38 56.9089C74.254 57.4234 69.8669 64.6372 70.567 73.0212Z"
        fill="#FFCA28"
      />
      <path
        d="M92.2378 86.4966C98.3564 85.9854 102.75 78.7732 102.051 70.3877C101.352 62.0023 95.8257 55.619 89.7071 56.1302C83.5885 56.6414 79.1949 63.8536 79.8937 72.239C80.5926 80.6245 86.1192 87.0078 92.2378 86.4966Z"
        fill="#FFF4D4"
      />
      <path
        d="M90.4868 76.775C90.5941 78.0245 91.5257 78.9629 92.5759 78.8781C93.626 78.7933 94.3883 77.7079 94.2697 76.4585C94.1511 75.2091 93.2308 74.2762 92.1806 74.361C91.1305 74.4458 90.3852 75.5313 90.4868 76.775Z"
        fill="#FFCA28"
      />
      <path
        d="M89.5891 66.0166C89.6963 67.2603 90.6279 68.1988 91.6781 68.114C92.7283 68.0292 93.4905 66.9438 93.3719 65.7C93.2534 64.4563 92.3331 63.5121 91.2829 63.5969C90.2327 63.6817 89.4987 64.7672 89.5891 66.0166Z"
        fill="#FFCA28"
      />
      <path
        d="M91.8078 67.4356L105.991 66.254C106.404 66.2166 106.787 66.0177 107.055 65.7004C107.323 65.383 107.456 64.9726 107.425 64.558C107.392 64.1408 107.195 63.7537 106.878 63.4815C106.56 63.2092 106.148 63.0739 105.731 63.1051L91.5481 64.2923C90.4584 64.3488 90.6617 67.5204 91.8078 67.4356Z"
        fill="#ECEFF1"
      />
      <path
        d="M92.6995 78.1771L106.888 76.9899C107.302 76.9539 107.686 76.7554 107.954 76.4377C108.223 76.12 108.355 75.7088 108.322 75.2938C108.289 74.8766 108.092 74.4896 107.775 74.2173C107.457 73.945 107.045 73.8097 106.628 73.8409L92.4454 75.0225C91.3501 75.1073 91.559 78.2845 92.6995 78.1771Z"
        fill="#ECEFF1"
      />
      <path
        d="M57.7503 47.0833C57.7503 47.0833 57.0446 54.2801 60.2572 60.2671C63.0069 65.3551 68.4667 72.0092 72.8819 75.6613L73.9434 75.4013C72.4076 69.5047 67.5576 59.0063 67.2584 55.0998C66.9591 51.1933 67.0269 46.6197 66.9535 44.4318"
        fill="#B28B67"
      />
      <path
        d="M71.2898 30.6431C71.2898 30.6431 65.0791 31.005 61.4656 33.7186C55.2549 38.4053 56.4857 50.9955 58.2868 57.152C60.0879 63.3086 68.7773 72.5237 71.5778 74.5363C72.0408 74.5363 73.3902 74.4232 73.3394 73.366C71.7867 68.5606 67.3714 55.9365 67.3714 55.9365L68.0433 38.9028"
        fill="#CDEBFA"
      />
      <path
        d="M73.5088 74.5363C74.1967 74.6292 74.8455 74.911 75.3833 75.3504C75.9027 75.7574 76.5407 76.4358 76.473 76.7468C76.4052 77.0577 75.5527 76.4471 75.4511 76.5715C75.3494 76.6959 76.9077 79.0534 76.6141 79.2286C76.3206 79.4039 74.9937 77.2725 74.9937 77.2725C74.9937 77.2725 76.281 79.5339 75.7842 79.6017C75.2873 79.6696 74.2258 77.4874 74.2258 77.4874C74.2258 77.4874 75.1913 79.4152 74.8582 79.4887C74.5251 79.5622 73.633 77.64 73.633 77.64C73.633 77.64 74.3614 79.2795 74.0847 79.3078C73.9435 79.3078 73.2208 78.505 72.9216 77.5156C72.6224 76.5263 72.2836 75.7009 72.549 74.9377"
        fill="#B28B67"
      />
      <path
        d="M69.1157 60.9568C68.122 58.0905 67.3711 55.9365 67.3711 55.9365L68.681 41.6447"
        stroke="#94C1F2"
        strokeWidth="0.5"
        strokeMiterlimit="10"
      />
      <path
        d="M56.8635 45.1159C56.8297 48.9941 57.2983 53.7656 58.2863 57.152C59.1559 60.1144 61.6176 63.7948 64.2543 67.0342"
        stroke="#94C1F2"
        strokeWidth="0.5"
        strokeMiterlimit="10"
      />
      <path
        d="M190.202 24.0569L191.202 23.3728C191.375 22.4959 191.616 21.6339 191.924 20.7949C192.276 20.1518 192.741 19.5773 193.296 19.0988C193.539 18.9349 194.657 18.3017 194.939 18.5844C195.075 18.7257 194.606 19.9864 194.375 20.2069C194.143 20.4274 193.522 21.3885 193.618 21.6938C193.714 21.999 194.245 21.3319 194.375 21.2076C194.504 21.0832 194.668 20.9249 194.725 21.0888C194.634 21.9117 194.32 22.6939 193.816 23.3502C193.377 23.8159 192.794 24.1189 192.161 24.2095L190.626 25.6003"
        fill="#FBDCB7"
      />
      <path
        d="M202.274 16.8714C203.077 17.3981 203.765 18.0834 204.295 18.8854C204.825 19.6873 205.186 20.589 205.357 21.5355C205.393 21.8728 205.518 22.1946 205.718 22.4683C205.915 22.6531 206.147 22.7955 206.401 22.8866C207.084 23.215 207.694 23.6775 208.194 24.2466C208.695 24.8158 209.076 25.4799 209.314 26.1996C209.518 26.8045 209.36 27.6694 209.597 28.1896C209.89 28.8284 210.974 28.9697 211.556 29.1676C212.435 29.4576 213.201 30.0156 213.747 30.7634C214.293 31.5112 214.591 32.4114 214.601 33.3377C214.61 34.2641 214.33 35.1701 213.799 35.9288C213.268 36.6875 212.513 37.2609 211.641 37.5686C211.86 37.9061 212.001 38.2878 212.056 38.6865C212.111 39.0852 212.077 39.4911 211.957 39.8752C211.713 40.6426 211.298 41.3444 210.743 41.9274C209.621 43.1538 208.243 44.1182 206.707 44.7522C205.171 45.3863 203.515 45.6745 201.856 45.5964C200.901 45.5709 199.957 45.3796 199.067 45.0311C198.079 44.5873 197.162 44.0006 196.345 43.2898C195.272 42.5389 194.389 41.5478 193.765 40.3953C193.2 39.2137 193.251 37.6647 194.177 36.7488C193.634 36.8346 193.077 36.7594 192.575 36.5324C192.073 36.3054 191.649 35.9365 191.354 35.4712C191.069 35.0013 190.881 34.4792 190.801 33.9355C190.72 33.3919 190.749 32.8376 190.886 32.3052C191.163 31.2392 191.661 30.2435 192.348 29.3824C192.875 28.8169 193.25 28.1262 193.438 27.3755C193.601 26.0978 192.873 24.9558 192.704 23.6894C192.416 21.7164 192.506 20.0204 194.194 18.9688C194.759 17.5611 195.391 16.4757 196.944 16.063C197.84 15.8277 198.776 15.7781 199.692 15.9171C200.608 16.0561 201.487 16.3808 202.274 16.8714Z"
        fill="#265AAB"
      />
      <path
        d="M198.214 31.8756C192.568 30.4 186.137 32.7292 184.698 32.7236C186.668 30.7505 190.276 26.3691 191.524 24.8484C191.555 24.5897 191.512 24.3274 191.4 24.0922C191.288 23.8569 191.112 23.6584 190.891 23.5198C190.891 23.5198 175.444 31.5194 177.013 36.7432C178.634 42.1931 193.771 39.519 194.979 41.085"
        fill="#CDEBFA"
      />
      <path
        d="M199.315 82.6376C199.315 83.1012 197.283 93.0286 197.136 94.0971C196.701 97.2008 198.339 100.316 198.181 105.121C197.989 110.973 195.504 126.695 195.188 127.39C194.872 128.085 193.359 127.447 193.359 127.447C193.359 127.447 188.989 106.359 188.746 97.7323C188.543 90.6203 189.57 75.2373 189.57 75.2373"
        fill="#FBDCB7"
      />
      <path
        d="M195.341 127.661C195.463 127.905 195.545 128.166 195.583 128.436C195.549 129.092 195.532 129.538 195.532 129.538H195.245L195.149 129.007C194.605 129.122 194.088 129.339 193.624 129.646C193.251 129.889 191.366 129.866 191.089 129.714C191.034 129.689 190.986 129.651 190.949 129.604C190.911 129.556 190.886 129.501 190.874 129.442C190.863 129.383 190.865 129.322 190.882 129.264C190.899 129.206 190.929 129.153 190.97 129.109C191.078 128.984 193.099 128.097 193.099 128.097"
        fill="#26387A"
      />
      <path
        d="M193.387 127.447C193.224 127.842 191.62 128.719 191.767 128.837C192.478 128.964 193.21 128.886 193.878 128.611C194.821 128.3 195.352 127.95 195.38 127.701L195.2 127.04"
        fill="#FFB288"
      />
      <path
        d="M213.335 83.0503C213.335 83.5139 211.641 93.4979 211.5 94.572C211.161 97.6871 212.894 100.746 212.883 105.562C212.883 111.414 210.862 127.204 210.568 127.91C210.274 128.617 208.739 128.023 208.739 128.023C208.739 128.023 203.731 107.106 203.222 98.4559C202.805 91.3609 203.369 75.9496 203.369 75.9496"
        fill="#FBDCB7"
      />
      <path
        d="M210.732 128.176C210.861 128.414 210.95 128.672 210.997 128.939C210.997 129.595 210.997 130.07 210.997 130.07H210.715L210.602 129.538C210.062 129.674 209.551 129.905 209.094 130.222C208.733 130.483 206.836 130.516 206.565 130.375C206.509 130.351 206.46 130.315 206.421 130.269C206.382 130.222 206.354 130.168 206.341 130.109C206.327 130.05 206.327 129.988 206.342 129.929C206.356 129.871 206.384 129.816 206.424 129.77C206.531 129.646 208.53 128.696 208.53 128.696"
        fill="#26387A"
      />
      <path
        d="M208.739 128.023C208.586 128.425 207.017 129.346 207.169 129.459C207.882 129.569 208.612 129.469 209.269 129.171C210.195 128.832 210.721 128.464 210.743 128.215L210.54 127.56"
        fill="#FFB288"
      />
      <path
        d="M208.242 57.5082C208.242 57.5082 212.714 60.448 214.424 67.4865C216.722 76.9842 213.335 95.5783 213.335 95.5783C208.563 96.8704 203.643 97.5374 198.7 97.5627C191.072 97.5005 186.555 94.9169 186.544 94.4589C186.533 94.001 185.415 79.9183 186.956 70.3697C188.695 59.6282 193.122 56.7619 193.122 56.7619"
        fill="#265AAB"
      />
      <path
        d="M202.172 31.6947C202.866 31.4346 204.668 32.0678 208.038 33.3624C209.518 34.9623 210.167 38.4788 210.483 41.5881C209.608 49.1637 208.631 53.302 209.105 55.8517C209.218 56.4566 209.772 56.9146 209.67 57.7626C209.489 59.5038 206.632 59.7695 201.201 60.3858C195.769 61.002 190.897 58.5371 191.19 57.7682C191.484 56.9994 192.41 56.321 192.557 55.8122C192.703 55.3033 193.121 48.8132 192.557 48.0444C191.992 47.2755 190.462 45.0593 190.462 42.9054C190.462 39.8639 192.156 38.5014 193.223 36.8336C195.047 34.0521 196.43 32.3109 198.191 31.8756C201.037 31.1745 202.172 31.6947 202.172 31.6947Z"
        fill="#CDEBFA"
      />
      <path
        d="M193.048 35.5333C193.147 36.36 192.983 37.1968 192.58 37.9247C192.376 38.2583 191.145 39.5133 190.886 40.3105"
        stroke="#6CB1EF"
        strokeWidth="0.5"
        strokeMiterlimit="10"
      />
      <path
        d="M200.874 27.9973C202.353 26.5275 202.568 23.7347 201.918 21.8464C201.162 19.7207 198.135 18.1321 196.052 19.4607C191.97 22.0726 196.114 32.7349 200.874 27.9973Z"
        fill="#FBDCB7"
      />
      <path
        d="M198.056 27.7373L198.135 32.26C198.135 32.26 197.966 33.272 199.739 33.3342C202.172 33.419 202.172 31.6721 202.172 31.6721L200.766 26.991"
        fill="#FBDCB7"
      />
      <path
        d="M199.897 17.4537C199.672 17.2924 199.403 17.2028 199.126 17.1968C198.849 17.1907 198.576 17.2685 198.344 17.4198C198.067 17.6513 197.777 17.8665 197.475 18.0643C197.22 18.1482 196.96 18.2125 196.696 18.2565C196.089 18.427 195.527 18.7249 195.045 19.1305C194.563 19.536 194.173 20.0399 193.901 20.6083C193.404 21.739 193.562 23.5764 194.499 24.3848C194.471 23.3833 194.708 22.3921 195.188 21.5129C195.285 21.3043 195.438 21.1266 195.629 20.9989C195.82 20.8713 196.043 20.7988 196.272 20.7892C196.786 20.8514 197.063 21.3998 197.435 21.756C198.037 22.2272 198.772 22.4962 199.536 22.5248C200.371 22.6492 201.015 22.7962 201.427 23.6159C201.592 23.9779 201.717 24.3571 201.8 24.7466C201.928 25.2032 202.015 25.6706 202.059 26.143C202.052 26.4378 202.083 26.7322 202.15 27.0193C202.27 27.2896 202.45 27.5286 202.678 27.7178C202.905 27.907 203.172 28.0414 203.46 28.1104C203.889 28.2517 204.589 28.3874 204.882 27.9012C205.12 27.4508 205.288 26.9665 205.379 26.4653C205.576 24.9146 205.203 23.3453 204.329 22.0499C203.426 20.6083 202.144 19.6472 200.981 18.4487C200.656 18.0793 200.293 17.7458 199.897 17.4537Z"
        fill="#265AAB"
      />
      <path
        d="M201.038 24.4696C201.072 24.2913 201.157 24.1263 201.28 23.9934C201.404 23.8605 201.563 23.765 201.738 23.7177C202.867 23.5424 202.72 25.2667 202.274 25.8773C202.014 26.2278 201.342 26.6914 201.038 25.7134"
        fill="#FBDCB7"
      />
      <path
        d="M213.296 59.8317L212.731 60.9963C212.731 60.9963 210.681 61.4825 210.472 61.8444C210.128 62.4097 209.614 63.2068 209.614 63.6534C209.614 64.1001 209.659 66.0788 210.218 66.0788C210.574 66.0788 211.398 64.4675 211.568 64.2414C211.969 63.7156 213.595 63.3256 213.595 61.9292L214.588 59.8317"
        fill="#FBDCB7"
      />
      <path
        d="M202.172 31.6325C202.172 31.6325 206.96 30.4113 210.698 32.7632C213.606 34.5949 221.087 43.7365 221.087 48.9715C221.087 52.4993 214.091 61.2395 214.091 61.2395L212.928 60.4084C212.928 60.4084 213.606 53.5395 215.644 49.7517C214.142 48.2535 208.304 43.5895 206.825 42.1139"
        fill="#CDEBFA"
      />
      <path d="M206.062 41.6503C207.107 42.5605 209.862 44.8219 209.862 44.8219" stroke="#6CB1EF" strokeWidth="0.5" strokeMiterlimit="10" />
    </svg>
  );
};

export default React.memo(UnfinishedPurchase);
