import Grid from '@material-ui/core/Grid';
import FieldText from 'components/Field/Text';
import { SvgIcon } from 'components/SvgIcon';
import Translate from 'components/Translate';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Observable } from 'rxjs/Observable';
import { cartService } from 'services/CartService';
import { ICEP, getAddress } from 'services/LocationService';

import Checkbox from '@material-ui/core/Checkbox/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel/FormControlLabel';
import Hidden from '@material-ui/core/Hidden';
import { useObservable } from 'react-use-observable';
import { AddressContext } from '..';
import CityState, { ICityState } from './CityState';
import SearchCEP from './SearchCEP';

const Br: React.FC = () => {
  const [currentPostalCode, setCurrentPostalCode] = useState('');
  const [cepNotFound, setCepNotFound] = useState(false);
  const [hasNumberBoolean, setHasNumberBoolean] = useState(true);
  const numberInputRef = useRef<HTMLInputElement>();

  const context = useContext(AddressContext);
  const model = context.data;

  const searchAddress = useCallback(
    (postalCode: string) => {
      getAddress(postalCode)
        .loader()
        .catch(() => Observable.empty<ICEP>())
        .do((data) => {
          setCepNotFound(!data.cep);
        })
        .filter((data) => !!data.cep)
        .subscribe((data) => {
          context.setData({
            postalCode: data.cep,
            street: data.street,
            district: data.neighborhood,
            city: data.city,
            province: data.state
          });
        });
    },
    [context]
  );

  const setPostalCode = useCallback(
    (postalCode: string) => {
      if (typeof postalCode !== 'string') {
        return;
      }

      context.setData({ postalCode });

      if (postalCode.length < 9 || postalCode === currentPostalCode) {
        return;
      }

      searchAddress(postalCode);

      setCurrentPostalCode(postalCode);
    },
    [context, currentPostalCode, searchAddress]
  );

  const setStreet = useCallback(
    (street: any) => {
      context.setData({ street });
    },
    [context]
  );

  const setNumber = useCallback(
    (number: any) => {
      const stringNumber = `${number}` || '';
      context.setData({ number: stringNumber });
    },
    [context]
  );

  const setHasNumber = useCallback(() => {
    setHasNumberBoolean(!hasNumberBoolean);
    const newString = !hasNumberBoolean ? '' : 'S/N';
    context.setData({ number: newString });
  }, [context, hasNumberBoolean]);

  const setDistrict = useCallback(
    (district: any) => {
      context.setData({ district });
    },
    [context]
  );

  const setComplement = useCallback(
    (complement: any) => {
      context.setData({ complement });
    },
    [context]
  );

  const setAddressData = useCallback(
    (result: ICityState) => {
      context.setData(result);
    },
    [context]
  );

  const [config] = useObservable(() => {
    return cartService
      .getConfig()
      .map((config) => ({
        templateId: config.template.id,
        ead: config.ead
      }))
      .distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b));
  }, []);

  const cepClosed = useCallback(
    (postalCode: string) => {
      if (!postalCode) {
        return;
      }

      setPostalCode(postalCode);

      setTimeout(() => {
        if (numberInputRef.current) {
          numberInputRef.current.focus();
        }
      }, 100);
    },
    [setPostalCode]
  );

  useEffect(() => {
    if (model.number === 'S/N') {
      setHasNumberBoolean(false);
    }
  }, [model?.number]);

  const ead = config ? config.ead : false;
  const templateId = config ? Number(config.templateId) : 18;

  if (!config) {
    return null;
  }

  return (
    <div>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12}>
          <SearchCEP onClose={cepClosed} isFullScreen={true} />
          <FieldText
            id="postalCode"
            label={<Translate term={ead ? 'CEP da Residência do Aluno' : 'CEP da Residência'} />}
            validation="required|min:9|max:9"
            value={model.postalCode}
            mask="postalCode"
            inputProps={{
              pattern: '[0-9]*',
              inputMode: 'numeric',
              className: 'theme-input',
              type: 'tel'
            }}
            InputLabelProps={{
              className: 'theme-label',
              id: 'label-postal-code'
            }}
            onChange={setPostalCode}
          />
          {cepNotFound ? (
            <span className={'cepNotFound'}>
              <SvgIcon name={'warning'} />
              Ops! Não localizamos o CEP {model.postalCode}, verifique se está correto, se estiver correto continue preenchendo normalmente.
            </span>
          ) : null}
        </Grid>
      </Grid>

      <Grid container spacing={1} className="chk-userAddress__container">
        <Grid item xs={9}>
          <FieldText
            id="street"
            label={<Translate term="Endereço" />}
            validation="required"
            value={model.street}
            inputProps={{
              className: 'theme-input'
            }}
            InputLabelProps={{
              className: 'theme-label',
              id: 'label-street'
            }}
            onChange={setStreet}
          />
        </Grid>

        <Grid item xs={3}>
          <FieldText
            id="number"
            mask="number"
            label={<Translate term="Número" />}
            disabled={!hasNumberBoolean}
            validation="required|max:11"
            value={model.number}
            inputProps={{
              className: `theme-input ${!hasNumberBoolean ? 'theme-input-disabled' : ''}`
            }}
            inputRef={numberInputRef}
            InputLabelProps={{
              className: 'theme-label',
              id: 'label-address-number'
            }}
            onChange={setNumber}
          />
        </Grid>

        <Grid container item xs={12}>
          {templateId === 18 && (
            <Hidden smDown>
              <Grid xs={9} item></Grid>
            </Hidden>
          )}
          <Grid item xs={12} sm={templateId === 18 ? 3 : 12} style={{ padding: 0 }} container>
            <FormControlLabel
              style={{ margin: 0 }}
              control={
                <Checkbox id="chk-savecard-button" style={{ padding: 2 }} className="checkbox" checked={!hasNumberBoolean} onChange={setHasNumber} />
              }
              label={<Translate className="chk-has-number" term="Não tenho número" />}
            />
          </Grid>
        </Grid>

        <Grid item xs={6} className="chk-userAddress__district">
          <FieldText
            id="district"
            label={<Translate term="Bairro" />}
            validation="required"
            value={model.district}
            inputProps={{
              className: 'theme-input'
            }}
            InputLabelProps={{
              className: 'theme-label',
              id: 'label-district'
            }}
            onChange={setDistrict}
          />
        </Grid>

        <Grid item xs={6} className="chk-userAddress__complement">
          <FieldText
            id="complement"
            label={<Translate term="Complemento" />}
            value={model.complement}
            inputProps={{
              className: 'theme-input'
            }}
            InputLabelProps={{
              className: 'theme-label',
              id: 'label-complement'
            }}
            onChange={setComplement}
          />
        </Grid>
      </Grid>

      <CityState
        prefixId="mainForm"
        template={Number(templateId)}
        disableAutocompleteOnProvince
        value={{ city: model.city, province: model.province }}
        onChange={setAddressData}
      />
    </div>
  );
};

export default Br;
