import Grid from '@material-ui/core/Grid';
import React from 'react';
import { makeStyles } from '@material-ui/core';

interface IProps {
  step: number;
  title: string;
  listData: Array<{ key: string; value: string }>;
}
const useStyles = makeStyles(() => ({
  root: {
    height: '100%',
    background: '#F9FAFB',
    border: '1px solid #B0BEC5',
    borderRadius: 8,
    display: 'flex',
    flexFlow: 'column',
    padding: 16
  },
  containerHead: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start'
  },
  containerTitle: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  step: {
    width: 30,
    height: 30,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 16,
    borderRadius: '50%',
    backgroundColor: '#CDEBFA'
  },
  title: {
    margin: 0,
    fontWeight: 600
  },
  dataList: {
    margin: '14px 0 10px 0',
    display: 'flex',
    flexFlow: 'row wrap'
  },
  dataContainer: {
    width: '100%',
    display: 'flex',
    marginBottom: 5
  },
  dataText: {
    margin: 0
  }
}));

const CardData: React.FC<IProps> = ({ step, title, listData }) => {
  const classes = useStyles({});

  return (
    <Grid className={classes.root} container>
      <Grid className={classes.containerHead} item xs={12}>
        <Grid className={classes.containerTitle} item xs={8}>
          <span className={classes.step}>{step}</span>
          <p className={classes.title}>{title}</p>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <dl className={classes.dataList}>
          {listData.map((data) => (
            <div className={classes.dataContainer} key={data.key}>
              <p className={classes.dataText}>
                <b>{data.key}:&nbsp;</b>
                {data.value}
              </p>
            </div>
          ))}
        </dl>
      </Grid>
    </Grid>
  );
};

export default React.memo(CardData);
