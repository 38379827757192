import React, { Fragment } from 'react';
import PaymentLoader from './PaymentLoader/index';
import Translate from 'components/Translate';
import { enPaymentMethod } from 'enums/enPaymentMethod';

interface IProps {
  paymentMethod: enPaymentMethod;
}
export const PaymentInProgress: React.FC<IProps> = ({ paymentMethod }) => {
  return (
    <Fragment>
      <div className="progressMessage">
        <Translate
          term={paymentMethod === enPaymentMethod.TERMINAL ? 'Aguardando o pagamento da maquininha...' : 'Estamos processando seu pagamento...'}
        />
      </div>
      <PaymentLoader />
    </Fragment>
  );
};
