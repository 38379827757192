/* eslint-disable @typescript-eslint/no-unused-vars */
import get from 'lodash/get';
import { Observable } from 'rxjs/Observable';
import { Subscriber } from 'rxjs/Subscriber';
import { Subscription } from 'rxjs/Subscription';
import { logService } from 'shared/services';
import * as Sentry from '@sentry/browser';

interface IIgnoreParam {
  (err: any): boolean;
}

function logError<T>(this: Observable<T>, ignore: IIgnoreParam = null): Observable<T> {
  return this.lift(new LogErrorOperator(ignore));
}

Observable.prototype.logError = logError;

declare module 'rxjs/Observable' {
  interface Observable<T> {
    logError: typeof logError;
  }
}

class LogErrorOperator {
  constructor(private ignore: IIgnoreParam) {}

  public call(subscriber: Subscriber<any>, source: Observable<any>): Subscription {
    return source.subscribe(new LogErrorSubscriber(subscriber, this.ignore));
  }
}

class LogErrorSubscriber extends Subscriber<any> {
  constructor(protected destination: Subscriber<any>, private ignore: IIgnoreParam) {
    super(destination);

    this.ignore = ignore;
  }

  public _next(value: any): void {
    this.destination.next(value);
  }

  public _complete(): void {
    this.destination.complete();
  }

  public _error(err: any): void {
    const code = get(err, 'code', null);
    const errorCode = get(err, 'data.code', null);

    Sentry.setTag('error_code', errorCode);
    Sentry.setTag('exception_code', code);

    if (!this.ignore || !this.ignore(err)) {
      logService.handleError(err);
    }

    this.destination.error(err);
  }
}
