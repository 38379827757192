/* eslint-disable @typescript-eslint/no-unused-vars */
import { BaseComponent, IStateBase } from 'components/BaseComponent';
import UserForm from 'components/Cart/MainForm/UserForm';
import { ICartModel } from 'interfaces/ICartModel';
import { IProductConfig, IProductData, IProduct } from 'interfaces/IProductData';
import Button from '@material-ui/core/Button/Button';
import Grid from '@material-ui/core/Grid/Grid';
import React from 'react';
import { cartService } from 'services/CartService';
import { paymentService } from 'services/PaymentService';
import { enStep, IStepProps } from './index';
import { FieldValidation } from 'components/Field';
import { SvgIcon } from 'components/SvgIcon';
import Logo from './Logo';
import Translate from 'components/Translate';
import { enPaymentType } from 'enums/enPaymentType';
import { parameterService } from 'services/ParameterService';
import { validate } from 'validators';
import PaymentButton from 'components/Cart/PaymentButton';
import StuddentForm from 'components/Cart/MainForm/StuddentForm';
import { unescapeHtml } from 'functions';
import Alert from 'shared/components/Alert';
import { enParameters } from 'enums/enParameters';
import FormHeader from 'components/Cart/FormHeader';

interface IState extends IStateBase {
  cart: IProductData;
  config: IProductConfig;
  model: ICartModel;
  changeCard: boolean;
}

export interface IProps extends IStepProps {
  onPay: () => void;
  product: IProduct;
}

export default class StepUser extends BaseComponent<IState, IProps> {
  constructor(props: any) {
    super(props);

    cartService
      .getCart()
      .map((cart) => cart.config)
      .filter((config) => config.paymentType !== enPaymentType.FREE || config.askAddressBeforePayment)
      .switchMap(() => parameterService.getAll())
      .bindComponent(this)
      .filter((params) => !!params.skip)
      .filter((params) => validate(params.name || params.nome, 'required|name|max:100').valid)
      .filter((params) => validate(params.email, 'required|email|max:150').valid)
      .filter((params) => validate(params.phone || params.cel, 'required|min:10|max:11').valid)
      .subscribe(() => {
        this.goToNextStep();
      });
  }

  componentDidMount() {
    this.observeState({ cart: null }, cartService.getCart());
    this.observeState({ config: null }, cartService.getConfig());
    this.bindModel(paymentService.getCart());
    parameterService.get(enParameters.changeCard).subscribe((changeCard) => this.setState({ changeCard: Boolean(changeCard) }));
  }

  onValidate(errors: any) {
    this.setState({ validation: errors || {} });
  }

  isValid(): boolean {
    const validation = this.state.validation || {};
    return Object.keys(validation).length === 0;
  }

  goToNextStep = async () => {
    if (await this.isFormValid()) {
      this.props.setStep(enStep.address);
    }
  };

  getForFree() {
    const { cart } = this.state;

    if (cart.config.paymentType === enPaymentType.FREE && !cart.config.askAddressBeforePayment) {
      return true;
    }

    return false;
  }

  finish = async () => {
    if (await this.isFormValid()) {
      this.props.onPay();
    }
  };

  render() {
    if (!this.state) {
      return null;
    }

    const { product } = this.props;
    const { formSubmitted, config, changeCard } = this.state;

    const disabledButton = !this.isValid() && formSubmitted;

    return (
      <div className="step">
        <FieldValidation.Provider value={this.registerFields}>
          <Grid className="grid" container>
            <Grid item xs={12} className="chk-multiStep__productName theme-header-bg theme-header">
              <Logo image={config.logo} />
              <h2 className="product-name">{unescapeHtml(product.title)}</h2>
            </Grid>
            <FormHeader />
            <Grid item xs={12} className="chk-form-component">
              {changeCard && <Alert message="Atenção! Este checkout irá alterar a sua forma de pagamento das próximas assinaturas." />}
              {config.ead ? <StuddentForm /> : <UserForm />}
            </Grid>
            <small className="chk-stepUser__securityMessage">
              <SvgIcon name="security" />
              <Translate term="Seus dados serão mantidos em sigilo" />
            </small>
            {this.getForFree() ? (
              <PaymentButton finish={this.finish} disabledButton={disabledButton} messageButton="Obter gratuitamente" />
            ) : (
              <Button
                onClick={this.goToNextStep}
                id="chk-next-button"
                fullWidth
                variant="contained"
                size="large"
                color="primary"
                className="chk-btnContinue theme-button"
                disabled={disabledButton}
              >
                <Translate term="Próximo" />
              </Button>
            )}
          </Grid>
        </FieldValidation.Provider>
      </div>
    );
  }
}
