import { BaseComponent, IStateBase } from 'components/BaseComponent';
import React, { RefObject } from 'react';
import { SvgIcon } from 'components/SvgIcon';
import { WithStyles } from 'shared/decorators/withStyles';

interface IState extends IStateBase {
  next: number;
  active: number;
  elements: number;
}

@WithStyles(() => ({
  '@global': {
    '@keyframes boop': {
      '0%': {
        transform: 'scale(0) rotate(-180deg)',
        opacity: 0
      },

      '25%': {
        transform: 'scale(1.1) rotate(20deg)',
        opacity: 1
      },

      '50%': {
        transform: 'scale(1) rotate(0deg)',
        opacity: 1
      },

      '75%': {
        transform: 'scale(1) rotate(0deg)',
        opacity: 1
      },

      '100%': {
        transform: 'scale(0) rotate(0)',
        opacity: 0
      }
    }
  },
  component: {
    width: '100px',
    height: '100px',
    position: 'relative',
    display: 'block',
    margin: '100px auto',

    '& .icon': {
      position: 'absolute',
      width: '100%',
      height: '100%',
      margin: 'auto',
      display: 'block',
      transform: 'scale(0)',

      '&.active': {
        animation: 'boop 1.5s ease-in-out forwards',
        transform: 'none'
      },

      '& svg': {
        maxWidth: '100%',
        maxHeight: '100%'
      }
    }
  }
}))
export default class PaymentLoader extends BaseComponent<IState> {
  public loaderElement: RefObject<any>;
  public timer: any;

  constructor(props: any) {
    super(props);
    this.loaderElement = React.createRef();
  }

  componentDidMount() {
    const length = this.loaderElement.current.children.length;

    this.loaderElement.current.children[0].classList.add('active');
    this.setState({ active: 0, next: 1, elements: length });
    this.timer = setInterval(() => this.handleAnimation(), 1500);
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  handleAnimation() {
    const next = this.state.next < this.state.elements ? this.state.next : 0;

    this.loaderElement.current.children[this.state.active].classList.remove('active');
    this.loaderElement.current.children[next].classList.add('active');

    this.setState({ active: next });
    this.setState({ next: next + 1 });
  }

  render() {
    return (
      <div className={this.props.classes.component} ref={this.loaderElement}>
        <div className="icon">
          <SvgIcon name="videoPaymentLoader" />
        </div>
        <div className="icon">
          <SvgIcon name="ticketPaymentLoader" />
        </div>
        <div className="icon">
          <SvgIcon name="relogioPaymentLoader" />
        </div>
        <div className="icon">
          <SvgIcon name="camisetaPaymentLoader" />
        </div>
        <div className="icon">
          <SvgIcon name="ebookPaymentLoader" />
        </div>
        <div className="icon">
          <SvgIcon name="tenisPaymentLoader" />
        </div>
      </div>
    );
  }
}
