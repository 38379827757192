import React from 'react';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import { makeStyles } from '@material-ui/core/styles';
import WarningRounded from '@material-ui/icons/WarningRounded';

interface IProps {
  message: string;
}

const useStyles = makeStyles({
  snackbar: {
    marginBottom: 16,
    maxWidth: 'none',
    boxShadow: 'none',
    backgroundColor: '#CDEBFA',
    padding: '6px 6px 6px 18px',
    color: '#37474F',
    fontWeight: 500
  },
  container: {
    display: 'flex',
    alignItems: 'center'
  },
  icon: {
    marginRight: 15,
    fill: '#039BE5'
  },
  text: {
    margin: 0,
    fontSize: '0.778rem'
  }
});
const Alert: React.FC<IProps> = ({ message }) => {
  const classes = useStyles({});

  return (
    <SnackbarContent
      className={classes.snackbar}
      message={
        <div className={classes.container}>
          <WarningRounded fontSize="small" className={classes.icon} />
          <p className={classes.text}>{message}</p>
        </div>
      }
    />
  );
};

export default Alert;
