import { BaseComponent, IStateBase } from 'components/BaseComponent';
import React from 'react';
import Dialog from '@material-ui/core/Dialog/Dialog';
import YoutubeEmbed from 'components/YoutubeEmbed/index';
import { cartService } from 'services/CartService';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import DialogActions from '@material-ui/core/DialogActions/DialogActions';
import { showAlert } from 'shared/services/dialog';
import { enPaymentType } from 'enums/enPaymentType';
import { getCdnImage } from 'functions';
import { IProductData } from 'interfaces/IProductData';
import { exitPopUpService } from 'services/ExitPopUpService';
import { WithStyles } from 'shared/decorators/withStyles';

interface IState extends IStateBase {
  cart: IProductData;
  open: boolean;
  timerMinutes: string;
  timerSeconds: string;
}

@WithStyles(() => ({
  component: {
    '& div[class^="MuiPaper-root-"]': {
      maxWidth: '600px',
      maxHeight: '500px'
    },
    '& iframe': {
      border: 0
    },
    '& .chk-btn-action': {
      border: 0,
      height: 'auto',
      margin: 0,
      padding: '0 !important',
      width: '100%',
      '& .btn-receive-discount': {
        padding: '25px 30px',
        fontWeight: 500,
        fontSize: '1.2rem',
        margin: 0,
        display: 'inline-block',
        border: 0,
        width: '100%',
        height: 'auto',
        overflow: 'hidden',
        position: 'relative',
        transition: 'all .3s ease-in-out',
        cursor: 'pointer',
        textTransform: 'uppercase'
      }
    },
    '& .chk-dialog-text': {
      padding: 0,
      overflow: 'hidden',
      minWidth: '600px',
      height: '450px',
      '& .chk-youtube': {
        height: '100%'
      },
      '& .chk-img-modal': {
        width: '100%',
        height: '100%'
      }
    },
    '& .chk-dialog-header': {
      alignItems: 'center',
      color: '#FFF',
      display: 'flex',
      overflowY: 'visible',
      height: '100%',
      justifyContent: 'space-between',
      padding: '20px',
      textAlign: 'center',
      width: '100%',
      '& span': {
        color: 'white',
        fontSize: '0.9rem'
      },
      '& .chk-timer': {
        borderRadius: '5px',
        color: '#FFF',
        fontSize: '1.5rem',
        padding: '0.2rem',
        width: '3rem'
      },
      '& .container-timer': {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'space-between',
        width: '90px'
      }
    }
  }
}))
export default class ExitPopUp extends BaseComponent<IState, {}> {
  constructor(props: any) {
    super(props);
    this.state = {
      open: false,
      timerMinutes: '14',
      timerSeconds: '57',
      cart: null
    };
  }

  componentDidMount() {
    this.observeState({ cart: null }, cartService.getCart());

    exitPopUpService
      .verifyTimer()
      .bindComponent(this)
      .subscribe((registredValue) => {
        this.handleClickOpen();
        this.setTimer(registredValue);
      });

    exitPopUpService
      .mouseEvent()
      .bindComponent(this)
      .subscribe((registredValue) => {
        this.handleClickOpen();
        this.setTimer(registredValue);
      });

    exitPopUpService
      .listenPostMessage()
      .map((data) => {
        if (!data.selectedOption) {
          this.setState({ open: false });
          return null;
        }

        if (data.selectedOption.id == 'exitPopUp') {
          this.setState({ open: true });
        } else {
          this.setState({ open: false });
        }
      })
      .bindComponent(this)
      .subscribe(() => {});
  }

  setTimer = (registredValue: any) => {
    const clock = setInterval(() => {
      let actualValue: any = new Date().getTime();
      let finalValueMinutes = (registredValue - actualValue) / 1000 / 60;
      let finalValueSeconds = ((registredValue - actualValue) / 1000 / 60 - Math.trunc(finalValueMinutes)) * 60;

      this.setState({
        timerMinutes: ('0' + Math.floor(finalValueMinutes)).slice(-2),
        timerSeconds: ('0' + finalValueSeconds.toFixed(0)).slice(-2)
      });

      if (parseInt(this.state.timerMinutes) <= 0 && parseInt(this.state.timerSeconds) <= 0) {
        this.setState({ open: false });
        clearInterval(clock);
      }
    }, 1000);
  };

  handleClickOpen = () => {
    this.setState({ open: true });

    window.requestAnimationFrame(() => {
      const audio: HTMLAudioElement = document.querySelector('#audioModal');

      if (!audio) {
        return;
      }

      try {
        audio.play();
      } catch (err) {
        console.log('browser bloqueou a execução do audio');
      }
    });
  };

  handleClose = () => {
    this.setCoupon();
    this.setState({ open: false });
  };

  setCoupon = () => {
    const coupon = (this.state.cart.config.exitModal && this.state.cart.config.exitModal.coupon) || null;

    if (!coupon) {
      return;
    }

    cartService
      .setCoupon(this.state.cart.config.exitModal.coupon, 2)
      .catch(() => {
        return showAlert('Ocorreu um erro ao aplicar o seu cupom, tente novamente').map(() => false);
      })
      .bindComponent(this)
      .subscribe();
  };

  render() {
    if (!this.state.cart) {
      return null;
    }

    const { cart } = this.state;
    const configExitModal = cart.config.exitModal;
    const mediaImage = configExitModal.media.image;
    const image = mediaImage && mediaImage.includes('data:') ? mediaImage : getCdnImage(mediaImage);
    const styleButton = {
      backgroundColor: `#${configExitModal.button.theme.backgroundColor}`,
      color: `#${configExitModal.button.theme.textColor}`,
      fontSize: `${configExitModal.button.theme.textSize}px`
    };

    return (
      <div>
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className={this.props.classes.component}
        >
          <DialogContent className="chk-dialog-header theme-exit-pop-up-header">
            <span className="theme-exit-pop-up-title">{configExitModal.timer.theme.title}</span>
            <div className="container-timer">
              {configExitModal.timer.enabled && (
                <div className="theme-exit-pop-up-timer">
                  {this.state.timerMinutes}:{this.state.timerSeconds}
                </div>
              )}
            </div>
          </DialogContent>
          <DialogContent className="chk-dialog-text">
            {configExitModal.media.video ? (
              <YoutubeEmbed url={configExitModal.media.video} />
            ) : (
              mediaImage && image && <img className="chk-img-modal" src={mediaImage.includes('data:') ? mediaImage : getCdnImage(mediaImage)} />
            )}
            {configExitModal.media.audio && (
              <audio id="audioModal">
                <source type="audio/mpeg" src={configExitModal.media.audio} />
              </audio>
            )}
          </DialogContent>
          <DialogActions className="chk-btn-action">
            {configExitModal.button.enabled && cart.config.paymentType !== enPaymentType.FREE && (
              <button
                id="chk-exitpopup-button"
                style={styleButton}
                className="btn-receive-discount theme-exit-pop-up-button"
                onClick={this.handleClose}
              >
                {configExitModal.button.theme.text}
              </button>
            )}
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
