import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import UnfinishedPurchase from './UnfinishedPurchase';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexFlow: 'column wrap',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '16px 0'
  },
  title: {
    maringTOp: 16,
    fontSize: '1.111rem',
    fontWeight: 600,
    textAlign: 'center'
  },
  description: {
    fontSize: '0.889rem',
    textAlign: 'center'
  }
}));

const EmptyState: React.FC = () => {
  const classes = useStyles({});

  return (
    <Grid className={classes.container} container>
      <UnfinishedPurchase />
      <span className={classes.title}>Ops!</span>
      <p className={classes.description} id="pagdividido-description-error">
        Infelizmente não encontramos ofertas disponíveis, mas fique tranquilo, você pode tentar outra forma de pagamento.
      </p>
    </Grid>
  );
};

export default React.memo(EmptyState);
