import { StandardTextFieldProps } from '@material-ui/core/TextField';
import React, { PureComponent, RefObject } from 'react';
import { validate } from 'validators';

import { FieldValidation, IFieldValidationContext } from '.';
import { Subscription } from 'rxjs/Subscription';
import { IProductConfig } from 'interfaces/IProductData';
import { cartService } from 'services/CartService';
import { Observable } from 'rxjs/Observable';
import { currencyService } from 'services/CurrencyService';
import { enCurrency } from 'enums/enCurrency';

export interface IStateFieldBase {
  touched: boolean;
  errorMessage: string;
  submitted: boolean;
  blur: boolean;
  currency: enCurrency;
  config: IProductConfig;
  selectListToTop: boolean;
  value: any;
}

export interface IPropsFieldBase extends StandardTextFieldProps {
  label: React.ReactNode;
  disabled?: boolean;
  value?: any;
  classes?: any;
  validation?: string;
  errorMessage?: string;
  onChange: (value: any) => void;
}

export default abstract class FieldBase<
  P extends IPropsFieldBase = IPropsFieldBase,
  S extends IStateFieldBase = IStateFieldBase
> extends PureComponent<P, S> {
  static getDerivedStateFromProps({ value, validation }: IPropsFieldBase, currentState: IStateFieldBase): IStateFieldBase {
    const error = validate(value, validation);

    return {
      ...currentState,
      errorMessage: error.message
    };
  }

  protected validationContext: IFieldValidationContext;

  protected inputRef: RefObject<any>;

  private subscriptions: Subscription[] = [];

  constructor(props: any) {
    super(props);
    this.state = { touched: false, blur: false, error: null, selectListToTop: false } as any;
    this.inputRef = React.createRef();
  }

  get errorMessage() {
    const { errorMessage: errorProp } = this.props;
    const { blur, submitted, errorMessage } = this.state;

    return submitted || blur ? errorProp || errorMessage : null;
  }

  public setFormSubmitted(submitted: boolean) {
    if (submitted === this.state.submitted) {
      return;
    }

    this.setState({ submitted });
  }

  public forceIsValid() {
    const { value, validation } = this.props;
    const result = validate(value, validation);
    return result.valid;
  }

  public isValid() {
    return !this.state.errorMessage && !this.props.errorMessage;
  }

  public getErrorMessage() {
    return this.state.errorMessage || this.props.errorMessage;
  }

  public getFieldId() {
    return this.props.id;
  }

  get isRequired() {
    return (this.props.validation || '').includes('required');
  }

  public componentDidMount() {
    this.setState({
      config: null,
      currency: enCurrency.BRL
    });

    this.subscriptions.push(cartService.getConfig().subscribe((config) => this.setState({ config })));

    this.subscriptions.push(currencyService.getCurrency().subscribe((currency) => this.setState({ currency })));

    this.subscriptions.push(
      Observable.fromEvent(window, 'scroll')
        .debounceTime(10)
        .filter(() => !!this.inputRef.current)
        .map(() => {
          const innerHeight = window.innerHeight;
          const elemRect = this.inputRef.current.getBoundingClientRect();

          return innerHeight - elemRect.bottom;
        })
        .map((dist) => dist < 210)
        .distinctUntilChanged()
        .subscribe((selectListToTop) => {
          this.setState({ selectListToTop });
        })
    );
  }

  public componentWillUnmount() {
    this.subscriptions.forEach((s) => s.unsubscribe());
    this.validationContext && this.validationContext.unbind(this);
  }

  public setContext(newContext: IFieldValidationContext): React.ReactNode {
    if (newContext === this.validationContext) return null;

    this.validationContext && this.validationContext.unbind(this);

    if (newContext) {
      this.validationContext = newContext;
      this.validationContext.bind(this);
    }

    return null;
  }

  public render() {
    return <FieldValidation.Consumer>{(context) => this.setContext(context)}</FieldValidation.Consumer>;
  }

  protected onChange(value: any) {
    this.setState({ touched: true, value });
    this.props.onChange(value);

    if (this.validationContext?.update) {
      this.validationContext?.update();
    }
  }

  protected onBlur(event: any) {
    if (this.state.value && !this.props.onBlur) {
      const value = typeof this.state.value === 'string' ? this.state.value.trim() : this.state.value;
      this.onChange(value);

      this.setState({ blur: true, submitted: true, value });
    }

    if (this.props.onBlur) {
      this.props.onBlur(event);
    }

    this.setState({ blur: true });

    if (this.validationContext?.update) {
      this.validationContext?.update();
    }
  }
}
