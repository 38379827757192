import { Observable } from 'rxjs/Observable';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { interval } from 'rxjs/observable/interval';
import { cartService } from './CartService';
import { PREVIEW_MODE } from 'settings';
import { checkLocalStorage } from 'functions';

export class TimeService {
  private timeLeft$ = new BehaviorSubject<number>(0);
  private reserveTime$ = new BehaviorSubject<number>(0);
  private isOver$ = new BehaviorSubject<boolean>(false);
  private show$ = new BehaviorSubject<boolean>(false);

  public setIsOver(): void {
    this.isOver$.next(true);
  }

  public resetIsOver(): void {
    this.isOver$.next(false);
  }

  public setReserveTime(reserveTime: number): void {
    this.reserveTime$.next(reserveTime);
  }

  public setTimeLeft(minutes: number): void {
    if (minutes <= 0 && !PREVIEW_MODE) {
      this.timeLeft$.next(0);
      this.timeLeft$.complete();
      this.setIsOver();
      return;
    }

    if (minutes <= 0) {
      this.timeLeft$.next(0);
      this.setIsOver();
      return;
    }

    const timeLeft = new Date().getTime() + 1000 * minutes;

    this.timeLeft$.next(timeLeft);
    this.show$.next(true);
  }

  public getIsOver(): Observable<boolean> {
    return this.isOver$.asObservable().distinctUntilChanged();
  }

  public getReserveTime(): Observable<number> {
    return this.reserveTime$.asObservable().distinctUntilChanged();
  }

  public calculateTime(): Observable<any> {
    return interval(50)
      .combineLatest(this.getTimeLeft(), cartService.getCart().take(1))
      .switchMap(([, time, cart]) => {
        const newTime = time - new Date().getTime();
        if (newTime <= 0) {
          this.setIsOver();
        }

        let localStorageKey: string = `${cart.contentId}-sun-scarcity-time`;

        if (PREVIEW_MODE) {
          localStorageKey = `${localStorageKey}-customizer`;
        }

        if (checkLocalStorage()) {
          window.localStorage.setItem(localStorageKey, (newTime / 1000).toString());
        }

        return Observable.of(newTime / 1000);
      })
      .takeWhile((time) => time > 0);
  }

  public getTimeLeft(): Observable<any> {
    return this.timeLeft$.asObservable();
  }
}

// tslint:disable-next-line:variable-name
export const TimerServiceFactory = () => new TimeService();

export const timerService = TimerServiceFactory();
