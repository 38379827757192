import { IProductData } from 'interfaces/IProductData';
import { apiService } from 'shared/services';
import md5 from 'md5';
import { CACHED_MOUNT } from 'settings';
import { enProductType } from 'enums/enProductType';

export class CacheService {
  private saved = false;

  setCache(key: string, data: IProductData): void {
    const hasPlan = data.next && data.next.plans;

    if (
      this.saved ||
      data.config.productType === enProductType.TICKET ||
      data.isCached ||
      data.coupon ||
      data.products[0].quantity != 1 ||
      data.config.isOneClick ||
      hasPlan ||
      data.config.currency !== window.CURRENCY
    ) {
      return;
    }

    this.saved = true;

    (data.user as any) = null;
    (data.hash as any) = md5(JSON.stringify(data));

    const cachedMount = CACHED_MOUNT;

    if (cachedMount && data.hash === cachedMount.hash) {
      return;
    }

    data = JSON.parse(JSON.stringify(data));

    (data.transactionKey as any) = null;

    apiService.post(`/cache/check/${key}`, data, {}, { local: true }).subscribe();
  }
}

// tslint:disable-next-line:variable-name
export const CacheServiceFactory = () => new CacheService();

export const cacheService = CacheServiceFactory();
