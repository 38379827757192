import React, { Fragment } from 'react';
import { BaseComponent, IStateBase } from 'components/BaseComponent';
import UserAddressForm from 'components/UserAddressForm';
import { SvgIcon } from 'components/SvgIcon';
import { userService } from 'services/UserService';
import Grid from '@material-ui/core/Grid/Grid';
import Button from '@material-ui/core/Button/Button';
import { FieldValidation } from 'components/Field';
import { cartService } from 'services/CartService';
import { paymentService } from 'services/PaymentService';
import { Observable } from 'rxjs/Observable';
import Translate from 'components/Translate';
import { HistoryContext } from 'shared/HistoryContext';
import { History } from 'history';
import AskAddressMessage from './AskAdressMessage';
import { WithStyles } from 'shared/decorators/withStyles';
import { thankYouService } from 'services/ThankYouService';

interface IState extends IStateBase {
  cart: any;
}

@WithStyles(() => ({
  component: {
    transition: 'all .5s ease-in-out',
    '& .chk-askAdress__content': {
      '& #chk-grid__form': {
        padding: '20px 20px 4px 20px',
        width: '100%'
      },
      '& .chk-grid__addressAfter': {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        padding: '0 20px',

        '& .icon': {
          height: '60px',
          marginRight: '20px',
          width: '85px',
          '& svg': {
            '& path': {
              fill: '#009358'
            }
          }
        },
        '& .chk-paragraph': {
          '& .title': {
            color: '#FFF',
            fontSize: '1em',
            textAlign: 'left',
            marginBottom: '5px'
          },
          '& .label': {
            fontSize: '0.8em',
            color: '#FFF',
            marginTop: 0
          }
        }
      },
      '& .chk-btn-download': {
        maxHeight: '60px',
        padding: '14px',
        marginTop: '8px',
        width: '100%',
        backgroundColor: '#F99104',
        color: '#FFF'
      }
    },
    '& .cepNotFound': {
      color: '#F59211',
      fontSize: '0.775rem',
      display: 'flex',
      '& svg': {
        '@media screen and (max-width: 600px)': {
          width: '30px'
        },
        height: '15px',
        width: '15px',
        margin: '0 5px 0 5px'
      }
    }
  }
}))
export default class AskAddress extends BaseComponent<IState> {
  private history: History;

  finish = async () => {
    if (!(await this.isFormValid())) {
      return;
    }

    userService
      .setBasicUser()
      .switchMap(() => {
        return Observable.combineLatest(paymentService.getPaymentResult());
      })
      .loader()
      .bindComponent(this)
      .subscribe(([paymentResult]) => {
        const urlThankYou = thankYouService.redirectThankYou(paymentResult);

        if (urlThankYou) {
          this.history.push(urlThankYou);
        }
      });
  };

  setHistory(history: History): any {
    this.history = history;
    return null;
  }

  componentDidMount() {
    this.observeState({ cart: null }, cartService.getCart());
    cartService.setIsAskAddressAfterPaymentStep(true);
  }

  componentWillUnmount() {
    cartService.setIsAskAddressAfterPaymentStep(false);
  }

  render() {
    if (!this.state) {
      return null;
    }

    // if (!paymentService.hasPayment()) {
    //   window.location.replace(`/${this.state.cart.contentId}`);
    // }

    return (
      <Fragment>
        <HistoryContext.Consumer>{(h) => this.setHistory(h)}</HistoryContext.Consumer>
        <div className={this.props.classes.component}>
          <FieldValidation.Provider value={this.registerFields}>
            <div className="chk-askAdress__content">
              <Grid container>
                <Grid item className="chk-grid__addressAfter" xs={12}>
                  <div className="icon">
                    <SvgIcon name="check" />
                  </div>
                  <div className="chk-paragraph">
                    <AskAddressMessage />
                  </div>
                </Grid>
                <div className="form" id="chk-grid__form">
                  <UserAddressForm />
                </div>
                <Button id="chk-askadress-button" className="chk-btn-download theme-button" onClick={this.finish}>
                  <Translate term="Concluir" />
                </Button>
              </Grid>
            </div>
          </FieldValidation.Provider>
        </div>
      </Fragment>
    );
  }
}
