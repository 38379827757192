import React from 'react';
import { cartService } from 'services/CartService';
import { enProductType } from 'enums/enProductType';
import { BaseComponent, IStateBase } from 'components/BaseComponent';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';
import { WithStyles } from 'shared/decorators/withStyles';
import { ISummaryItems } from 'interfaces/ISummaryItems';
import { IProductData, IShipping, IProductConfig, IProduct } from 'interfaces/IProductData';
import { currencyService } from 'services/CurrencyService';
import currencyFormatter from 'currency-formatter';
import { paymentService } from 'services/PaymentService';
import { enPaymentMethod } from 'enums/enPaymentMethod';
import { shippingService } from 'services/ShippingService';
import { IPaymentModel } from 'interfaces/IPaymentModel';
import Translate from 'components/Translate';
import { IS_ECOMMERCE } from 'settings';
import { enCurrency } from 'enums/enCurrency';
import { enTerminalMethod } from 'enums/enTerminalMethod';

interface IState extends IStateBase {
  config: IProductConfig;
  summaryItems: ISummaryItems[];
  currency: enCurrency;
  numberProducts: number;
}

interface IProps {
  classes?: any;
}

@WithStyles(() => ({
  component: {
    border: '1px solid #E3E3E3',

    '& .title': {
      color: '#0B0B0B',
      fontSize: '0.875rem'
    },

    '& .productTitle': {
      wordBreak: 'break-word'
    },

    '& .item': {
      padding: 8,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',

      '&:nth-child(even)': {
        backgroundColor: '#F2F2F2'
      },

      '&: .summary-content': {
        wordBreak: 'break-word'
      },

      '& .summary-content, & .value': {
        fontSize: '0.875rem',
        color: '#596375'
      },

      '& .coupon': {
        color: '#009358',
        textDecoration: 'line-through'
      }
    }
  }
}))
export default class Summary extends BaseComponent<IState, IProps> {
  constructor(props: any) {
    super(props);
    this.state = { summaryItems: [], currency: enCurrency.BRL, config: null, numberProducts: 0 };
  }

  componentDidMount() {
    this.observeState({ currency: null }, currencyService.getCurrency());
    this.observeState({ config: null }, cartService.getConfig());

    cartService
      .getCart()
      .combineLatest(
        paymentService.getCart(),
        paymentService.getCart().map((payment) => payment.shipping),
        shippingService.getShipping().map((shipping) => shipping.shippingItems),
        currencyService.getCurrency()
      )
      .bindComponent(this)
      .subscribe(([cart, payment, selectedShipping, shipping, currency]) => {
        this.setState({ numberProducts: cart.products.length });

        const summaryItems: ISummaryItems[] = [];

        // Product Info

        cart.products.map((product: IProduct) => {
          const productInfo = this.productInfo(product, currency);
          summaryItems.push(productInfo);
        });

        // Coupon Info

        if (cart.coupon) {
          const couponInfo = this.couponInfo(cart, currency);
          summaryItems.push(couponInfo);
        }

        // Shipping Info

        const selectedShippingObj = selectedShipping && shipping.find((s) => s.code === selectedShipping.code);

        if (selectedShippingObj) {
          const shippingInfo = this.shippingInfo(selectedShippingObj, currency);
          summaryItems.push(shippingInfo);
        }

        // Payment Method Info

        payment.payment.map((p, index) => {
          const creditCardTitle = payment.payment.length > 1 ? ` ${index + 1}` : '';
          const paymentInfo = this.paymentInfo(p, cart, currency, creditCardTitle);
          summaryItems.push(paymentInfo);
        });
        this.setState({ summaryItems });
      });
  }

  productInfo = (product: IProduct, currency: enCurrency) => {
    return {
      title: `${product.quantity}x`,
      description: product.title,
      value: currencyFormatter.format(product.taxedPrice, { code: currency, precision: 2 })
    };
  };

  couponInfo = (cart: IProductData, currency: enCurrency) => {
    return {
      title: 'Cupom:',
      description: cart.coupon.code,
      value: currencyFormatter.format(cart.coupon.value, { code: currency, precision: 2 }),
      customClass: 'coupon'
    };
  };

  shippingInfo = (shipping: IShipping, currency: enCurrency) => {
    return {
      title: 'Frete:',
      description: shipping.title,
      value: currencyFormatter.format(shipping.value, { code: currency, precision: 2 })
    };
  };

  paymentInfo = (payment: IPaymentModel, cart: IProductData, currency: enCurrency, index?: string) => {
    let description = `Cartão de Crédito${index}`;
    const { paymentMethod, method } = payment;

    const isTerminal = paymentMethod === enPaymentMethod.TERMINAL && method === enTerminalMethod.CREDIT;

    let value = currencyFormatter.format(cart.price, { code: currency, precision: 2 });

    if (payment.paymentMethod === enPaymentMethod.BANKSLIP) {
      description = 'Boleto';
    }

    if (payment.paymentMethod === enPaymentMethod.PIX) {
      description = 'Pix';
    }

    if (payment.paymentMethod === enPaymentMethod.PAYPAL) {
      description = 'Paypal';
    }

    if (payment.paymentMethod === enPaymentMethod.EDUZZ) {
      description = 'Saldo Eduzz';
    }

    if (payment.paymentMethod === enPaymentMethod.PAGDIVIDIDO) {
      description = 'Pagdividido';
      value = payment?.pagDivididoOffer?.description;
    }

    if (payment.paymentMethod === enPaymentMethod.TERMINAL) {
      description = 'Maquininha de pagamento';
    }

    if (
      isTerminal ||
      ![
        enPaymentMethod.PAYPAL,
        enPaymentMethod.BANKSLIP,
        enPaymentMethod.EDUZZ,
        enPaymentMethod.PIX,
        enPaymentMethod.PAGDIVIDIDO,
        enPaymentMethod.TERMINAL
      ].includes(payment.paymentMethod)
    ) {
      const numberInstallments = payment.numberInstallments || cart.installments[0].installment;

      if (numberInstallments) {
        let installmentValue = 0;
        payment.installments
          .filter((i) => i.installment === numberInstallments)
          .forEach((i) => {
            installmentValue = i.value;
          });

        value = `${numberInstallments > 1 ? `${numberInstallments}x` : ''} ${currencyFormatter.format(installmentValue, {
          code: currency,
          precision: 2
        })}`;
      }
    }

    return {
      title: 'Forma de Pagamento Escolhida:',
      description,
      value
    };
  };

  render() {
    const { config, summaryItems, numberProducts } = this.state;
    const { classes } = this.props;

    if (!config) {
      return null;
    }

    if ((config.productType !== enProductType.PHYSICAL || IS_ECOMMERCE) && !config.package && numberProducts === 1) {
      return null;
    }

    if (numberProducts <= 1 && config.productType !== enProductType.PHYSICAL) {
      return null;
    }

    if (summaryItems.length === 0) {
      return null;
    }

    return (
      <Grid container spacing={0} className={`${classes.component} summary-cart`}>
        <Grid item xs={12} className="item">
          <Typography variant="body1" className="title">
            <Translate term="Resumo da compra:" />
          </Typography>
        </Grid>
        {summaryItems.map((item, index) => (
          <Grid item xs={12} className="item" key={index}>
            <Typography variant="body1" className="summary-content">
              <span className="title">
                <Translate term={item.title} />{' '}
              </span>
              <span className="productTitle">
                <Translate term={item.description} />
              </span>
            </Typography>
            <Typography variant="body1" className={`value ${item.customClass || ''}`} align="right">
              {item.value}
            </Typography>
          </Grid>
        ))}
      </Grid>
    );
  }
}
