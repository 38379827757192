export function round2(value: any) {
  value = value.toString();

  if (value.split('.').length < 2) {
    value = value + '.00';
  }

  if (value.split('.')[1].length < 2) {
    value = value + '0';
  }

  const integerValue = Number(value.split('.')[0]);
  const decValue = Number(value.split('.')[1].substr(0, 2) / 100);
  const finalValue = integerValue + decValue;

  return finalValue;
}

export function round10(value: number) {
  return ((type, value: any, exp) => {
    if (typeof exp === 'undefined' || +exp === 0) {
      return Math[type](value);
    }
    value = +value;
    exp = +exp;
    if (isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0)) {
      return NaN;
    }
    value = value.toString().split('e');
    value = Math[type](+(value[0] + 'e' + (value[1] ? +value[1] - exp : -exp)));
    value = value.toString().split('e');
    return +(value[0] + 'e' + (value[1] ? +value[1] + exp : exp));
  })('round', value, -2);
}

export function nl2br(str: string) {
  return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + '<br />' + '$2');
}
