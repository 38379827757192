/* eslint-disable @typescript-eslint/no-unused-vars */
import { Observable } from 'rxjs/Observable';
import { Subscriber } from 'rxjs/Subscriber';
import { Subscription } from 'rxjs/Subscription';

let globalLoaderComponent: any;
let refs = 0;
let targets = {};

export function setup(loader: { show: Function; hide: Function }): void {
  globalLoaderComponent = loader;
}

export function isLoading() {
  return Object.keys(targets).length > 0;
}

function loader<T>(this: Observable<T>, loaderComponent: any = globalLoaderComponent): Observable<T> {
  return this.lift(new LoaderOperator(loaderComponent));
}

Observable.prototype.loader = loader;

declare module 'rxjs/Observable' {
  interface Observable<T> {
    loader: typeof loader;
  }
}

class LoaderOperator {
  constructor(private loaderComponent: any) {}

  public call(subscriber: Subscriber<any>, source: Observable<any>): Subscription {
    return source.delay(10).subscribe(new LoaderSubscriber(subscriber, this.loaderComponent));
  }
}

class LoaderSubscriber extends Subscriber<any> {
  private ref: number;

  constructor(protected destination: Subscriber<any>, private loader: { show: Function; hide: Function }) {
    super(destination);
    this.ref = ++refs;
    this.show();
  }

  public _next(value: any): void {
    this.hide();
    this.destination.next(value);
  }

  public _complete(): void {
    this.hide();
    this.destination.complete();
  }

  public _error(err: any): void {
    this.hide();
    this.destination.error(err);
  }

  public _unsubscribe(): void {
    this.hide();
    this.destination.unsubscribe();
  }

  private show(): void {
    targets[this.ref] = true;
    setTimeout(() => {
      if (this.loader && isLoading()) {
        this.loader.show();
      }
    }, 0);
  }

  private hide(): void {
    delete targets[this.ref];
    setTimeout(() => {
      if (this.loader && !isLoading()) {
        this.loader.hide();
      }
    }, 0);
  }
}
