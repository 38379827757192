/* eslint-disable max-lines */
import React from 'react';
import { ITemplateState } from 'components/Cart';
import { BaseComponent } from 'components/BaseComponent';
import { cartService } from 'services/CartService';
import MultiStep from './MultiStep';
import Footer from 'components/Cart/Footer';
import Header from './Header';
import OneClickBuy from './OneClickBuy';
import { WithStyles } from 'shared/decorators/withStyles';
import TopBar from 'components/TopBar';

interface IState extends ITemplateState {}

@WithStyles(() => ({
  '@global': {
    '.step-payment .links .chk-installment-fee': {
      display: 'inline !important'
    }
  },
  component: {
    '& .theme_16': {
      overflowX: 'hidden'
    },

    '& label': {
      transform: 'translate(0, -6px) scale(0.75)',
      transformOrigin: 'top left'
    },

    '& .chk-main-container': {
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover'
    },

    '& .chk-content-container': {
      maxWidth: '1080px',
      minHeight: '100vh',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'relative',
      margin: 'auto',

      '& >div': {
        zIndex: 2
      },

      '@media screen and (max-width:768px)': {
        maxWidth: '100%'
      }
    },

    '& .field-select': {
      backgroundColor: '#FFF',
      border: '1px solid #d9d9d9',
      borderRadius: '1px',
      fontSize: '1rem',
      padding: '2px 2px 1px 8px'
    },

    '& .chk-multiple-cards': {
      textAlign: 'center'
    },

    '& .chk-form-container': {
      position: 'relative',
      display: 'block',
      width: '320px',
      margin: '115px 0 8px 0',

      '& iframe': {
        height: '100%',
        width: '100%'
      }
    },

    '& .chk-oneClickBuy__productPrice .chk-full': {
      textAlign: 'right'
    },

    '& .chk-parts': {
      textAlign: 'right',
      color: '#333',

      '& .chk-number-parts': {
        fontSize: '0.875rem',
        fontWeight: 600
      },

      '& .chk-no-fee': {
        fontWeight: 'bold',
        fontSize: '14px'
      }
    },

    '& .chk-number-price': {
      fontSize: '24px',
      fontWeight: 600
    },

    '& .chk-small-price': {
      fontSize: '12px',
      marginTop: '4px'
    },

    '& .chk-creditCardForm__saveCard, & .checkbox_wallet': {
      padding: '4px 2px',

      '& span': {
        fontSize: '0.665rem'
      },

      '& label': {
        margin: 0,
        transform: 'translate(0, -6px) scale(1)'
      },

      '& .checkbox': {
        margin: '0 .375rem 0 0',
        width: '20px',
        height: '20px',

        '& svg': {
          color: '#000',
          width: '25px',
          height: 'auto'
        }
      }
    },

    '& .chk-has-number': {
      fontSize: '0.95rem',
      color: '#636871'
    },

    '& .chk-previousStep': {
      margin: '0 0 12px 0'
    },

    '& .chk-coupon .chk-coupon-set': {
      height: '30px',

      '& button': {
        height: '37px'
      }
    },

    '& .chk-oneClickBuy__productPrice': {
      '& .chk-parts': {
        textAlign: 'right',

        '& .chk-number-parts': {
          fontSize: '0.75rem',
          fontWeight: 600,
          color: '#585858',
          marginRight: '5px'
        },

        '& .chk-number-price': {
          fontSize: '1.25rem',
          fontWeight: 700
        },

        '& .chk-small-price': {
          fontSize: '0.625rem',
          letterSpacing: '0.42px',
          color: '#585858',
          fontWeight: 600
        },

        '& .chk-no-fee': {
          fontWeight: 'bold',
          fontSize: '14px'
        }
      }
    },

    '& .chk-oneClickBuy__product': {
      padding: '16px',

      '& .chk-oneClickBuy__title': {
        overflow: 'hidden',
        textOverflow: 'ellipsis',

        '& h1': {
          display: 'inline-block',
          fontSize: '0.875rem',
          lineHeight: '19px',
          textAlign: 'left',
          width: '100%',
          wordBreak: 'break-word'
        }
      }
    },

    '& .chk-oneClickBuy__content': {
      '& .summary-cart': {
        margin: '12px 0 0',
        '& .title, & .content, & .value': {
          fontSize: '0.75rem'
        },
        '& .item:nth-child(even)': {
          backgroundColor: '#fff'
        }
      },

      '& .chk-oneClickBuy__form': {
        backgroundColor: ' #fff',
        padding: '16px'
      },

      '& .chk-installments-wrapper': {
        margin: '4px 0 0 0'
      },

      '& .chk-stepCreditCard__finishButton': {
        borderRadius: '4px',
        boxShadow: 'none',
        fontWeight: 500,
        fontSize: '1rem',
        height: '55px',
        margin: '16px 0',
        textTransform: 'capitalize',
        padding: '10px'
      },

      '& .chk-askAdress__content': {
        '& .form': {
          padding: '20px 20px 4px 20px',
          width: '100%'
        },

        '& .chk-btn-download': {
          maxHeight: '60px',
          padding: '14px',
          marginTop: '8px',
          width: '100%'
        }
      }
    },

    '& .chk-creditCard__content': {
      '& .chk-creditCard__cvvBrand': {
        margin: '4px 0 0 0',

        '& .chk-creditCard__cvv': {
          '& .field': {
            '& label[data-shrink="false"]': {
              zIndex: 1
            },

            '& label[data-shrink="true"]': {
              color: '#FFF'
            }
          }
        }
      }
    },

    '& .chk-installments__installmentFieldSelect': {
      margin: 0,

      '& .field-select': {
        fontSize: '0.75rem'
      }
    },

    '& .chk-installments__value': {
      fontSize: '0.75rem',
      color: '#585858',

      '& span': {
        fontWeight: 700,
        marginRight: '8px'
      },

      '& a': {
        textDecoration: 'underline',
        color: '#333',
        cursor: 'pointer'
      }
    },

    '& .chk-multiStep__productName': {
      alignItems: 'center',
      backgroundColor: '#333',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      borderRadius: '4px 4px 0 0',
      color: 'white',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
      padding: '45px 20px 24px 20px',
      wordBreak: 'break-word',
      textAlign: 'center',

      '& h2': {
        fontWeight: 'normal',
        fontSize: '16px',
        margin: 0
      },

      '& .chk-logo__content': {
        position: 'absolute',
        top: '-60px',
        backgroundPosition: 'center',
        backgroundSize: '100%',
        backgroundColor: '#FFF',
        backgroundRepeat: 'no-repeat'
      },

      '& .chk-multiStep__closeForm': {
        backgroundColor: '#333',
        border: '3px solid #FFF',
        borderRadius: '100%',
        cursor: 'pointer',
        height: '30px',
        position: 'absolute',
        right: '-8px',
        top: '-10px',
        width: '30px',
        outline: 0,

        '& svg': {
          width: '8px',
          stroke: '#FFF'
        }
      }
    },

    '& .links': {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row',
      justifyContent: 'center',
      margin: '16px 0',

      '& a': {
        display: 'inline-block',
        textDecoration: 'none',
        color: '#3C3C3C',

        '&:after': {
          content: '"|"',
          display: 'inline-block',
          margin: '0 5px'
        },

        '&:last-child:after': {
          content: '""'
        }
      },

      '& .chk-media-footer': {
        fontSize: '.75rem',
        textAlign: 'center',
        color: 'black',
        textShadow: '1px 1px 2px #fff'
      },

      '& .chk-installment-fee:after': {
        margin: '0 5px',
        content: '"|"'
      }
    },

    '& .chk-oneClickBuy': {
      backgroundColor: '#F5F5F5',
      borderRadius: '8px',
      boxShadow: '0 8px 20px 10px rgba(0, 0, 0, .15)'
    },

    '& .chk-couponPrice': {
      fontSize: '0.75rem',
      color: '#333',
      textAlign: 'right'
    },

    '& .brand-icon': {
      marginTop: '29px'
    },

    '& .tooltip-cvv-card .cvv-question-mark': {
      margin: '-4px 10px 0 0',
      fontSize: '0.666rem',
      width: '18px',
      height: '18px'
    },

    '& .chk-installment-fee': {
      display: 'none'
    },

    '& .cepNotFound': {
      color: '#F59211',
      fontSize: '0.775rem',
      display: 'flex',

      '& svg': {
        width: '30px',

        height: '15px',
        margin: '0 5px 0 5px'
      }
    }
  }
}))
export default class WidgetTheme extends BaseComponent<IState, any> {
  componentDidMount() {
    this.observeState({ cart: null }, cartService.getCart());
    window.parent.postMessage('EdzWidgetLoaded', '*');
  }

  render() {
    if (!this.state) {
      return null;
    }

    const { cart } = this.state;
    const product = cart.products[0];

    return (
      <div className={`${this.props.classes.component} theme-hotsite`}>
        <TopBar />
        <div className="chk-main-container">
          <div className="chk-content-container">
            <div className="chk-form-container">
              <Header cart={cart} />
              {cart.config.isOneClick ? (
                <OneClickBuy onPay={this.props.onPay} hiddenFooter />
              ) : (
                <MultiStep product={product} onPay={this.props.onPay} />
              )}
              <div className="links">
                <Footer />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
