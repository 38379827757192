import React, { useState } from 'react';
import copy from 'copy-to-clipboard';
import FieldText from 'components/Field/Text';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';
import { SvgIcon } from 'components/SvgIcon';

interface IProps {
  pixCode: string;
  buttonVariant?: 'text' | 'outlined' | 'contained';
}

const Component: React.FC<IProps> = ({ pixCode, buttonVariant = 'contained' }) => {
  const [open, setOpen] = useState(false);

  const copyPixCode = () => {
    copy(pixCode);
    setOpen(true);
    setTimeout(() => {
      setOpen(false);
    }, 3000);
  };

  return (
    <div className="chk-pix-instructions-code">
      <FieldText
        id="pix-code"
        label=""
        value={pixCode}
        disabled={true}
        inputProps={{
          className: 'theme-input chk-pix-code-input'
        }}
        onChange={null}
      />
      <Tooltip
        PopperProps={{
          disablePortal: true
        }}
        open={open}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        title="Código Pix copiado!"
        arrow
      >
        <Button
          id="copy-pix"
          onClick={copyPixCode}
          fullWidth
          variant={buttonVariant}
          size="large"
          className={`chk-pix-copy-button ${buttonVariant === 'text' ? 'chk-pix-copy-button-text' : ''}`}
        >
          <SvgIcon name="copyIcon" /> Copiar código Pix
        </Button>
      </Tooltip>
    </div>
  );
};

export const PixCodeCopyPaste = React.memo(Component);
