import { Observable } from 'rxjs/Observable';
import { checkLocalStorage } from 'functions';

export function get<T = any>(key: string): Observable<T> {
  return Observable.of(true)
    .map(() => (checkLocalStorage() ? localStorage.getItem(key) : null))
    .map((data) => (data ? JSON.parse(data) : null));
}

export function set<T = any>(key: string, value: T): Observable<T> {
  return Observable.of(true)
    .map(() => (checkLocalStorage() ? localStorage.setItem(key, JSON.stringify(value)) : null))
    .map(() => value);
}

export function clear(regexp?: RegExp): Observable<void> {
  return Observable.of(true).map(() => {
    if (!regexp) {
      return checkLocalStorage() ? localStorage.clear() : null;
    }

    Object.keys(localStorage)
      .filter((k) => regexp.test(k))
      .forEach((key) => (checkLocalStorage() ? localStorage.removeItem(key) : null));
  });
}

export function checkStorage() {
  try {
    return !!window.localStorage;
  } catch (error) {
    return false;
  }
}
