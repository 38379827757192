import { apiService } from 'shared/services';
import { GOOFY_ID } from 'settings';
import { Observable } from 'rxjs/Observable';
export class InvoiceService {
  create(transactionKey: string): Observable<any> {
    return apiService.post<any>(`/invoice/${transactionKey}`, {
      goofyId: GOOFY_ID
    });
  }
}

// tslint:disable-next-line:variable-name
export const InvoiceServiceFactory = () => new InvoiceService();

export const invoiceService = InvoiceServiceFactory();
