/* eslint-disable max-len */
import { makeStyles } from '@material-ui/styles';
import React, { useState, useRef, useCallback } from 'react';

import { useObservable } from 'react-use-observable';

import Translate from 'components/Translate';
import { SvgIcon } from 'components/SvgIcon';
import { CountriesMenu } from 'shared/components/CountriesMenu';
import { ICountry } from 'interfaces/ICountry';
import { internationalService } from 'services/InternationalService';
import { getUrlCountryImage } from 'shared/utils/getUrlCountryImage';

const useStyles = makeStyles({
  flag: {
    width: 30,
    height: 20,
    borderRadius: 3,
    marginRight: 8,

    '& img': {
      width: '100%',
      height: '100%',
      borderRadius: 3
    }
  },

  icon: {
    '&.open': {
      transform: 'rotate(180deg)'
    }
  },

  component: {
    '&.chk-config-country': {
      float: 'right',

      '& .chk-config-button': {
        position: 'relative',
        borderRadius: '4px',
        width: 'auto',
        display: 'flex',
        alignItems: 'center',
        padding: 0,
        cursor: 'pointer',
        border: 'none',

        '& svg': {
          width: '15px'
        },

        '& .chk-option-active': {
          fontSize: '12px',
          color: '#232323',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          height: '21px',
          lineHeight: '28px',
          display: 'flex',
          alignItems: 'center'
        }
      }
    },

    '& .chk-configCountry___avatar': {
      alignSelf: 'flex-end',
      position: 'absolute',
      margin: '45px 20px',

      '& img': {
        height: '32px',
        width: '32px'
      }
    }
  }
});

const ConfigCountry: React.FC = React.memo(() => {
  const [country] = useObservable(() => internationalService.getSelectedCountry(), []);
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef();

  const classes = useStyles({});

  const toggleMenu = useCallback(() => {
    setIsOpen((prev) => !prev);
  }, []);

  const setLanguage = useCallback((option: ICountry) => {
    internationalService.setCountryByISO(option.isoAlpha3);
  }, []);

  if (!country) {
    return null;
  }

  return (
    <div className={`chk-config-country ${classes.component}`} ref={ref} id="chk-config-country">
      <a id={`chk-selectlang-button-${country.lang}`} className="chk-config-button" onClick={() => setIsOpen(true)}>
        <div className={classes.flag}>
          <img src={getUrlCountryImage(country.isoAlpha2)} alt={country.name} />
        </div>
        <span className="chk-option-active">
          <Translate term="Alterar país" />
          <div className={isOpen ? `${classes.icon} open` : classes.icon}>
            <SvgIcon name="chevronDown" />
          </div>
        </span>
      </a>

      <CountriesMenu isOpen={isOpen} closeMenu={toggleMenu} onSelectItem={setLanguage} title={'Alterar país'} anchor={ref} />
    </div>
  );
});

export default React.memo(ConfigCountry);
