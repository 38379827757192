import Grid from '@material-ui/core/Grid';
import ContextAddress from 'components/UserAddressForm/ContextAddress';
import { IUserAddressModel } from 'interfaces/IUserAddressModel';
import React from 'react';
import { paymentService } from 'services/PaymentService';
import { useMappedObservable } from 'shared/hooks/useMappedObservable';

import { enPagdivididoSteps } from '../..';
import NextStep from '../../NextStep';
import StepTitle from '../../StepTitle';

const Address: React.FC = () => {
  let [paymentAddress] = useMappedObservable(
    () => paymentService.getCart(),
    (data) => data.address,
    []
  );

  paymentAddress = paymentAddress || {};

  return (
    <Grid container spacing={2}>
      <StepTitle title="Para finalizar adicione seu endereço de moradia." step={enPagdivididoSteps.ADDRESS} />
      <Grid item xs={12}>
        <ContextAddress
          personType="F"
          data={paymentAddress}
          onChange={(data: Partial<IUserAddressModel>) => {
            paymentService.setAddressData('address', data);
          }}
        />
      </Grid>
      <NextStep allowBack={true} buttonText="Continuar" toStep={enPagdivididoSteps.SUMMARY} />
    </Grid>
  );
};

export default React.memo(() => <Address />);
