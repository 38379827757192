import * as React from 'react';
import { BaseComponent, IStateBase } from 'components/BaseComponent';
import { ICartModel } from 'interfaces/ICartModel';
import UserForm from 'components/Cart/MainForm/UserForm';
import Checkbox from '@material-ui/core/Checkbox/Checkbox';
import Translate from 'components/Translate';
import Grid from '@material-ui/core/Grid/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { paymentService } from 'services/PaymentService';
import { withStyles } from '@material-ui/core/styles';

interface IState extends IStateBase {
  cart: ICartModel;
}

const styles = () => ({
  root: {
    background: '#ddd',
    borderRadius: '3px',
    margin: '10px 0'
  },
  label: { fontSize: '0.8rem' }
});

class Purchaser extends BaseComponent<IState> {
  public componentDidMount() {
    this.observeState({ cart: null }, paymentService.getCart());
  }

  public switchSamePerson = () => {
    const { cart } = this.state;

    const samePerson = !cart.samePerson;

    paymentService.setData({
      name: samePerson ? cart.studdent?.name : '',
      email: samePerson ? cart.studdent?.email : '',
      document: samePerson ? cart.studdent?.document : '',
      phone: samePerson ? cart.studdent?.phone : '',
      samePerson
    });
  };

  render() {
    if (!this.state || !this.state.cart) {
      return null;
    }

    const { samePerson } = this.state.cart;
    const { classes } = this.props;

    return (
      <React.Fragment>
        <Grid container spacing={2}>
          <Grid item xs={12} className={classes.root}>
            <FormControlLabel
              control={<Checkbox id={'chk-sameperson'} className="checkbox" value="1" checked={!!samePerson} onChange={this.switchSamePerson} />}
              label={
                <div className={classes.label}>
                  <Translate
                    term="Selecione esta opção caso você seja o aluno que será matriculado no curso e {important}"
                    properties={{
                      important: (
                        <b>
                          <Translate term="também o responsável pelo pagamento" />
                        </b>
                      )
                    }}
                  />
                </div>
              }
            />
          </Grid>
          {!samePerson && <UserForm />}
        </Grid>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(Purchaser);
