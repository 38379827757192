import validator from 'validatorjs';
import { validTlds } from './listTld';

const validate = (email: string): boolean => {
  if (!email) {
    return true;
  }

  const finalEmail = email.split('.').reverse()[0];

  if (!validTlds.includes(`.${finalEmail}`)) {
    return false;
  }

  return !!`${email}`.match(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+){1,}$/);
};

validator.register('email', validate, 'email');
