import validator from 'validatorjs';
import { paymentService } from '../services/PaymentService';

const validate = (cardNumber: string): boolean => {
  const paymentData = paymentService.getCurrentCart();

  if (!paymentData || paymentData.payment.length === 1) {
    return true;
  }

  const cardNumbers = paymentData.payment.map((p) => p.creditCard).filter((c) => c === cardNumber);

  return cardNumbers.length === 1;
};

validator.register('unique_card', validate, 'unique_card');
