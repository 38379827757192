import React from 'react';
import { IProductData } from 'interfaces/IProductData';
import YoutubeEmbed from '../../YoutubeEmbed';
import Translate from 'components/Translate';
import Hidden from '@material-ui/core/Hidden';
import { getCdnImage } from 'functions';
import { enProductType } from 'enums/enProductType';
import { TicketTimer } from '../TicketTimer/index';
import { Locale } from '../Locale';
import { WithStyles } from 'shared/decorators/withStyles';
import { nl2br } from 'shared/functions';
import { ticketService } from 'services/TicketService';
import { BaseComponent, IStateBase } from 'components/BaseComponent';
import { Button } from '@material-ui/core';
import { configService } from 'services/ConfigService';
import { parameterService } from 'services/ParameterService';
import { enParameters } from 'enums/enParameters';

interface IProps {
  cart: IProductData;
  classes?: any;
  handleClose?: () => void;
}

interface IState extends IStateBase {
  showTimer: boolean;
  bannerRightId: string;
  bannerRightUrl?: string;
  bannerYoutube: string;
  changeCard: boolean;
}

@WithStyles(() => ({
  component: {
    outline: 'none',
    '& .chk-banner-right': {
      padding: '20px',
      backgroundColor: '#fff',
      border: '1px solid #e8e7e7',
      borderRadius: '4px',
      '&.timer': {
        margin: '0 0 20px 0'
      },

      '& .chk-img': {
        padding: '0 4px 4px',
        border: '1px solid #e2e2e2'
      },

      '& .product-name': {
        textAlign: 'left'
      },

      '& .open-modal-description': {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        marginBottom: '10px',
        width: '100%'
      },

      '&.locale': {
        margin: '20px 0 0 0'
      },

      '@media screen and (max-width: 600px)': {
        height: '70vh',
        maxWidth: '90vw',
        overflowY: 'scroll',
        width: '100%'
      }
    },
    '& .closeButtonGrid': {
      backgroundColor: '#FFF',
      borderTop: '1px solid #cecece',
      display: 'flex',
      justifyContent: 'center',
      paddingBottom: '14px',
      position: 'relative',
      width: '90vw',

      '& .closeButton': {
        fontWeight: 600,
        letterSpacing: '0.5px',
        borderRadius: '3px',
        color: '#009358',
        fontSize: '0.875rem',
        height: '40px',
        padding: '7px 18px',
        marginTop: '16px',
        boxShadow: '1px 1px 1px RGBA(0,0,0,0.2)'
      }
    },
    '& .alert': {
      fontSize: '0.675rem',
      color: '#2E2E2E',

      '@media screen and (max-width: 600px)': {
        position: 'relative',
        top: '2px'
      }
    },

    '& .tooltip': {
      width: '20px',
      height: '20px',
      borderRadius: '50%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: '15px',
      fontStyle: 'italic'
    },

    '& img': {
      width: '100%'
    },

    '& .chk-youtube': {
      margin: '0 0 16px 0',
      height: '100%',
      '@media screen and (max-width: 600px)': {
        height: 'auto'
      }
    },

    '& .chk-sideBar__description': {
      fontSize: '0.875rem',
      borderRadius: '4px',
      boxSizing: 'border-box',
      lineHeight: '1.333rem',

      '& .title': {
        margin: '0 0 14px 0px',

        '& span': {
          fontSize: '0.75rem',
          fontWeight: 700,
          textTransform: 'uppercase'
        }
      },

      '& .content-description': {
        color: '#807e7e',
        wordBreak: 'break-word'
      },

      '& .infos': {
        borderTop: '1px solid #cecece',
        padding: '8px 0 0',
        fontSize: '0.875rem',
        margin: '8px 0 0 0',

        '& .producerName': {
          display: 'inline-block'
        }
      }
    }
  },
  changeCardText: {
    color: '#807e7e'
  }
}))
export class Sidebar extends BaseComponent<IState, IProps> {
  constructor(props: any) {
    super(props);
    this.state = { showTimer: false, bannerRightId: null, bannerYoutube: null, changeCard: false };
  }

  componentDidMount() {
    this.observeState(
      { bannerRightId: null },
      configService.getMappedConfig((c) => c.template.media.bannerRight)
    );
    this.observeState(
      { bannerYoutube: null },
      configService.getMappedConfig((c) => c.template.media.bannerYoutube)
    );
    this.observeState(
      { bannerRightUrl: null },
      configService.getMappedConfig((c) => c.template.media.bannerRightUrl)
    );

    parameterService.get(enParameters.changeCard).subscribe((changeCard) => this.setState({ changeCard: Boolean(changeCard) }));

    ticketService
      .show()
      .bindComponent(this)
      .subscribe((showTimer) => {
        this.setState({ showTimer });
      });
  }

  render() {
    const { showTimer, bannerRightId, bannerYoutube, changeCard, bannerRightUrl } = this.state;
    const { classes, cart, handleClose } = this.props;

    const bannerUrl = getCdnImage(bannerRightId);

    const product = cart.content;

    return (
      <div className={classes.component}>
        {showTimer && cart.config.productType == enProductType.TICKET && (
          <div>
            <Hidden xsDown>
              <div className={'chk-banner-right timer'}>
                <TicketTimer />
              </div>
            </Hidden>
          </div>
        )}

        <div className={'chk-banner-right'}>
          <Hidden>
            <YoutubeEmbed url={bannerYoutube} />
            {bannerRightId ? (
              bannerRightUrl ? (
                <a href={bannerRightUrl} target="_blank" rel="noopener noreferrer">
                  <img alt={cart.content.title} src={bannerUrl.includes('data:') ? bannerRightId : bannerUrl} />
                </a>
              ) : (
                <img alt={cart.content.title} src={bannerUrl.includes('data:') ? bannerRightId : bannerUrl} />
              )
            ) : (
              <div className="chk-sideBar__description">
                <div className="title">
                  <Translate term="Detalhes" />
                </div>
                <div
                  className="content-description"
                  dangerouslySetInnerHTML={{ __html: nl2br(product.description) }}
                  id="content-product-description"
                />
                {product.producerName && (
                  <div className="infos">
                    <span className="producerName">
                      <Translate term="Oferecido por" /> <b>{product.producerName}</b>
                    </span>
                    <br />
                    <span>{product.producerEmail}</span>
                  </div>
                )}
              </div>
            )}
            {changeCard && (
              <span>
                <b>Atenção!</b>
                <br />
                <span>
                  Você está alterando o pagamento da sua assinatura. Após concluir a alteração as novas cobranças dessa assinatura serão feitas no
                  cartão informado.
                  <br />
                  <br />
                </span>
                Se existir uma cobrança em aberto a mesma será realizada assim que a alteração for confirmada.
                <br />
                <br />
                Em caso de dúvidas entre em contato com o produtor através do e-mail abaixo ou com nosso atendimento pelo e-mail: suporte@eduzz.com
                <br />
                <br />
              </span>
            )}
          </Hidden>
        </div>
        {cart.config.productType == enProductType.TICKET ? (
          <Hidden xsDown>
            <div className={'chk-banner-right locale'}>
              <Locale />
            </div>
          </Hidden>
        ) : null}
        <Hidden smUp>
          <div className="closeButtonGrid">
            <Button onClick={handleClose} size="large" color="primary" className="closeButton theme-button">
              <Translate term="Voltar ao carrinho" />
            </Button>
          </div>
        </Hidden>
      </div>
    );
  }
}
