import React, { useCallback, useMemo, useState } from 'react';
import Menu from '@material-ui/core/Menu/Menu';
import { CountriesList } from './CountriesList';
import { useObservable } from 'react-use-observable';

import { ICountry } from 'interfaces/ICountry';
import FieldText from 'components/Field/Text';
import { SvgIcon } from 'components/SvgIcon';
import { makeStyles } from '@material-ui/core';
import { MenuHeader } from './MenuHeader';
import { internationalService } from 'services/InternationalService';

interface IProps {
  isOpen: boolean;
  closeMenu: () => void;
  onSelectItem?: (option: ICountry) => void;
  hasDDI?: boolean;
  title: string;
  anchor: React.MutableRefObject<any>;
}

const useStyles = makeStyles({
  countriesContainer: {
    padding: '0 14px',
    width: 400,

    '@media screen and (max-width: 640px)': {
      width: 350
    }
  },
  list: {
    marginBottom: 16,
    maxHeight: '260px',
    overflowY: 'auto'
  },

  countryMenuList: {
    marginBottom: '1rem',
    borderRadius: '4px',
    border: '1px solid #CCCCCC',

    padding: '0 8px',

    '& svg': {
      width: 24,
      height: 24,
      marginRight: 8
    },

    '& input': {
      borderWidth: '0 !important',

      '&::placeholder': {
        color: '#000000',
        opacity: 0.6
      }
    }
  },

  countryFilterField: {
    border: '1px solid #CCCCCC',
    borderRadius: 2,
    padding: '0 8px'
  }
});

const CountriesMenu: React.FC<IProps> = React.memo(({ isOpen, closeMenu, hasDDI = false, title, anchor, onSelectItem }) => {
  const [country] = useObservable(() => internationalService.getSelectedCountry(), []);
  const options = useMemo(() => internationalService.getCountries(), []);
  const [search, setSearch] = useState('');
  const ref = anchor;

  const classes = useStyles({});

  const handleCloseMenu = useCallback(() => {
    closeMenu();
    setSearch('');
  }, [closeMenu]);

  const selectOption = useCallback(
    (option: ICountry) => {
      onSelectItem(option);
      handleCloseMenu();
    },
    [handleCloseMenu, onSelectItem]
  );

  const filterCountries = useCallback(
    (option: ICountry) => {
      if (!option) {
        return true;
      }

      const { name, nativeName } = option;
      const term = search.toLowerCase();

      return name.toLowerCase().includes(term.toLowerCase()) || nativeName.toLocaleLowerCase().includes(term.toLowerCase());
    },
    [search]
  );

  if (!country) {
    return null;
  }

  return (
    <Menu
      id="lock-menu"
      anchorEl={ref.current}
      open={isOpen}
      onClose={handleCloseMenu}
      getContentAnchorEl={null}
      anchorOrigin={{ vertical: 36, horizontal: 'center' }}
      transformOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      <div className={classes.countriesContainer}>
        <MenuHeader title={title} closeMenu={closeMenu} />

        <div className={`${classes.countryMenuList} `}>
          <FieldText
            id={'filter-country-field'}
            label={null}
            placeholder={'Buscar país'}
            value={search}
            icon={<SvgIcon name="search" />}
            InputLabelProps={{
              className: 'theme-label',
              id: 'label-filter-country-field'
            }}
            onChange={(value) => setSearch(value)}
            onKeyDown={(e) => e.stopPropagation()}
          />
        </div>
        <div className={classes.list}>
          <CountriesList country={country} options={options} filterCountries={filterCountries} selectOption={selectOption} hasDDI={hasDDI} />
        </div>
      </div>
    </Menu>
  );
});

export { CountriesMenu };
