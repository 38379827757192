import React from 'react';
import Paper from '@material-ui/core/Paper/Paper';

export default function SuggestionsContainer(props: any) {
  const { containerProps, children } = props;

  return (
    <Paper {...containerProps} square className={`${containerProps.className} autocomplete-container`}>
      {children}
    </Paper>
  );
}
