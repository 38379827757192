import React from 'react';
import { BaseComponent, IStateBase } from 'components/BaseComponent';
import { getInternetStatus } from '../shared/services/api';

interface IState extends IStateBase {
  online: boolean;
}

export default class InternetStatus extends BaseComponent<IState> {
  constructor(props: any) {
    super(props);

    this.state = {
      online: true
    };
  }

  componentDidMount() {
    getInternetStatus().subscribe((online) => {
      this.setState({ online });
    });
  }

  render() {
    if (this.state.online) {
      return null;
    }

    return <div className="offline-alert">Você está sem internet, verifique sua conexão</div>;
  }
}
