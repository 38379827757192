import React from 'react';
import { BaseComponent, IStateBase } from 'components/BaseComponent';
import { ticketService } from 'services/TicketService';
import Grid from '@material-ui/core/Grid/Grid';
import { SvgIcon } from 'components/SvgIcon';
import { WithStyles } from 'shared/decorators/withStyles';
import { Translate } from 'components/Translate';

interface IState extends IStateBase {
  time: number;
  progress: number;
  minutes: any;
  seconds: any;
  isTimeOver: boolean;
}

@WithStyles(() => ({
  component: {
    '@media screen and (max-width: 600px)': {
      '& .alert': {
        fontSize: '0.675rem',
        textAlign: 'center',
        position: 'relative',
        top: '2px'
      }
    },
    '& .time': {
      fontSize: '1.325rem',
      color: '#2E2E2E',
      width: '100%',
      padding: '10px 0',

      '@media screen and (max-width: 600px)': {
        fontSize: '0.665rem',
        textAlign: 'center',
        '& .alert': {
          fontSize: '0.675rem',
          textAlign: 'center',
          position: 'relative',
          top: '2.5px'
        }
      },

      '& svg': {
        height: '20px',
        width: '20px',
        marginRight: '5px'
      }
    },

    '& .progressBar': {
      height: '10px',
      backgroundColor: '#ccc',

      '& .filledBar': {
        height: '10px',
        backgroundColor: '#F66401'
      }
    },

    '& .timeOver': {
      fontSize: '0.675rem',
      color: 'red'
    }
  }
}))
export class TicketTimer extends BaseComponent<IState, {}> {
  constructor(props: any) {
    super(props);
    this.state = { time: 0, progress: 0, minutes: '', seconds: '', isTimeOver: false };
  }

  componentDidMount() {
    ticketService
      .getIsOver()
      .bindComponent(this)
      .subscribe((isTimeOver) => this.setState({ isTimeOver }));

    ticketService
      .calculateTime()
      .distinctUntilChanged()
      .combineLatest(ticketService.getReserveTime())
      .bindComponent(this)
      .subscribe(([time, reserveTime]) => {
        let minutes = ('0' + Math.floor(time / 60)).slice(-2);
        let seconds = ('0' + ((time / 60 - Math.floor(time / 60)) * 60).toFixed(0)).slice(-2);
        let progress = 100 - (time * 100) / (reserveTime * 60);
        this.setState({ time, progress, minutes, seconds });
      });
  }

  render() {
    const { isTimeOver } = this.state;

    return (
      <div className={this.props.classes.component}>
        <Grid container alignItems={'flex-end'}>
          <Grid item xs={5} sm={12} className="alert-container">
            <span id="booking_label" className="alert">
              <Translate term="Sua reserva expira em:" />
            </span>
          </Grid>
          <Grid item xs={7} sm={12}>
            <div id="booking_time" className="time theme-icon">
              <SvgIcon name="timer_icon" />
              {isTimeOver ? '00' : this.state.minutes} : {isTimeOver ? '00' : this.state.seconds}
            </div>
            <div className="progressBar">
              <div id="booking_countdown_bar" className="filledBar theme-button" style={{ width: `${100 - this.state.progress}%` }} />
            </div>
          </Grid>
          {isTimeOver ? (
            <Grid item xs={12}>
              <span className="timeOver">Sua reserva expirou, recarregue a página para continuar</span>
            </Grid>
          ) : null}
        </Grid>
      </div>
    );
  }
}
