import React from 'react';
import Translate from 'components/Translate';
import TextField from '@material-ui/core/TextField/TextField';

export default function Input(props: any) {
  const { classes, ref, endAdornment, errorMessage, disableUnderline, ...other } = props;

  return (
    <TextField
      fullWidth
      {...{
        fullWidth: true,
        margin: 'normal',
        ...other,
        error: !!errorMessage,
        helperText: <Translate term={errorMessage} />,
        FormHelperTextProps: {
          classes: {
            root: 'field-error',
            error: 'error',
            disabled: 'disabled'
          }
        },
        submitted: null,
        touched: null
      }}
      InputProps={{
        disableUnderline,
        inputRef: ref,
        classes: {
          input: classes.input
        },
        endAdornment
      }}
    />
  );
}
