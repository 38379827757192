import { enParameters } from 'enums/enParameters';
import { ICartModel } from 'interfaces/ICartModel';
import { FC, useEffect } from 'react';
import { useObservable } from 'react-use-observable';
import { Observable } from 'rxjs/Observable';
import { paymentService } from 'services/PaymentService';
import { IConfigBackRedirect } from '../../interfaces/IProductData';
import { sanitizeParameters } from './utils';

type Props = Partial<IConfigBackRedirect>;

const BackRedirect: FC<Props> = ({ active, url }) => {
  const [cart] = useObservable(() => paymentService.getCart(), []);

  function handleParameters(data: ICartModel) {
    const cityKey = data.selectedCountry === 'BRA' ? 'city' : 'cityName';
    const samePersonValue = data?.samePerson && String(data?.samePerson);

    return sanitizeParameters({
      [enParameters.country]: data?.selectedCountry,
      [enParameters.doc]: data?.document,
      [enParameters.email]: data?.email,
      [enParameters.name]: data?.name,
      [enParameters.phone]: data?.phone,
      [enParameters.studentDocument]: data?.studdent?.document,
      [enParameters.studentEmail]: data?.studdent?.email,
      [enParameters.studentName]: data?.studdent?.name,
      [enParameters.studentPhone]: data?.studdent?.phone,
      [enParameters.samePerson]: samePersonValue,
      [enParameters.city]: data?.address?.[cityKey],
      [enParameters.comp]: data?.address?.complement,
      [enParameters.location]: data?.address?.country,
      [enParameters.district]: data?.address?.district,
      [enParameters.num]: data?.address?.number,
      [enParameters.state]: data?.address?.province,
      [enParameters.street]: data?.address?.street,
      [enParameters.zip]: data?.address?.postalCode
    });
  }

  useEffect(() => {
    if (!active || !url) return () => {};

    window.history.pushState(null, '');

    const redirect = Observable.fromEvent(window, 'popstate').subscribe(() => {
      const parameters = handleParameters(cart);
      const searchParams = new URLSearchParams(parameters).toString();
      window.location.href = searchParams ? `/${url}&${searchParams}` : `/${url}`;
    });

    return () => {
      redirect.unsubscribe();
    };
  }, [active, cart, url]);

  return null;
};

export default BackRedirect;
