export class CookieService {
  public getCookie(name: string) {
    const cookies = `; ${document.cookie}`;
    const cookieParts = cookies.split(`; ${name}=`);

    if (cookieParts.length === 2) {
      return cookieParts.pop()?.split(';').shift();
    }

    return null;
  }

  public setCookie(name: string, value: string, daysExpire: number) {
    const ONE_DAY_MILLISECONDS = 86400000;
    const days = daysExpire || 1;
    const expirationDate = new Date();

    expirationDate.setTime(+expirationDate + days * ONE_DAY_MILLISECONDS);

    document.cookie = `${name}=${value}; expires=${expirationDate.toUTCString()}`;
  }
}

export const cookieService = new CookieService();
