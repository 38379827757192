import * as React from 'react';
import Grid from '@material-ui/core/Grid';

import { paymentService } from 'services/PaymentService';
import { BaseComponent, IStateBase } from 'components/BaseComponent';
import { cartService } from 'services/CartService';
import FieldText from 'components/Field/Text';
import { IS_ECOMMERCE } from 'settings';
import Fab from '@material-ui/core/Fab';
import RemoveIcon from '@material-ui/icons/Remove';
import AddIcon from '@material-ui/icons/Add';
import Translate from 'components/Translate';
import { WithStyles } from 'shared/decorators/withStyles';
import { IProductData } from 'interfaces/IProductData';

interface IState extends IStateBase {
  cart: IProductData;
  quantity: number;
  limit: number;
  hideQuantity: boolean;
}

interface IProps {
  isPin?: boolean;
  isTicket?: boolean;
  hideLabel?: boolean;
}

@WithStyles(() => ({
  component: {
    display: 'hidden',

    '& .quantityTicket': {
      height: '12px',
      textAlign: 'end',
      width: '85px',

      color: 'blue'
    },

    '& .quantity-content': {
      margin: '10px 0',

      '& .quantity-form': {
        width: '120px',
        margin: '5px 0',
        border: '1px solid #d9d9d9',
        padding: '0 5px',
        borderRadius: '2px'
      }
    },

    '& .quantity-form__ticket': {
      '& .field.normal': {
        margin: 0,
        padding: 0,
        width: '50px'
      },

      '& .field.normal input': {
        color: '#596375',
        padding: 0,
        margin: 0,
        width: '50px',
        boxShadow: 'none',
        border: 'none',
        textAlign: 'center'
      },

      '& .quantityTicket': {
        height: '50px'
      },

      '& .fab__ticket': {
        backgroundColor: 'transparent',
        boxShadow: 'none',
        width: '25px',
        height: '25px',
        border: 'none',
        color: '#596375',

        '&:disabled': {
          borderColor: '#ccc',
          color: '#ccc',

          '& svg': {
            borderColor: '#ccc'
          }
        },

        '& svg': {
          fontSize: '1rem',
          width: '20px',
          height: '20px',
          border: '1px solid #596375',
          borderRadius: '100%'
        }
      }
    }
  }
}))
export default class QuantityForm extends BaseComponent<IState, IProps> {
  componentDidMount() {
    this.observeState({ cart: null }, cartService.getCart());

    cartService
      .getCart()
      .combineLatest(paymentService.getProduct(0))
      .bindComponent(this)
      .subscribe(([cart, product]) => {
        if (IS_ECOMMERCE) {
          return;
        }

        if (!product) {
          return;
        }

        if (this.props.isPin) {
          this.setState({
            limit: cart.pins.purchaseLimit,
            hideQuantity: cart.pins.hideQuantity,
            quantity: product.quantity
          });
          return;
        }

        this.setState({
          limit: cart.config.purchaseLimit,
          hideQuantity: cart.config.hideQuantity,
          quantity: product.quantity
        });
      });
  }

  handleChange = (quantity: number) => {
    if (quantity == this.state.quantity) {
      return;
    }

    if (quantity > this.state.limit) {
      quantity = this.state.limit;
    }

    const minimum = this.state.cart.config.purchaseMinimumLimit || 1;

    if (quantity < minimum) {
      quantity = minimum;
    }

    paymentService.setProductData(0, { quantity: Number(quantity) });
  };

  decrement = () => {
    const minimum = this.state.cart.config.purchaseMinimumLimit || 1;

    if (this.state.quantity === minimum) {
      return;
    }

    if (this.state.quantity <= minimum) {
      paymentService.setProductData(0, { quantity: minimum });
      return;
    }

    paymentService.setProductData(0, { quantity: this.state.quantity - 1 });
  };

  increment = () => {
    if (this.state.quantity >= this.state.limit) {
      paymentService.setProductData(0, { quantity: this.state.limit });
      return;
    }

    paymentService.setProductData(0, { quantity: this.state.quantity + 1 });
  };

  render() {
    if (!this.state) {
      return null;
    }

    const { quantity, limit, hideQuantity, cart } = this.state;
    const minimum = cart.config.purchaseMinimumLimit || 1;

    return (
      <React.Fragment>
        <div className={this.props.classes.component}>
          <Grid id="ticket_price_unique" className="item right" item xs={4}>
            {!hideQuantity && (
              <div className="quantity-content">
                <div className="quantity-label theme-label">
                  <Translate term="Quantidade" />
                </div>
                <div className="quantity-form theme-input">
                  <Grid className="quantity-form__ticket" direction={'row'} alignItems={'center'} justify={'flex-start'} wrap={'nowrap'} container>
                    <Fab className="fab__ticket" disabled={quantity === minimum} onClick={this.decrement} size="small">
                      <RemoveIcon />
                    </Fab>
                    <FieldText
                      id="quantity"
                      label={''}
                      validation={`required|numeric|min:1${limit > 0 ? `|max:${limit}` : ''}`}
                      disabled={true}
                      value={quantity}
                      inputProps={{
                        className: 'quantityTicket quantity'
                      }}
                      InputLabelProps={{
                        className: 'theme-label',
                        hidden: hideQuantity
                      }}
                      onChange={this.handleChange}
                    />
                    <Fab className="fab__ticket" disabled={quantity >= limit} onClick={this.increment} size="small">
                      <AddIcon />
                    </Fab>
                  </Grid>
                </div>
              </div>
            )}
          </Grid>
        </div>
      </React.Fragment>
    );
  }
}
