import React, { useState, useEffect } from 'react';
import { paymentWaiter } from 'services/PaymentWaiterService';
import { useObservable } from 'react-use-observable';
import * as qrcode from 'qrcode';

const Component: React.FC = () => {
  const [qrcodeUrl, setQrCodeUrl] = useState('');
  const [waiter] = useObservable(() => paymentWaiter.getWaiter(), []);

  useEffect(() => {
    if (!waiter || !waiter?.waiting) {
      return;
    }

    const url = waiter.result.url;

    qrcode.toDataURL(url, { margin: 0, width: 170 }).then((encoded) => {
      setQrCodeUrl(encoded);
    });
  }, [waiter?.waiting, waiter]);

  return (
    <div className="chk-pix-qr-code-container">
      <img id="qrcode-pix" width="170" src={qrcodeUrl} alt="pix" />
    </div>
  );
};

export const QRCode = React.memo(Component);
